import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ArtikelFilter'
})
export class ArtikelFilterPipe implements PipeTransform {

  transform(products: any[], search: string = ''): any[] {
    if (!search.trim()) {
      return products;
    }

    return products.filter(article => {
      return article.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          article.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

}
