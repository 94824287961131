export enum ExecutionActionTypes {
    SETUP = 'setup',
    PROCESSING = 'processing',
    TEARDOWN = 'teardown',
    PAUSE = 'pause',
    COMPLETE = 'complete',
    BOOKING = 'booking',
    OFFSHIFT = 'offshift',
    MAINTENANCE = 'maintenance',
    MALFUNCTION = 'malfunction'
}
