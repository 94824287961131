import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-message-electricity-costs',
  templateUrl: './message-electricity-costs.component.html',
  styleUrls: ['./message-electricity-costs.component.scss']
})
export class MessageElectricityCostsComponent implements OnInit {

  public header: string;
  public content: string;

  constructor(public DialogRef: MatDialogRef<MessageElectricityCostsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data.header) {
      this.header = data.header;
    }

    if (data.content) {
      this.content = data.content;
    }

  }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

}
