import {Component} from '@angular/core';
import {ProcessTypes} from '../../../Enums/process-types.enum';
import {ProcessComponent} from '../base-process/process.component';


@Component({
  selector: 'app-mss-process',
  templateUrl: './mss-process.component.html',
  styleUrls: ['../base-process/process.component.scss']
})
export class MssProcessComponent extends ProcessComponent {
  declare index: number;
  public mssProducts: any;

  public getOutputProductListTop10() {
    this.Apiservice.getProducts('mss').subscribe((mssProducts: any) => {
      if (mssProducts) {
        this.mssProducts = mssProducts;
      }
    });
  }

  public getMssProductsToShow(): any[] {
    if (this.mssProducts) {
      const result = [];
      for (const el of this.mssProducts) {
        const sel = this.selectedOutputProducts.find(ex => ex.id === el.id);
        if (!sel) {
          result.push(el);
        }
      }

      return result;
    } else {
      return [];
    }
  }

  public get Index() {
    return this.index;
  }


  public set Index(value: number) {
    this.index = value;

    if (this.index === 1) {
      this.getRessourceTypes();
    } else if (this.index === 2) {

    } else if (this.index === 3) {
      if (!this.dataService.ProcessToEdit && !this.goBackToMetaData) {

        if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
          this.treatmentprocess.name = 'Instandhaltung';
        }
        else {
          this.treatmentprocess.name = this.typChosen;
        }

      }
    } else if (this.index === 4) {

    }
  }

  go(num: number) {
    if (this.Index === 3) {
      this.goBackToMetaData = true;
    }
    this.Index = this.Index + num;
  }

  public confirmProcessType() {
      this.Index = 1;
  }

  public confirmRessources() {
    this.treatmentprocess.resource_types = [];
    for (const rec of this.selectedRessources) {
      rec.notvalid = false;
      this.treatmentprocess.resource_types.push({
        id: rec.id,
        quantity: rec.quantity
      });
    }
    this.typChosen === ProcessTypes.INSTANDHALTUNG ? this.Index = 3 : this.Index = 2;
  }

  public confirmOutputProducts() {
    if (this.selectedOutputProducts.length > 0) {
      this.treatmentprocess.output = [];

      for (const pr of this.selectedOutputProducts) {
        this.treatmentprocess.output.push(pr.id);
      }
      this.Index = 3;
    }
  }

  public confirmMetaData() {
    this.Index = 4;
    this.goBackToMetaData = false;
  }

  public goBackToProcessTypeChoice() {
    this.Index = 0;
  }

  public goBackToRessourceChoice() {
    this.goBackToMetaData = true;
    this.Index = 1;
  }

  public backToMetaData() {
    this.goBackToMetaData = true;
    this.Index = 3;
  }


}
