import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../Dialogs/messageb/messagebox.component';
import * as moment from 'moment/moment';
import {DataShareService} from './data-share.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class GlobalFunctionsService {
    constructor(public dialog: MatDialog, private dataService: DataShareService, private router: Router) { }


    public durationToString(totalSeconds: unknown): string {
        // @ts-ignore
        const hours = Math.floor(totalSeconds / 3600);
        // @ts-ignore
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        // @ts-ignore
        const seconds = Math.floor(totalSeconds % 60);
        let result = `${hours.toString()}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        if (!!hours) {
            result = `${hours.toString()}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
        return result;
    }

    public handleAPIsaveError(error: any, object: string) {
        if (error) {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = error.name;
            this.dialog.open(MessageboxComponent, dialogConfig1);
        } else {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = `${object} konnte nicht gespeichert werden`;
            this.dialog.open(MessageboxComponent, dialogConfig1);
        }
    }

    public handleAPIgetError(error: any, object: string) {
        if (error) {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = error.name;
            this.dialog.open(MessageboxComponent, dialogConfig1);
        } else {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = `${object} konnte nicht geladen werden`;
            this.dialog.open(MessageboxComponent, dialogConfig1);
        }
    }

    public findEndDate(endOfFoundDay, current: moment.Moment, minutes: number, hours: number, endDate: moment.Moment) {
        const delta = endOfFoundDay.clone().diff(current, 'seconds');
        const requiredSeconds = (minutes * 60) + (hours * (60 * 60));
        const remaining = requiredSeconds - delta;
        const remHours = remaining / (60 * 60);

        let leftHours = Math.trunc(remHours);
        let lefMinutes = Math.trunc((remHours - leftHours) * 60);
        if (lefMinutes > 0 && lefMinutes <= 30) {
            lefMinutes = 30;
        } else if (lefMinutes > 30) {
            leftHours = leftHours + 1;
            lefMinutes = 0;
        }
        hours = leftHours;
        minutes = lefMinutes;

        current = current.clone().add(1, 'days');
        while (current.day() > 5 || current.day() < 1) {
            current = current.clone().add(1, 'days');
        }

        const remainingLeadTime = hours + (minutes / 60);

        current = moment(current.clone().format('yyyy-MM-DD') + ' ' + '07:00:00');

        if (current.day() === 5) {
            endOfFoundDay = moment(current.clone().format('yyyy-MM-DD') + ' ' + '11:30:00');
        } else {
            endOfFoundDay = moment(current.format('yyyy-MM-DD') + ' ' + '16:00:00');
        }
        const currentD = new Date(current.format('yyyy-MM-DD HH:mm:ss'));
        endDate = moment(new Date(currentD)).add(remainingLeadTime, 'hour').clone();
        return {endOfFoundDay, current, minutes, hours, endDate};
    }


    public getOutputSubstitution(batch: any, mat: string) {

        if (mat) {
            if (batch.transfer) {
                if (batch.transfer.substitution) {
                    if (batch.transfer.substitution[mat]) {
                        if (batch.transfer.substitution[mat] !== 'none') {
                            return ' (' + batch.transfer.substitution[mat] + ')';
                        }
                    }
                }
            }
        }

        return '';
    }

    public handleServerNotReachableError() {
        if (!this.dataService.serverErrorFlag.value) {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = 'ARP service ist nicht ereichbar';
            const messageBoxError = this.dialog.open(MessageboxComponent, dialogConfig1);
            this.dataService.changeserverErrFlag();
            messageBoxError.afterClosed().subscribe(() => {
                this.dataService.changeserverErrFlag();
                this.router.navigate(['/']).catch(err => console.log(err));
            });

        }
    }
}


