<div class="inventarWrapper">
    <h1 class="tableheader">Typen</h1>

    <div class="tablesWrapper" *ngIf="userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.view)">

        <div class="invCol" fxLayout="column" fxFlexAlign="stretch">
            <div class="columnHeader">
                <h2>
                    Maschinen
                    <mat-icon matPrefix class="icon" *ngIf="userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.edit)" (click)="addResourceTypMachine()">add</mat-icon>
                </h2>

            </div>
            <mat-form-field>

                <input matInput type="search" placeholder="Suchtext" [(ngModel)]="searchString">
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
                <div class="Card machine" *ngFor="let machine of machines| RestypeworkerFilter:searchString"
                     (click)="choose(machine)">
                    <div fxLayout="column">
                        <div fxLayout="row" fxFlexAlign="stretch">
                            <p class="listelement">{{machine.name}}</p>
                            <img *ngIf="machine.bluectrl_sync" class="bluectrlLogoSmall" src="assets/icons/logo_bluectrl_colour.svg">
                        </div>
                        <p class="listelement">Maschine</p>

                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
            <div class="paginator"
                 [ngClass]="{'paginatorBoth': skipMachine && showNextMachine,  'paginatorRight': !skipMachine}">
                <div class="previous" (click)="paginateLeftMachine()" *ngIf="skipMachine">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div class="next" (click)="paginateRightMachine()" *ngIf="showNextMachine">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="invColRight" fxLayout="column" fxFlexAlign="stretch">
            <div class="columnHeader">
                <h2>
                    Mitarbeiter
                    <mat-icon matPrefix class="icon"*ngIf="userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.edit)" (click)="addResourceTypWorker()">add</mat-icon>
                </h2>

            </div>

            <mat-form-field>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput type="search" placeholder="Suchtext" [(ngModel)]="searchString2">
            </mat-form-field>

            <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
                <div class="Card worker" *ngFor="let worker of workers | RestypeworkerFilter:searchString2"
                     (click)="choose(worker)">
                    <div fxLayout="column">
                        <p class="listelement">{{worker.name}}</p>
                        <p class="listelement">Mitarbeiter</p>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
            <div class="paginator"
                 [ngClass]="{'paginatorBoth': skipWorker && showNextWorker,  'paginatorRight': !skipWorker}">
                <div class="previous" (click)="paginateLeftWorker()" *ngIf="skipWorker">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div class="next" (click)="paginateRightWorker()" *ngIf="showNextWorker">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>


        <div class="newRes" *ngIf="flagResource && userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.edit)">
            <div class="columnHeader">
                <h2 class="newResource">Neuer Ressourcentyp</h2>
                <button (click)="cancelNew()">
                    <mat-icon class="newButton" matPrefix>cancel</mat-icon>
                </button>
            </div>

            <hr style="width: 290px; float: left">

            <div class="formInput">
                <div class="inputsWrap">
                    <p>Name:</p>
                    <p>Gruppe:</p>

                    <p>Standardmenge:</p>
                    <p>Schrittweite:</p>

                    <p *ngIf="group.value === 'human'">Kosten [€/h]:</p>
                    <p *ngIf="group.value !== 'human'">Kosten [€/t]:</p>
                    <p *ngIf="group.value !== 'human'">Verbrauch [kW]:</p>

                </div>
                <div [formGroup]="resourceTypeForm" fxLayout="column" style="margin-left: 10px">
                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input required id="name" formControlName="name" matInput>
                        </mat-form-field>

                        <div *ngIf="name.invalid && (name.dirty || name.touched)"
                             class="alert alert-danger">

                            <div *ngIf="name.errors?.required">
                                Name wird benötigt.
                            </div>
                            <div *ngIf="name.errors?.minlength">
                                Der Name muss mindestens 3 Zeichen haben.
                            </div>
                        </div>
                    </div>

                    <mat-form-field style="width: 180px">
                        <input required matInput value="{{getCurrentResourceType()}}" disabled>
                    </mat-form-field>

                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input id="defaultQuantity" formControlName="defaultQuantity" required type="number" min="0.1"
                                   max="5" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="defaultQuantity.invalid && (defaultQuantity.dirty || defaultQuantity.touched)"
                             class="alert alert-danger">

                            <div *ngIf="defaultQuantity.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="defaultQuantity.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="defaultQuantity.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input id="stepsize" formControlName="stepsize" required type="number" min="0.1" max="5" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="stepsize.invalid && (stepsize.dirty || stepsize.touched)"
                             class="alert alert-danger">

                            <div *ngIf="stepsize.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="stepsize.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="stepsize.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="group.value === 'human'">
                        <mat-form-field>
                            <input id="costperhour" formControlName="costperhour" type="number" min="0" max="5000" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="costperhour.invalid && (costperhour.dirty || costperhour.touched)"
                             class="alert alert-danger">

                            <div *ngIf="costperhour.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="costperhour.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="costperhour.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="group.value !== 'human'">
                        <mat-form-field>
                            <input id="costperton" formControlName="costperton" type="number" min="0" max="5000" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="costperton.invalid && (costperton.dirty || costperton.touched)"
                             class="alert alert-danger">

                            <div *ngIf="costperton.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="costperton.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="costperton.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="group.value !== 'human'">
                        <mat-form-field>
                            <input id="energyConsumption" formControlName="energyConsumption" type="number" min="0" max="2000" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="energyConsumption.invalid && (energyConsumption.dirty || energyConsumption.touched)"
                             class="alert alert-danger">

                            <div *ngIf="energyConsumption.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="energyConsumption.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="energyConsumption.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <mat-icon class="buttonSave icon" (click)="saveResource()" matPrefix>save</mat-icon>
                </div>

            </div>
        </div>

        <div fxLayout="column" class="newRes" *ngIf="flagEditResource && userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.detail)">
            <div class="ResourceChoiceHeader">
                <h2 class="newResource">Ressourcentyp</h2>
                <button *ngIf="!editFlag && userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.edit)" (click)="edit()">
                    <mat-icon class="newButton" matPrefix>edit</mat-icon>
                </button>
                <button *ngIf="!editFlag && !resourceToEdit.bluectrl_sync && userAccess.hasAccessRightsEn(accesAreas.resource_type, accessTyps.delete)" (click)="delete()">
                    <mat-icon class="newButton" matPrefix>delete</mat-icon>
                </button>
                <button *ngIf="editFlag" (click)="cancelEdit()">
                    <mat-icon class="newButton" matPrefix>cancel</mat-icon>
                </button>
            </div>
            <hr>
            <div class="formInput" *ngIf="editFlag; else elseBlock">

                <div class="inputsWrap">
                    <p>Name:</p>
                    <p>Gruppe:</p>

                    <p>Standardmenge:</p>
                    <p>Schrittweite:</p>
                    <p *ngIf="group.value === 'human'">Kosten [€/h]:</p>
                    <p *ngIf="group.value !== 'human'">Kosten [€/t]:</p>
                    <p *ngIf="group.value !== 'human'">Verbrauch [kW]:</p>
                </div>
                <div [formGroup]="resourceTypeForm" fxLayout="column" style="margin-left: 10px">
                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input required  formControlName="name" matInput [readonly]="resourceToEdit.bluectrl_sync">
                        </mat-form-field>
                        <div *ngIf="name.invalid && (name.dirty || name.touched)"
                             class="alert alert-danger">
                            <div *ngIf="name.errors?.required">
                                Name wird benötigt.
                            </div>
                            <div *ngIf="name.errors?.minlength">
                                Der Name muss mindestens 3 Zeichen haben.
                            </div>
                        </div>
                    </div>
                    <mat-form-field>
                        <input value="{{getCurrentResourceType()}}" disabled matInput>
                    </mat-form-field>
                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input id="defaultQuantity2" formControlName="defaultQuantity" required type="number" min="0.1"
                                   step="0.1" matInput [readonly]="resourceToEdit.bluectrl_sync">
                        </mat-form-field>

                        <div *ngIf="defaultQuantity.invalid && (defaultQuantity.dirty || defaultQuantity.touched)"
                             class="alert alert-danger">

                            <div *ngIf="defaultQuantity.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="defaultQuantity.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="defaultQuantity.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper">
                        <mat-form-field>
                            <input id="stepsize2" formControlName="stepsize" required type="number" min="0.1" step="0.1" matInput [readonly]="resourceToEdit.bluectrl_sync">
                        </mat-form-field>

                        <div *ngIf="stepsize.invalid && (stepsize.dirty || stepsize.touched)"
                             class="alert alert-danger">

                            <div *ngIf="stepsize.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="stepsize.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="stepsize.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="resourceToEdit.group === 'human'">
                        <mat-form-field>
                            <input id="costperhour2" formControlName="costperhour" type="number" min="0" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="costperhour.invalid && (costperhour.dirty || costperhour.touched)"
                             class="alert alert-danger">

                            <div *ngIf="costperhour.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="costperhour.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="costperhour.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="resourceToEdit.group !== 'human'">
                        <mat-form-field>
                            <input formControlName="costperton" type="number" min="0" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="costperton.invalid && (costperton.dirty || costperton.touched)"
                             class="alert alert-danger">

                            <div *ngIf="costperton.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="costperton.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="costperton.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="errorWrapper" *ngIf="resourceToEdit.group !== 'human'">
                        <mat-form-field>
                            <input formControlName="energyConsumption" type="number" min="0" step="0.1" matInput>
                        </mat-form-field>

                        <div *ngIf="energyConsumption.invalid && (energyConsumption.dirty || energyConsumption.touched)"
                             class="alert alert-danger">

                            <div *ngIf="energyConsumption.errors?.required">
                                Wert wird benötigt.
                            </div>
                            <div *ngIf="energyConsumption.errors?.min">
                                Wert zu klein.
                            </div>
                            <div *ngIf="energyConsumption.errors?.max">
                                Wert zu groß.
                            </div>
                        </div>
                    </div>

                    <img *ngIf="resourceToEdit && resourceToEdit.bluectrl_sync" class="bluectrlLogo" src="assets/icons/logo_bluectrl_colour.svg">

                    <mat-icon class="buttonSave icon" (click)="updateResource()" matPrefix *ngIf="editFlag">save</mat-icon>
                </div>
            </div>

            <ng-template #elseBlock>
                <div class="formInput" >
                    <div class="inputsWrap">
                        <p>Name:</p>
                        <p>Gruppe:</p>
                        <p>Standardmenge:</p>
                        <p>Schrittweite:</p>
                        <p *ngIf="group.value === 'human'">Kosten [€/h]:</p>
                        <p *ngIf="group.value !== 'human'">Kosten [€/t]:</p>
                        <p *ngIf="group.value !== 'human'">Verbrauch [kW]:</p>
                    </div>
                    <div fxLayout="column" style="margin-left: 10px">
                        <mat-form-field>
                            <input  [(ngModel)]="resourceToEdit.name" disabled matInput>
                        </mat-form-field>

                        <mat-form-field>
                            <input value="{{getCurrentResourceType()}}" disabled matInput>
                        </mat-form-field>


                        <mat-form-field>
                            <input  [ngModel]="resourceToEdit.default_quantity| number: '1.0-2':'de'" (ngModelChange)="resourceToEdit.default_quantity=$event" disabled matInput>
                        </mat-form-field>

                        <mat-form-field>
                            <input  [ngModel]="resourceToEdit.step_size| number: '1.0-2':'de'" (ngModelChange)="resourceToEdit.step_size=$event" disabled matInput>
                        </mat-form-field>

                        <div *ngIf="resourceToEdit.group === 'human'">
                            <mat-form-field>
                                <input  [ngModel]="resourceToEdit.cost_per_hour| number: '1.0-2':'de'" (ngModelChange)="resourceToEdit.cost_per_hour=$event" disabled matInput>
                            </mat-form-field>
                        </div>

                        <div *ngIf="resourceToEdit.group !== 'human'">
                            <mat-form-field>
                                <input  [ngModel]="resourceToEdit.cost_per_ton| number: '1.0-2':'de'" (ngModelChange)="resourceToEdit.cost_per_ton=$event" disabled matInput>
                            </mat-form-field>
                        </div>

                        <div *ngIf="resourceToEdit.group !== 'human'">
                            <mat-form-field>
                                <input  [ngModel]="resourceToEdit.power_consumption| number: '1.0-2':'de'" (ngModelChange)="resourceToEdit.power_consumption=$event" disabled matInput>
                            </mat-form-field>
                        </div>

                        <img *ngIf="resourceToEdit && resourceToEdit.bluectrl_sync" class="bluectrlLogo" src="assets/icons/logo_bluectrl_colour.svg">
                    </div>

                </div>

            </ng-template>

        </div>


    </div>
</div>
