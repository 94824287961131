
    <mat-dialog-content class="toAlignIcons">
        <p class="alignLeft">EUI: <strong>{{lastScaleWeighing.eui}}</strong></p>
        <p class="alignLeft">Distanz: <strong>{{lastScaleWeighing.distance}} m</strong></p>
        <p class="alignLeft">Batterie: <strong> {{batteryPercentage}} % ({{lastScaleWeighing.voltage}} V)</strong></p>
        <p class="alignLeft" *ngIf="selectedContainer"> Container:
            <strong *ngIf="selectedContainer.name.length > 0; else elseBlock">{{selectedContainer.name}} </strong>
            <ng-template #elseBlock> <strong> - </strong></ng-template>
        </p>

        <p class="alignLeft">Waage: <strong>{{lastScaleWeighing.scaleName}} </strong></p>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="okButton" fxFlexAlign="flex-start" (click)="Confirm()">Ok</button>
    </mat-dialog-actions>

