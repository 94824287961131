<div class="executionWrap">
    <div fxLayout="row" *ngIf="executionBatch && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.view)">
        <div class="batchHead" fxLayout="column">
            <div class="infoElement" fxLayout="row">
                <p class="nameElement">Charge</p>
                <p class="valueElement">{{executionBatch.name}}</p>
            </div>
            <div class="infoElement" fxLayout="row">
                <p class="nameElement">Prozess</p>
                <p class="valueElement">{{executionBatch.process.name}} </p>
            </div>
            <div class="infoElement" fxLayout="row">
                <p class="nameElement">Kommentar</p>
                <p class="valueElement">{{commentText}}</p>
            </div>
        </div>
        <ng-container *ngIf="executionBatch.process.process_category !== 'maintenance'">
            <div class="batchHead" fxLayout="column">
                <div class="infoElement" fxLayout="row">
                    <p class="nameElement">Input</p>
                    <ng-container *ngIf="tenant === typeEnvironmentVsa; else mssInput">
                        <p class="valueElement">{{executionBatch.input.id}} {{executionBatch.input.name}}</p>
                    </ng-container>
                    <ng-template #mssInput>
                        <p class="valueElement">Aufgabematerial</p>
                    </ng-template>

                    <div fxLayout="row" *ngIf="executionBatch.transfer" style="margin-left: 5px">
                        <p class="valueElement"> (Charge: </p>
                        <p class="valueElement" style="margin-left: 5px"> {{executionBatch.transfer.erp_batch}})</p>
                    </div>
                </div>
                <div class="infoElement" fxLayout="row">
                    <p class="nameElement">Planmenge</p>
                    <p class="valueElement flex2">{{executionBatch.quantity| number:'1.2-2':'de'}} t</p>
                    <img *ngIf="executionBatch.transfer" alt="ksa" class="ksalogo" src="../../../../assets/images/ksa.png">

                </div>
            </div>
        </ng-container>

    </div>
    <app-execution-screen-vsa style="flex-grow: 2;" [exBatch]="executionBatch"  (newExBatch)="updateExecutionBatchFromChildComponent($event)" *ngIf="tenant === typeEnvironmentVsa; else ssTenant"></app-execution-screen-vsa>
    <ng-template #ssTenant>
        <app-execution-screen-mss style="flex-grow: 2;" [exBatch]="executionBatch" (newExBatch)="updateExecutionBatchFromChildComponent($event)" ></app-execution-screen-mss>
    </ng-template>
</div>

