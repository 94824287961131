
    <mat-dialog-content>
        <p><strong>{{txt}}</strong></p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="yesButton" (click)="Option1()">{{option1}}</button>
        <button class="noButton"  (click)="Option2()">{{option2}}</button>
        <button class="noButton"  (click)="Option3()">{{option3}}</button>
    </mat-dialog-actions>


