import { Pipe, PipeTransform } from '@angular/core';
import {Batch} from '../Models/Models';
import * as moment from 'moment';

@Pipe({
  name: 'batchFilter'
})
export class BatchFilterPipe implements PipeTransform {

  transform(batches: Batch[], search: string = ''): any[] {
    if (!search.trim()) {
      return batches;
    }


    return batches.filter(batch => {
      if (batch.input) {
        return batch.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            batch.input.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            batch.output.some(out => out.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            batch.resources.some(rt => rt.resource_type.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            batch.resources.some(rt => rt.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            batch.output.some(out => out.id.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            batch.input.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            (moment(batch.scheduled_start).format('dddd DD. MMMM yyyy HH:mm').toLowerCase().indexOf(search.toLowerCase()) !== -1);
      }
      else {
        return batch.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      }


    });
  }

}
