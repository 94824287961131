import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {DataShareService} from '../../Services/data-share.service';

@Component({
  selector: 'app-tare-selection',
  templateUrl: './container-selection.component.html',
  styleUrls: ['./container-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContainerSelectionComponent implements OnInit {

  messageID: string;
  public Container: any;
  public scaleType: string;

  constructor(private Apiservice: APIService, public DialogRef: MatDialogRef<ContainerSelectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string, public dataService: DataShareService) {
    if (data) {
      this.scaleType = data as string;
    }
  }




  ngOnInit(): void {
    this.receiveContainer();

  }

  public Confirm(container) {
    this.dataService.chooseContainer(container);
    this.DialogRef.close(true);
  }

  public Close() {
    this.dataService.chooseContainer(null);
    this.DialogRef.close(false);
  }


  receiveContainer() {
    this.messageID = '1';
    this.Apiservice.getAvailableContainers().subscribe((container: any) => {
      if (container) {
        if (container.length > 0) {
          this.Container = container.filter(ex => ex.scale_type === this.scaleType);
          this.Container = this.Container.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
        }
      }
    });


    // return this.Apiservice.getTara(this.messageID);
  }

}
