import {Injectable} from '@angular/core';

import {MsalService} from '@azure/msal-angular';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
    providedIn: 'root'
})
export class GetProfileService {

    private profileSubject = new BehaviorSubject(null);
    public profile$ = this.profileSubject.asObservable();

    constructor(private authService: MsalService, private http: HttpClient) {
    }

    getProfile() {
        console.log('call get profile');

        this.http.get(GRAPH_ENDPOINT).toPromise()
            .then(profile => {
                this.profileSubject.next(profile);
            });
    }
}

