<div class="inventarWrapper">
    <h1 class="tableheader">Inventar</h1>
    <div class="tablesWrapper" *ngIf="userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.view)">

        <div class="invCol" fxLayout="column" fxFlexAlign="stretch">
            <div class="columnHeader">
                <h2>Maschinen</h2>
                <mat-icon matPrefix class="icon" (click)="addRessourceMachine()" *ngIf="userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.edit) && resourceTypesMachine">add</mat-icon>
            </div>

            <mat-form-field>
                <input matInput type="search" placeholder="Suchtext" [(ngModel)]="SearchMachine">
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>


            <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
                <div class="Card machine" *ngFor="let machine of machines | WorkerFilter:SearchMachine"
                     (click)="choose(machine)">
                    <div fxLayout="row" fxFlexAlign="stretch">
                        <p class="listelement">{{machine.name}}</p>
                        <img *ngIf="machine.bluectrl_sync" class="bluectrlLogoSmall" src="assets/icons/logo_bluectrl_colour.svg">
                    </div>
                    {{machine.resource_type.name}} <br>
                    {{machine.serial}}
                </div>
            </cdk-virtual-scroll-viewport>
            <div class="paginator"
                 [ngClass]="{'paginatorBoth': skipMachine && showNextMachine,  'paginatorRight': !skipMachine}">
                <div class="previous" (click)="paginateLeftMachine()" *ngIf="skipMachine">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div class="next" (click)="paginateRightMachine()" *ngIf="showNextMachine">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="invColRight" fxLayout="column" fxFlexAlign="stretch">
            <div class="columnHeader">
                <h2>Mitarbeiter</h2>
                <mat-icon matPrefix class="icon" *ngIf="userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.edit) && resourceTypesWorker" (click)="addResourceWorker()">add</mat-icon>
            </div>

            <mat-form-field>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput type="search" placeholder="Suchtext" [(ngModel)]=SearchWorker>
            </mat-form-field>

            <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
                <div class="Card worker" *ngFor="let worker of workers | WorkerFilter: SearchWorker"
                     (click)="choose(worker)">
                    {{worker.name}} <br>
                    {{worker.resource_type.name}} <br>

                </div>
            </cdk-virtual-scroll-viewport>
            <div class="paginator"
                 [ngClass]="{'paginatorBoth': skipWorker && showNextWorker,  'paginatorRight': !skipWorker}">
                <div class="previous" (click)="paginateLeftWorker()" *ngIf="skipWorker">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div class="next" (click)="paginateRightWorker()" *ngIf="showNextWorker">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <div class="newRes" *ngIf="flagResource && userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.edit)" fxLayout="column">
            <div class="columnHeader">
                <h2 *ngIf="flagMaschine" class="newResource">Neue Maschine</h2>
                <h2 *ngIf="flagWork" class="newResource">Neue Mitarbeiter</h2>
                <button (click)="cancelNew()">
                    <mat-icon class="newButton" matPrefix>cancel</mat-icon>
                </button>
            </div>
            <hr>
            <div class="formInput">
                <div fxLayout="column" class="settingP">
                    <p >Name:</p>
                    <p >Typ:</p>
                    <p *ngIf="flagMaschine" class="workerSetting">SN:</p>
                    <p *ngIf="flagMaschine" class="workerSetting">Miete/Zinsen [€/h]:</p>
                    <p *ngIf="flagMaschine" class="workerSetting">AfA [€/h]:</p>

                </div>
                <div fxLayout="column" style="margin-left: 10px">
                    <mat-form-field>
                        <input matInput [(ngModel)]="resourceToEdit.name">
                    </mat-form-field>

                    <mat-form-field *ngIf="ressourceTypeListToSelect">
                        <mat-select *ngIf="flagMaschine" class="select" [(ngModel)]="resourceToEdit.resource_type.id">
                            <mat-option *ngFor="let ResType of ressourceTypeListToSelect"
                                        [value]="ResType.id">{{ResType.name}}</mat-option>
                        </mat-select>
                        <mat-select *ngIf="flagWork" class="select" [(ngModel)]="resourceToEdit.resource_type.id">
                            <mat-option *ngFor="let ResType of ressourceTypeListToSelect"
                                    [value]="ResType.id">{{ResType.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="flagMaschine">
                        <input  matInput [(ngModel)]="resourceToEdit.serial">
                    </mat-form-field>

                    <mat-form-field *ngIf="flagMaschine">
                        <input  type ="number" matInput [(ngModel)]="resourceToEdit.rent">
                    </mat-form-field>

                    <mat-form-field *ngIf="flagMaschine">
                        <input  type ="number" matInput [(ngModel)]="resourceToEdit.depreciation">
                    </mat-form-field>

                    <mat-icon class="buttonSave icon" (click)="saveResource()" matPrefix>save</mat-icon>
                </div>

            </div>
        </div>
        <div class="newRes" *ngIf="flagEditResource" fxLayout="column">
            <div class="ResourceChoiceHeader">
                <h2 class="newResource">Ressource</h2>
                <button *ngIf="!editFlag && userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.edit)" (click)="edit()">
                    <mat-icon class="newButton" matPrefix>edit</mat-icon>
                </button>
                <button *ngIf="!editFlag && !resourceToEdit.bluectrl_sync && userAccess.hasAccessRightsEn(accesAreas.resource, accessTyps.delete)" (click)="delete()">
                    <mat-icon class="newButton" matPrefix>delete</mat-icon>
                </button>
                <button *ngIf="editFlag" (click)="cancelEdit()">
                    <mat-icon class="newButton" matPrefix>cancel</mat-icon>
                </button>
            </div>
            <hr>
            <div class="formInput" *ngIf="editFlag; else elseBlock" >
                <div fxLayout="column" class="settingP">
                    <p>Name:</p>
                    <p>Typ:</p>
                    <p *ngIf="resourceToEdit.resource_type.group === 'machine'" class="workerSetting">SN:</p>
                    <p *ngIf="resourceToEdit.resource_type.group === 'machine'" class="workerSetting">Miete/Zinsen [€/h]:</p>
                    <p *ngIf="resourceToEdit.resource_type.group === 'machine'" class="workerSetting">AfA [€/h]:</p>
                </div>
                <div fxLayout="column" style="margin-left: 10px">
                    <mat-form-field>
                        <input matInput [(ngModel)]="resourceToEdit.name">
                    </mat-form-field>

                    <mat-form-field *ngIf="ressourceTypeListToSelect">
                        <mat-select *ngIf="resourceToEdit.resource_type.group === 'human'" class="select"
                                [(ngModel)]="resourceToEdit.resource_type.id">
                            <mat-option *ngFor="let ResType of ressourceTypeListToSelect"
                                    [value]="ResType.id">{{ResType.name}}</mat-option>
                        </mat-select>
                        <mat-select *ngIf="resourceToEdit.resource_type.group === 'machine'" class="select"
                                [(ngModel)]="resourceToEdit.resource_type.id" [disabled]="resourceToEdit.bluectrl_sync">
                            <mat-option *ngFor="let ResType of ressourceTypeListToSelect"
                                    [value]="ResType.id">{{ResType.name}}</mat-option>


                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                        <input matInput [(ngModel)]="resourceToEdit.serial"
                                [disabled]="resourceToEdit.bluectrl_sync">
                    </mat-form-field>
                    <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                        <input  type ="number" matInput [(ngModel)]="resourceToEdit.rent">
                    </mat-form-field>

                    <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                        <input  type ="number" matInput [(ngModel)]="resourceToEdit.depreciation">
                    </mat-form-field>

                    <img *ngIf="resourceToEdit && resourceToEdit.bluectrl_sync" class="bluectrlLogo" src="assets/icons/logo_bluectrl_colour.svg">

                    <mat-icon class="buttonSave icon" (click)="updateResource()" matPrefix *ngIf="editFlag">save
                    </mat-icon>
                </div>

            </div>
            <ng-template #elseBlock>
                <div class="formInput">
                    <div class="settingP2">
                        <p>Name:</p>
                        <p>Typ:</p>
                        <p *ngIf="resourceToEdit.resource_type.group === 'machine'">SN:</p>
                        <p *ngIf="resourceToEdit.resource_type.group === 'machine'" class="workerSetting">Miete/Zinsen [€/h]:</p>
                        <p *ngIf="resourceToEdit.resource_type.group === 'machine'" class="workerSetting">AfA [€/h]:</p>
                    </div>
                    <div fxLayout="column" style="margin-left: 10px">
                        <mat-form-field>
                            <input matInput [(ngModel)]="resourceToEdit.name" disabled>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput value="{{resourceToEdit.resource_type.name}}" disabled>
                        </mat-form-field>

                        <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                            <input matInput [(ngModel)]="resourceToEdit.serial" disabled>
                        </mat-form-field>
                        <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                            <input  type ="number" matInput [(ngModel)]="resourceToEdit.rent" disabled>
                        </mat-form-field>

                        <mat-form-field *ngIf="resourceToEdit.resource_type.group === 'machine'">
                            <input  type ="number" matInput [(ngModel)]="resourceToEdit.depreciation" disabled>
                        </mat-form-field>

                        <img *ngIf="resourceToEdit && resourceToEdit.bluectrl_sync" class="bluectrlLogo" src="assets/icons/logo_bluectrl_colour.svg">
                    </div>

                </div>
            </ng-template>

        </div>


    </div>
</div>
