export enum ExecutionTypes {
    SETUP = 'setup',
    PROCESSING = 'processing',
    TEARDOWN = 'teardown',
    PAUSE = 'pause',
    COMPLETE = 'complete',
    BOOKED = 'booked',
    OFFSHIFT = 'offshift',
    MAINTENANCE = 'maintenance',
    MALFUNCTION = 'malfunction'

}
