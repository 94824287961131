import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {AccessTypes} from '../../../Enums/access-types';
import {APIService} from '../../../Services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataShareService} from '../../../Services/data-share.service';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WebsocketService} from '../../../Services/websocket.service';
import {UserAccessService} from '../../../Services/user-access.service';
import * as moment from 'moment/moment';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {environment} from '../../../../environments/environment';
import {updateExecutionBatch} from '../sharedFunctions';
import {EnvironmentTypes} from '../../../Enums/environment-types';

@Component({
    selector: 'app-execution-screen-base',
    templateUrl: './execution-screen-base.component.html',
    styleUrls: ['./execution-screen-base.component.scss']
})
export class ExecutionScreenBaseComponent implements OnInit, OnDestroy {

    tenant = environment.tenant;
    typeEnvironmentVsa = EnvironmentTypes.VSA;

    public updateDataHandler: any;
    public batch: any;
    public executionBatch: any;
    public slideCenter: boolean;

    private idToLoad: string;
    public accessTyps = AccessTypes;
    public accessAreas = AccessAppAreas;
    public commentText: any;
    public currentExecution: any;
    public lastExecution: any;


    constructor(public apiService: APIService,
                public router: Router,
                private route: ActivatedRoute,
                public dataService: DataShareService,
                public functionsService: GlobalFunctionsService,
                public dialog: MatDialog,
                public websocket: WebsocketService,
                public userAccess: UserAccessService) {

    }

    ngOnInit(): void {

        this.route.params.subscribe(event => {
            if (event.id) {

                this.idToLoad = event.id as string;

                if (this.apiService.versioningFinished) {
                    this.loadBatch();
                } else {
                    this.apiService.OnVersioningFinished.subscribe(this.loadBatch.bind(this));
                }

            } else {
                this.showUserInfoDialog('Charge nicht gefunden');
                this.router.navigate(['/home']).catch(err => console.log(err));
            }
        });

    }

    ngOnDestroy() {
        if (this.updateDataHandler) {
            clearInterval(this.updateDataHandler);
        }
    }

    public updateExecutionBatchFromChildComponent(newExBatch: any) {
        console.log(newExBatch);
        this.executionBatch = newExBatch;
    }

    public loadBatch() {
        this.apiService.getSingleBatch(this.idToLoad).subscribe((data: any) => {
            if (data) {
                if (data.id) {
                    this.updateExecutionState(data);
                    // this.runUpdateHandler();
                }
            }
        });

    }

    public runUpdateHandler() {
        if (this.updateDataHandler) {
            clearInterval(this.updateDataHandler);
        }

        this.updateDataHandler = setInterval(() => {
            if (this.executionBatch && !this.slideCenter) {
                this.apiService.getSingleBatch(this.executionBatch.id).subscribe((data: any) => {
                    if (data) {
                        if (data.id) {
                            this.updateExecutionState(data);
                        }
                    }
                });
            }
        }, 10000);

    }

    public updateExecutionState(data: any) {
        if (data.scheduled_start) {
            data.scheduled_start = moment.utc(data.scheduled_start).local();
        }

        if (data.scheduled_end != null) {
            data.scheduled_end = moment.utc(data.scheduled_end).local();
        }


        this.executionBatch = data;
        updateExecutionBatch.call(this);
        this.apiService.getBatchComment(this.executionBatch.id).subscribe((data3: any) => {
            if (data3) {
                if (data3.text) {
                    this.commentText = data3.text;
                } else {
                    this.apiService.getProcessComment(this.executionBatch.process_id).subscribe((data2: any) => {
                        if (data2) {
                            this.commentText = data2.text;
                        }
                    });
                }
            }
        });


    }

    private showUserInfoDialog(text: string) {
        const dialogConfigx = new MatDialogConfig();
        dialogConfigx.disableClose = true;
        dialogConfigx.autoFocus = true;
        dialogConfigx.panelClass = 'dialogStyles';
        dialogConfigx.data = text;
        this.dialog.open(MessageboxComponent, dialogConfigx);
    }


}
