import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'WorkerFilter'
})
export class WorkerFilterPipe implements PipeTransform {

    transform(workers: any[], search: string = ''): any[] {
        if (!search.trim()) {
            return workers;
        }

        return workers.filter(worker => {
            return worker.resource_type.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                worker.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                worker.serial.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
    }

}
