<section fxLayout="column">
    <div class="headerline" fxLayout="row">
        <p class="viewheader">Chargen</p>
        <button (click)="newBatch()" *ngIf="userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.edit)">
            <mat-icon class="newButton icon" matPrefix>add</mat-icon>
        </button>
    </div>
    <hr>

    <mat-form-field class="searchbar">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput type="search" placeholder="Suchtext" [(ngModel)]="searchString">
    </mat-form-field>

    <div class="content" fxLayout="row" *ngIf="userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.view)">

        <cdk-virtual-scroll-viewport class="listview viewport" itemSize="200">
            <div [ngClass]="{'batchentrySel': SelectedBatch && SelectedBatch.id === batch.id, 'batchentry': !SelectedBatch ||SelectedBatch && SelectedBatch.id !== batch.id}"
                 *ngFor="let batch of Batches| batchFilter:searchString" fxLayout="column" (click)="selectBatch(batch)">
                <ng-container>
                    <div class="unplanedBatch" *ngIf="!batch.scheduled_start">
                        <i class="material-icons icon">warning</i>
                        <p class="unplanedErrorTxt">ACHTUNG: Charge ist ungeplant. Bearbeitung notwendig</p>
                    </div>
                    <img *ngIf="batch.transfer" class="ksalogo" src="../../../../assets/images/ksa.png" alt="img">
                    <ng-container *ngIf="tenant !==  typeEnvironmentMss; else mssBlock">
                        <div class="elementrow" fxLayout="row">
                            <p class="header">Chargenname</p>
                            <p class="txt">{{batch.name}}</p>
                        </div>
                        <div class="elementrow" fxLayout="row">
                            <p class="header">Processname</p>
                            <p class="txt">{{batch.process.name}}</p>
                        </div>
                        <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                            <div class="elementrow" fxLayout="row">
                                <p class="header">Inputartikel</p>
                                <p class="txt">{{batch.input.id}} {{batch.input.name}} - {{batch.quantity}}t </p>
                            </div>
                            <div class="elementrow" fxLayout="row" *ngIf="batch.transfer">
                                <p class="header">Chargenr.</p>
                                <p class="txt">{{batch.transfer.erp_batch}}</p>
                            </div>
                        </ng-container>
                        <div class="elementrow" fxLayout="row">
                            <p class="header">Startzeitpunkt</p>
                            <p class="txt"
                               *ngIf="batch.scheduled_start; else elseBlock">{{batch.scheduled_start | moment: 'dddd DD. MMMM yyyy HH:mm'}}</p>
                            <ng-template #elseBlock>Ungeplant</ng-template>
                        </div>
                        <div class="processentry2" fxLayout="column" *ngIf="batch.process.process_category !== 'maintenance'">
                            <div class="flexRow">
                                <div class="elementrow" fxLayout="column">
                                    <p class="header">Input</p>
                                    <div class="outputmaterialentry">
                                        <p class="outmaterial">{{batch.input.id}} {{batch.input.name}} - {{batch.quantity}}t</p>
                                    </div>
                                    <p *ngIf="batch.resources && batch.resources.length > 0" class="header">Ressourcen</p>
                                    <div class="ressourcesentry" *ngFor="let ressour of batch.resources">
                                        <p class="resour">{{ressour.name}} ({{ressour.resource_type.name}})</p>
                                    </div>

                                </div>
                                <div class="flexIcon">
                                    <mat-icon class="iconArrow">chevron_right</mat-icon>
                                </div>

                                <div class="addinfo flexResources" >
                                    <p class="addHeader">Output</p>
                                    <div class="outputmaterialentry" *ngFor="let outmat of batch.output">
                                        <p class="outmaterial">{{outmat.id}} {{outmat.name}}  {{getOutputSubstitution(batch, outmat.id)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </ng-container>
                    <ng-template #mssBlock>
                        <div fxLayout="row">
                            <div style="flex: 1">
                                <div class="elementrow" fxLayout="row">
                                    <p class="header">Chargenname</p>
                                    <p class="txt">{{batch.name}}</p>
                                </div>
                                <div class="elementrow" fxLayout="row">
                                    <p class="header">Prozessname</p>
                                    <p class="txt">{{batch.process.name}}</p>
                                </div>
                                <div class="elementrow" fxLayout="row" *ngIf="batch.transfer">
                                    <p class="header">Chargenr.</p>
                                    <p class="txt">{{batch.transfer.erp_batch}}</p>
                                </div>
                                <div class="elementrow" fxLayout="row">
                                    <p class="header">Startzeitpunkt</p>
                                    <p class="txt"
                                       *ngIf="batch.scheduled_start; else elseBlock">{{batch.scheduled_start | moment: 'dddd DD. MMMM yyyy HH:mm'}}</p>
                                    <ng-template #elseBlock>Ungeplant</ng-template>
                                </div>
                                <div *ngIf="batch.process.process_category !== 'maintenance'" style="margin-left: 5px">
                                            <p *ngIf="batch.resources && batch.resources.length > 0" class="header"  style="margin-bottom: 1px;">Ressourcen</p>
                                            <div class="ressourcesentry" *ngFor="let ressour of batch.resources">
                                                <p class="resour">{{ressour.name}} ({{ressour.resource_type.name}})</p>
                                            </div>
                                </div >
                            </div>
                            <div style="flex: 1; display: flex;">
                                <div class="flexIcon">
                                    <mat-icon class="iconArrow">chevron_right</mat-icon>
                                </div>
                                <div class="flexOutput">
                                    <p class="addHeader">Output</p>
                                    <div class="outputmaterialentry" *ngFor="let outmat of batch.output">
                                        <p class="outmaterial">{{outmat.id}} {{outmat.name}}  {{getOutputSubstitution(batch, outmat.id)}}</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </ng-template>

                </ng-container>

            </div>
        </cdk-virtual-scroll-viewport>
        <div class="selectedBatch" *ngIf="SelectedBatch && userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.view)">
            <div class="headerline" fxLayout="row">
                <p class="viewheader">Ausgewählt</p>
                <button [disabled]="SelectedBatch.state !== null" *ngIf="userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.edit)" (click)="changeBatch()">
                    <mat-icon class="newButton icon" matPrefix >edit</mat-icon>
                </button>
                <button [disabled]="SelectedBatch.state !== null" *ngIf="userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.delete)" (click)="deleteBatch()">
                    <mat-icon class="newButton icon" matPrefix >delete</mat-icon>
                </button>
                <button [disabled]="SelectedBatch.state !== null" *ngIf="userAccess.hasAccessRightsEn(accesAreas.plan, accessTyps.edit)" (click)="planBatch()">
                    <mat-icon class="newButton icon" matPrefix >alarm</mat-icon>
                </button>
                <button class="Button" *ngIf="SelectedBatch.state !== null && userAccess.hasAccessRightsEn(accesAreas.execution, accessTyps.view)"  (click)="showInExectutions(SelectedBatch)" >
                    <mat-icon  class="maticon" matPrefix>play_arrow</mat-icon>
                </button>

            </div>
            <hr>

            <div class="elementrow" fxLayout="row">
                <p class="header">ChargenName</p>
                <p class="txt">{{SelectedBatch.name}}</p>
            </div>

            <img *ngIf="SelectedBatch.transfer" class="ksalogoselected" src="../../../../assets/images/ksa.png" alt="img">

            <ng-container *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                <ng-container *ngIf="tenant !==  typeEnvironmentMss">
                    <div class="elementrow" fxLayout="row">
                        <p class="header">Inputartikel</p>
                        <p class="txt">{{SelectedBatch.input.id}} {{SelectedBatch.input.name}}</p>
                    </div>
                </ng-container>
            </ng-container>

            <div class="elementrow" fxLayout="row" *ngIf="SelectedBatch.transfer">
                <p class="header">Chargenr.</p>
                <p class="txt">{{SelectedBatch.transfer.erp_batch}}</p>
            </div>
            <div class="elementrow" fxLayout="row" *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                <p class="header">Chargenmenge</p>
                <p class="txt">{{SelectedBatch.quantity| number: '1.0-3':'de'}} t</p>
            </div>
            <div class="elementrow" fxLayout="row">
                <p class="header">Startzeitpunkt</p>
                <p class="txt"
                   *ngIf="SelectedBatch.scheduled_start">{{SelectedBatch.scheduled_start | moment:'dddd DD. MMMM YYYY HH:mm'}}</p>
            </div>
            <div class="elementrow" fxLayout="row">
                <p class="header">DLZ</p>
                <p class="txt">{{SelectedBatch.lead_time | number: '1.0-2':'de'}} h</p>
            </div>

            <ng-container *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                <div class="headerlineResources" fxLayout="row">
                    <p class="viewheader">Outputmaterial</p>
                </div>
                <hr class="breakerUnderElement">

                <div class="outputmaterialentry" *ngFor="let outmat of SelectedBatch.output">
                    <p class="outmaterialselected">{{outmat.id}} {{outmat.name}} {{getOutputSubstitution(SelectedBatch, outmat.id)}}</p>
                </div>

                <div *ngIf="SelectedBatch.resources && SelectedBatch.resources.length > 0" class="headerlineResources" fxLayout="row">
                    <p class="viewheader">Ressourcen</p>
                </div>
                <hr class="breakerUnderElement">

                <div class="outputmaterialentry" *ngFor="let ress of SelectedBatch.resources">
                    <p class="outmaterialselected">{{ress.resource_type.name}} ({{ress.name}})</p>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="paginator"  [ngClass]="{'paginatorBoth': skip && showNext,  'paginatorRight': !skip}">
        <div class="previous" (click)="paginateLeft()" *ngIf="skip">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div class="next" (click)="paginateRight()" *ngIf="showNext">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>

</section>
