import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import {DataShareService} from '../../../Services/data-share.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import * as moment from 'moment';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {ContainerSelectionComponent} from '../../../Dialogs/container-selection/container-selection.component';
import {WebsocketService} from '../../../Services/websocket.service';
import {MoverSelectionComponent} from '../../../Dialogs/mover-selection/mover-selection.component';
import {ErrorWeighningComponent} from '../../../Dialogs/errorMessageWeighning/errorweighning.component';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {UserAccessService} from '../../../Services/user-access.service';
import {OptionBoxInputComponent} from '../../../Dialogs/option-box-input/option-box-input.component';
import {ShowBatteryStatusComponent} from '../../../Dialogs/show-battery-status/show-battery-status.component';
import {Subscription} from 'rxjs';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {
    onBruttoInput, onTaraInput, updateExecutionBatch, showWeighingSavedDialog,
    startWebSocketSubscription,
    unsubscribeWebSocketSubscription
} from 'src/app/Components/execution-screen/sharedFunctions';


moment.locale('de');


@Component({
    selector: 'app-execution-screen-vsa',
    templateUrl: './execution-screen-vsa.component.html',
    styleUrls: ['./execution-screen-vsa.component.scss']
})
export class ExecutionScreenVsaComponent implements OnInit, OnDestroy, OnChanges {

    @Input() exBatch;
    @Output() newExBatch = new EventEmitter<any>();

    public weightingActive: boolean;
    public selectedProduct: any;
    public productQuantity: number;
    public isToggled = false;
    public systemScaleSelected = false;
    public stopProximitySubscription = false;
    public batch: any;

    public Scales: any[];
    public SelectedScale: any;
    public weighingId: string;
    public currentWeighting: any;

    public selectedContainer: {
        tare: number,
        name: string,
        scale_type: string,
        id: string,
    };

    public selectedMover: {
        name: string,
        scale_type: string,
        id: string,
    };

    public manuelSelected: boolean;
    public executionBatch: any;
    public currentExecution: any;
    public lastExecution: any;
    public slideLeft: boolean;
    public slideCenter: boolean;
    public slideRight: boolean;
    public manualTara: any;
    public startTime = 0;

    public manualBrutto: number;
    public containerWeight: any;
    public moverWeight: any;

    public accessTyps = AccessTypes;
    public accessAreas = AccessAppAreas;
    public commentText: any;
    public showBatteryIcon = false;

    public lastScaleWeighing: any;
    private subscriptionWebSocketConnected: Subscription;
    private subscriptionWebSocketMessage: Subscription;
    private subscriptionWebSocketError: Subscription;
    private subscriptionWebSocketClosed: Subscription;
    private subscriptionWebSocketConnectionError: Subscription;
    private toUpdateTimeForProximity: NodeJS.Timer;
    private updateRevaluationStatus: NodeJS.Timeout;
    public revaluation: boolean;
    public weighingFinished = false;
    public enableWeighningButton = true;
    public tableRows: any;

    constructor(public apiService: APIService,
                public router: Router,
                public dataService: DataShareService,
                public functionsService: GlobalFunctionsService,
                public dialog: MatDialog,
                public websocket: WebsocketService,
                public userAccess: UserAccessService) {

    }

    ngOnChanges(): void {
        this.executionBatch = this.exBatch;
        if (this.exBatch) { this.tableRows = this.getWeightingList(this.exBatch); }
        updateExecutionBatch.call(this);
    }


    ngOnInit(): void {

        if (this.apiService.versioningFinished === true) {
            this.getRevaluationStatus();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.getRevaluationStatus.bind(this));
        }

        // tslint:disable-next-line:max-line-length
        startWebSocketSubscription.call(this, this.WebsocketConnected, this.WebsocketMessage, this.WebsocketError, this.WebsocketClosed, this.WebsocketConnectError);
    }

    ngOnDestroy() {
        // tslint:disable-next-line:max-line-length
        unsubscribeWebSocketSubscription.call(this, this.subscriptionWebSocketConnected, this.subscriptionWebSocketMessage, this.subscriptionWebSocketError, this.subscriptionWebSocketClosed, this.subscriptionWebSocketConnectionError);

        if (this.toUpdateTimeForProximity) {
            clearInterval(this.toUpdateTimeForProximity);
        }

        if (this.updateRevaluationStatus) {
            clearInterval(this.updateRevaluationStatus);
        }
    }


    updateExecutionBatchForParentComponent(value: any) {
        this.newExBatch.emit(value);
    }

    public updateExecutionBatchFromChildComponent(newExBatch: any) {
        this.executionBatch = newExBatch;
        this.updateExecutionBatchForParentComponent(newExBatch);
    }

    public getRevaluationStatus() {
        if (this.updateRevaluationStatus) {
            clearInterval(this.updateRevaluationStatus);
        }

        this.updateRevaluationStatus = setInterval(() => {
            this.apiService.getRevaluationStatus().subscribe((data: any) => {
                if (data) {
                    this.revaluation = data.revaluation;
                }
            });
        }, 5000);
        this.apiService.getRevaluationStatus().subscribe((data: any) => {
            if (data) {
                if (data.revaluation) {
                    this.revaluation = data.revaluation;
                }
            }
        });

    }

    private WebsocketMessage(msg: any) {
        if (msg.type === 'message') {
            if (this.revaluation) {
                this.closeWebsocket();
                return;
            }
            if (msg.data) {

                const result = JSON.parse(msg.data);
                // if proximity, store in lastScale Weighing
                if (result.topic === 'proximity' && !this.stopProximitySubscription && result.scale_id === this.SelectedScale.id) {
                    console.log(result);
                    this.lastScaleWeighing = result;
                    console.log('got proximity message');
                    this.showBatteryIcon = true;
                    // tslint:disable-next-line:max-line-length
                    this.dataService.lastScaleWeighingChange(this.lastScaleWeighing.eui, this.lastScaleWeighing.distance, this.lastScaleWeighing.voltage, this.lastScaleWeighing.container_id, this.SelectedScale.name);

                    // tslint:disable-next-line:max-line-length
                    // if selectedScale is the same as from webSocket message and message has a container, then set this container as selected
                    if (this.SelectedScale.id === this.lastScaleWeighing.scale_id && this.lastScaleWeighing.container_id !== null) {
                        this.apiService.getAvailableContainers().subscribe((container: any) => {
                            if (container && container.length > 0) {
                                this.selectedContainer = container.find(ex => ex.id === this.lastScaleWeighing.container_id);
                                if (this.selectedContainer) {
                                    this.dataService.selectedContainer = this.selectedContainer;
                                    this.containerWeight = this.selectedContainer.tare;
                                    if (this.SelectedScale.requires_mover) {
                                        this.closeWebsocket();
                                        this.selectMover();
                                    } else {
                                        this.isToggled = true;
                                        this.requestWeighing();
                                    }
                                }
                            }
                        });

                    }
                }

                // if "weighing", set the weighing result
                else {
                    if (result.id === this.weighingId && result.net !== null) {
                        this.isToggled = false;
                        console.log('got correct weighing result');
                        this.currentWeighting = result;
                        this.enableWeighningButton = true;
                        this.weighingFinished = false;
                        // round to 3 decimal places
                        this.productQuantity = Math.round(result.net * 1000) / 1000;
                        this.manualBrutto = Math.round(this.currentWeighting.gross * 1000) / 1000;
                        this.manualTara = Math.round(this.currentWeighting.tare * 1000) / 1000;

                    } else {
                        this.isToggled = false;
                    }
                }
            }
        }

    }

    private WebsocketConnected() {
        // SET WEIGHT ABO
        if (this.stopProximitySubscription) {
            this.requestWeighing();

        }
    }

    private requestWeighing() {
        if (this.selectedContainer) {

            let container = null;
            let mover = null;

            if (this.selectedContainer) {
                container = this.selectedContainer.id;
            }

            if (this.selectedMover) {
                mover = this.selectedMover.id;
            }

            this.apiService.StartWeighingSubscription(this.SelectedScale.id, container, mover).subscribe({
                next: this.handleStartWeighningSubscription.bind(this),
                error: this.StartWeighingSubscriptionError.bind(this)
            });

        }
    }

    private StartWeighingSubscriptionError(err) {
        this.resetWeighing();
        this.isToggled = false;
        this.websocket.connectToSecuredServer(true);
        if (err.status === 503) {
            const dialogConfigs = new MatDialogConfig();
            dialogConfigs.disableClose = true;
            dialogConfigs.autoFocus = true;
            dialogConfigs.panelClass = 'dialogStyles';
            dialogConfigs.data = 'Lagerneubewertung läuft - Wiegen aktuell nicht möglich';
            this.dialog.open(MessageboxComponent, dialogConfigs);
        }
    }

    private handleStartWeighningSubscription(scaleSub: any) {
        if (scaleSub) {
            if (scaleSub.id) {
                this.weighingId = scaleSub.id;
            }
        }
    }

    private WebsocketClosed() {

    }

    private WebsocketConnectError() {

    }

    private WebsocketError() {

    }

    public revaluationInProgress() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Lagerneubewertung läuft - Wiegen aktuell nicht möglich';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    private resetWeighing() {
        this.selectedContainer = null;
        this.selectedMover = null;
        this.currentWeighting = null;
        this.weighingId = null;
        this.closeWebsocket();
    }

    public selectContainer() {
        this.showBatteryIcon = false;
        this.isToggled = false;
        this.stopProximitySubscription = true;
        if (this.toUpdateTimeForProximity) {
            clearInterval(this.toUpdateTimeForProximity);
        }

        this.resetWeighing();

        if (this.SelectedScale.online === true) {
            const dialogConfig1 = this.createDialogWindow();
            const dialogRef2 = this.dialog.open(ContainerSelectionComponent, dialogConfig1);
            dialogRef2.afterClosed().subscribe(result => {
                if (result === true) {
                    this.selectedContainer = this.dataService.selectedContainer;
                    this.containerWeight = this.dataService.selectedContainer.tare;
                    this.selectMover();
                } else {
                    if (this.SelectedScale.requires_container) {
                        this.showUserInfoDialog('Für die gewählte Waage ist ein Container notwendig!');
                    } else {
                        this.containerWeight = this.dataService.selectedContainer.tare;
                        this.selectMover();
                    }
                }
            });
        } else {
            this.showUserInfoDialog('Die gewählte Waage ist aktuell offline!');
        }
    }

    private createDialogWindow() {
        let dialogConfig1: MatDialogConfig;
        dialogConfig1 = {
            position: {
                top: '64px'
            },
            height: '100%',
            width: '100vw',
            maxWidth: '100vw',
            panelClass: 'full-screen-modal',
        };
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = this.SelectedScale.type;
        return dialogConfig1;
    }

    private showUserInfoDialog(text: string) {
        const dialogConfigx = new MatDialogConfig();
        dialogConfigx.disableClose = true;
        dialogConfigx.autoFocus = true;
        dialogConfigx.panelClass = 'dialogStyles';
        dialogConfigx.data = text;
        this.dialog.open(MessageboxComponent, dialogConfigx);
    }

    private selectMover() {
        if (this.SelectedScale.requires_mover) {
            const dialogConfig1 = this.createDialogWindow();
            const dialogRef2 = this.dialog.open(MoverSelectionComponent, dialogConfig1);

            dialogRef2.afterClosed().subscribe(result => {
                if (result === true) {
                    this.selectedMover = this.dataService.selectedMover;
                    this.moverWeight = this.dataService.selectedMover.tare;
                    this.isToggled = true;
                    this.closeWebsocket();
                    this.websocket.connectToSecuredServer(true);
                    this.requestWeighing();

                } else {
                    this.showUserInfoDialog('Für die gewählte Waage ist ein Mover notwendig!');
                    this.resetWeighing();
                    if (!this.stopProximitySubscription) {
                        // START WEBSOCKET
                        this.websocket.connectToSecuredServer(true);
                    }
                }
            });
        } else {
            this.isToggled = true;
            // START WEBSOCKET
            this.websocket.connectToSecuredServer(true);
        }
    }

    public showBatteryDialog() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = this.lastScaleWeighing;
        this.dialog.open(ShowBatteryStatusComponent, dialogConfig1);
    }

    public loaderClick() {

        if (!this.selectedContainer) {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = 'Bitte zu erst einen Container wählen bzw. die Wiegung starten';
            this.dialog.open(MessageboxComponent, dialogConfig1);
            this.isToggled = true;
            setTimeout(() => this.isToggled = false, 1000);
        } else if (this.websocket.IsWebsocketConnected()) {
            this.enableWeighningButton = false;
            this.requestWeighing();
            this.isToggled = true;
            // setTimeout(() => this.isToggled = false, 1000);
        } else {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = 'Aktuell besteht keine Verbindung zur Waage';
            this.dialog.open(MessageboxComponent, dialogConfig1);
            this.isToggled = true;
            setTimeout(() => this.isToggled = false, 1000);
        }

    }

    public getWeightingList(batch): any[] {

        const weighings = [];

        for (const key of Object.keys(batch.output_weights)) {
            if (batch.output_weights[key]) {
                const wg = batch.output_weights[key];

                weighings.push({
                    id: key,
                    name: wg.name,
                    weight: wg.weight
                });
            }


        }

        return weighings;

    }

    public getWeightSum(): number {

        let total = 0;
        if (this.executionBatch) {
            for (const key of Object.keys(this.executionBatch.output_weights)) {
                if (this.executionBatch.output_weights[key]) {
                    const wg = this.executionBatch.output_weights[key];

                    total += wg.weight;
                }
            }

        }

        return total;
    }

    onSwipe() {
        this.slideRight = false;
        this.slideCenter = false;
        this.selectedProduct = undefined;
    }

    public closeScalesInterface() {
        this.slideRight = false;
        this.slideCenter = false;
        this.selectedProduct = undefined;
        this.resetWeighing();

    }

// CHANGE ACTION


    public selectOutputProduct(product: any) {

        if (this.userAccess.hasAccessRightsEn(AccessAppAreas.weighing, AccessTypes.edit) && !this.revaluation) {
            this.manualTara = 0;
            this.manualBrutto = 0;
            this.productQuantity = undefined;
            this.selectedProduct = product;
            this.getScales();
        }

    }

    public selectScaleInterface(scale: any) {
        if (scale) {
            this.showBatteryIcon = false;
            this.stopProximitySubscription = false;
            this.startTime = 0;
            if (this.toUpdateTimeForProximity) {
                clearInterval(this.toUpdateTimeForProximity);
            }
            this.toUpdateTimeForProximity = setInterval(() => {
                this.startTime = this.startTime + 1;
            }, 1000);
            this.manuelSelected = false;
            this.SelectedScale = scale;
            this.systemScaleSelected = true;
            this.resetWeighing();
            // START WEBSOCKET
            this.websocket.connectToSecuredServer(true);
        }


    }


    chooseManuell() {
        // clear timer for receiving 'proximity event'
        this.startTime = 0;
        // enable weighningButton
        this.enableWeighningButton = true;
        if (this.toUpdateTimeForProximity) {
            clearInterval(this.toUpdateTimeForProximity);
        }

        this.systemScaleSelected = false;
        this.SelectedScale = null;
        this.manuelSelected = true;
        // this.productQuantity = 0.0;
        this.resetWeighing();
    }

    private closeWebsocket() {
        if (this.websocket.IsWebsocketConnected()) {
            this.websocket.Disconnect();
        }
    }

    public confirmQuantity() {

        if (this.SelectedScale && this.currentWeighting) {
            if (this.productQuantity <= 0) {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Verwiegung nicht möglich, Nettowerte gleich oder kleiner Null können nicht gespeichert werden (Brutto - Tara)';
                this.dialog.open(MessageboxComponent, dialogConfig1);
            } else {
                if (!this.stopProximitySubscription) {
                    this.closeWebsocket();
                }
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'dialogStyles';
                dialogConfig.data = {
                    txt: 'Möchten Sie folgende Verwiegung speichern?',
                    // tslint:disable-next-line:max-line-length
                    product: this.selectedProduct.name + this.functionsService.getOutputSubstitution(this.executionBatch, this.selectedProduct.id),
                    weight: this.getWeighingInTons(this.currentWeighting.net),
                    batch: this.executionBatch.name,
                    unit: this.SelectedScale.unit,
                    option1: 'Speichern',
                    option2: 'Abbrechen'
                };

                const dialogRef = this.dialog.open(OptionBoxInputComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 1) {
                        this.enableWeighningButton = false;
                        const weightId = this.currentWeighting.id;
                        const batchId = this.executionBatch.id;
                        const materialId = this.selectedProduct.id;

                        this.apiService.SetScaleWeightResult(weightId, batchId, materialId).subscribe({
                            next: this.handleSetScaleWeightResult.bind(this),
                            error: this.handleSetScaleWeightResultError.bind(this)
                        });
                    } else if (result === 2) {
                        if (!this.stopProximitySubscription) {
                            this.websocket.connectToSecuredServer(true);
                        }
                        console.log('no weighted');
                    }
                });
            }
        } else if (this.productQuantity > 0 && this.selectedProduct) {
            if (!this.stopProximitySubscription) {
                this.closeWebsocket();
            }
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = {
                txt: 'Möchten Sie folgende Verwiegung speichern?',
                // tslint:disable-next-line:max-line-length
                product: this.selectedProduct.name + this.functionsService.getOutputSubstitution(this.executionBatch, this.selectedProduct.id),
                weight: this.productQuantity,
                batch: this.executionBatch.name,
                unit: 't',
                option1: 'Speichern',
                option2: 'Abbrechen'
            };
            const dialogRef = this.dialog.open(OptionBoxInputComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                if (result === 1) {
                    const data = {
                        net_weight: this.productQuantity,
                        batch_id: this.executionBatch.id,
                        material_id: this.selectedProduct.id
                    };

                    this.manualTara = 0;
                    this.manualBrutto = 0;
                    this.productQuantity = undefined;

                    this.apiService.SetWeightResult(data).subscribe((setWeightResult: any) => {
                        if (setWeightResult) {
                            if (setWeightResult.id && setWeightResult.material) {
                                if (this.executionBatch) {
                                    if (this.executionBatch.output_weights) {
                                        const outputMat = this.executionBatch.output_weights[setWeightResult.material.id];
                                        if (outputMat) {
                                            outputMat.weight += setWeightResult.net_weight;
                                            showWeighingSavedDialog.call(this);
                                        }
                                    }
                                    this.tableRows = this.getWeightingList(this.executionBatch);

                                }
                            } else {
                                const dialogConfig1 = new MatDialogConfig();
                                dialogConfig1.disableClose = true;
                                dialogConfig1.autoFocus = true;
                                dialogConfig1.panelClass = 'dialogStyles';
                                dialogConfig1.data = 'Fehler beim Speichern der Verwiegung';
                                this.dialog.open(MessageboxComponent, dialogConfig1);
                            }
                        } else {
                            const dialogConfig1 = new MatDialogConfig();
                            dialogConfig1.disableClose = true;
                            dialogConfig1.autoFocus = true;
                            dialogConfig1.panelClass = 'dialogStyles';
                            dialogConfig1.data = 'Fehler beim Speichern der Verwiegung';
                            this.dialog.open(MessageboxComponent, dialogConfig1);
                        }
                    });
                } else if (result === 2) {
                    console.log('no weighted');
                }
            });

        } else {
            let dialogConfig1: MatDialogConfig;
            dialogConfig1 = {
                data: {content: `<b>Verwiegung nicht möglich.</b><br /><b>Nettowerte gleich oder kleiner Null können nicht gespeichert werden (Brutto - Tara)</b>`}
            };
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            this.dialog.open(ErrorWeighningComponent, dialogConfig1);
        }
    }

    private handleSetScaleWeightResultError() {
        this.NoStopProximitySubscription();
    }

    private NoStopProximitySubscription() {
        if (!this.stopProximitySubscription) {
            this.websocket.connectToSecuredServer(true);
        }
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Fehler beim Speichern der Verwiegung';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    private handleSetScaleWeightResult(scaleWeightResult: any) {
        if (scaleWeightResult) {
            if (scaleWeightResult.id && scaleWeightResult.material) {

                if (this.executionBatch) {
                    if (this.executionBatch.output_weights) {
                        const outputMat = this.executionBatch.output_weights[scaleWeightResult.material.id];
                        if (outputMat) {
                            if (!this.stopProximitySubscription) {
                                this.websocket.connectToSecuredServer(true);
                            }
                            outputMat.weight += scaleWeightResult.net_weight;
                            showWeighingSavedDialog.call(this);

                        }
                    }
                    this.tableRows = this.getWeightingList(this.executionBatch);

                }
            } else {
                this.NoStopProximitySubscription();
            }
        } else {
            this.NoStopProximitySubscription();
        }
    }

    onBruttoInput($event) {
        onBruttoInput.call(this, $event);
    }

    onTaraInput($event) {
        onTaraInput.call(this, $event);
    }

    public getScales() {
        this.SelectedScale = null;
        this.apiService.getAvailableScales().subscribe((scales: any) => {
            if (scales) {
                if (scales.length > 0) {
                    this.Scales = scales;
                }
            }
            this.productQuantity = 0.0;
            this.slideCenter = true;
            this.slideRight = true;
            this.chooseManuell();
        });

    }

    public getWeighingInTons(value: number): number {
        switch (this.SelectedScale.unit) {
            case 't': {
                return value;
            }
            case 'kg': {
                return value * 1000;
            }
            case 'dag': {
                return value * 10000;
            }
            case 'g': {
                return value * 1000000;
            }
            default: {
                return value;
            }
        }
    }


}
