import {Component} from '@angular/core';
import {ProcessComponent} from '../base-process/process.component';


@Component({
  selector: 'app-vsa-process',
  templateUrl: './vsa-process.component.html',
  styleUrls: ['../base-process/process.component.scss']
})
export class VsaProcessComponent extends ProcessComponent  {

}
