import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {APIService} from 'src/app/Services/api.service';
import {DataShareService} from 'src/app/Services/data-share.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import * as moment from 'moment';
import {QuestionBoxComponent} from '../../../Dialogs/question-box/question-box.component';
import {ResourcetypeGroups} from '../../../Enums/resourcetype-groups.enum';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProcessTypes} from '../../../Enums/process-types.enum';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {environment} from '../../../../environments/environment';
import {EnvironmentTypes} from '../../../Enums/environment-types';


@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit, AfterViewInit, OnDestroy {
    index: number;
    inputproducts: any;
    outputProducts: any;
    chosenProducts: any;
    goBackToMetaData: boolean;
    public searchString4 = '';
    public searchString5 = '';
    public showNextMachine: boolean;
    public skipMachine = 0;
    public showNextWorker: boolean;
    public skipWorker = 0;
    public suggestedValue;

    public ProcessErrors = [{
        code: 'ABP-001',
        name: 'Allgemeiner Fehler beim Speichern'
    },
        {
            code: 'ABP-002',
            name: 'Prozess mit selben Namen bereits vorhanden'
        },
        {
            code: 'ABP-003',
            name: 'Name nicht gesetzt'
        },
        {
            code: 'ABP-004',
            name: 'Inputartikel nicht gesetzt'
        },
        {
            code: 'ABP-005',
            name: 'Inputartikel ungültig'
        },
        {
            code: 'ABP-006',
            name: 'Kein Output-Artikel gewählt'
        },
        {
            code: 'ABP-007',
            name: 'Einer oder mehrere Output-Artikel ungültig'
        },
        {
            code: 'ABP-008',
            name: 'Minimale Chargengröße nicht gewählt'
        },
        {
            code: 'ABP-009',
            name: 'Durchsatz nicht gesetzt'
        },
        {
            code: 'ABP-010',
            name: 'Kein Ressourcentyp gewählt'
        },
        {
            code: 'ABP-011',
            name: 'Einer oder mehrere Ressourcentypen ungültig'
        },
        {
            code: 'ABP-012',
            name: 'Allgemeiner Fehler beim Löschen'
        },
        {
            code: 'ABP-013',
            name: 'Prozess mit dieser ID nicht gefunden'
        }
    ];

    public searchStringProduct: string;


    public set SearchStringProduct(value: string) {
        this.searchStringProduct = value;
    }

    public get SearchStringProduct(): string {
        return this.searchStringProduct;
    }

    public searchStringOutProduct: string;

    public set SearchStringOutProduct(value: string) {
        this.searchStringOutProduct = value;
    }

    public get SearchStringOutProduct(): string {
        return this.searchStringOutProduct;
    }

    public machines: any[];
    public workers: any[];

    public selectedRessources: any[] = [];
    resources: any;
    treatmentprocess: any;


    public showAllInput = false;
    public showAllOutput = false;
    public selectedInputProduct: any;
    public selectedOutputProducts: any[] = [];
    public typChosen = ProcessTypes.AUFBEREITUNG;
    public processTypToSelect = [ProcessTypes.ZERKLEINERUNG, ProcessTypes.AUFBEREITUNG, ProcessTypes.INSTANDHALTUNG];

    inputproductsTopTen: any;
    outputproductsTopTen: any;
    outputproductsAll: any;
    ready = false;

    private gotNavigationEndSatement = false;
    private destroyed = new Subject<any>();
    commentText: string;


    public set Index(value: number) {
        this.index = value;

        if (this.index === 1) {

        } else if (this.index === 2) {
            this.getRessourceTypes();


        } else if (this.index === 3) {

        } else if (this.index === 4) {
            if (!this.dataService.ProcessToEdit && !this.goBackToMetaData) {
                if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
                    this.treatmentprocess.name = 'Instandhaltung';
                } else {
                    this.treatmentprocess.name = this.typChosen + ' ' + this.selectedInputProduct.id + ' ' + this.selectedInputProduct.name;
                }

            }
        }
    }

    public get Index() {
        return this.index;
    }

    @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
        event.returnValue = false;
    }

    constructor(public Apiservice: APIService,
                public dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private functionsService: GlobalFunctionsService,
                public dataService: DataShareService) {

        this.router.events.pipe(
            filter((event: any) => event instanceof NavigationEnd),
            takeUntil(this.destroyed)
        ).subscribe((event) => {
            if (event.id > 1) {
                this.gotNavigationEndSatement = true;
                // trick the Router into believing it's last link wasn't previously loaded
                // this.router.navigated = false;
                // if you need to scroll back to top, here is the right place
                console.log('navigation end');

                this.initView();
            } else {
                this.router.navigate(['/home']);
            }

        });

    }

    ngOnDestroy() {
        this.destroyed.next(this);
        this.destroyed.complete();
    }


    validityChecked($event: any, ressource: any) {
        if ($event.IdRessource === ressource.id) {
            const index = this.selectedRessources.findIndex(x => x.id === $event.IdRessource);
            if (index !== -1) {
                this.selectedRessources[index].valid = $event.validity;
                this.selectedRessources[index].suggestedValue = $event.suggestedValue;
            }

        }
    }

    public checkValidity() {
        if (this.selectedRessources) {
            return this.selectedRessources.some(el => el.valid === false);
        }
    }

    ngOnInit(): void {

    }

    public initView() {
        this.treatmentprocess = {
            minbatchsize: 1,
            leadtimeperton: 0,
            setuptimebefore: 0,
            setuptimeafter: 0
        };
        this.getInputProductListTop10();
        this.getOutputProductListTop10();
        this.Index = 0;
        if (this.dataService.ProcessToEdit) {
            if (this.dataService.ProcessToEdit.process_category !== 'maintenance') {
                // LOAD WITH
                this.treatmentprocess = {};
                this.Apiservice.getProcessComment(this.dataService.ProcessToEdit.id).subscribe((comment: any) => {
                    if (comment) {
                        this.treatmentprocess.commentText = comment.text;
                        this.commentText = comment.text;
                    }
                });

                if ( environment.tenant === EnvironmentTypes.VSA) {
                    this.selectedInputProduct = {
                        id: this.dataService.ProcessToEdit.input.id,
                        name: this.dataService.ProcessToEdit.input.name,
                    };
                }

                if (this.dataService.ProcessToEdit.process_category === 'processing') {
                    this.typChosen = ProcessTypes.AUFBEREITUNG;
                } else {
                    this.typChosen = ProcessTypes.ZERKLEINERUNG;
                }

                this.selectedOutputProducts = [];

                for (const ot of this.dataService.ProcessToEdit.output) {
                    this.selectedOutputProducts.push({
                        name: ot.name,
                        id: ot.id
                    });
                }

                this.treatmentprocess.minbatchsize = this.dataService.ProcessToEdit.minbatchsize;
                this.treatmentprocess.leadtimeperton = this.dataService.ProcessToEdit.leadtimeperton;
                this.treatmentprocess.setuptimebefore = this.dataService.ProcessToEdit.setuptimebefore;
                this.treatmentprocess.setuptimeafter = this.dataService.ProcessToEdit.setuptimeafter;
                this.treatmentprocess.id = this.dataService.ProcessToEdit.id;
                this.treatmentprocess.name = this.dataService.ProcessToEdit.name;
                this.treatmentprocess.process_category = this.dataService.ProcessToEdit.process_category;
            } else {
                // LOAD WITH
                this.treatmentprocess = {};
                this.Apiservice.getProcessComment(this.dataService.ProcessToEdit.id).subscribe((comment: any) => {
                    if (comment) {
                        this.treatmentprocess.commentText = comment.text;
                        this.commentText = comment.text;
                    }
                });
                this.typChosen = this.typChosen = ProcessTypes.INSTANDHALTUNG;
                this.treatmentprocess.leadtimeperton = this.dataService.ProcessToEdit.leadtimeperton;
                this.treatmentprocess.id = this.dataService.ProcessToEdit.id;
                this.treatmentprocess.name = this.dataService.ProcessToEdit.name;
                this.treatmentprocess.process_category = this.dataService.ProcessToEdit.process_category;
            }


        }
    }

    ngAfterViewInit() {
        this.ready = true;
    }

    public showAllInputProducts() {
        if (this.showAllInput) {
            this.getInputProductListTop10();
            this.showAllInput = false;
        } else {
            this.getInputProductListAll();
            this.showAllInput = true;
        }
    }

    public showAllOuputProducts() {
        if (this.showAllOutput) {
            this.getOutputProductListTop10();
            this.showAllOutput = false;
        } else {
            this.getOutputProductListAll();
            this.showAllOutput = true;
        }
    }

    go(num: number) {
        if (this.Index === 4) {
            this.goBackToMetaData = true;
        }
        this.Index = this.Index + num;
    }

    onChoiceProcessType($event: any) {
        if (!this.dataService.ProcessToEdit) {
            this.initView();
            this.goBackToMetaData = false;

            if (this.selectedInputProduct) {
                this.selectedInputProduct = null;
            }
            if (this.selectedOutputProducts) {
                this.selectedOutputProducts = [];
            }
            if (this.commentText) {
                this.commentText = '';
            }
            if (this.selectedRessources) {
                this.selectedRessources = [];
            }
            if (this.treatmentprocess.leadtimeperton) {
                this.treatmentprocess.leadtimeperton = 0;
            }
        }
    }

    public getInputProductListTop10() {
        this.Apiservice.getProducts('input', true).subscribe((inputProducts: any) => {
            if (inputProducts) {
                this.inputproductsTopTen = inputProducts;
                this.inputproducts = this.inputproductsTopTen;
                if (this.inputproductsTopTen.length < 10) {
                    this.Apiservice.getProducts('intermediate', true).subscribe((interProducts: any) => {
                        if (interProducts) {
                            for (const pr of interProducts) {
                                this.inputproductsTopTen.push(pr);
                            }
                            this.inputproducts = this.inputproductsTopTen;
                        }
                    });
                }

            }
        });

    }

    public getInputProductListAll() {
        this.inputproducts = [];

        this.Apiservice.getProducts('input').subscribe((inputProducts: any) => {
            if (inputProducts) {
                this.inputproductsTopTen = inputProducts;
                this.Apiservice.getProducts('intermediate').subscribe((interProducts: any) => {
                    if (interProducts) {
                        for (const pr of interProducts) {
                            this.inputproductsTopTen.push(pr);
                        }
                        this.inputproducts = this.inputproductsTopTen;
                    }
                });


            }
        });
    }

    public getOutputProductListTop10() {
        // SetOutputProductsToShow
        this.Apiservice.getProducts('output', true).subscribe((outputProducts: any) => {
            if (outputProducts) {
                this.outputproductsTopTen = outputProducts;
                this.outputProducts = this.outputproductsTopTen;
                if (this.outputproductsTopTen.length < 10) {
                    this.Apiservice.getProducts('intermediate', true).subscribe((interProducts: any) => {
                        if (interProducts) {
                            for (const pr of interProducts) {
                                this.outputproductsTopTen.push(pr);
                            }
                            this.outputProducts = this.outputproductsTopTen;
                        }
                    });
                }
            }
        });
    }

    public getOutputProductListAll() {
        this.Apiservice.getProducts('output').subscribe((outputproducts: any) => {
            if (outputproducts) {
                this.outputproductsAll = outputproducts;
                this.outputProducts = this.outputproductsAll;
                this.Apiservice.getProducts('intermediate').subscribe((interProducts: any) => {
                    if (interProducts) {
                        for (const pr of interProducts) {
                            this.outputproductsAll.push(pr);
                        }
                        this.outputProducts = this.outputproductsAll;
                    }
                });
            }
        });
    }

    public getRessourceTypes() {

        this.Apiservice.getResourceTypes(ResourcetypeGroups.MACHINE, 0, 100).subscribe((resourcetypes: any) => {
            if (resourcetypes) {
                this.machines = resourcetypes;
                this.showNextMachine = resourcetypes.length === 100;
                if (this.dataService.ProcessToEdit) {
                    // add Selected Ressource
                    for (const r of this.dataService.ProcessToEdit.resource_types) {
                        const m = this.machines.find(ex => ex.id === r.resource_type.id);
                        if (m) {
                            // LOAD DETAILS
                            if (!this.selectedRessources.find(ex => ex.id === m.id)) {
                                this.Apiservice.getRessourceType(m.id).subscribe((resourcetype: any) => {
                                    if (resourcetype) {
                                        this.selectedRessources.push({
                                            id: m.id,
                                            group: m.group,
                                            name: m.name,
                                            bluectrl_sync: m.bluectrl_sync,
                                            cost_per_hour: resourcetype.cost_per_hour,
                                            cost_unit: resourcetype.cost_unit,
                                            default_quantity: resourcetype.default_quantity,
                                            step_size: resourcetype.step_size,
                                            quantity: r.quantity,
                                            valid: true,
                                            suggestedValue: 0,
                                        });
                                    }
                                });
                            }
                        }
                    }
                }

            }
        });

        this.Apiservice.getResourceTypes(ResourcetypeGroups.HUMAN, 0, 100).subscribe((resourcetypes: any) => {
            if (resourcetypes) {
                this.showNextWorker = resourcetypes.length === 100;
                this.workers = resourcetypes;

                if (this.dataService.ProcessToEdit) {
                    // add Selected Ressource
                    for (const r of this.dataService.ProcessToEdit.resource_types) {
                        const m = this.workers.find(ex => ex.id === r.resource_type.id);
                        if (m) {
                            // LOAD DETAILS
                            if (!this.selectedRessources.find(ex => ex.id === m.id)) {

                                this.Apiservice.getRessourceType(m.id).subscribe((resourcetype: any) => {
                                    if (resourcetype) {
                                        this.selectedRessources.push({
                                            id: m.id,
                                            group: m.group,
                                            name: m.name,
                                            bluectrl_sync: m.bluectrl_sync,
                                            cost_per_hour: resourcetype.cost_per_hour,
                                            cost_unit: resourcetype.cost_unit,
                                            default_quantity: resourcetype.default_quantity,
                                            step_size: resourcetype.step_size,
                                            quantity: r.quantity,
                                            valid: true,
                                            suggestedValue: 0,
                                        });
                                    }
                                });
                            }
                        }
                    }
                }
            }
        });

    }

    private loadMachines(skip, limit) {
        // LOAD MACHINE
        this.Apiservice.getResourceTypes(ResourcetypeGroups.MACHINE, skip, limit).subscribe((ressourceTypeMachines: any) => {
            if (ressourceTypeMachines) {
                this.showNextMachine = ressourceTypeMachines.length === 100;
                this.machines = ressourceTypeMachines.sort((a, b) => a.name.localeCompare(b.name));
            } else {
            }
        });
    }

    private loadHumans(skip, limit) {
        // LOAD HUMANS
        this.Apiservice.getResourceTypes(ResourcetypeGroups.HUMAN, skip, limit).subscribe((ressourceTypeHumans: any) => {
            if (ressourceTypeHumans) {
                this.showNextWorker = ressourceTypeHumans.length === 100;
                this.workers = ressourceTypeHumans.sort((a, b) => a.name.localeCompare(b.name));
            } else {
            }
        });
    }

    public paginateRightMachine() {
        this.skipMachine = this.skipMachine + 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateLeftMachine() {
        this.skipMachine = this.skipMachine - 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateRightWorker() {
        this.skipWorker = this.skipWorker + 100;
        this.loadHumans(this.skipWorker, 100);
    }

    public paginateLeftWorker() {
        this.skipWorker = this.skipWorker - 100;
        this.loadHumans(this.skipWorker, 100);
    }

    public getWorkerRessourceToShow(): any[] {
        let result = [];
        for (const el of this.workers) {
            const sel = this.selectedRessources.find(ex => ex.id === el.id);
            if (!sel) {
                result.push(el);
            }
        }
        result = result.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });
        return result;
    }

    public getMachineRessourceToShow(): any[] {
        let resultMachines = [];
        for (const el of this.machines) {
            const sel = this.selectedRessources.find(ex => ex.id === el.id);
            if (!sel) {
                resultMachines.push(el);
            }
        }
        resultMachines = resultMachines.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });
        return resultMachines;
    }

    public getOutputProductsToShow(): any[] {
        if (this.outputProducts) {
            const result = [];
            for (const el of this.outputProducts) {
                const sel = this.selectedOutputProducts.find(ex => ex.id === el.id);
                if (!sel) {
                    result.push(el);
                }
            }

            return result;
        } else {
            return [];
        }
    }

    public selectInputProduct(product: any) {
        this.selectedInputProduct = product;
    }

    public selectOutputProduct(product: any) {
        this.selectedOutputProducts.push(product);
    }

    public unselectOutputProduct(product: any) {
        this.selectedOutputProducts = this.selectedOutputProducts.filter(ex => ex.id !== product.id);
    }

    public confirmProcessType() {

        if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
            this.Index = 2;
        } else {
            this.Index = 1;
        }

    }

    public confirmInputProduct() {
        if (this.selectedInputProduct) {
            this.treatmentprocess.input = {
                id: this.selectedInputProduct.id,
                name: this.selectedInputProduct.name
            };
            this.Index = 2;
        }
    }

    public confirmRessources() {
        this.treatmentprocess.resource_types = [];
        for (const rec of this.selectedRessources) {
            rec.notvalid = false;
            this.treatmentprocess.resource_types.push({
                id: rec.id,
                quantity: rec.quantity
            });
        }
        if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
            this.Index = 4;
        } else {
            this.Index = 3;
        }

    }

    public confirmOutputProducts() {
        if (this.selectedOutputProducts.length > 0) {
            this.treatmentprocess.output = [];

            for (const pr of this.selectedOutputProducts) {
                this.treatmentprocess.output.push(pr.id);
            }
            this.Index = 4;
        }
    }

    public confirmMetaData() {
        this.Index = 5;
        this.goBackToMetaData = false;
    }

    public goBackToProcessTypeChoice() {
        this.Index = 0;
    }

    public goBackToInputProduct() {
        if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
            this.Index = 0;
        } else {
            this.Index = 1;
        }
    }


    public goBackToRessourceChoice() {
        this.goBackToMetaData = true;
        this.Index = 2;
    }

    public backToMetaData() {
        this.goBackToMetaData = true;
        this.Index = 4;
    }

    chooseResource(resource) {
        if (resource.group === 'human') {
            this.workers = this.workers.filter(ex => ex.id !== resource.id);
        }
        if (resource.group === 'machine') {
            this.machines = this.machines.filter(ex => ex.id !== resource.id);
        }
        // GET DETAIL
        this.Apiservice.getRessourceType(resource.id).subscribe((resourcetype: any) => {
            if (resourcetype) {
                this.selectedRessources.push({
                    id: resource.id,
                    group: resource.group,
                    name: resource.name,
                    bluectrl_sync: resource.bluectrl_sync,
                    cost_per_hour: resourcetype.cost_per_hour,
                    cost_unit: resourcetype.cost_unit,
                    default_quantity: resourcetype.default_quantity,
                    step_size: resourcetype.step_size,
                    quantity: resourcetype.default_quantity,
                    valid: true,
                    suggestedValue: 0,
                });
            }
        });
    }

    chooseResourceBack(resource) {
        this.selectedRessources = this.selectedRessources.filter(ex => ex.id !== resource.id);
        if (resource.group === 'human') {
            this.workers.push(resource);
        }
        if (resource.group === 'machine') {
            this.machines.push(resource);
        }
    }

    delete() {
        this.chosenProducts = [];
    }

    public createApiObject(): any {
        if (this.treatmentprocess) {
            const outputProductsArray = [];
            let processType = '';
            if (this.typChosen === ProcessTypes.ZERKLEINERUNG) {
                processType = 'shredding';
            } else if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
                processType = 'maintenance';
            } else {
                processType = 'processing';
            }

            let process = {};

            if (this.typChosen === ProcessTypes.INSTANDHALTUNG) {
                if (this.treatmentprocess.resource_types.length > 0) {
                    let resourceTypes = '{';

                    for (const rec of this.treatmentprocess.resource_types) {
                        resourceTypes += '"' + rec.id + '": ' + rec.quantity.toString() + ',';
                    }
                    resourceTypes = resourceTypes.substring(0, resourceTypes.length - 1) + '}';
                    process = {
                        name: this.treatmentprocess.name,
                        resource_type_ids: JSON.parse(resourceTypes),
                        process_category: processType
                    };
                } else {
                    process = {
                        name: this.treatmentprocess.name,
                        process_category: processType
                    };
                }
            } else {
                for (const out of this.treatmentprocess.output) {
                    outputProductsArray.push(out);
                }
                let resourceTypes = '{';
                for (const rec of this.treatmentprocess.resource_types) {
                    resourceTypes += '"' + rec.id + '": ' + rec.quantity.toString() + ',';
                }
                resourceTypes = resourceTypes.substring(0, resourceTypes.length - 1) + '}';
                const leadtimeperton = 1.0 / this.treatmentprocess.leadtimeperton;
                environment.tenant === EnvironmentTypes.VSA ?
                    process = {
                        name: this.treatmentprocess.name,
                        minbatchsize: this.treatmentprocess.minbatchsize,
                        leadtimeperton: leadtimeperton.toFixed(4),
                        setuptimebefore: this.treatmentprocess.setuptimebefore,
                        setuptimeafter: this.treatmentprocess.setuptimeafter,
                        input_id: this.treatmentprocess.input.id,
                        output_ids: outputProductsArray,
                        resource_type_ids: JSON.parse(resourceTypes),
                        process_category: processType
                    } :
                    process = {
                        name: this.treatmentprocess.name,
                        minbatchsize: this.treatmentprocess.minbatchsize,
                        leadtimeperton: leadtimeperton.toFixed(4),
                        setuptimebefore: this.treatmentprocess.setuptimebefore,
                        setuptimeafter: this.treatmentprocess.setuptimeafter,
                        output_ids: outputProductsArray,
                        resource_type_ids: JSON.parse(resourceTypes),
                        process_category: processType
                    };
            }

            return process;
        }
        return null;
    }

    saveProcess() {
        this.treatmentprocess.valid = true;
        this.treatmentprocess.created = moment(new Date()).add(1, 'hour').utc().format();

        if (this.treatmentprocess.id) {
            const process = this.createApiObject();


            this.Apiservice.UpdateProcess(process, this.treatmentprocess.id).subscribe((treatmentprocess: any) => {
                if (treatmentprocess) {
                    if (treatmentprocess.id) {
                        if (this.commentText !== this.treatmentprocess.commentText) {
                            this.Apiservice.AddProcessComment(treatmentprocess.id, this.commentText).subscribe((comment: any) => {
                                if (comment) {
                                    if (comment.text) {

                                    } else {
                                        const error = this.ProcessErrors.find(ex => ex.code === comment.errorcode);
                                        if (error) {
                                            const errorDialog = new MatDialogConfig();
                                            errorDialog.disableClose = true;
                                            errorDialog.autoFocus = true;
                                            errorDialog.panelClass = 'dialogStyles';
                                            errorDialog.data = error.name;
                                            this.dialog.open(MessageboxComponent, errorDialog);
                                        } else {
                                            const errorDialog = new MatDialogConfig();
                                            errorDialog.disableClose = true;
                                            errorDialog.autoFocus = true;
                                            errorDialog.panelClass = 'dialogStyles';
                                            errorDialog.data = 'Kommentar konnte nicht gespeichert werden';
                                            this.dialog.open(MessageboxComponent, errorDialog);
                                        }
                                    }
                                }
                            }, error => {
                                console.log(error);
                            });
                        }
                        const savedProcessDialog = new MatDialogConfig();
                        savedProcessDialog.disableClose = true;
                        savedProcessDialog.autoFocus = true;
                        savedProcessDialog.panelClass = 'dialogStyles';
                        savedProcessDialog.data = 'Prozess wurde gespeichert';
                        const dialogRef = this.dialog.open(MessageboxComponent, savedProcessDialog);
                        dialogRef.afterClosed().subscribe(result => {
                            if (result === true) {
                                // NAVIGATE BACK TO BATCHES
                                this.dataService.activeEdit = false;
                                this.router.navigate(['/process']);
                            }
                        });
                    } else {
                        const error = this.ProcessErrors.find(ex => ex.code === treatmentprocess.errorcode);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            });
        } else {

            const process = this.createApiObject();
            this.Apiservice.AddProcess(process).subscribe((treatmentprocess: any) => {
                if (treatmentprocess) {
                    if (treatmentprocess.id) {
                        if (this.commentText) {
                            if (this.commentText.length > 0) {
                                this.Apiservice.AddProcessComment(treatmentprocess.id, this.commentText).subscribe((comment: any) => {
                                    if (comment) {
                                        if (comment.text) {
                                            console.log(comment);
                                        } else {
                                            const error = this.ProcessErrors.find(ex => ex.code === comment.errorcode);
                                            if (error) {
                                                const errorDialog = new MatDialogConfig();
                                                errorDialog.disableClose = true;
                                                errorDialog.autoFocus = true;
                                                errorDialog.panelClass = 'dialogStyles';
                                                errorDialog.data = error.name;
                                                this.dialog.open(MessageboxComponent, errorDialog);
                                            } else {
                                                const errorDialog = new MatDialogConfig();
                                                errorDialog.disableClose = true;
                                                errorDialog.autoFocus = true;
                                                errorDialog.panelClass = 'dialogStyles';
                                                errorDialog.data = 'Kommentar konnte nicht gespeichert werden';
                                                this.dialog.open(MessageboxComponent, errorDialog);
                                            }
                                        }
                                    }
                                }, error => {
                                    console.log(error);
                                });
                            }
                        }
                        const savedProcessDialog = new MatDialogConfig();
                        savedProcessDialog.disableClose = true;
                        savedProcessDialog.autoFocus = true;
                        savedProcessDialog.panelClass = 'dialogStyles';
                        savedProcessDialog.data = 'Prozess wurde gespeichert';
                        const dialogRef = this.dialog.open(MessageboxComponent, savedProcessDialog);
                        dialogRef.afterClosed().subscribe(result => {
                            if (result === true) {
                                // NAVIGATE BACK TO BATCHES
                                this.dataService.activeEdit = false;
                                this.router.navigate(['/process']);
                            }
                        });
                    } else {
                        const error = this.ProcessErrors.find(ex => ex.code === treatmentprocess.errorcode);
                        if (error) {
                            const errorDialog = new MatDialogConfig();
                            errorDialog.disableClose = true;
                            errorDialog.autoFocus = true;
                            errorDialog.panelClass = 'dialogStyles';
                            errorDialog.data = error.name;
                            this.dialog.open(MessageboxComponent, errorDialog);
                        } else {
                            const errorDialog = new MatDialogConfig();
                            errorDialog.disableClose = true;
                            errorDialog.autoFocus = true;
                            errorDialog.panelClass = 'dialogStyles';
                            errorDialog.data = 'Prozess konnte nicht gespeichert werden';
                            this.dialog.open(MessageboxComponent, errorDialog);
                        }
                    }
                }
            }, error => {
                console.log(error);
            });
        }
    }

    cancelProcess() {
        const confirmDialog = new MatDialogConfig();
        confirmDialog.disableClose = true;
        confirmDialog.autoFocus = true;
        confirmDialog.panelClass = 'dialogStyles';
        confirmDialog.data = 'Ihre Änderungen werden verworfen. Möchten Sie Ihre Änderungen wirklich verwerfen?';
        const dialogRef = this.dialog.open(QuestionBoxComponent, confirmDialog);
        dialogRef.afterClosed().subscribe(data => {
            if (data === true) {
                this.dataService.activeEdit = false;
                this.router.navigate(['/process']);
            }
        });
    }

    onInputChangeBatchsize(value: any) {
        if (value.value > 999) {
            this.treatmentprocess.minbatchsize = 999;
        }
        if (value.value < 0) {
            this.treatmentprocess.minbatchsize = 0;
        }

    }

    onInputChange(value: any) {
        if (value.value > 999) {
            this.treatmentprocess.leadtimeperton = 999;
        }
        if (value.value < 0) {
            this.treatmentprocess.leadtimeperton = 0;
        }

    }

    onInputChangeTimeAfter(value: any) {
        if (value.value > 48) {
            this.treatmentprocess.setuptimeafter = 48;
        }
        if (value.value < 0) {
            this.treatmentprocess.setuptimeafter = 0;
        }

    }

    onInputChangeTimeBefore(value: any) {
        if (value.value > 48) {
            this.treatmentprocess.setuptimebefore = 48;
        }
        if (value.value < 0) {
            this.treatmentprocess.setuptimebefore = 0;
        }
    }

    onKeydown($event, ressource) {
        if ($event.target.value > 999) {
            $event.target.value = ressource.default_quantity;
            ressource.quantity = ressource.default_quantity;
        }
        if ($event.target.value < 0) {
            $event.target.value = 21;
            ressource.quantity = 21;
        }

    }

}
