import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {APIService} from '../../../Services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {QuestionBoxComponent} from '../../../Dialogs/question-box/question-box.component';
import {RessouretypeTypes} from '../../../Enums/ressouretype-types.enum';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {NavigationEnd, Router} from '@angular/router';
import * as moment from 'moment';
import {OptionBoxComponent} from '../../../Dialogs/option-box/option-box.component';
import {DateService} from '../../../Services/date.service';
import {DataShareService} from '../../../Services/data-share.service';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {OptionBox3optionsComponent} from '../../../Dialogs/option-box3options/option-box3options.component';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {UserAccessService} from '../../../Services/user-access.service';
import {DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';
import {ProcessTypes} from '../../../Enums/process-types.enum';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {environment} from '../../../../environments/environment';
import {EnvironmentTypes} from '../../../Enums/environment-types';


moment.locale('de');

@Component({
    selector: 'app-batch-new',
    templateUrl: './new-batch.component.html',
    styleUrls: ['./new-batch.component.scss']
})
export class NewBatchComponent implements OnInit, OnDestroy {
    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;
    private commentToCompare: any;
    public searchString = '';
    public showNext: boolean;
    public planningUnplanedbatch  = false;
    public skip = 0;
    public batch: any;
    public processes: any[];
    public ressources: any[];
    public SelectedProcess: any;
    public index: number;
    public productStock: any;
    public quantity: number;
    public offset = new Date().getTimezoneOffset();

    public DateToday = new Date();
    public DateToday2 = new Date(this.DateToday.getTime() - (this.offset * 60 * 1000));

    public minDate = new Date();
    public minDateToChoose = new Date();
    public maxDate: string;
    public fakeTime: string;
    public timeSlot: any;
    public batchName: string;
    public RequiredRessources: any[];
    public SelectedRessources: any[];
    goBackToMetaData: boolean;
    public availableResourcesofChosenType: any[];
    public ksaBookingActive = false;
    public KSAS: any[];
    public erpBatches: any[];
    public chosenERPbatch: any;
    public substitutions: any;
    public batchForPlan: any;
    public processForPlan: any;
    public timeSlotCalculationOngoing = false;
    public currentPlanId: string;
    public waitForPlanHandler: any;
    public ResourceMachine: any[];
    public ResourceWorker: any[];
    public showAvailableResources: boolean;
    public selectedResource: any;
    public commentText: string;
    public throughput: number;

    private gotNavigationEndSatement = false;
    private destroyed = new Subject<any>();
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public callingFirstTime = true;
    public goBackToProcess = false;

    public BatchErrors = [{
        code: 'ABC-001',
        name: 'Allgemeiner Fehler beim Speichern'
    },
        {
            code: 'ABC-002',
            name: 'Prozess zu Charge nicht gefunden'
        },
        {
            code: 'ABC-003',
            name: 'Charge mit dieser ID nicht gefunden'
        },
        {
            code: 'ABC-004',
            name: 'Allgemeiner Fehler beim Löschen'
        },
        {
            code: 'ABC-005',
            name: 'Charge wurde bereits aufbereitet und konnte nicht bearbeitet werden'
        }
    ];


    public set Quantity(value: number) {
        this.quantity = value;

        this.quantity = Math.round(this.quantity * 100) / 100;
    }

    @Input() set Process(value: any) {
        this.SelectedProcess = value;
        this.index = 1;
    }

    set Index(value: number) {
        this.index = value;
        if (this.index === 0) {
            // GET Processes
            this.getProcesses();
        } else if (this.index === 1) {
            if ( this.SelectedProcess.process_category !== 'maintenance') {
                if (this.tenant !== EnvironmentTypes.MSS) {
                    this.getSingleMaterialinStock();
                }
                else {
                    this.setBatchNameAndQuantity();
                }

            }
            else {
                this.setBatchNameForMaintenanceProcess();
            }

        } else if (this.index === 3) {
            if (!this.RequiredRessources) {
                this.setRequiredResources();
            }

            if (!this.SelectedRessources) {
                this.SelectedRessources = [];
            }


            if (this.dataService.BatchToEdit) {
                this.setRequiredResourcesForExistingBatch();
            }
        }
    }

    private setRequiredResourcesForExistingBatch() {
        this.SelectedRessources = [];
        for (const re of this.dataService.BatchToEdit.resources) {
            if (this.timeSlot) {
                const inreq = this.timeSlot.availableRessource.find(ex => ex.id === re.id);
                if (inreq) {
                    this.SelectedRessources.push(inreq);
                }
            } else if (this.fakeTime) {
                const inreq = this.ResourceWorker.find(ex => ex.id === re.id);
                if (inreq) {
                    this.SelectedRessources.push(inreq);
                }

                const inreq2 = this.ResourceMachine.find(ex => ex.id === re.id);
                if (inreq2) {
                    this.SelectedRessources.push(inreq2);
                }
            }
        }

        this.updateRessourcesDone();
    }

    private setRequiredResources() {
        this.RequiredRessources = [];
        for (const rs of this.SelectedProcess.resource_types) {
            this.RequiredRessources.push({
                id: rs.resource_type.id,
                name: rs.resource_type.name,
                grouptype: rs.resource_type.group,
                grouptypeToShow: rs.resource_type.group === 'machine' ? 'Maschine' : 'Mitarbeiter',
                quantity: rs.quantity,
                done: false,
                selectedQuantity: 0
            });
        }
        this.RequiredRessources = [];
        this.SelectedRessources = [];
        for (const rs of this.SelectedProcess.resource_types) {
            this.RequiredRessources.push({
                id: rs.resource_type.id,
                name: rs.resource_type.name,
                grouptype: rs.resource_type.group,
                grouptypeToShow: rs.resource_type.group === 'machine' ? 'Maschine' : 'Mitarbeiter',
                quantity: rs.quantity,
                done: false,
                selectedQuantity: 0
            });
        }
        this.sortBy();
    }

    private setBatchNameForMaintenanceProcess() {
        if (!this.goBackToMetaData) {
            this.batchName = this.SelectedProcess.name;
        }
        if (this.dataService.BatchToEdit) {
            if (!this.goBackToMetaData) {
                this.batchName = this.dataService.BatchToEdit.name;
            }
            this.throughput = this.dataService.BatchToEdit.lead_time;
        }
    }

    private setBatchNameAndQuantity() {
        if (!this.goBackToMetaData) {
            this.batchName = this.SelectedProcess.name;
        }
        if (this.quantity <= 0) {
            this.quantity = this.SelectedProcess.minbatchsize;
        }
        if (this.dataService.BatchToEdit) {
            if (!this.goBackToMetaData) {
                this.batchName = this.dataService.BatchToEdit.name;
            }
            this.quantity = this.dataService.BatchToEdit.quantity;
        }
    }

    private getSingleMaterialinStock() {
        this.apiService.getSingleMaterialStock(this.SelectedProcess.input.id).subscribe((data: any) => {
            if (data) {
                if (!this.goBackToMetaData) {
                    this.batchName = this.SelectedProcess.name;
                }

                this.productStock = data;
                if (this.quantity <= 0) {
                    this.quantity = this.SelectedProcess.minbatchsize;
                }
                if (this.dataService.BatchToEdit) {
                    if (!this.goBackToMetaData) {
                        this.batchName = this.dataService.BatchToEdit.name;
                    }
                    this.quantity = this.dataService.BatchToEdit.quantity;
                    this.productStock.used_stock = this.productStock.used_stock - this.quantity;
                }
            }
        });
    }

    private getProcesses() {
        let skip_to = null;
        if (this.dataService.BatchToEdit && this.callingFirstTime) {
            this.callingFirstTime = false;
            skip_to = this.dataService.BatchToEdit.process_id;
        }
        this.apiService.getProcessList(this.skip, 100, skip_to).subscribe({
            next: (data: any) => {
                if (data) {
                    this.processes = data.processes.sort((a, b) => a.name.localeCompare(b.name));
                    for (const p of this.processes) {
                        p.leadtimeperton = 1.0 / p.leadtimeperton;
                    }
                    this.skip = data.skip;
                    this.showNext = data.processes.length === 100;
                    if (this.dataService.BatchToEdit && !this.goBackToProcess) {
                        const fnd = this.processes.find(ex => ex.id === this.dataService.BatchToEdit.process_id);
                        if (fnd) {
                            // LOAD DETAIL
                            this.apiService.getBatchComment(this.dataService.BatchToEdit.id).subscribe((comment: any) => {
                                if (comment) {
                                    this.commentToCompare = comment.text;
                                    this.commentText = comment.text;
                                    this.selectProcess(fnd);
                                }
                            });
                            this.selectProcess(fnd);
                        }
                    }
                }
            },
            error:() => {
                this.showErrorDialog();
                this.skip = 0;
                this.apiService.getProcessList(this.skip, 100).subscribe({
                    next: (data: any) => {
                        if (data) {
                            this.processes = data.processes.sort((a, b) => a.name.localeCompare(b.name));
                            for (const p of this.processes) {
                                p.leadtimeperton = 1.0 / p.leadtimeperton;
                            }
                            this.skip = data.skip;
                            this.showNext = data.processes.length === 100;
                        }
                    },
                });
            },
        });
    }

    private showErrorDialog() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Der zu Grunde liegende Prozess wurde gelöscht oder geändert. Bitte wählen Sie einen neuen Prozess aus.';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    private sortBy() {
        const sortBy = [{
            prop: 'grouptype',
            direction: 1
        }, {
            prop: 'name',
            direction: 1
        }];
        this.RequiredRessources = this.RequiredRessources.sort((a, b) => {
            let i = 0;
            let result = 0;
            while (i < sortBy.length && result === 0) {
                // tslint:disable-next-line:max-line-length
                result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
                i++;
            }
            return result;
        });
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    get Index(): number {
        return this.index;
    }

    constructor(public apiService: APIService,
                public dialog: MatDialog,
                private functionsService: GlobalFunctionsService,
                public dateService: DateService,
                public dataService: DataShareService,
                public router: Router,
                public userAccess: UserAccessService,
                dateTimeAdapter: DateTimeAdapter<any>) {
        dateTimeAdapter.setLocale('de-DE'); // change locale to De
        this.router.events.pipe(
            filter((event: any) => event instanceof NavigationEnd),
            takeUntil(this.destroyed)
        ).subscribe((event) => {
            if (event.id > 1) {
                this.gotNavigationEndSatement = true;
            } else {
                this.router.navigate(['/home']).catch(err => console.log(err));
            }
        });
    }




    @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
        event.returnValue = false;
    }

    public paginateRight() {
        this.skip = this.skip + 100;
        this.getProcesses();
    }

    public paginateLeft() {
        this.skip = this.skip - 100;
        this.getProcesses();
    }


    ngOnInit(): void {
        if (this.dataService.BatchToPlan) {
            this.apiService.getSingleProcess(this.dataService.BatchToPlan.process.id).subscribe((data: any) => {
                if (data) {
                    this.planningUnplanedbatch = true;
                    this.SelectedProcess = data;
                    this.runPlaning(this.dataService.BatchToPlan.id);
                }
            });

        } else {

            if (this.dataService.BatchToEdit) {
                if (this.dataService.BatchToEdit.minDate) {
                    const mom = moment(this.dataService.BatchToEdit.minDate);
                    if (mom.isBefore(moment(new Date()))) {
                        this.minDate = this.DateToday;
                    } else {
                        this.minDate = mom.toDate();
                    }
                }

                if (this.dataService.BatchToEdit.maxDate) {
                    const mom = moment(this.dataService.BatchToEdit.maxDate);
                    if (mom.isBefore(moment(new Date()))) {
                        this.maxDate = this.DateToday2.toISOString().split('T')[0];
                    } else {
                        this.maxDate = mom.format('yyyy-MM-DD');
                    }
                }

                if (this.dataService.BatchToEdit.transfer) {
                    this.ksaBookingActive = true;
                    this.chosenERPbatch = this.dataService.BatchToEdit.transfer.erp_batch;
                }
            }


            this.Index = 0;
            if (this.batch) {
                if (this.batch.quantity) {
                    this.quantity = this.batch.quantity;
                }
                if (this.batch.process_id) {
                    this.Index = 1;
                }
            } else {
                this.quantity = 0;
            }
        }
    }

    ngOnDestroy() {
        this.destroyed.next(this);
        this.destroyed.complete();
        if (this.waitForPlanHandler) {
            clearInterval(this.waitForPlanHandler);
        }
    }

    public openAvailableResources(ress: any) {
        this.selectedResource = ress;
        this.showAvailableResources = true;

        if (this.timeSlot && this.timeSlot.resources) {
            if (ress.grouptype === RessouretypeTypes.MACHINE) {
                this.availableResourcesofChosenType = this.updateAvailableResourcesofChosenType(ress, 'machine');
            } else {
                this.availableResourcesofChosenType = this.updateAvailableResourcesofChosenType(ress, 'human');
            }
        }
        return [];

    }

    private updateAvailableResourcesofChosenType(ress: any, resType: string) {
        const all = this.timeSlot.resources.filter(ex => ex.resource_type.name === ress.name &&
            ex.resource_type.group === resType);
        let result: any[] = [];

        if (this.SelectedRessources && this.SelectedRessources.length > 0) {
            for (const rs of all) {

                const sels = this.SelectedRessources.find(ex => ex.id === rs.id);
                if (!sels) {
                    result.push(rs);
                }
            }
        } else {
            result = all;
        }

        return result;

    }

    onInputQuantity(value: any, $event) {
        if (value.value > 12000) {
            this.quantity = 12000;
        }
        if (value.value < 0) {
            this.quantity = 0;
        }
        if ($event.key === 'e') {
            this.quantity = 0;
        }
    }

    public getEndTime(): moment.Moment {
        if (this.batch.schedule) {

            const current = moment(this.batch.schedule);
            const endOfFoundDay = moment(current.format('yyyy-MM-DD') + ' ' + '17:00:00');
            const d = new Date(this.batch.schedule);
            const hours = Math.trunc(this.batch.lead_time);
            const minutes = Math.trunc((this.batch.lead_time - hours) * 60);
            const endDate = this.getEndDate(minutes, hours, d, endOfFoundDay, current);
            return moment(endDate);
        }
        return null;
    }

    private getEndDate(minutes: number, hours: number, d: Date, endOfFoundDay: moment.Moment, current: moment.Moment) {
        if (minutes > 0 && minutes <= 30) {
            minutes = 30;
        } else if (minutes > 30) {
            hours = hours + 1;
            minutes = 0;
        }

        const roundedLead = hours + (minutes / 60.0);

        let endDate = moment(moment(d).add(roundedLead, 'hours').clone()).clone();

        while (endDate.isAfter(endOfFoundDay)) {
            // Calculate Timediff
            const delta = endOfFoundDay.clone().diff(current, 'seconds');
            const requiredSeconds = (minutes * 60) + (hours * (60 * 60));
            const remaining = requiredSeconds - delta;
            const remHours = remaining / (60 * 60);

            let leftHours = Math.trunc(remHours);
            let lefMinutes = Math.trunc((remHours - leftHours) * 60);
            if (lefMinutes > 0 && lefMinutes <= 30) {
                lefMinutes = 30;
            } else if (lefMinutes > 30) {
                leftHours = leftHours + 1;
                lefMinutes = 0;
            }
            hours = leftHours;
            minutes = lefMinutes;

            current = current.clone().add(1, 'days');
            while (current.day() > 5 || current.day() < 1) {
                current = current.clone().add(1, 'days');
            }

            const remainingLeadTime = hours + (minutes / 60);
            current = moment(current.clone().format('yyyy-MM-DD') + ' ' + '07:00:00');
            endOfFoundDay = moment(current.clone().format('yyyy-MM-DD') + ' ' + '17:00:00');
            const currentD = new Date(current.format('yyyy-MM-DD HH:mm:ss'));
            endDate = moment(new Date(currentD)).add(remainingLeadTime, 'hour').clone();
        }
        return endDate;
    }



    public getEndTimeToEdit(): moment.Moment {
        if (this.dataService.BatchToEdit) {
            if (this.dataService.BatchToEdit.schedule) {
                const current = moment(this.dataService.BatchToEdit.schedule);
                const endOfFoundDay = moment(current.format('yyyy-MM-DD') + ' ' + '17:00:00');
                const d = new Date(this.dataService.BatchToEdit.schedule);
                const hours = Math.trunc(this.dataService.BatchToEdit.lead_time);
                const minutes = Math.trunc((this.dataService.BatchToEdit.lead_time - hours) * 60);
                const endDate = this.getEndDate(minutes, hours, d, endOfFoundDay, current);

                return moment(endDate);
            }
        }
        return null;
    }

    selectProcess(process: any) {
        this.SelectedProcess = JSON.parse(JSON.stringify(process));
        if (this.SelectedProcess.process_category === 'processing') {
            this.SelectedProcess.process_category_to_show = ProcessTypes.AUFBEREITUNG;
        }
        else if (this.SelectedProcess.process_category === 'maintenance') {
            this.SelectedProcess.process_category_to_show = ProcessTypes.INSTANDHALTUNG;
        }
        else {
            this.SelectedProcess.process_category_to_show = ProcessTypes.ZERKLEINERUNG;
        }

        this.RequiredRessources = undefined;
        this.availableResourcesofChosenType = undefined;
        this.SelectedRessources = undefined;
        this.Quantity = this.SelectedProcess.minbatchsize;
        if (!this.commentText) {
            this.apiService.getProcessComment(process.id).subscribe((data: any) => {
                if (data) {
                    this.commentText = data.text;
                    this.commentToCompare = data.text;
                }
            });
        }
        if (process.transferable) {
            this.apiService.getTransferableProcesses(process.id).subscribe((data: any) => {
                this.KSAS = Object.entries(data.substitutions).map(([key, values]) => ({key, values, value: values[0]}));

                if (this.dataService.BatchToEdit) {
                    if (this.dataService.BatchToEdit.transfer) {
                        const substis = this.dataService.BatchToEdit.transfer.substitution;

                        for (const ksa of this.KSAS) {
                            if (substis[ksa.key]) {
                                ksa.value = substis[ksa.key];
                            }
                        }
                    }
                }

                this.erpBatches = data.erp_batches;

            });
        }
    }

    public confirmProcess() {
        this.batch = {};
        this.batch.process_id = this.SelectedProcess.id;
        this.Index = 1;
    }

    public backToProcesses() {
        const fnd = this.processes.find(ex => ex.id === this.SelectedProcess.id);
        this.goBackToProcess = true;
        this.selectProcess(fnd);
        this.goBackToMetaData = true;
        this.chosenERPbatch = null;
        this.KSAS = null;
        this.ksaBookingActive = false;
        this.Index = 0;

    }

    backToQuantityCheck() {

        this.index = 1;

    }

    public confirmMaintenance() {
        if (!this.batchName || this.batchName.length <= 0) {
            this.showMissedBatchedNameHint();
        }
        this.Index = 2;
    }

    private showMissedBatchedNameHint() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = 'Bitte Chargennamen angeben';
        this.dialog.open(MessageboxComponent, dialogConfig);
        return;
    }

    public confirmQuantity() {
        this.goBackToMetaData = true;
        if (this.quantity <= 0) {
            return;
        }

        if (!this.batchName || this.batchName.length <= 0) {
            this.showMissedBatchedNameHint();
        }

        if (this.ksaBookingActive) {
            if (!this.chosenERPbatch) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'dialogStyles';
                dialogConfig.data = 'Bei aktiver KSA Umbuchung muss eine Charge ausgewählt werden';
                this.dialog.open(MessageboxComponent, dialogConfig);

                return;
            }
        }

        if (this.quantity < this.SelectedProcess.minbatchsize) {
            // Quantity lower than min batch size

            // SHOW MESSAGE BOX
            this.createDialog('Gewählte Menge ist kleiner als die Minimale Chargenmenge des gewählten Prozesses. Wollen Sie die Menge trotzdem übernehmen?');
        }

        if (this.tenant !== EnvironmentTypes.MSS) {
            if (!this.productStock) {
                // No Stock

                // SHOW MESSAGE BOX
                this.createDialog('Der gewählte Inputartikel besitzt keinen Bestand. Chargen ohne ausreichend Bestand können nicht aufbereitet werden. Möchten Sie die Menge trotzdem übernehmen?', true);

            } else if (this.quantity > this.productStock.unused_stock) {
                // Quantity greater than stock

                // SHOW MESSAGE BOX
                this.createDialog('Gewählte Menge ist größer als der verfügbare Bestand. Chargen ohne ausreichend Bestand können nicht aufbereitet werden. Möchten Sie die Menge trotzdem übernehmen?', true);

            } else if (this.quantity >= this.SelectedProcess.minbatchsize) {
                this.checkKSAbookingAndGotoIndex2();
            }
        }
        else {
            if (this.quantity >= this.SelectedProcess.minbatchsize) {
                this.checkKSAbookingAndGotoIndex2();
            }
        }


    }

    private createDialog(dialogText: string, stockunavailable = false) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = dialogText;
        const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            if (data === true) {
                this.checkKSAbookingAndGotoIndex2(stockunavailable);
            }
        });
    }

    private checkKSAbookingAndGotoIndex2(stockunavailable = false) {
        this.batch.quantity = this.quantity;
        this.batch.stockunavailable = stockunavailable;
        if (this.ksaBookingActive) {
            this.substitutions = this.KSAS.reduce((obj, item) => (obj[item.key] = item.value, obj), {});
        }
        this.Index = 2;
    }


    public plannedLeadTime(): string {
        if (this.batchForPlan) {
            const time = this.batchForPlan.lead_time;
            return this.getTime(time);
        } else {
            return '';
        }
    }

    private getTime(time) {
        let hours = Math.trunc(time);
        let minutes = Math.trunc((time - hours) * 60);
        if (minutes > 0 && minutes <= 30) {
            minutes = 30;
        } else if (minutes > 30) {
            hours = hours + 1;
            minutes = 0;
        }
        return hours + ' Stunde(n) ' + minutes + ' Minuten';
    }

    public getTimeSlotLeadTime(): string {
        if (this.timeSlot) {
            const time = this.timeSlot.lead_time;
            return this.getTime(time);
        } else {
            return '';
        }
    }




    public selectRessource(machines: any) {
        const sle = this.RequiredRessources.find(ex => ex.id === machines.resource_type.id);
        if (sle) {
            if (sle.done === true) {
                return;
            }
        }

        this.SelectedRessources.push(machines);
        this.availableResourcesofChosenType = this.availableResourcesofChosenType.filter(ex => ex.id !== machines.id);
        this.updateRessourcesDone();

    }

    unselectRessource(recs: any) {
        this.SelectedRessources = this.SelectedRessources.filter(ex => ex.id !== recs.id);
        this.updateRessourcesDone();
        if (this.selectedResource.name === recs.resource_type.name) {
            this.availableResourcesofChosenType.push(recs);
        }

    }

    private updateRessourcesDone() {
        for (const rs of this.RequiredRessources) {
            rs.done = false;
            rs.selectedQuantity = 0;
        }

        for (const rs of this.SelectedRessources) {
            const avRes = this.RequiredRessources.find(ex => ex.id === rs.resource_type.id);
            if (avRes) {

                avRes.selectedQuantity += 1;
                if (avRes.selectedQuantity >= avRes.quantity) {
                    avRes.done = true;
                }
            }
        }
    }

    public isRessourceSelectionDone(): boolean {
        let done = true;

        if (this.RequiredRessources) {
            for (const rec of this.RequiredRessources) {
                if (rec.done === false) {
                    done = false;
                }
            }
        } else {
            return false;
        }

        return done;
    }

    public updateBatch(nwBatch, plan) {
        this.apiService.UpdateBatch(nwBatch, this.dataService.BatchToEdit.id).subscribe((data: any) => {
            if (data) {
                if (data.id) {
                    this.saveBatchComment(this.dataService.BatchToEdit.id);
                    this.dataService.activeEdit = false;
                    if (!plan) {
                        if (this.batch.scheduled_start) {
                            if (this.userAccess.hasAccessRightsEn(AccessAppAreas.plan, AccessTypes.view)) {
                                this.showDialogBatchWasSaved(data);
                            } else {
                                this.showDialopgThatChangesHaveBeenSaved();
                            }
                        } else {
                            this.showDialopgThatChangesHaveBeenSaved();
                        }
                    } else {
                        this.runPlaning(data.id);
                    }
                } else {
                    this.handleRessourceError(data);
                }
            }
        });
    }

    private showDialogBatchWasSaved(data: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = {
            txt: 'Charge wurde gespeichert',
            option1: 'Weitere Charge',
            option2: 'im Kalender anzeigen'
        };
        const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                // NAVIGATE BACK TO BATCHES
                this.router.navigate(['/batches']).catch(err => console.log(err));
            } else if (result === 2) {
                this.dateService.changeDate(moment(this.batch.scheduled_start));
                this.router.navigate(['/calendar']).catch(err => console.log(err));
                this.dataService.changeBatchToCalender(data);
            }
        });
    }

    private showDialopgThatChangesHaveBeenSaved() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Änderungen gespeichert';
        this.dialog.open(MessageboxComponent, dialogConfig1);
        this.router.navigate(['/batches']).catch(err => console.log(err));
    }

    public saveBatch(plan: boolean = false) {
        if (this.dataService.BatchToEdit) {
            const nwBatch = this.getBatchForSave();
            if (!this.dataService.BatchToEdit.scheduled_start) {
                this.updateBatch(nwBatch, plan);
            }
            else {

                if (this.userAccess.hasAccessRightsEn(AccessAppAreas.plan, AccessTypes.edit)) {

                    if (this.dataService.BatchToEdit.quantity !== nwBatch.quantity) {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = {
                            txt: 'Die aktuelle Planung wird auf Grund der Mengenänderung gelöscht',
                            option1: 'Neu Planen',
                            option2: 'Ok ',
                            option3: 'Abbrechen ',
                        };
                        this.openDialogwith3Options(dialogConfig, nwBatch, plan);
                    }

                    else {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = {
                            txt: 'Die aktuelle Planung wird auf Grund der Änderung gelöscht',
                            option1: 'Neu Planen',
                            option2: 'Ok ',
                            option3: 'Abbrechen ',
                        };
                        this.openDialogwith3Options(dialogConfig, nwBatch, plan);
                    }


                } else {
                    if (this.dataService.BatchToEdit.quantity !== nwBatch.quantity) {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = {
                            txt: 'Die aktuelle Planung wird auf Grund der Mengenänderung gelöscht',
                            option1: 'OK',
                            option2: 'Abbrechen'
                        };
                        this.openDialog(dialogConfig, nwBatch, plan);
                    }

                    else {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = {
                            txt: 'Die aktuelle Planung wird auf Grund der Änderung gelöscht',
                            option1: 'OK',
                            option2: 'Abbrechen'
                        };
                        this.openDialog(dialogConfig, nwBatch, plan);
                    }
                }
            }
        } else {

            const nwBatch = this.getBatchForSave();

            this.addBatch(nwBatch, plan);
        }
    }

    private addBatch(nwBatch, plan: boolean) {
        this.apiService.AddBatch(nwBatch).subscribe((data: any) => {
            if (data) {
                if (data.id) {
                    if (!plan) {
                        this.saveBatchComment(data.id);
                        this.dataService.activeEdit = false;
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        if (this.timeSlot || this.fakeTime) {
                            dialogConfig.data = {
                                txt: 'Charge wurde gespeichert',
                                option1: 'Weitere Charge',
                                option2: 'im Kalender anzeigen'
                            };
                            const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
                            dialogRef.afterClosed().subscribe(result => {
                                if (result === 1) {
                                    // NAVIGATE BACK TO BATCHES
                                    this.router.navigate(['/batches']).catch(err => console.log(err));
                                } else if (result === 2) {
                                    this.dateService.changeDate(moment(this.batch.schedule.clone()));
                                    this.router.navigate(['/calendar']).catch(err => console.log(err));
                                    this.dataService.changeBatchToCalender(data);
                                }
                            });
                        } else {
                            dialogConfig.data = 'Charge wurde gespeichert';
                            const dialogRef = this.dialog.open(MessageboxComponent, dialogConfig);
                            dialogRef.afterClosed().subscribe(() => {
                                this.router.navigate(['/batches']).catch(err => console.log(err));

                            });
                        }
                    } else {
                        this.saveBatchComment(data.id);
                        this.runPlaning(data.id);
                    }

                } else {
                    this.handleRessourceError(data);
                }
            }
        });
    }

    private openDialogwith3Options(dialogConfig: MatDialogConfig, nwBatch, plan: boolean) {
        const dialogRef = this.dialog.open(OptionBox3optionsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                this.apiService.UpdateBatch(nwBatch, this.dataService.BatchToEdit.id).subscribe((data: any) => {
                    if (data) {
                        if (data.id) {
                            this.dataService.activeEdit = false;
                            if (this.userAccess.hasAccessRightsEn(AccessAppAreas.plan, AccessTypes.edit)) {
                                this.runPlaning(data.id);
                            }
                        } else {
                            this.handleRessourceError(data);
                        }
                    }
                });
            } else if (result === 2) {
                this.updateBatch(nwBatch, plan);
            } else if (result === 3) {
                this.router.navigate(['/batches']).catch(err => console.log(err));
            }
        });
    }

    private openDialog(dialogConfig: MatDialogConfig, nwBatch, plan: boolean) {
        const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                // NAVIGATE BACK TO BATCHES
                this.updateBatch(nwBatch, plan);
            } else if (result === 2) {
                this.router.navigate(['/batches']).catch(err => console.log(err));
            }
        });
    }

    private saveBatchComment(id) {
        if (this.commentText !== this.commentToCompare) {
            this.apiService.postBatchComment(id, this.commentText).
            subscribe({
                next: null,
                error: this.handleCommentError.bind(this)
            });

        }
    }

    private handleCommentError(data2: any) {
        const error = this.BatchErrors.find(ex => ex.code === data2.errorcode);
        this.functionsService.handleAPIsaveError(error, 'Kommentar');
    }

    private getBatchForSave(): any {
        if (this.dataService.BatchToEdit) {
            if (this.ksaBookingActive) {
                let resources = this.batch.resources;
                if (!resources) {
                    resources = [];
                }
                return {
                    process_id: this.batch.process_id,
                    name: this.batchName,
                    quantity: this.batch.quantity,
                    stockunavailable: this.batch.stockunavailable,
                    resource_ids: resources,
                    transfer: {
                        erp_batch: this.chosenERPbatch,
                        substitution: this.substitutions
                    }
                };
            } else {
                let resources = this.batch.resources;
                if (!resources) {
                    resources = [];
                }
                if (this.SelectedProcess.process_category === 'maintenance') {
                    return {
                        process_id: this.batch.process_id,
                        name: this.batchName,
                        quantity: this.throughput,
                        resource_ids: []
                    };
                }
                else {
                    return {
                        process_id: this.batch.process_id,
                        name: this.batchName,
                        quantity: this.batch.quantity,
                        stockunavailable: this.batch.stockunavailable,
                        resource_ids: resources,
                    };
                }
            }
        } else {
            if (this.ksaBookingActive) {
                return {
                    process_id: this.batch.process_id,
                    name: this.batchName,
                    quantity: this.batch.quantity,
                    stockunavailable: this.batch.stockunavailable,
                    transfer: {
                        erp_batch: this.chosenERPbatch,
                        substitution: this.substitutions
                    },
                    resource_ids: []
                };
            } else {
                if (this.SelectedProcess.process_category === 'maintenance') {
                    return {
                        process_id: this.batch.process_id,
                        name: this.batchName,
                        quantity: this.throughput,
                        resource_ids: []
                    };
                }
                else {
                    return {
                        process_id: this.batch.process_id,
                        name: this.batchName,
                        quantity: this.batch.quantity,
                        stockunavailable: this.batch.stockunavailable,
                        resource_ids: []
                    };
                }
            }
        }
    }

    cancelProcess() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = 'Ihre Änderungen werden verworfen. Möchten Sie Ihre Änderungen wirklich verwerfen?';
        const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            if (data === true) {
                this.dataService.activeEdit = false;
                this.router.navigate(['/batches']).catch(err => console.log(err));
            }
        });
    }


    public getOutputSubstitution(mat: string) {

        if (mat) {
            if (this.ksaBookingActive && this.substitutions) {
                const vl = this.substitutions[mat];
                if (vl !== 'none') {
                    if (vl) {
                        return ' (' + vl + ')';
                    }
                }
            }
        }

        return '';
    }

    public maskKsaProductValue(ksa: string) {
        if (ksa === 'none') {
            return 'nicht umbuchen';
        } else {
            return ksa;
        }
    }

    private runPlaning(batchId: string) {
        // LOAD BATCH
        if (batchId) {
            console.log(batchId);
            this.apiService.getSingleBatch(batchId).subscribe({
                next: (batch: any)  => {
                    this.handleGetBatch(batch);
                },
            });
        }
    }

    private handleGetBatch(batch: any) {
        this.dataService.BatchToPlan = null;
        this.batchForPlan = batch;
        if (this.batchForPlan) {
            if (this.batchForPlan.minDate) {
                const momMin = moment(this.batchForPlan.minDate);
                if (!momMin && momMin.isBefore(moment(new Date()))) {
                    this.minDate = this.DateToday;
                } else {
                    this.minDate = momMin.toDate();
                }
            } else {
                this.minDate = this.DateToday;
            }

            const year = this.DateToday2.getFullYear();
            const month = this.DateToday2.getMonth();
            const day = this.DateToday2.getDate();
            const c = new Date(year + 1, month, day);

            if (this.batchForPlan.maxDate) {
                const momMax = moment(this.batchForPlan.maxDate);
                if (!momMax && momMax.isBefore(moment(new Date()))) {
                    this.maxDate = c.toISOString();
                    this.maxDate = this.maxDate.substring(0, this.maxDate.length - 1);
                } else {
                    this.maxDate = momMax.format('yyyy-MM-DD');
                }
            } else {
                this.maxDate = c.toISOString();
                this.maxDate = this.maxDate.substring(0, this.maxDate.length - 1);
            }
        }

        // LOAD SINGLE PROCESS FOR RESSOURCETYPES
        this.getSingleProcess();
    }

    private getSingleProcess() {
        this.apiService.getSingleProcess(this.batchForPlan.process_id).subscribe((process: any) => {
            if (process) {
                if (process.id) {
                    this.index = 3;
                    this.processForPlan = process;
                    this.RequiredRessources = [];
                    if (this.processForPlan.resource_types) {
                        for (const rs of this.processForPlan.resource_types) {
                            this.RequiredRessources.push({
                                id: rs.resource_type.id,
                                name: rs.resource_type.name,
                                grouptype: rs.resource_type.group,
                                grouptypeToShow: rs.resource_type.group === 'machine' ? 'Maschine' : 'Mitarbeiter',
                                quantity: rs.quantity,
                                done: false,
                                selectedQuantity: 0
                            });
                        }
                    }
                    this.RequiredRessources = [];
                    this.SelectedRessources = [];
                    if (this.processForPlan.resource_types) {
                        for (const rs of this.processForPlan.resource_types) {
                            this.RequiredRessources.push({
                                id: rs.resource_type.id,
                                name: rs.resource_type.name,
                                grouptype: rs.resource_type.group,
                                grouptypeToShow: rs.resource_type.group === 'machine' ? 'Maschine' : 'Mitarbeiter',
                                quantity: rs.quantity,
                                done: false,
                                selectedQuantity: 0
                            });
                        }
                    }
                    if (this.processForPlan.resource_types) {
                        this.sortBy();
                        this.SelectedRessources = [];
                    }


                }
            }
        });
    }

    public calculateTimeSlot() {
        if (this.batchForPlan) {
            this.SelectedRessources = [];
            this.selectedResource = null;
            this.availableResourcesofChosenType = [];
            this.updateRessourcesDone();
            if (!this.timeSlotCalculationOngoing) {
                this.timeSlotCalculationOngoing = true;

                this.timeSlot = null;
                let mnDate: string = null;
                let mxDate: string = null;

                const nowCalc = moment.utc(Date.now());
                const plusCalc = moment.utc(nowCalc).add(1, 'years');

                if (this.minDate) {
                    const eariestStart = moment.utc(moment(this.minDate));
                    mnDate = moment.max(nowCalc, eariestStart).format();
                    // nwBatch.minDate = mom;
                }

                if (this.maxDate) {
                    const latestEnd = moment.utc(moment(this.maxDate));
                    mxDate = moment.min(plusCalc, latestEnd).format();
                    // nwBatch.maxDate = mom;
                }

                this.apiService.requestPlan(this.batchForPlan.id, mxDate, mnDate).
                subscribe({
                    next: this.handleRequestingPlan.bind(this),
                    error: this.handleRequestingPlanError.bind(this)
                });
            }
        } else {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = 'Charge konnte nicht geladen werden';
            this.dialog.open(MessageboxComponent, dialogConfig1);
        }
    }

    private handleRequestingPlanError(error) {
        this.timeSlotCalculationOngoing = false;

        if (error.status === 406) {

            if (error.error.detail) {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = error.error.detail;
                this.dialog.open(MessageboxComponent, dialogConfig1);
            } else {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Charge konnte nicht geplant werden';
                this.dialog.open(MessageboxComponent, dialogConfig1);
            }


        }
    }

    private handleRequestingPlan(plan: any) {
        if (plan) {
            console.log(plan);
            if (plan.id) {
                // SAVE IT
                this.currentPlanId = plan.id;
                this.runPlansIntervall();
            } else {
                this.timeSlotCalculationOngoing = false;
            }
        } else {
            this.timeSlotCalculationOngoing = false;
        }
    }

    private stopCallPlansHandler() {
        if (this.waitForPlanHandler) {
            clearInterval(this.waitForPlanHandler);
            this.waitForPlanHandler = null;
        }
    }

    private runPlansIntervall() {
        this.stopCallPlansHandler();
        if (!this.waitForPlanHandler) {
            this.waitForPlanHandler = setInterval(() => {
                if (this.currentPlanId) {
                    this.apiService.getPlan(this.currentPlanId).
                    subscribe({
                        next: this.handlePlanning.bind(this),
                        error: this.handlePlanningError.bind(this)
                    });
                } else {
                    this.handlePlanningError();
                }

            }, 2000);
        }
    }

    private handlePlanningError() {
        this.stopCallPlansHandler();
        this.timeSlotCalculationOngoing = false;
    }

    private handlePlanning(plan: any) {
        if (plan && plan.id) {
            if (plan.id === this.currentPlanId) {
                if (plan.planning_finished === true) {
                    // PLANNING DONE

                    if (plan.minDate) {
                        plan.minDate = moment.utc(plan.minDate).local();
                    }
                    if (plan.maxDate) {
                        plan.maxDate = moment.utc(plan.maxDate).local();
                    }
                    if (plan.scheduled_start) {
                        plan.scheduled_start = moment.utc(plan.scheduled_start).local();
                    }
                    if (plan.scheduled_end) {
                        plan.scheduled_end = moment.utc(plan.scheduled_end).local();
                    }


                    console.log(plan);
                    this.timeSlot = plan;
                    this.stopCallPlansHandler();
                    this.timeSlotCalculationOngoing = false;
                }
            }
        } else {
            this.stopCallPlansHandler();
            this.timeSlotCalculationOngoing = false;
        }
    }

    public confirmTimeSlot() {
        const batch = this.getBatchWorkaroundForSave();
        if (batch) {
            this.apiService.UpdateBatch(batch, this.batchForPlan.id).
            subscribe({
                next: this.handleUpdateBatch.bind(this),
                error: this.handleRessourceError.bind(this)
            });
        } else {
            const dialogConfig1 = new MatDialogConfig();
            dialogConfig1.disableClose = true;
            dialogConfig1.autoFocus = true;
            dialogConfig1.panelClass = 'dialogStyles';
            dialogConfig1.data = 'Charge konnte nicht erstellt werden';
            this.dialog.open(MessageboxComponent, dialogConfig1);
        }
    }

    private handleUpdateBatch() {
        this.apiService.ConfirmPlan(this.timeSlot.id).subscribe(() => {
            this.dataService.activeEdit = false;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = {
                txt: 'Planung wurde gespeichert',
                option1: 'Weitere Charge planen',
                option2: 'Im Kalender anzeigen'
            };

            const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                if (result === 1) {
                    // NAVIGATE BACK TO BATCHES
                    this.router.navigate(['/batches']).catch(err => console.log(err));
                } else if (result === 2) {
                    this.dateService.changeDate(moment(this.timeSlot.scheduled_start.clone()));
                    this.router.navigate(['/calendar']).catch(err => console.log(err));
                }
            });
        });
    }

    private handleRessourceError(data: any) {
        const error = this.BatchErrors.find(ex => ex.code === data.errorcode);
        this.functionsService.handleAPIsaveError(error, 'Ressource');
    }

    private getBatchWorkaroundForSave(): any {
        if (this.batchForPlan) {
            const resources = [];
            console.log(this.SelectedRessources);

            for (const r of this.SelectedRessources) {
                resources.push(r.id);
            }

            let mnDate: string = null;
            let mxDate: string = null;

            if (this.minDate) {
                const mom = moment(this.minDate).format();
                mnDate = mom.split('T')[0] + 'T00:00:00.0000';
                // nwBatch.minDate = mom;
            }

            if (this.maxDate) {
                const mom = moment(this.maxDate).format();
                mxDate = mom.split('T')[0] + 'T00:00:00.0000';
                // nwBatch.maxDate = mom;
            }

            if (this.batchForPlan.transfer) {
                return {
                    process_id: this.batchForPlan.process_id,
                    name: this.batchForPlan.name,
                    quantity: this.batchForPlan.quantity,
                    stockunavailable: this.batchForPlan.stockunavailable,
                    resource_ids: resources,
                    transfer: this.batchForPlan.transfer,
                    maxDate: mxDate,
                    minDate: mnDate
                };
            } else {
                return {
                    process_id: this.batchForPlan.process_id,
                    name: this.batchForPlan.name,
                    quantity: this.batchForPlan.quantity,
                    stockunavailable: this.batchForPlan.stockunavailable,
                    resource_ids: resources,
                    transfer: this.batchForPlan.transfer,
                    maxDate: mxDate,
                    minDate: mnDate
                };
            }


        } else {
            return null;
        }
    }


}


