import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-affected-batches',
  templateUrl: './affected-batches.component.html',
  styleUrls: ['./affected-batches.component.css']
})
export class AffectedBatchesComponent implements OnInit {

  public batches: any;


  constructor(public DialogRef: MatDialogRef<AffectedBatchesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.batches = data;

  }


  ngOnInit(): void {
  }

  public Continue() {
    this.DialogRef.close(true);
  }

  public Cancel() {
    this.DialogRef.close(false);
  }

}
