import {Component, OnInit} from '@angular/core';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {QuestionBoxComponent} from '../../../Dialogs/question-box/question-box.component';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {DataShareService} from '../../../Services/data-share.service';
import {UserAccessService} from '../../../Services/user-access.service';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {environment} from '../../../../environments/environment';
import {EnvironmentTypes} from '../../../Enums/environment-types';

@Component({
    selector: 'app-batch-view',
    templateUrl: './batch-view.component.html',
    styleUrls: ['./batch-view.component.scss']
})
export class BatchViewComponent implements OnInit {

    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;

    public Batches: any[];
    public SelectedBatch: any;
    public searchString = '';
    public showNext: boolean;
    public skip = 0;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;

    constructor(public apiService: APIService,
                public router: Router,
                public dataService: DataShareService,
                public dialog: MatDialog,
                public userAccess: UserAccessService) {
    }

    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.updateBatchList(0, 100);
            this.dataService.ReleaseBatch();
            // this.getTreatmentsFromAPI();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }
    }

    private ApiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.updateBatchList(0, 100);
            this.dataService.ReleaseBatch();
            // this.getTreatmentsFromAPI();
        }
    }

    public paginateRight() {
        this.skip = this.skip + 100;
        this.updateBatchList(this.skip, 100);
    }

    public paginateLeft() {
        this.skip = this.skip - 100;
        this.updateBatchList(this.skip, 100);
    }

    private updateBatchList(skip, limit) {
        this.apiService.getBatchList(skip, limit, true).subscribe({
            next: this.handleReceivedBatches.bind(this),
            error: this.apiService.showError.bind(this)
        });
    }

    private handleReceivedBatches(data: any) {
        this.showNext = data.length === 100;
        for (const bt of data) {
            if (bt.scheduled_start) {
                bt.scheduled_start = moment.utc(bt.scheduled_start).local();
            } else {
                bt.scheduled_start = null;
            }

            if (bt.scheduled_end) {
                bt.scheduled_end = moment.utc(bt.scheduled_end).local();
            } else {
                bt.scheduled_end = null;
            }
        }

        this.Batches = data.filter(ex => !ex.scheduled_start);

        const all = data.filter(ex => ex.scheduled_start)
            .sort((a, b) => a.scheduled_start.valueOf() - b.scheduled_start.valueOf());
        for (const a of all) {
            this.Batches.push(a);
        }

        if (this.dataService.BatchToShow) {
            this.apiService.getSingleBatch(this.dataService.BatchToShow.id).subscribe((batch: any) => {
                if (batch) {
                    console.log('found batch');
                    if (batch.scheduled_start) {
                        batch.scheduled_start = moment.utc(batch.scheduled_start).local();
                    } else {
                        batch.scheduled_start = null;
                    }

                    if (batch.scheduled_end) {
                        batch.scheduled_end = moment.utc(batch.scheduled_end).local();
                    } else {
                        batch.scheduled_end = null;
                    }
                    this.SelectedBatch = batch;
                    this.dataService.BatchToShow = null;
                }
            });
        }
    }

    public planBatch() {
        if (this.SelectedBatch) {
            this.dataService.activeEdit = true;
            this.dataService.BatchToPlan = this.SelectedBatch;
            this.router.navigate(['batches/config']).catch(err => console.log(err));
        }
    }

    deleteBatch() {
        if (this.SelectedBatch) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = 'Möchten Sie die ausgewählte Charge wirklich löschen?';
            const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(data => {
                if (data === true) {
                    this.apiService.DeleteBatch(this.SelectedBatch).subscribe((dataD: any) => {
                        if (dataD) {
                            if (dataD.id) {
                                const dialogConfig1 = new MatDialogConfig();
                                dialogConfig1.disableClose = true;
                                dialogConfig1.autoFocus = true;
                                dialogConfig1.panelClass = 'dialogStyles';
                                dialogConfig1.data = 'Charge wurde gelöscht';
                                const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                                dialogRef2.afterClosed().subscribe(result => {
                                    if (result === true) {
                                        // NAVIGATE BACK TO BATCHES
                                        this.SelectedBatch = null;
                                        this.updateBatchList(0, 100);
                                    }
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    public selectBatch(batch: any) {
        this.SelectedBatch = batch;
    }

    public newBatch() {
        this.dataService.activeEdit = true;
        this.router.navigate(['/batches/config']).catch(err => console.log(err));
    }

    changeBatch() {
        if (this.SelectedBatch) {
            this.dataService.activeEdit = true;
            this.dataService.SetBatchToEdit(this.SelectedBatch);
            this.router.navigate(['batches/config']).catch(err => console.log(err));
        }
    }

    public showInExectutions(SelectedBatch: any) {
        // this.dataService.BatchForTreatment = SelectedBatch;
        this.router.navigate(['/execution/' + SelectedBatch.id]).catch(err => console.log(err));
    }

    public getOutputSubstitution(batch: any, mat: string) {
        if (mat) {
            if (batch.transfer) {
                if (batch.transfer.substitution) {
                    if (batch.transfer.substitution[mat]) {
                        if (batch.transfer.substitution[mat] !== 'none') {
                            return ' (' + batch.transfer.substitution[mat] + ')';
                        }
                    }
                }
            }
        }
        return '';
    }
}
