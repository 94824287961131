<ng-container>
    <h1 class="tableheader">Lager</h1>
    <span class="btnWrapper">
             <button *ngIf="tenant ===  typeEnvironmentMss" (click)="showMSSproducts()" [class.invisible]="CurrentProductView !== 'material'" class="btn"
                     mat-button>M</button>
            <button (click)="showVIproducts()" [class.marginleft]="tenant === typeEnvironmentMss" [class.invisible]="CurrentProductView !== 'input'" class="btn"
                    mat-button>VI</button>
            <button (click)="showVZproducts()" [class.invisible]="CurrentProductView !== 'intermediate'" class="btn"
                    mat-button>VZ</button>
            <button (click)="showVOproducts()" [class.invisible]="CurrentProductView !== 'output'" class="btn"
                    mat-button>VO</button>
                <mat-form-field class="filterInput">
                <input [(ngModel)]="searchMaterial" (keyup)="applyFilter($event)" matInput placeholder="Suchtext"
                       type="search">
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </span>
    <table *ngIf="stockList" [dataSource]="dataSource" class="StockTable" fxFlexAlign="stretch" mat-table
           matSort>
        <ng-container matColumnDef="Material">
            <th *matHeaderCellDef class="thMaterial" mat-header-cell> Material</th>
            <td *matCellDef="let element" class="tdMaterial" mat-cell>
                {{element.id}} {{element.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Menge">
            <th *matHeaderCellDef class="thMenge" mat-header-cell> Menge <br> [t]</th>
            <td *matCellDef="let element" class="tdMenge" mat-cell>
                {{element.stock_level | number: '1.0-3':'de'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="InCharge">
            <th *matHeaderCellDef class="thinChargen" mat-header-cell> Verplant <br> [t]</th>
            <td *matCellDef="let element" class="tdMenge" mat-cell>
                {{element.used_stock | number: '1.0-3':'de'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Offen">
            <th *matHeaderCellDef class="thOffen" mat-header-cell> Offen <br> [t]</th>
            <td *matCellDef="let element" class="tdMenge" mat-cell>
                {{element.unused_stock | number: '1.0-3':'de'}}
            </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
    <div [ngClass]="{'paginatorBoth': skipMaterial && showNextMaterial,  'paginatorRight': !skipMaterial}"
         class="paginator">
        <div (click)="paginateLeftMaterial()" *ngIf="skipMaterial" class="previous">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div (click)="paginateRightMaterial()" *ngIf="showNextMaterial" class="next">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</ng-container>
