import { Injectable } from '@angular/core';
import {UserRoles} from '../Enums/user-roles';
import {AccessTypes} from '../Enums/access-types';
import {AccessAppAreas} from '../Enums/access-app-areas';
import {UserAccessTable} from '../Classes/user-access-table';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {

  private UserRoles: UserRoles[];

  constructor() {
    this.UserRoles = [];

  }

  public setUserRights(roles: UserRoles[]) {
    this.UserRoles = roles;
    console.log(this.UserRoles);
  }


  public hasAccessRights(area: string, type: string) {
    const permissions = UserAccessTable;

    const ar = permissions.find(ex => ex.area === area as AccessAppAreas);

    if (ar) {
      const access = ar.access.find(ex => ex.type === type as AccessTypes);
      if (access) {
        for (const r of this.UserRoles) {
          if (access.roles.find(ex => ex === r)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasAccessRightsEn(area: AccessAppAreas, type: AccessTypes) {
    const permissions = UserAccessTable;

    const ar = permissions.find(ex => ex.area === area);

    if (ar) {
      const access = ar.access.find(ex => ex.type === type);
      if (access) {
        for (const r of this.UserRoles) {
          if (access.roles.find(ex => ex === r)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public getUserRoles(): string {
    let result = '';

    for (const r of this.UserRoles) {
      result += r.toString() + '; ';
    }

    return result;

  }

}
