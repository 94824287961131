<div style="height: 100%">

    <div class="actions" fxLayout="column" style="height: 100%">

        <table class="tableExecutionsTypes" style="height: 100%">
            <tr>
                <th class="actionHeader" style="width: 20%"></th>
                <th class="actionHeader" style="width: 30%">Soll</th>
                <th class="actionHeader" style="width: 30%">Ist</th>
                <th class="actionHeader" style="width: 30%"></th>
            </tr>
            <tr *ngIf="executionBatch.process.process_category !== 'maintenance'">
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'setup'}">
                    Rüsten
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'setup'}">{{getSetupTotalTime()}}</td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'setup'}">{{getCurrentTime('setup')}}</td>
                <td>
                    <button *ngIf="currentExecution && currentExecution.execution_type === 'setup' && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="startSetupBefore()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'setup'}"
                                class="PausePlayIcon">pause
                        </mat-icon>

                    </button>
                    <button *ngIf="(!currentExecution || currentExecution.execution_type !== 'setup') && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="startSetupBefore()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'setup'}"
                                class="PausePlayIcon">play_arrow
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="executionBatch.process.process_category !== 'maintenance'">
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'processing'}">
                    Aufbereiten
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'processing'}">{{getProcessingTotalTime()}}</td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'processing'}">{{getCurrentTime('processing')}}</td>
                <td>
                    <button *ngIf="currentExecution && currentExecution.execution_type === 'processing' && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="runTreatment()">
                        <mat-icon
                                [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'processing'}"
                                class="PausePlayIcon">pause
                        </mat-icon>
                    </button>
                    <button *ngIf="(!currentExecution || currentExecution.execution_type !== 'processing') && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="runTreatment()">
                        <mat-icon
                                [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'processing'}"
                                class="PausePlayIcon">play_arrow
                        </mat-icon>

                    </button>
                </td>
            </tr>
            <tr *ngIf="executionBatch.process.process_category !== 'maintenance'">
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'teardown'}">
                    Nachbereiten
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'teardown'}">{{getTearDownTotalTime()}}</td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'teardown'}">{{getCurrentTime('teardown')}}</td>
                <td>
                    <button *ngIf="currentExecution && currentExecution.execution_type === 'teardown' && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="SetupAfter()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'teardown'}"
                                class="PausePlayIcon">pause
                        </mat-icon>
                    </button>
                    <button *ngIf="(!currentExecution || currentExecution.execution_type !== 'teardown') && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="SetupAfter()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'teardown'}"
                                class="PausePlayIcon">play_arrow
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'malfunction'}">
                    Störung
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'malfunction'}">
                    -
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'malfunction'}">{{getCurrentTime('malfunction')}}</td>
                <td>
                    <button *ngIf="currentExecution && currentExecution.execution_type === 'malfunction' && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="StartMalfunction()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'malfunction'}"
                                class="PausePlayIcon">pause
                        </mat-icon>
                    </button>
                    <button *ngIf="(!currentExecution || currentExecution.execution_type !== 'malfunction') && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="StartMalfunction()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'malfunction'}"
                                class="PausePlayIcon">play_arrow
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'maintenance'}">
                    Instandhaltung
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'maintenance'}">{{getMaintenanceTotalTime()}}</td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'maintenance'}">{{getCurrentTime('maintenance')}}</td>
                <td>
                    <button *ngIf="currentExecution && currentExecution.execution_type === 'maintenance' && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="StartMaintenance()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'maintenance'}"
                                class="PausePlayIcon">pause
                        </mat-icon>
                    </button>
                    <button *ngIf="(!currentExecution || currentExecution.execution_type !== 'maintenance') && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="StartMaintenance()">
                        <mat-icon
                                [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'maintenance'}"
                                class="PausePlayIcon">play_arrow
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="tdAction"
                    [ngClass]="{'tdActionSel': currentExecution && currentExecution.execution_type === 'pause'}">
                    Pause
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'pause'}">-
                </td>
                <td class="tdTime"
                    [ngClass]="{'tdTimeSel': currentExecution && currentExecution.execution_type === 'pause'}">{{getCurrentTime('pause')}}</td>
                <td>
                    <button *ngIf="!currentExecution && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="RunPause()">
                        <mat-icon class="PausePlayIcon">pause</mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="tdAction" [ngClass]="{'tdActionSel': !currentExecution && !executionBatch.completed}">
                    Keine Schicht
                </td>
                <td class="tdTime" [ngClass]="{'tdTimeSel': !currentExecution && !executionBatch.completed}">-</td>
                <td class="tdTime" [ngClass]="{'tdTimeSel': !currentExecution && !executionBatch.completed}">-</td>

                <td>
                    <button *ngIf="currentExecution && !executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)"
                            class="actionButton4" (click)="ShiftStop()">
                        <mat-icon class="PausePlayIcon">stop</mat-icon>
                    </button>
                </td>

            </tr>

            <tr>
                <td>
                    <hr>
                </td>
                <td>
                    <hr>
                </td>
                <td>
                    <hr>
                </td>
                <td>
                    <hr>
                </td>
            </tr>

            <tr>
                <td class="tdAction">Summe</td>
                <td class="tdTime">{{getTotalTime()}}</td>
                <td class="tdTime">{{getCurrentSumTimeComponent()}}</td>
                <td>
                    <button class="actionButton4" (click)="finishBatch()"
                            *ngIf="!executionBatch.completed && userAccess.hasAccessRightsEn(accessAreas.execution, accessTyps.edit)">
                        <mat-icon class="CheckIcon">check_box</mat-icon>
                    </button>
                </td>
            </tr>
        </table>

    </div>
</div>
