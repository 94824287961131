import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {
    MsalModule,
    MsalInterceptor,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalGuardConfiguration, MsalRedirectComponent
} from '@azure/msal-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';


import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatTabsModule} from '@angular/material/tabs';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {LayoutModule} from '@angular/cdk/layout';
import {ProcessComponent} from './Components/process/base-process/process.component';
import {LoginComponent} from './Components/login/login.component';
import {HomePageComponent} from './Components/home-page/base-home-page/home-page.component';
import {RessourceViewComponent} from './Components/ressource-view/ressource-view.component';
import {MomentPipe} from './Services/moment.pipe';
import {SelectorComponent} from './Components/cw-calender/selector/selector.component';
import {MachineFilterPipe} from './Services/machine-filter.pipe';
import {ArtikelFilterPipe} from './Services/artikel-filter.pipe';
import {WorkerFilterPipe} from './Services/worker.pipe';
import {RestypeworkerFilterPipe} from './Services/restypeworker-filter.pipe';
import {CwCalenderComponent} from './Components/cw-calender/cw-calender.component';
import {DayPipePipe} from './Services/day-pipe.pipe';
import {ProcessViewComponent} from './Components/process-view/base-process-view/process-view.component';
import {ResourcetypeViewComponent} from './Components/resourcetype-view/resourcetype-view.component';
import {environment} from '../environments/environment';
import {BatchViewComponent} from './Components/batch-view/base-batch-view/batch-view.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NewBatchComponent} from './Components/batch-new/base-batch-new/new-batch.component';
import {QuestionBoxComponent} from './Dialogs/question-box/question-box.component';
import {MessageboxComponent} from './Dialogs/messageb/messagebox.component';
import {MessageElectricityCostsComponent} from "./Dialogs/messageToShowSaveElectricityCosts/message-electricity-costs.component";
import {OptionBoxComponent} from './Dialogs/option-box/option-box.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AffectedBatchesComponent} from './Dialogs/affected-batches/affected-batches.component';
import {ExecutionScreenVsaComponent} from './Components/execution-screen/execution-screen-vsa/execution-screen-vsa.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TreatmentBookViewComponent} from './Components/treatment-book-view/treatment-book-view.component';
import {TreatmentBookComponent} from './Components/treatment-book/treatment-book.component';
import {ProcessFilterPipe} from './Services/process-filter.pipe';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication
} from '@azure/msal-browser';
import {LogLevel} from 'msal';
import {StockPipePipe} from './Services/stock-pipe.pipe';
import {MoverViewComponent} from './Components/mover-view/mover-view.component';
import {ContainerSelectionComponent} from './Dialogs/container-selection/container-selection.component';
import {TreatmantBookFilterPipe} from './Services/treatmant-book-filter.pipe';
import {AffectedRessourcesComponent} from './Dialogs/affected-ressources/affected-ressources.component';
import {ResourcetypeFilterPipe} from './Services/resourcetype-filter.pipe';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BatchFilterPipe} from './Services/batch-filter.pipe';
import {ForbiddenValidatorDirective} from './Services/forbidden-step.directive';
import {MinMaxValidatorDirective} from './Services/min-max-validator.directive';
import {MatSortModule} from '@angular/material/sort';
import * as Hammer from 'hammerjs';
import {MatRadioModule} from '@angular/material/radio';
import {Http200HeaderInceptorService} from './Interceptors/http200-header-inceptor.service';
import { MoverSelectionComponent } from './Dialogs/mover-selection/mover-selection.component';
import { StatementForwardingComponent } from './Components/statement-forwarding/statement-forwarding.component';
import {MatSelectModule} from '@angular/material/select';
import { LoginFailedComponent } from './Components/login-failed/login-failed.component';
import {ErrorWeighningComponent} from './Dialogs/errorMessageWeighning/errorweighning.component';
import { ContainerViewComponent } from './Components/container-view/container-view.component';
import { OptionBox3optionsComponent } from './Dialogs/option-box3options/option-box3options.component';
import { OptionBoxInputComponent } from './Dialogs/option-box-input/option-box-input.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { WeighingFilterComponent } from './Components/weighing-filter/weighing-filter.component';
import { ShowBatteryStatusComponent } from './Dialogs/show-battery-status/show-battery-status.component';
import { WeekReportComponent } from './Components/week-report/week-report.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ExecutionScreenMssComponent } from './Components/execution-screen/execution-screen-mss/execution-screen-mss.component';
import { MaterialNameComponent } from './Components/material-name/material-name.component';
import { ExecutionsTableComponent } from './Components/execution-screen/executions-table/executions-table.component';
import { ExecutionScreenBaseComponent } from './Components/execution-screen/execution-screen-base/execution-screen-base.component';
import { MssProcessComponent } from './Components/process/mss-process/mss-process.component';
import { VsaProcessComponent } from './Components/process/vsa-process/vsa-process.component';
import { MaterialTableComponent } from './Components/home-page/material-table/material-table.component';
import { BatchesTableComponent } from './Components/home-page/batches-table/batches-table.component';
import { ResourceUsageComponent } from './Components/resource-usage/resource-usage.component';
import { SettingsPageComponent } from './Components/settings-page/settings-page.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MenuListItemComponent } from './Components/menu-list-item/menu-list-item.component';
import {NavService} from "./Services/navigation-service/nav.service";
import {
    MessageForMssWeighningConfirmationComponent
} from "./Dialogs/messageForMssWeighningConfirmation/messageForMssWeighningConfirmation.component";



@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    override cancelBtnLabel = 'Abbrechen';
    override setBtnLabel = 'Übernehmen';
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: {direction: Hammer.DIRECTION_ALL},
    } as any;
}

// Remove this line to use Angular Universal
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    /*console.log(message);*/
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            authority: environment.msalAuthority,
            clientId: environment.msalClientId,
            redirectUri: '/',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    //
    // To use MSAL protected ARP API, define entpoints & scopes - for details see references in api.service.ts
    //
    // IMPORTANT - make sure that the scoped application supports v2 access tokens!!!
    // in manifest: "accessTokenAcceptedVersion": 2,
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-app-manifest
    //
    // EDS l1f3~ done.
    //

    for (const scope of environment.authScopes) {
        protectedResourceMap.set(
            scope.apiUrl,
            [
                scope.scope
            ]
        );
    }

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },

        loginFailedRoute: '/login-failed'
    };
}



@NgModule({
    declarations: [
        AppComponent,
        ProcessComponent,
        LoginComponent,
        HomePageComponent,
        RessourceViewComponent,
        MomentPipe,
        RestypeworkerFilterPipe,
        ProcessFilterPipe,
        SelectorComponent,
        MachineFilterPipe,
        WorkerFilterPipe,
        CwCalenderComponent,
        DayPipePipe,
        ArtikelFilterPipe,
        ProcessViewComponent,
        ResourcetypeViewComponent,
        BatchViewComponent,
        NewBatchComponent,
        QuestionBoxComponent,
        MessageboxComponent,
        OptionBoxComponent,
        AffectedBatchesComponent,
        ExecutionScreenVsaComponent,
        TreatmentBookViewComponent,
        TreatmentBookComponent,
        StockPipePipe,
        MoverViewComponent,
        ContainerSelectionComponent,
        TreatmantBookFilterPipe,
        AffectedRessourcesComponent,
        ResourcetypeFilterPipe,
        BatchFilterPipe,
        ForbiddenValidatorDirective,
        ForbiddenValidatorDirective,
        MinMaxValidatorDirective,
        MoverSelectionComponent,
        StatementForwardingComponent,
        LoginFailedComponent,
        ErrorWeighningComponent,
        ContainerViewComponent,
        OptionBox3optionsComponent,
        OptionBoxInputComponent,
        WeighingFilterComponent,
        ShowBatteryStatusComponent,
        WeekReportComponent,
        ExecutionScreenMssComponent,
        MaterialNameComponent,
        ExecutionsTableComponent,
        ExecutionScreenBaseComponent,
        MssProcessComponent,
        VsaProcessComponent,
        MaterialTableComponent,
        BatchesTableComponent,
        ResourceUsageComponent,
        SettingsPageComponent,
        MessageElectricityCostsComponent,
        MenuListItemComponent,
        MessageForMssWeighningConfirmationComponent

    ],
    imports: [

        BrowserModule,
        NoopAnimationsModule,
        AppRoutingModule,
        ClipboardModule,
        HttpClientModule,
        MatSidenavModule,
        MatListModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatTableModule,
        MatTabsModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        MsalModule,
        LayoutModule,
        FlexLayoutModule,
        MatDialogModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatRadioModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MatSortModule,
        HammerModule,
        MatSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatSlideToggleModule,
        MatExpansionModule

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            provide: OwlDateTimeIntl,
            useClass: DefaultIntl,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        NavService,
        { provide: HTTP_INTERCEPTORS, useClass: Http200HeaderInceptorService, multi: true },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
