import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-option-box-input',
  templateUrl: './option-box-input.component.html',
  styleUrls: ['./option-box-input.component.css']
})
export class OptionBoxInputComponent implements OnInit {

  public txt: string;
  public option1: string;
  public option2: string;
  public product: any;
  public weight: any;
  public batch: any;
  public unit: any;

  constructor(public DialogRef: MatDialogRef<OptionBoxInputComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.product = data.product;
    this.weight = data.weight;
    this.batch = data.batch;
    this.unit = data.unit;

    if (data.txt) {
      this.txt = data.txt;
    }

    if (data.option1) {
      this.option1 = data.option1;
    }

    if (data.option2) {
      this.option2 = data.option2;
    }


  }



  ngOnInit(): void {

  }

  public Option1() {
    this.DialogRef.close(1);
  }

  public Option2() {
    this.DialogRef.close(2);
  }

}
