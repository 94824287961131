<section fxLayout="column">
    <div class="headerline" fxLayout="row">
        <p class="viewheader">Abrechnungen</p>
    </div>
    <hr>
    <div fxLayout="row" class="searchWrap">
        <mat-form-field class="searchbar">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="search" placeholder="Filtertext" [(ngModel)]="SearchString">
        </mat-form-field>
        <button mat-button class="btn" (click)="toggle()" [disabled]="!flagShowBtn"
                [ngClass]="{'toggled': !flagShowBtn, 'Nottoggled': flagShowBtn}">Alle
        </button>
        <button mat-button class="btn" (click)="toggle()" [disabled]="flagShowBtn"
                [ngClass]="{'toggled': flagShowBtn, 'Nottoggled': !flagShowBtn}">Offene
        </button>
    </div>


    <div class="content" fxLayout="row" *ngIf="userAccess.hasAccessRightsEn(accesAreas.calculation, accessTyps.view)">
        <cdk-virtual-scroll-viewport class="listview viewport" itemSize="200">
            <div class="batchentry" [class.booked]="batch.booked"
                 [ngClass]="{'batchentrySel': SelectedBatch && SelectedBatch.id === batch.id}"
                 *ngFor="let batch of batches | batchFilter:SearchString" fxLayout="column"
                 (click)="selectBatch(batch)">
                <img *ngIf="batch.transfer" class="ksalogo" src="assets/images/ksa.png" alt="ksalogo">
                <div class="elementrow2" fxLayout="row">
                    <p class="header">Chargenname: </p>
                    <p class="txt"> {{batch.name}}</p>
                </div>


                <div class="elementrow2" fxLayout="row">
                    <p class="header">Startzeitpunkt: </p>
                    <p class="txt"
                       *ngIf="batch.scheduled_start"> {{batch.scheduled_start | moment: 'dddd DD. MMMM yyyy HH:mm'}} (geplant)</p>
                </div>
                <div class="elementrow2" *ngIf="batch.transfer" fxLayout="row">
                    <p class="header">Chargennr: </p>
                    <p class="txt"> {{batch.transfer.erp_batch}}</p>
                </div>
                <div class="addinfo" fxLayout="row" >
                    <div class="elementrow" fxLayout="column">
                        <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                            <ng-container *ngIf="tenant !==  typeEnvironmentMss">
                                <p class="header">Input</p>
                                <div class="outputmaterialentry" fxLayout="row">
                                    <p class="outmaterial">{{batch.input.id}} {{batch.input.name}} - {{batch.quantity}}t</p>
                                </div>
                            </ng-container>
                        </ng-container>
                        <p class="header">Ressourcentypen</p>
                        <div class="ressourcesentry" *ngFor="let ressour of batch.resources">
                            <p class="resour">{{ressour.name}} ({{ressour.resource_type.name}})</p>
                        </div>

                    </div>
                    <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                        <div class="flexIcon">
                            <mat-icon class="iconArrow">chevron_right</mat-icon>
                        </div>
                        <div class="addinfo" [class.flexResources] = "tenant !==  typeEnvironmentMss">
                            <p class="addHeader">Output</p>
                            <div class="outputmaterialentry" *ngFor="let outmat of batch.output">
                                <p class="outmaterial">{{outmat.id}} {{outmat.name}} {{functionsService.getOutputSubstitution(batch, outmat.id)}}</p>
                            </div>

                        </div>
                    </ng-container>

                </div>


            </div>
        </cdk-virtual-scroll-viewport>

        <div class="selectedBatch" *ngIf="SelectedBatch">
            <div class="headerline" fxLayout="row">
                <p class="viewheader">Ausgewählt</p>
                <button (click)="bookBatch()">
                    Öffnen
                </button>
            </div>
            <hr>

            <div class="elementrow3" fxLayout="row">
                <p class="header">Charge:</p>
                <p class="txt">{{SelectedBatch.name}}</p>
            </div>
            <img *ngIf="SelectedBatch.transfer" class="ksalogoselected" src="assets/images/ksa.png" alt="ksalogo">
            <ng-container *ngIf="tenant !==  typeEnvironmentMss">
                <div class="elementrow3" fxLayout="row" *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                    <p class="header">Inputartikel:</p>
                    <p class="txt">{{SelectedBatch.input.id}} {{SelectedBatch.input.name}}</p>
                </div>
            </ng-container>
            <div class="elementrow3" fxLayout="row" *ngIf="SelectedBatch.transfer">
                <p class="header">Chargenr.</p>
                <p class="txt">{{SelectedBatch.transfer.erp_batch}}</p>
            </div>
            <div class="elementrow3" fxLayout="row" *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                <p class="header">Menge:</p>
                <p class="txt">{{SelectedBatch.quantity | number: '1.2-2':'de'}}t</p>
            </div>
            <div class="elementrow3" fxLayout="row">
                <p class="header">Realer Startzeitpunkt:</p>
                <p class="txt"
                   *ngIf="SelectedBatch.executions.length > 0">{{getStartTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</p>
            </div>
            <div class="elementrow3" fxLayout="row">
                <p class="header">Abgeschlossen:</p>
                <p class="txt"
                   *ngIf="SelectedBatch.executions.length > 0">{{getEndTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</p>
            </div>
            <div class="elementrow3" fxLayout="row">
                <p class="header">DLZ:</p>
                <p class="txt" *ngIf="SelectedBatch.executions">{{getRealRuntime() | number: '1.2-2':'de'}} h</p>
            </div>


            <ng-container *ngIf="SelectedBatch.process.process_category !== 'maintenance'">
                <div class="headerlineResources" fxLayout="row">
                    <p class="viewheader">Outputmaterial</p>
                </div>
                <hr class="breakerUnderElement">

                <table class="productTable2">
                    <tr>
                        <th>Name</th>
                        <th>Menge</th>
                    </tr>
                    <tr *ngFor="let outmat of getOutputProducts()">
                        <td class="outprodName">{{outmat.id}} {{outmat.name}} {{functionsService.getOutputSubstitution(SelectedBatch, outmat.id)}}</td>
                        <td>{{outmat.weight | number: '1.2-2':'de'}} t</td>
                    </tr>
                    <tr style="border-top: 1pt  solid black;" >
                        <th>Summe</th>
                        <th>{{getOutputMaterialSum() | number: '1.2-2':'de'}} t</th>
                    </tr>
                </table>
            </ng-container>

        </div>
    </div>
    <div class="paginator" [ngClass]="{'paginatorBoth': skip && showNext,  'paginatorRight': !skip}">
        <div class="previous" (click)="paginateLeft()" *ngIf="skip">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div class="next" (click)="paginateRight()" *ngIf="showNext">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>

</section>
