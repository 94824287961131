import {Injectable} from '@angular/core';
import * as moment from 'moment/moment';
import {MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';


/*export function getAvailableScales() {
    this.apiService.getAvailableScales().subscribe((scales: any) => {
        if (scales) {
            if (scales.length > 0) {
                this.Scales = scales;
            }
        }
        this.productQuantity = 0.0;
        this.slideCenter = true;
        this.slideRight = true;
    });
}*/

export function showWeighingSavedDialog() {
    const showWeighingSavedDialog = new MatDialogConfig();
    showWeighingSavedDialog.disableClose = true;
    showWeighingSavedDialog.autoFocus = true;
    showWeighingSavedDialog.panelClass = 'dialogStyles';
    showWeighingSavedDialog.data = 'Verwiegung gespeichert';
    const dialogreference = this.dialog.open(MessageboxComponent, showWeighingSavedDialog);
    setTimeout(() => {
        dialogreference.close();
    }, 3000);
}

export function updateExecutionBatch() {
    if (this.executionBatch) {
        if (this.executionBatch.executions) {
            this.currentExecution = this.executionBatch.executions.find(ex => !ex.ended);

            // LAST ONE
            let last = this.executionBatch.executions.filter(ex => ex.ended);

            if (last.count > 0) {
                last = last.sort((a, b) => (a.started < b.started ? -1 : 1));

                this.lastExecution = last[last.length - 1];
            }

            if (this.currentExecution) {
                const cur = moment.utc(this.currentExecution.started).local();
                if (cur.isAfter(moment(this.dataService.currentServerTime).local())) {
                    this.dataService.currentServerTime = moment.utc(this.currentExecution.started).local().toDate();
                }
            } else if (this.lastExecution) {
                const cur = moment.utc(this.lastExecution.ended).local();
                if (cur.isAfter(moment(this.dataService.currentServerTime).local())) {
                    this.dataService.currentServerTime = moment.utc(this.currentExecution.started).local().toDate();
                }
            }
        }
    }
}

export function getCurrentSumTime() {
    if (this.executionBatch) {
        if (this.executionBatch.executions) {
            // SUM ALL
            let seconds = 0.0;
            for (const et of this.executionBatch.executions.filter(ex => ex.ended)) {
                const start = moment(et.started);
                const end = moment(et.ended);

                seconds += end.diff(start, 'seconds');
            }

            if (this.currentExecution) {
                const cur = moment.utc(this.currentExecution.started).local();
                const now = moment(this.dataService.currentServerTime).local();
                seconds += now.diff(cur, 'seconds');
            }
            return this.functionsService.durationToString(seconds);
        }
    }
    return '0:00:00';
}

export function onBruttoInput($event) {
    if ($event.target.value > 999) {
        this.manualBrutto = 0;
        $event.target.value = 0;
    }

    if ($event.target.value === '') {
        $event.target.value = 0;
    }
    if ($event.target.value < 0 || isNaN($event.target.value)) {
        this.manualBrutto = 0;
        $event.target.value = 0;

    }
    if ($event.key === 'e') {
        this.manualBrutto = 0;
        $event.target.value = 0;
    }

    if ($event.target.value.substring($event.target.value.indexOf('.')).length > 4) {
        this.manualBrutto = 0;
        $event.target.value = 0;
    }

    this.productQuantity = this.manualBrutto - this.manualTara;


}

export function onTaraInput($event) {
    if ($event.target.value > 999) {
        this.manualTara = 0;
        $event.target.value = 0;
    }

    if ($event.target.value === '') {
        $event.target.value = 0;
    }
    if ($event.target.value < 0 || isNaN($event.target.value)) {
        this.manualTara = 0;
        $event.target.value = 0;

    }
    if ($event.key === 'e') {
        this.manualTara = 0;
        $event.target.value = 0;
    }

    if ($event.target.value.substring($event.target.value.indexOf('.')).length > 4) {
        this.manualTara = 0;
        $event.target.value = 0;
    }

    if (this.manualTara !== undefined || this.manualTara !== 0) {
        this.productQuantity = this.manualBrutto - this.manualTara;
    }


}

export function startWebSocketSubscription(WebsocketConnected, WebsocketMessage, WebsocketError, WebsocketClosed, WebsocketConnectError) {
    this.lastScaleWeighing = {};
    this.subscriptionWebSocketConnected = this.websocket.WebsocketConnected.subscribe(WebsocketConnected.bind(this));
    this.subscriptionWebSocketMessage = this.websocket.WebsocketMessage.subscribe(WebsocketMessage.bind(this));
    this.subscriptionWebSocketError = this.websocket.WebsocketError.subscribe(WebsocketError.bind(this));
    this.subscriptionWebSocketClosed = this.websocket.WebsocketClosed.subscribe(WebsocketClosed.bind(this));
    this.subscriptionWebSocketConnectionError = this.websocket.ConnectionError.subscribe(WebsocketConnectError.bind(this));
}

// tslint:disable-next-line:max-line-length
export function unsubscribeWebSocketSubscription(subscriptionWebSocketConnected, subscriptionWebSocketMessage, subscriptionWebSocketError, subscriptionWebSocketClosed, subscriptionWebSocketConnectionError) {
    this.closeWebsocket();
    subscriptionWebSocketConnected.unsubscribe();
    subscriptionWebSocketMessage.unsubscribe();
    subscriptionWebSocketError.unsubscribe();
    subscriptionWebSocketClosed.unsubscribe();
    subscriptionWebSocketConnectionError.unsubscribe();
}


@Injectable({
    providedIn: 'root'
})

export class GlobalFunctionsService {
    constructor() {
    }

}





