
    <mat-dialog-content>
        <div fxLayout="column">
            <p><strong>Folgende Ressourcen würden durch diese Änderung beeinflusst werden:</strong></p>

            <div fxLayout="column" *ngFor="let bt of resources">
                <p>
                    {{bt.name}}
                </p>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions >
        <button class="yesButton"  (click)="Cancel()">Abbrechen</button>
        <button class="noButton"  (click)="Continue()">Fortfahren</button>
    </mat-dialog-actions>

