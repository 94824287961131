
    <mat-dialog-content>
        <p><strong>{{txt}}</strong></p>
        <div class="wrappingClass">
            <p>Charge <strong>{{batch}}</strong></p>
            <p *ngIf="product">Material <strong>{{product}}</strong></p>
            <p>Nettogewicht <strong>{{weight| number: '1.0-3':'de'}} {{unit}}</strong></p>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="yesButton buttonDialog"  (click)="Option1()">{{option1}}</button>
        <button class="noButton buttonDialog"  (click)="Option2()">{{option2}}</button>
    </mat-dialog-actions>


