
<div>
    <h1 class="tableheader">Einstellungen</h1>
    <div class="displayRow">
        <div class="displayColumn leftCol">
            <p style="text-align: right">
                Stromkosten [€/kWh]
            </p>
            <p style="margin-top: 18px">
                Gemeinkosten Strom [%]
            </p>
        </div>
        <div class="displayColumn rightCol">
            <mat-form-field>
                <input step="any" type="number" matInput class="electricityInput" [(ngModel)]="electricityCosts" (keyup)="onInputElectricityCosts($event.target, $event)">
            </mat-form-field>
            <mat-form-field>
                <input step="any" type="number" matInput class="electricityInput" [(ngModel)]="overheadCosts" (keyup)="onInputOverheadCosts($event.target, $event)">
            </mat-form-field>

            <mat-icon *ngIf="overheadCosts !== null && electricityCosts !== null && overheadCosts >=0 && electricityCosts >=0" class="buttonSave icon" (click)="saveElectricity()" matPrefix>save</mat-icon>
        </div>
    </div>



</div>
