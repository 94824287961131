<div class="wrapper">
    <div fxLayout="row notToPrint">
        <p class="viewheader notToPrint">Wochenbericht</p>
        <div class="dateDiv notToPrint" fxFlexAlign="center" fxLayout="row">
            <p class="kwselector notToPrint">
                KW
                <i (click)="goCw(-1)" class="material-icons notToPrint">arrow_left</i>
                <span>
                    {{ dateService.date | async | moment:'ww' }}
                </span>
                <i (click)="goCw(1)" class="material-icons notToPrint">arrow_right</i>
                <i (click)="goYear(-1)" class="material-icons notToPrint">arrow_left</i>
                <span class="notToPrint">
                    {{ dateService.date | async | moment:'yyyy' }}
                </span>
                <i (click)="goYear(1)" class="material-icons notToPrint">arrow_right</i>
                <span class="btnWrapper notToPrint">
                    <button (click)="currentweek()" class="btn" mat-button>Aktuelle Woche</button>
                </span>
            </p>

        </div>
    </div>
    <div class="forPrinting">
        <p>
            Kalenderwoche
            <span>
                    {{ dateService.date | async | moment:'ww' }}
                </span>
        </p>
    </div>


    <table *ngIf="batchesToShow" [dataSource]="batchesToShow" class="reportsTable" fxFlexAlign="stretch"
           mat-table matSort>

        <ng-container matColumnDef="Datum">
            <th *matHeaderCellDef mat-header-cell style="width: 10%" class="headerCell">Datum</th>
            <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                {{element.date}}
            </td>
            <td class="footerCell" mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="Zeit">
            <th *matHeaderCellDef mat-header-cell style="width: 10%" class="headerCell">Zeit (Soll)</th>
            <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                {{element.start}} -
                {{element.end}}
            </td>
            <td mat-footer-cell *matFooterCellDef class="tableCell sumCell"> Summen</td>
        </ng-container>
        <ng-container matColumnDef="Dauer">
            <th *matHeaderCellDef mat-header-cell style="width: 20%" class="headerCell">Dauer (Ist)</th>
            <td *matCellDef="let element" [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'" mat-cell>
                <div fxLayout="column">
                    <table class="executionsTimeTable">
                        <tr *ngIf="!element.haveBefore && element.hasTeardownOrSetup">
                            <td class="tableCellTop" style="margin-top: -5px">Rüsten :</td>
                            <td class="tableCellTop">{{functionsService.durationToString(getTeardownAndSetupTime(element))}}</td>
                        </tr>
                        <tr *ngFor="let time of element.execution_times| keyvalue">
                            <td class="tableCellTop" *ngIf="!element.haveBefore">
                                <ng-container *ngIf="time.key !=='teardown' && time.key !=='setup'">
                                    {{convertExecutionName(time.key)}}:
                                </ng-container>
                            </td>
                            <td class="tableCellTop" *ngIf="!element.haveBefore"><ng-container *ngIf="time.key !=='teardown' && time.key !=='setup'">
                                {{functionsService.durationToString(getCurrentTime(element, time.key))}}
                            </ng-container>
                            </td>
                        </tr>
                        <tr class="sumCell" *ngIf="!element.haveBefore && element.execution_time != 0">
                            <td class="tableCellTop">Summe</td>
                            <td class="tableCellTop">{{functionsService.durationToString(element.execution_time)}}</td>
                        </tr>
                    </table>


                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef class="tableCell  executionsSumCell">
                <div fxLayout="column">
                    <table class="executionsTimeTable">
                        <tr *ngIf="getTeardownSetupTimeTotal(batchesToShow) !== '' && batchesToShow.length > 0">
                            <td class="tableCellTop" style="margin-top: -5px">Rüsten :</td>
                            <td>{{getTeardownSetupTimeTotal(batchesToShow)}}</td>
                        </tr>
                        <tr *ngFor="let time of getExecutionsTimeTotal(batchesToShow)| keyvalue">
                            <td class="tableCellTop">
                                <ng-container *ngIf="time.key !=='teardown' && time.key !=='setup'">
                                    {{convertExecutionName(time.key)}}:
                                </ng-container>
                            </td>
                            <td class="tableCellTop"><ng-container *ngIf="time.key !=='teardown' && time.key !=='setup'">
                                {{functionsService.durationToString(time.value)}}
                            </ng-container>
                            </td>
                        </tr>
                        <tr class="sumCell">
                            <td class="executionsTimeTable">Summe</td>
                            <td>{{getExecutionTimeTotal(batchesToShow)}}</td>
                        </tr>
                    </table>
                </div>

            </td>
        </ng-container>
        <ng-container matColumnDef="Charge">
            <th *matHeaderCellDef mat-header-cell style="width: 15%" class="headerCell">Charge</th>
            <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                {{element.name}}
            </td>
            <td mat-footer-cell *matFooterCellDef class="tableCell"></td>
        </ng-container>
        <ng-container matColumnDef="Aufgabe">
            <th *matHeaderCellDef mat-header-cell style="width: 15%" class="headerCell">Aufgabe</th>
            <ng-container *ngIf="tenant ===  typeEnvironmentMss; else VSAinputblock">
                <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                    <ng-container *ngIf="element.process.process_category !== 'maintenance'">
                        <ng-container *ngIf="!element.haveBefore">Aufgabematerial </ng-container>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef
                    class="tableCell sumCell">Aufgabematerial
                </td>
            </ng-container>
            <ng-template #VSAinputblock>
                <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times) && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                    <ng-container *ngIf="element.process.process_category !== 'maintenance'">
                        <ng-container *ngIf="!element.haveBefore">{{element.input.name}} ({{element.quantity}} t)</ng-container>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef
                    class="tableCell sumCell"> {{getOutputSumTotal(batchesToShow)| number: '1.3-3':'de'}} t
                </td>
            </ng-template>

        </ng-container>
        <ng-container matColumnDef="Produkt">
            <th *matHeaderCellDef mat-header-cell style="width: 15%" class="headerCell">Produkt</th>
            <td *matCellDef="let element" mat-cell [ngClass]="checkObjectHasProperties(element.execution_times)  && !element.haveBefore ? 'tableCell' : 'tableCellMiddle'">
                <ng-container *ngFor="let ot of getWeightingList(element)">
                    {{ot.id}}
                    {{ot.name}} {{getOutputSubstitution(element, ot.id)}}
                    <ng-container *ngIf="!element.haveBefore">({{ot.weight  | number: '1.3-3':'de'}} t)</ng-container>

                    <br>
                </ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef
                class="tableCell sumCell"> {{getWeightSumTotal(batchesToShow)| number: '1.3-3':'de'}} t
            </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        <tr class="footerRow" mat-footer-row id="footer"
            *matFooterRowDef="['Datum', 'Zeit', 'Dauer', 'Charge', 'Aufgabe', 'Produkt'];"></tr>
    </table>
</div>
