<div class="flexWrapper" fxLayout="column">
    <div *ngIf="treatments && treatments.length > 0 && userAccess.hasAccessRightsEn(accesAreas.execution, accessTyps.view)"
         class="treatmentList">
        <div fxFlexAlign="stretch" fxLayout="row">
            <h1>Laufende Aufbereitungen </h1>
            <div *ngIf="treatments.length>4">
                <mat-icon (click)="showMoreCharges()" *ngIf="!showMore" class="toggleIcon" matPrefix>
                    keyboard_arrow_down
                </mat-icon>
                <mat-icon (click)="showMoreCharges()" *ngIf="showMore" class="toggleIcon" matPrefix>keyboard_arrow_up
                </mat-icon>
            </div>

        </div>
        <hr style="margin-top: -18px;">
        <div class="displayExecutions">
            <div *ngFor="let tr of treatments; index as i">
                <p *ngIf="i<4 && !showMore">
                <span (click)="selectTreatment(tr)" *ngIf="i<4 && !showMore" class="chooseExecution">{{tr.batch_name}}
                    <strong>Status: </strong> {{getTreatmentState(tr.execution_type)}}
                    <strong>Zeit: </strong> {{getCurrentTime(tr.execution_type, tr)}}</span>
                </p>
                <p *ngIf="showMore">
                    <span (click)="selectTreatment(tr)" *ngIf="showMore" class="chooseExecution">{{tr.batch_name}}
                        <strong>Status: </strong> {{getTreatmentState(tr.execution_type)}}
                        <strong>Zeit: </strong> {{getCurrentTime(tr.execution_type, tr)}}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="tablesWrap">
        <div *ngIf="userAccess.hasAccessRightsEn(accesAreas.material, accessTyps.view)" class="leftTable"
             fxLayout="column">
            <app-material-table style="display: flex; flex-direction: column"></app-material-table>
        </div>
        <div *ngIf="userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.view)" class="content" fxLayout="column">
            <app-batches-table style="display: flex; flex-direction: column"></app-batches-table>
        </div>
    </div>
</div>

