import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'RestypeworkerFilter'
})
export class RestypeworkerFilterPipe implements PipeTransform {

  transform(machines: any[], search: string = ''): any[] {
    if (!search.trim()) {
      return machines;
    }

    return machines.filter(machine => {
      return machine.group.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          machine.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

}
