export enum AccessAppAreas {
    batch = 'batch',
    container = 'container',
    execution = 'execution',
    material = 'material',
    mover = 'mover',
    plan = 'plan',
    process = 'process',
    resource = 'resource',
    resource_type = 'resource_type',
    scale = 'scale',
    weighing = 'weighing',
    calculation = 'calculation',
    weighing_filter = 'weighing_filter',
    week_report = 'week_report',
    settings = 'settings',
    resource_usage = 'resource_usage'
}
