import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ResourceTypeFilter'
})
export class ResourcetypeFilterPipe implements PipeTransform {

  transform(workers: any[], search: string = ''): any[] {
    if (!search.trim()) {
      return workers;
    }
    return workers.filter(worker => {
      return worker.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

}
