import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeWhile, timer} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-message-weighning-confirmation',
  templateUrl: './messageForMssWeighningConfirmation.component.html',
  styleUrls: ['./messageForMssWeighningConfirmation.component.scss']
})
export class MessageForMssWeighningConfirmationComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<MessageForMssWeighningConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.product = data.product;
    this.weight = data.weight;
    this.batch = data.batch;
    this.unit = data.unit;
    if (data.txt) {
      this.txt = data.txt;
    }

    if (data.option1) {
      this.option1 = data.option1;
    }

    if (data.option2) {
      this.option2 = data.option2;
    }

  }

  public txt: string;
  public option1: string;
  public option2: string;
  public product: any;
  public weight: any;
  public batch: any;
  public unit: any;

  ngOnInit(): void {
  }

  @Input() seconds = 3;

  timeRemaining$ = timer(0, 1000).pipe(
      map(n => (this.seconds - n) * 1000),
      takeWhile(n => n >= 0),
  );

  public cancelWeighning() {
    this.DialogRef.close(true);
  }

}
