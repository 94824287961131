import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {environment} from '../../environments/environment';
import {ResourcetypeGroups} from '../Enums/resourcetype-groups.enum';
import {MaterialTypes} from '../Enums/material-types.enum';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../Dialogs/messageb/messagebox.component';
import {UserAccessService} from './user-access.service';
import {DataShareService} from './data-share.service';
import {Router} from '@angular/router';
import {GlobalFunctionsService} from './global-functions';

@Injectable({
    providedIn: 'root'
})
export class APIService {

    public DefaultPostHeader: HttpHeaders;
    public DefaultGetHeader: HttpHeaders;

    private versioned = false;
    private versions: string[];
    public versioningFinished = false;
    public OnVersioningFinished = new EventEmitter();
    private dialogConfig = new MatDialogConfig();


    // tslint:disable-next-line:max-line-length
    constructor(private functionsService: GlobalFunctionsService, private http: HttpClient, public dialog: MatDialog, public userAccess: UserAccessService, public dataService: DataShareService, public router: Router, ) {
        registerLocaleData(localeDe);
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;
        this.dialogConfig.panelClass = 'dialogStyles';

        this.DefaultPostHeader = new HttpHeaders();
        const utcOffset = -(new Date().getTimezoneOffset());
        this.DefaultPostHeader.append('Content-Type', 'application/json');
        this.DefaultPostHeader.append('utc-offset', utcOffset.toString());
        this.DefaultPostHeader.append('platform', 'WEB');
        this.DefaultPostHeader.append('app-version', '0.1.0');
        this.DefaultPostHeader.append('version', '1.0');
        this.DefaultPostHeader.append('accept', 'application/json');
        this.DefaultPostHeader.append('Access-Control-Allow-Origin', '*');
        this.DefaultPostHeader.append('Access-Control-Allow-Origin', 'localhost:4200');
        this.DefaultPostHeader.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.DefaultPostHeader.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

        this.DefaultGetHeader = new HttpHeaders();
        this.DefaultGetHeader.append('utc-offset', utcOffset.toString());
        this.DefaultGetHeader.append('platform', 'WEB');
        this.DefaultGetHeader.append('app-version', '0.1.0');
        this.DefaultGetHeader.append('version', '1.0');
        this.DefaultGetHeader.append('accept', 'application/json');
        this.DefaultGetHeader.append('Access-Control-Allow-Origin', '*');
        this.DefaultGetHeader.append('Access-Control-Allow-Origin', 'localhost:4200');
        this.DefaultGetHeader.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.DefaultGetHeader.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');



    }

    public SetTokenAquiringFinished() {
        this.checkIfVersioned();
    }

    private checkIfVersioned() {
        this.versioned = false;
        this.versioningFinished = false;
        this.versions = [];
        // LOAD openapi.json
        const configUrl = environment.arpApiUrl + '/openapi.json';
        const versions: string[] = [];
        this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(this.handleError)
            ).subscribe((data: any) => {
            console.log(data);


            if (data.paths) {
                for (const pth of Object.keys(data.paths)) {
                    if (pth.indexOf('openapi.json') >= 0) {
                        this.versioned = true;
                        versions.push(pth);
                    }
                }
            }

            if (versions.length > 0) {
                for (const v of versions) {
                    // CALL
                    console.log(v);
                    const url = environment.arpApiUrl + v;
                    this.http.get(url, {headers: this.DefaultGetHeader})
                        .pipe(
                            catchError(this.handleError)
                        ).subscribe((datax: any) => {
                        if (datax) {
                            if (datax.servers) {
                                for (const server of datax.servers) {
                                    if (server.url) {
                                        this.versions.push(server.url);
                                    }
                                }
                            }
                        }

                        if (this.versions.length >= versions.length) {
                            console.log(this.versions);
                            if (this.versions.find(ex => ex.indexOf('v2_0') >= 0)) {
                                console.log('version check finished');
                                console.log(this.versions);
                                this.versioningFinished = true;
                                this.getUserInformations();
                                this.OnVersioningFinished.emit();
                            } else {
                                // WAIT TO RETRY
                                setTimeout(() => {
                                    this.checkIfVersioned();
                                }, 3000);
                            }


                        }

                    });
                }
            } else {
                console.log('no versions');
                this.versioningFinished = true;
                this.getUserInformations();
                this.OnVersioningFinished.emit();
            }

        });

    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                ` Backend returned code ${error.status},` +
                `body was: ${error.message}`);
            console.log(error);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            error);
    }

    public showError() {
        this.functionsService.handleServerNotReachableError();
    }

    private GetActiveApiUrl(version = '1.0'): string {
        if (this.versioned === true) {
            // CHECK VERSION
            const casted = '/v' + version.replace('.', '_');

            if (this.versions.find(ex => ex === casted)) {
                return environment.arpApiUrl + casted + '/';
            } else {
                console.error('version ' + version + ' in api not available');
                return environment.arpApiUrl + this.versions[0] + '/';
            }

        } else {
            return environment.arpApiUrl + '/';
        }
    }

    // API V2 CALLS

    private getUserInformations() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'users/me';

        this.http.get(configUrl, {headers: this.DefaultGetHeader}
        ).pipe(
            catchError(error => {
                console.log(error);

                if (error.status === 404) {
                    this.dialogConfig.data = 'Material konnte nicht gefunden werden';
                    this.dialog.open(MessageboxComponent, this.dialogConfig);
                } else {
                    this.handleDefaultErrors(error);
                }

                return throwError(error);
            })
        ).subscribe((user: any) => {
           if (user.roles) {
               this.userAccess.setUserRights(user.roles);
           }
        });
    }

    private handleDefaultErrors(error: any) {
        if (this.dialog.openDialogs.length > 0) {
            return;
        }
        if (error.status === 500) {
            // INTERNAL SERVER ERROR
            this.dialogConfig.data = 'Kommunikation mit Server fehlgeschlagen';
            this.dialog.open(MessageboxComponent, this.dialogConfig);
        } else if (error.status === 401) {
            // UNAUTHORIZED
            this.dialogConfig.data = 'Sie sind nicht angemeldet';
            this.dialog.open(MessageboxComponent, this.dialogConfig);
        } else if (error.status === 403) {
            // FORBIDDEN
            this.dialogConfig.data = 'Sie verfügen nicht über die notwendigen Berechtigungen';
            this.dialog.open(MessageboxComponent, this.dialogConfig);
            /*} else if (error.status === 406) {
                // NOT ACCEPTABLE
                this.dialogConfig.data = 'Die Anfrage kann in dieser Form nicht ausgeführt werden';
                this.dialog.open(MessagebComponent, this.dialogConfig);*/
        } else if (error.status === 422) {
            // Validation error
            this.dialogConfig.data = 'Die Eingaben sind in dieser Form nicht korrekt';
            this.dialog.open(MessageboxComponent, this.dialogConfig);
        } else {
            // UNHANDLED
            console.error(error);
        }
    }

    public getMaterialStock(type: MaterialTypes, skip, limit) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'materials/?type=' + type.toString() + `&show_usage=true&skip=${skip}&limit=${limit}`;

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader}
        )
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Material konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );

        //
        // //
        // // Test call to ARP API using MSAL
        // //
        // // References:
        // // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=angular2
        // // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-call-api?tabs=javascript
        // // https://thecodeblogger.com/2020/05/05/angular-app-and-azure-ad-protected-web-api-using-msal/
        // // https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular
        // // https://stackoverflow.com/questions/60561556/is-active-directory-not-supporting-authorization-code-flow-with-pkce
        // //
        // // EDS l1f3~ done.
        // //
        // console.log('=====================================');
        // console.log('Test call to ARP API using MSAL');
        // console.log('=====================================');
        // const arpApiMeEndpoint = this.GetActiveApiUrl('2.0') + 'users/me';
        //
        // // Legacy XHR - HTTP 200 - works because of MSAL interceptor
        // this.http.get(arpApiMeEndpoint)
        //     .subscribe(
        //         res => console.log('HTTP response', res),
        //         err => console.log('HTTP Error', err),
        //         () => console.log('HTTP request completed.')
        //     );
        //
        // // Modern fetch - HTTP 401 Unauthorized - fails because of missing token
        // /*fetch(arpApiMeEndpoint)
        //     .then(response => console.log(response));*/
        // console.log('=====================================');


    }

    public getSingleMaterialStock(materialId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'materials/' + materialId + '/?show_usage=true';

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Material konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getSingleMaterial(materialId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'materials/' + materialId + '/';

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Material konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getProducts(type: string, top10 = false) {
        let configUrl = this.GetActiveApiUrl('2.0') + 'materials/?type=' + type + '&show_usage=false&skip=0';

        let limit = '&limit=100';
        if (top10 === true) {
            limit = '&orderby=stock_level&limit=10';
        }

        configUrl += limit;

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Material konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getWeighings(skip, limit, dateFrom, dateTo, product, productName, scale, user, batchName, nettoFrom, nettoTo) {
        let configUrl = this.GetActiveApiUrl('2.0') + `weighings/?date_from=${dateFrom}` + `&date_to=${dateTo}`;
        if (user != null) {
            configUrl = configUrl + `&username=${user}`;
        }
        if (batchName != null) {
            configUrl = configUrl + `&batch_name=${batchName}`;
        }
        if (product != null) {
            configUrl = configUrl + `&material_id=${product}`;
        }
        if (productName != null) {
            configUrl = configUrl + `&material_name=${productName}`;
        }
        if (scale != null && scale !== '') {
            configUrl = configUrl + `&scale_id=${scale}`;
        }
        if (nettoFrom != null) {
            configUrl = configUrl + `&net_weight_from=${nettoFrom}`;
        }
        if (nettoTo != null) {
            configUrl = configUrl + `&net_weight_to=${nettoTo}`;
        }

        configUrl = configUrl + `&skip=${skip}&limit=${limit}`;

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public deleteWeighing(weighing) {
        let configUrl = this.GetActiveApiUrl('2.0') + `weighings/${weighing.id}`;
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    if (error.status === 404) {
                        this.dialogConfig.data = 'Wiegen nicht gefunden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    }
                    else if (error.status === 403) {
                        this.dialogConfig.data = 'Operation unzulässig';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getResourceTypes(group: ResourcetypeGroups, skip, limit) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/?group=' + group + `&skip=${skip}&limit=${limit}`;
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public getRessourceType(typeId: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/' + typeId + '/';
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Ressourcetyp konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );

    }

    public AddNewRessouceType(ressourceType: any) {

        if (ressourceType) {
            if (ressourceType.id) {
                // YES
                return this.UpdateRessourceType(ressourceType, ressourceType.id);

            } else {
                const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/';
                console.log('API CALL: ' + configUrl);
                return this.http.post<any>(configUrl, ressourceType, {headers: this.DefaultPostHeader})
                    .pipe(
                        catchError(error => {
                            this.handleDefaultErrors(error);
                            return throwError(error);
                        })
                    );
            }
        } else {
            return null;
        }

    }

    public UpdateRessourceType(ressourceType: any, id: string) {
        if (ressourceType) {
            if (id) {
                // YES
                const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/' + id + '/';
                console.log('API CALL: ' + configUrl);
                return this.http.put<any>(configUrl, ressourceType, {headers: this.DefaultPostHeader})
                    .pipe(
                        catchError(error => {
                            this.handleDefaultErrors(error);
                            return throwError(error);
                        })
                    );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public getByResourceTypeAffectedResources(resourcetypeId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/' + resourcetypeId + '/resources/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Ressourcentyp konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public DeleteResourceType(resourceTypeId: string) {
        console.log(resourceTypeId);
        const configUrl = this.GetActiveApiUrl('2.0') + 'resource_types/' + resourceTypeId + '/?purge=false';
        console.log('API CALL: ' + configUrl);
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Ressourcentyp konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 406) {
                        this.dialogConfig.data = 'Der zu löschende Resourcetyp verfügt über aktive Resourcen';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getAllRessource(group: ResourcetypeGroups, skip, limit) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'resources/?group=' + group + `&skip=${skip}&limit=${limit}`;

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public addNewRessource(ressource: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'resources/';
        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, ressource, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 406) {
                        this.dialogConfig.data = 'Falschen Ressourcetyp ausgewählt';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public UpdateResource(resource: any, resourceId: string) {
        if (resource) {
            if (resourceId) {
                // YES
                const configUrl = this.GetActiveApiUrl('2.0') + 'resources/' + resourceId + '/';
                console.log('API CALL: ' + configUrl);
                return this.http.put<any>(configUrl, resource, {headers: this.DefaultPostHeader})
                    .pipe(
                        catchError(error => {
                            console.log(error);

                            if (error.status === 404) {
                                this.dialogConfig.data = 'Ressource konnte nicht gefunden werden';
                                this.dialog.open(MessageboxComponent, this.dialogConfig);
                            } else {
                                this.handleDefaultErrors(error);
                            }

                            return throwError(error);
                        })
                    );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public DeleteResource(resourceId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'resources/' + resourceId + '/';
        console.log('API CALL: ' + configUrl);
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Ressource konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getProcessList(skip, limit, skip_to?) {
        let configUrl = this.GetActiveApiUrl('2.0') + `processes/?skip=${skip}&limit=${limit}`;
        if (skip_to) {
            configUrl += `&skip_to=${skip_to}`;
        }
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public getSingleProcess(processId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + processId + '/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Prozess konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );

    }

    public getAdditionalCosts(batchId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/additional_costs/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Kosten konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );

    }

    public addAdditionalCosts(batchId: string, costs: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/additional_costs/';
        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, costs, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public AddProcess(process: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/';
        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, process, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Prozess konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 406) {
                        // tslint:disable-next-line:max-line-length
                        this.dialogConfig.data = 'Falsches Material wurde gewählt, eventuell ist der Input fehlerhaft oder nicht kompatibel';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public DeleteProcess(processId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + processId + '/';
        console.log('API CALL: ' + configUrl);
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Prozess konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 406) {
                        this.dialogConfig.data = 'Der zu löschende Prozess verfügt über aktive Chargen';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public UpdateProcess(process: any, id: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + id + '/';
        console.log('API CALL: ' + configUrl);
        return this.http.put<any>(configUrl, process, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 406) {
                        this.dialogConfig.data = 'Prozess konnte nicht gespeichert werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 404) {
                        this.dialogConfig.data = 'Prozess konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public AddProcessComment(processId: string, commentText: string) {
        const textComment = {
            text: commentText
        };
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + `${processId}` + '/comment/';
        return this.http.post<any>(configUrl, textComment, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    return throwError(error);
                })
            );
    }

    public getProcessComment(processId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + `${processId}` + '/comment/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    return throwError(error);
                })
            );
    }

    public getBatchList(skip, limit, showHistorical = false) {

        let configUrl = this.GetActiveApiUrl('2.0') + 'batches/';

        if (showHistorical) {
            configUrl += `?show_historic=true&skip=${skip}&limit=${limit}&show_deleted=false`;
        } else {
            configUrl += `?skip=${skip}&limit=${limit}`;
        }
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public getBatchForProcess(processId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + processId + '/batches/';


        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Prozess konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getSingleBatch(batchId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/';
        console.log('API Call: ' + configUrl);
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Charge konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getCalculationBatch(batchId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/calculation/';


        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Charge konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getBatchListByCw(year: number, cw: number) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + year.toString() + '/' + cw.toString() + '/';
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public getBatchListByCwReport(year: number, cw: number) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + year.toString() + '/' + cw.toString() + '/?view=report';
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }


    public AddBatch(batch: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/';
        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, batch, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 406) {
                        this.dialogConfig.data = 'Falscher Prozess wurde ausgewählt';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public DeleteBatch(batch: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batch.id + '/?purge=false';
        console.log('API CALL: ' + configUrl);
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Charge konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 406) {
                        this.dialogConfig.data = 'Die zu löschende Charge verfügt über aktive Aufbereitungen';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public UpdateBatch(batch: any, batchId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/';
        console.log('API CALL: ' + configUrl);
        return this.http.put<any>(configUrl, batch, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Charge konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getBatchComment(batchId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + `${batchId}` + '/comment/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    return throwError(error);
                })
            );
    }

    public postBatchComment(batchId: string, commentText: string) {
        const textComment = {
            text: commentText
        };
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + `${batchId}` + '/comment/';
        return this.http.post<any>(configUrl, textComment, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    return throwError(error);
                })
            );
    }

    public ConfirmPlan(planId: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'plans/' + planId + '/schedule/';
        console.log('API CALL: ' + configUrl);
        return this.http.put<any>(configUrl, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Plan konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );

    }
    public GetExecutionsForBooking(skip, limit, showBooked: boolean = false) {
        let configUrl = this.GetActiveApiUrl('2.0') + `batches/bookable/?skip=${skip}&limit=${limit}&show_deleted=false`;

        if (showBooked === true) {
            configUrl = configUrl + '&show_booked=true';
        }

        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public getOpenExecutions() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'executions/?show_finished=false&skip=0&limit=100&show_deleted=false';


        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public changeBatchQuantity(batchId: string, quantity: number) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/?quantity=' + quantity.toString();
        console.log('API CALL: ' + configUrl);
        return this.http.patch<any>(configUrl, null, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 403) {
                        this.dialogConfig.data = 'Aktion nicht möglich';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 404) {
                        this.dialogConfig.data = 'Charge nicht gefunden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public changeExecutionState(batchId: string, executionType: string, costs_per_ton?: number) {


        const configUrl = this.GetActiveApiUrl('2.0') + 'batches/' + batchId + '/execute/?execution_type=' + executionType;
        console.log('API CALL: ' + configUrl);
        let body: { costs_per_ton: number; };
        if (costs_per_ton != null) {
            body = {
                "costs_per_ton": costs_per_ton
            };
        }
        else {
            body = null;
        }
        return this.http.put<any>(configUrl, body, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Charge konnte nicht gefunden werden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 409) {
                        this.dialogConfig.data = 'Aufbereitung ist nicht möglich';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public SetWeightResult(data: any) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'weighings/';
        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, data, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 406) {
                        this.dialogConfig.data = 'Falsche Waage ausgewälht';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public SetScaleWeightResult(weighingId: string, batchId: string, materialId: string, containerId?: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'weighings/' + weighingId;

        const data = {
            batch_id: batchId,
            material_id: materialId,
            container_id: containerId
        };

        console.log('API CALL: ' + configUrl);
        return this.http.post<any>(configUrl, data, {headers: this.DefaultPostHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 406) {
                        this.dialogConfig.data = 'Falsche Waage ausgewälht';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );
    }

    public getAvailableScales() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'scales/';
        console.log('API Call: ' + configUrl);
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public getAvailableContainers() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'containers/';
        console.log('API Call: ' + configUrl);
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public createContainer(RailwayCarriageToSave) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'containers/';
        console.log('API Call: ' + configUrl);
        return this.http.post(configUrl, RailwayCarriageToSave,  {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public updateContainer(RailwayCarriageToSave, containerId) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'containers/' + containerId + '/';
        console.log('API Call: ' + configUrl);
        console.log(RailwayCarriageToSave);
        return this.http.put(configUrl, RailwayCarriageToSave,  {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public deleteContainer(RailwayCarriageToDelete) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'containers/' + RailwayCarriageToDelete.id + '/';
        console.log('API Call: ' + configUrl);
        return this.http.delete(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public getAvailableMover() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'movers/';
        console.log('API Call: ' + configUrl);
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public updateMoverTare(moverId: string, name: string, type: string, tare: number) {
        const data = {
            name,
            scale_type: type,
            tare
        };

        console.log(data);

        const configUrl = this.GetActiveApiUrl('2.0') + 'movers/' + moverId + '/';
        console.log('API Call: ' + configUrl);

        return this.http.put(configUrl, data, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public updateContainerTare(containerId: string, name: string, type: string, tare: number) {
        const data = {
            name,
            scale_type: type,
            tare
        };

        console.log(data);

        const configUrl = this.GetActiveApiUrl('2.0') + 'containers/' + containerId + '/';
        console.log('API Call: ' + configUrl);

        return this.http.put(configUrl, data, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public StartWeighingSubscription(scale: string, container: string, mover: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'scales/weigh';
        console.log('API Call: ' + configUrl);

        if (mover) {
            const data = {
                scale_id: scale,
                container_id: container,
                mover_id: mover
            };
            console.log(data);
            return this.http.post(configUrl, data, {headers: this.DefaultGetHeader})
                .pipe(
                    catchError(error => {
                        console.log(error);

                        if (error.status === 404) {
                            this.dialogConfig.data = 'Waage wurde nicht gefunden';
                            this.dialog.open(MessageboxComponent, this.dialogConfig);
                        } else if (error.status === 428) {
                            console.log('Waage ist nicht kompatibel');
                            this.dialogConfig.data = 'Waage ist nicht kompatibel';
                            this.dialog.open(MessageboxComponent, this.dialogConfig);
                        } else if (error.status === 503) {
                            console.log('Lagerneubewertung läuft - Wiegen aktuell nicht möglich');
                        } else {
                            this.handleDefaultErrors(error);
                        }

                        return throwError(error);
                    })
                );

        } else {
            const data = {
                scale_id: scale,
                container_id: container
            };
            console.log(data);
            return this.http.post(configUrl, data, {headers: this.DefaultGetHeader})
                .pipe(
                    catchError(error => {
                        console.log(error);

                        if (error.status === 404) {
                            this.dialogConfig.data = 'Waage wurde nicht gefunden';
                            this.dialog.open(MessageboxComponent, this.dialogConfig);
                        } else if (error.status === 428) {
                            console.log('Waage ist nicht kompatibel');
                            this.dialogConfig.data = 'Waage ist nicht kompatibel';
                            this.dialog.open(MessageboxComponent, this.dialogConfig);
                        } else if (error.status === 503) {
                            console.log('Lagerneubewertung läuft - Wiegen aktuell nicht möglich');
                        } else {
                            this.handleDefaultErrors(error);
                        }

                        return throwError(error);
                    })
                );
        }
    }

    public getTransferableProcesses(id: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'processes/' + id + `/transfer/`;
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public requestPlan(batchId: string, maxDate: string, minDate: string) {

        const data = {
            maxDate,
            minDate,
            batch_id: batchId
        };

        console.log(data);

        const configUrl = this.GetActiveApiUrl('2.0') + 'plans/';
        console.log('API Call: ' + configUrl);

        return this.http.post(configUrl, data, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public getPlan(planId: string) {


        const configUrl = this.GetActiveApiUrl('2.0') + 'plans/' + planId + '/';
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );

    }

    public createTareWeighting(scale: string) {

        const configUrl = this.GetActiveApiUrl('2.0') + 'scales/tare';
        console.log('API Call: ' + configUrl);
        const data = {
            scale_id: scale
        };
        console.log(data);
        return this.http.post(configUrl, data, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);

                    if (error.status === 404) {
                        this.dialogConfig.data = 'Waage wurde nicht gefunden';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 428) {
                        console.log('Waage ist nicht kompatibel');
                        this.dialogConfig.data = 'Waage ist nicht kompatibel';
                        this.dialog.open(MessageboxComponent, this.dialogConfig);
                    } else if (error.status === 503) {
                        console.log('Lagerneubewertung läuft - Wiegen aktuell nicht möglich');
                    } else {
                        this.handleDefaultErrors(error);
                    }

                    return throwError(error);
                })
            );


    }

    public getPreliminaryWeighing(weighingId: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + 'weighings/preliminary/' + weighingId + '/';
        console.log('API Call: ' + configUrl);
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(this.handleError)
            );
    }

    public getRevaluationStatus() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'erp/status/';
        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(this.handleError)
            );
    }

    public getEnergyCosts() {
        const configUrl = this.GetActiveApiUrl('2.0') + 'settings/';


        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public updateEnergyCosts(energy_costs: number, energy_overhead: number) {
        const data = {
            energy_costs: energy_costs,
            energy_overhead: energy_overhead,
        };

        const configUrl = this.GetActiveApiUrl('2.0') + 'settings/';
        console.log('API Call: ' + configUrl);

        return this.http.put(configUrl, data, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }

    public getResourceUsage(startDate: string, endDate: string) {
        const configUrl = this.GetActiveApiUrl('2.0') + `resource_usage/?start=${startDate}&end=${endDate}`;
        console.log('API Call: ' + configUrl);

        return this.http.get(configUrl, {headers: this.DefaultGetHeader})
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.handleDefaultErrors(error);
                    return throwError(error);
                })
            );
    }
}
