import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {AccessTypes} from '../../../Enums/access-types';
import * as moment from 'moment/moment';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import {DataShareService} from '../../../Services/data-share.service';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WebsocketService} from '../../../Services/websocket.service';
import {UserAccessService} from '../../../Services/user-access.service';
import {QuestionBoxComponent} from '../../../Dialogs/question-box/question-box.component';
import {ExecutionActionTypes} from '../../../Enums/execution-action-types.enum';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {ExecutionTypes} from '../../../Enums/execution-types.enum';
import {OptionBoxComponent} from '../../../Dialogs/option-box/option-box.component';
import {environment} from '../../../../environments/environment';
import {getCurrentSumTime, updateExecutionBatch} from '../sharedFunctions';
import {EnvironmentTypes} from '../../../Enums/environment-types';
import {ProcessTypesAPI} from "../../../Enums/process-types.enum";

@Component({
  selector: 'app-executions-table',
  templateUrl: './executions-table.component.html',
  styleUrls: ['./executions-table.component.scss']
})
export class ExecutionsTableComponent implements OnChanges {
  @Input() exBatch;

  public executionBatch: any;
  public currentExecution: any;
  public lastExecution: any;
  public accessTyps = AccessTypes;
  public accessAreas = AccessAppAreas;

  public TreatmentErrors = [{
    code: 'Treat-001',
    name: 'Charge wird bereits aufbereitet'
  },
    {
      code: 'Treat-002',
      name: 'Charge nicht zulässig für Aufbereitung'
    },
    {
      code: 'Treat-006',
      name: 'Keine Aufbereitung gefunden'
    },
    {
      code: 'Treat-003',
      name: 'Aufbereitung hat bereits den selben Status'
    },
    {
      code: 'Treat-005',
      name: 'Aufbereitung bereits abgeschlossen'
    },
    {
      code: 'Treat-004',
      name: 'Fehler beim Speichern der Aufbereitung'
    }];

  @Output() newExBatch = new EventEmitter<any>();

  updateExecutionBatchForParentComponent(value: any) {
    this.newExBatch.emit(value);
  }

  constructor(public apiService: APIService,
              public router: Router,
              public dataService: DataShareService,
              public functionsService: GlobalFunctionsService,
              public dialog: MatDialog,
              public websocket: WebsocketService,
              public userAccess: UserAccessService) {

  }

  ngOnChanges(): void {
    this.executionBatch = this.exBatch;
    updateExecutionBatch.call(this);
  }


  public getSetupTotalTime(): string {
    if (this.executionBatch) {
      if (this.executionBatch.setup_time) {
        const seconds = this.executionBatch.setup_time * 3600;
        return this.functionsService.durationToString(seconds);
      }
    }
    return '0:00:00';
  }

  public getProcessingTotalTime(): string {
    if (this.executionBatch) {
      if (this.executionBatch.processing_time) {
        const seconds = this.executionBatch.processing_time * 3600;
        return this.functionsService.durationToString(seconds);
      }
    }
    return '0:00:00';
  }

  public getMaintenanceTotalTime(): string {
    if (this.executionBatch) {
      if (this.executionBatch.process.process_category !== ProcessTypesAPI.MAINTENANCE) {
        return '-';
      } else {
        const seconds = this.executionBatch.lead_time * 3600;
        return this.functionsService.durationToString(seconds);
      }
    }
    return '0:00:00';
  }

  public getTearDownTotalTime(): string {
    if (this.executionBatch) {
      if (this.executionBatch.teardown_time) {
        const seconds = this.executionBatch.teardown_time * 3600;
        return this.functionsService.durationToString(seconds);
      }
    }
    return '0:00:00';
  }


  public getCurrentTime(type: string): string {


    if (this.executionBatch) {
      if (this.executionBatch.executions) {
        // SUM ALL
        let seconds = 0.0;
        for (const et of this.executionBatch.executions.filter(ex => ex.execution_type === type && ex.ended)) {
          const start = moment.utc(et.started).local();
          const end = moment.utc(et.ended).local();

          seconds += end.diff(start, 'seconds');
        }

        if (this.currentExecution) {
          if (this.currentExecution.execution_type === type) {
            const cur = moment.utc(this.currentExecution.started).local();
            const now = moment(this.dataService.currentServerTime).local();
            seconds += now.diff(cur, 'seconds', true);
          }
        }
        return this.functionsService.durationToString(Math.round(seconds));
      }
    }
    return '0:00:00';
  }

  public getCurrentSumTimeComponent() {
    return getCurrentSumTime.call(this);
  }

  public getTotalTime(): string {
    if (this.executionBatch) {
      if (this.executionBatch.lead_time) {
        return this.functionsService.durationToString(this.executionBatch.lead_time * 3600);
      }
    }

    return '0:00:00';
  }


  public finishBatch() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialogStyles';
    dialogConfig.data = 'Möchten Sie die aktuelle Charge wirklich abschließen?';
    const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data === true) {
        // tslint:disable-next-line:max-line-length
        this.apiService.changeExecutionState(this.executionBatch.id, ExecutionActionTypes.COMPLETE).subscribe((statechange: any) => {
          if (statechange) {
            if (statechange.id) {

              // FINISHED
              const dialogConfig1 = new MatDialogConfig();
              dialogConfig1.disableClose = true;
              dialogConfig1.autoFocus = true;
              dialogConfig1.panelClass = 'dialogStyles';
              dialogConfig1.data = 'Charge wurde abgeschlossen';
              const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
              this.dataService.changeserverErrFlag();
              dialogRef2.afterClosed().subscribe(() => {
                this.router.navigate(['/home']).catch(err => console.log(err));
              });


            } else {
              this.showTreatmentErrorDialog(data);
            }
          }
        });
      }
    });
  }

  public showTreatmentErrorDialog(data: any) {
    const errortxt = this.TreatmentErrors.find(ex => ex.code === data.errorcode);

    if (errortxt) {
      const dialogConfig1 = new MatDialogConfig();
      dialogConfig1.disableClose = true;
      dialogConfig1.autoFocus = true;
      dialogConfig1.panelClass = 'dialogStyles';
      dialogConfig1.data = errortxt.name;
      const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
      dialogRef2.afterClosed().subscribe(result => {
        if (result === true) {
          // NAVIGATE BACK TO BATCHES

        }
      });
    }
  }

  public startSetupBefore() {

    if (this.currentExecution && this.currentExecution.execution_type === ExecutionActionTypes.SETUP) {
      this.breakExecution();
    } else {
      if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS) {
        this.showCheckWagonParametersDialog().subscribe(result => {
          if (result === 1) {
            this.changeExecutionState(ExecutionActionTypes.SETUP);
          } else if (result === 2) {
            console.log('not start');
          }
        });
      }
      else {
        this.changeExecutionState(ExecutionActionTypes.SETUP);
      }

    }
  }

  public changeExecutionState(actionType: ExecutionActionTypes) {
    this.apiService.changeExecutionState(this.executionBatch.id, actionType).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.id) {
          this.updateExecutionState(data);
          this.getCurrentStates();
        } else {
          this.showTreatmentErrorDialog(data);
        }
      }
    });
  }



  public updateExecutionState(data: any) {
    if (data.scheduled_start) {
      data.scheduled_start = moment.utc(data.scheduled_start).local();
    }

    if (data.scheduled_end != null) {
      data.scheduled_end = moment.utc(data.scheduled_end).local();
    }

    this.updateExecutionBatchForParentComponent(data);

    updateExecutionBatch.call(this);
  }

  public runTreatment() {

    if (this.currentExecution && this.currentExecution.execution_type === ExecutionActionTypes.PROCESSING) {
      this.breakExecution();
    } else {
      if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS) {
        this.showCheckWagonParametersDialog().subscribe(result => {
          if (result === 1) {
            this.changeExecutionState(ExecutionActionTypes.PROCESSING);
          } else if (result === 2) {
            console.log('not start');
          }
        });
      }
      else {
        console.log(environment.tenant);
        console.log('not true');
        this.changeExecutionState(ExecutionActionTypes.PROCESSING);
      }

    }
  }

  public SetupAfter() {

    if (this.currentExecution && this.currentExecution.execution_type === ExecutionActionTypes.TEARDOWN) {
      this.breakExecution();
    } else {
      if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS) {
        this.showCheckWagonParametersDialog().subscribe(result => {
          if (result === 1) {
            this.changeExecutionState(ExecutionActionTypes.TEARDOWN);
          } else if (result === 2) {
            console.log('not start');
          }
        });
      }
      else {
        this.changeExecutionState(ExecutionActionTypes.TEARDOWN);
      }

    }
  }

  public StartMaintenance() {

    if (this.currentExecution && this.currentExecution.execution_type === ExecutionActionTypes.MAINTENANCE) {
      this.breakExecution();
    } else {
      if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS && this.executionBatch.process.process_category !== ProcessTypesAPI.MAINTENANCE) {
        this.showCheckWagonParametersDialog().subscribe(result => {
          if (result === 1) {
            this.changeExecutionState(ExecutionActionTypes.MAINTENANCE);
          } else if (result === 2) {
            console.log('not start');
          }
        });
      }
      else {
        this.changeExecutionState(ExecutionActionTypes.MAINTENANCE);
      }

    }
  }

  public StartMalfunction() {

    if (this.currentExecution && this.currentExecution.execution_type === ExecutionActionTypes.MALFUNCTION) {
      this.breakExecution();
    } else {
      if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS && this.executionBatch.process.process_category !== ProcessTypesAPI.MAINTENANCE) {
        this.showCheckWagonParametersDialog().subscribe(result => {
          if (result === 1) {
            this.changeExecutionState(ExecutionActionTypes.MALFUNCTION);
          } else if (result === 2) {
            console.log('not start');
          }
        });
      }
      else {
        this.changeExecutionState(ExecutionActionTypes.MALFUNCTION);
      }

    }
  }

  public RunPause() {


    if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS && this.executionBatch.process.process_category !== ProcessTypesAPI.MAINTENANCE) {
      this.showCheckWagonParametersDialog().subscribe(result => {
        if (result === 1) {
          this.changeExecutionState(ExecutionActionTypes.PAUSE);
        } else if (result === 2) {
          console.log('not start');
        }
      });
    }
    else {
      this.changeExecutionState(ExecutionActionTypes.PAUSE);
    }


  }

  public ShiftStop() {
    if (this.checkIfSumNull() && environment.tenant === EnvironmentTypes.MSS && this.executionBatch.process.process_category !== ProcessTypesAPI.MAINTENANCE) {
      this.showCheckWagonParametersDialog().subscribe(result => {
        if (result === 1) {
          this.changeExecutionState(ExecutionActionTypes.OFFSHIFT);
        } else if (result === 2) {
          console.log('not start');
        }
      });
    }
    else {
      this.changeExecutionState(ExecutionActionTypes.OFFSHIFT);
    }


  }

  public breakExecution() {
    this.apiService.changeExecutionState(this.executionBatch.id, ExecutionActionTypes.PAUSE).subscribe((data: any) => {
      if (data) {
        if (data.id) {
          this.updateExecutionState(data);
        } else {
          this.showTreatmentErrorDialog(data);
        }
      }
    });
  }

  getCurrentStates(): any[] {
    const result = [];

    const summedTimesByExecutionType = [];
    for (const e of this.executionBatch.executions.filter(ex => ex.ended)) {
      const start = moment(e.started);
      const end = moment(e.ended);

      const delta = end.diff(start, 'seconds');

      // if there is already an entry with the current execution type, add the calculated delta between start and end
      if (summedTimesByExecutionType.find(ex => ex.type === e.execution_type)) {
        const btw = summedTimesByExecutionType.find(ex => ex.type === e.execution_type);
        btw.seconds += delta;
      } else {
        summedTimesByExecutionType.push({
          type: e.execution_type,
          seconds: delta
        });
      }
    }
    for (const summedTime of summedTimesByExecutionType) {
      const nw = {
        type: summedTime.type,
        time: moment.duration(summedTime.seconds, 'seconds'),
        timeAsString: this.functionsService.durationToString(summedTime.seconds),
        totalTime: 0,
        totalTimeAsString: ''
      };


      if (summedTime.type === ExecutionTypes.SETUP) {
        nw.totalTime = this.executionBatch.setup_time * 3600;
        nw.totalTimeAsString = this.functionsService.durationToString(nw.totalTime);
      } else if (summedTime.type === ExecutionTypes.TEARDOWN) {
        nw.totalTime = this.executionBatch.teardown_time * 3600;
        nw.totalTimeAsString = this.functionsService.durationToString(nw.totalTime);
      }
      if (summedTime.type === ExecutionTypes.PROCESSING) {
        nw.totalTime = this.executionBatch.processing_time * 3600;
        nw.totalTimeAsString = this.functionsService.durationToString(nw.totalTime);
      }
      result.push(nw);
    }
    return result;
  }

  checkIfSumNull(): boolean {
    return getCurrentSumTime.call(this) === '0:00:00';
  }

  public showCheckWagonParametersDialog() {
    const checkWagonParametersDialog = new MatDialogConfig();
    checkWagonParametersDialog.disableClose = true;
    checkWagonParametersDialog.autoFocus = true;
    checkWagonParametersDialog.panelClass = 'dialogStyles';
    checkWagonParametersDialog.data = {
      txt: `Soll die Aufstellung der Waggons gespeichert und fixiert werden? Sobald die Aufstellung fixiert ist, kann sie nicht mehr geändert werden.`,
      option1: 'Ja',
      option2: 'Nein'
    };
    const dialogReference = this.dialog.open(OptionBoxComponent, checkWagonParametersDialog);
    return dialogReference.afterClosed();
  }

}
