import {Component, OnInit} from '@angular/core';


import * as moment from 'moment/moment';
import {MatDialog} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import {DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';

moment.locale('de');


@Component({
    selector: 'app-resource-usage',
    templateUrl: './resource-usage.component.html',
    styleUrls: ['./resource-usage.component.scss']
})
export class ResourceUsageComponent implements OnInit{
    public startDate = this.getMonday();
    public endDate = new Date();
    public resourceTypes: any;


    constructor(public dialog: MatDialog, public apiService: APIService,
                public dataService: DataShareService, public router: Router,
                dateTimeAdapter: DateTimeAdapter<any>) {
        dateTimeAdapter.setLocale('de-DE'); // change locale to De
    }

    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.evaluateResourceTypes();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.evaluateResourceTypes.bind(this));
        }
    }

    public getMonday(): Date {
        let date = new Date();
        const day = date.getDay(),
            diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        let monday = new Date(date.setDate(diff));
        return new Date(monday.setHours(0, 0));
    }


    public evaluateResourceTypes() {
        let startDate = moment.utc(this.startDate).format().toString();
        let endDate = moment.utc(this.endDate).format().toString();
        this.apiService.getResourceUsage(startDate.substring(0, startDate.length - 4), endDate.substring(0, startDate.length - 4)).subscribe({
            next: (resourceUsage) => {
                this.handleResourceUsage(resourceUsage);
            }
        });
    }

    private handleResourceUsage(data: any) {
        this.resourceTypes = data;
    }

}
