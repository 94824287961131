<div fxLayout="row">
    <app-executions-table [exBatch]="exBatch" (newExBatch)="updateExecutionBatchFromChildComponent($event)"
                          class="blankIntro" [ngClass]="{slider2: !slideLeft}" fxLayout="column"
                          *ngIf="executionBatch && !slideRight">
    </app-executions-table>
    <div class="blankIntro" [ngClass]="{slider: slideCenter, slider2: !slideCenter}" fxLayout="column"
         *ngIf="executionBatch && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.view) && executionBatch.process.process_category !== 'maintenance'">

        <div class="actions" fxLayout="column" *ngIf="!weightingActive && this.executionBatch.output_weights">

            <table class="tableExecutionsTypes">
                <tr>
                    <th class="tdAction" style="width: 20%">Output</th>
                    <th *ngIf="revaluation; else elseRevaluationFalse" class="revaluationHeader" style="width: 55%">
                        Lagerneubewertung läuft - Wiegen aktuell nicht möglich
                    </th>
                    <ng-template #elseRevaluationFalse>
                        <th class="actionHeader" style="width: 55%"></th>
                    </ng-template>
                    <th class="actionHeader2" style="width: 25%">Verwogen</th>
                    <th></th>
                </tr>

                <tr *ngFor="let ot of tableRows"
                    [ngClass]="{selectedRow: selectedProduct && selectedProduct.id === ot.id}">
                    <td class="tdAction2">{{ot.id}}</td>
                    <td class="tdTime2">{{ot.name}} {{functionsService.getOutputSubstitution(executionBatch, ot.id)}}</td>
                    <td class="tdTime3 scaleiconWrap2"
                        (click)="selectOutputProduct(ot)">{{ot.weight  | number: '1.3-3':'de'}} t
                    </td>
                    <td class="tdTime2 scaleiconWrap" (click)="selectOutputProduct(ot)"
                        *ngIf="!executionBatch.completed && !executionBatch.booked && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit)">
                        <img alt="scale" *ngIf="selectedProduct && selectedProduct.id === ot.id"
                             class="img-responsive scaleicon" src="../../../../assets/icons/tonnage_svg_blue.svg">
                        <img alt="scale" *ngIf="!selectedProduct || slideRight && selectedProduct.id !== ot.id"
                             class="img-responsive scaleicon" src="../../../../assets/icons/tonnage_svg_black.svg">
                    </td>
                </tr>

                <tr>
                    <td>
                        <hr>
                    </td>
                    <td>
                        <hr>
                    </td>
                    <td>
                        <hr>
                    </td>
                    <td>
                        <hr>
                    </td>
                </tr>
                <tr>
                    <th class="actionHeader" style="width: 20%"></th>
                    <th class="actionHeader" style="width: 30%">Input</th>
                    <th class="actionHeader2" style="width: 40%">Output</th>
                    <th class="actionHeader"></th>
                </tr>

                <tr>
                    <td class="tdAction">Summe</td>
                    <td class="tdTime">{{executionBatch.quantity | number: '1.3-3':'de'}} t</td>
                    <td class="tdTime3">{{getWeightSum() | number: '1.3-3':'de'}} t</td>
                    <td></td>
                </tr>
            </table>

        </div>


    </div>
    <div class="blankIntro" (swiperight)="onSwipe()" [ngClass]="{slider: slideRight}" fxLayout="column"
         *ngIf="executionBatch && slideRight && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit) && executionBatch.process.process_category !== 'maintenance'">
        <div class="actions2">
            <div class="headerSelectedProductWrap">
                <div class="scaleInterface">
                    <div class="scaleButtons" *ngIf="userAccess.hasAccessRightsEn(accessAreas.scale, accessTyps.view)">
                        <div *ngFor="let s of Scales">
                            <button *ngIf="s" class="scaleButton"
                                    [ngClass]="{'selectedScaleButton': SelectedScale && SelectedScale.id === s.id}"
                                    (click)="selectScaleInterface(s)">
                                <div>
                                    {{s.name}}
                                </div>
                                <div class="scaleIconDiv">
                                    <mat-icon class="circleIcon"
                                              [ngClass]="{'onlineCircle': s.online === true, 'offlineCircle':  s.online === false}">
                                        circle
                                    </mat-icon>
                                </div>
                            </button>
                        </div>

                        <div>
                            <button class="scaleButton2" (click)="chooseManuell()"
                                    [class.backgroundChosen]="manuelSelected">
                                Manuell
                            </button>
                        </div>

                        <div class="closeButtonWrap">
                            <button class="CancelButton" (click)="closeScalesInterface()">
                                <mat-icon class="CloseIcon">close</mat-icon>
                            </button>
                        </div>


                    </div>
                    <div class="wrapForScale">
                        <div *ngIf="manuelSelected">

                            <div class="ManuelWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Brutto</p>
                                <div style="margin-left: 5px">
                                    <mat-form-field>
                                        <input class="fontSize" step="0.001" pattern="[0-9]*(\.?[0-9]{1,4}$)?" min="0"
                                               type="number" [(ngModel)]="manualBrutto" value="{{manualBrutto}}"
                                               (keyup)="onBruttoInput($event)" matInput>
                                    </mat-form-field>

                                </div>

                                <p style="padding-left: 5px;">t</p>
                            </div>
                            <div class="ManuelWrap TaraWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Tara</p>
                                <div style="margin-left: 22px">
                                    <mat-form-field>
                                        <input class="fontSize" step="0.001" pattern="[0-9]*(\.?[0-9]{1,4}$)?" min="0"
                                               type="number"
                                               [(ngModel)]="manualTara" value="{{manualTara}}"
                                               (keyup)="onTaraInput($event)" matInput>
                                    </mat-form-field>

                                </div>

                                <p style="padding-left: 5px;">t</p>
                            </div>


                            <div class="ManuelWrap NettoWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Netto</p>
                                <div style="margin-left: 11px">
                                    <p>{{productQuantity | number: '1.3-3':'de'}}</p>
                                </div>
                                <p style="padding-left: 5px;" *ngIf="productQuantity">t</p>
                            </div>

                        </div>
                        <div class="wrapData" (click)="selectContainer()" *ngIf="systemScaleSelected">
                            <div class="infoElement2" *ngIf="SelectedScale.unit === 'kg'; else elseBRBlock">
                                <p class="nameElementSelProd2">Brutto</p>
                                <p class="valueElementInLine2"
                                   *ngIf="currentWeighting">{{getWeighingInTons(currentWeighting.gross) | number: '1.0-0':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                            </div>
                            <ng-template #elseBRBlock>
                                <div class="infoElement2">
                                    <p class="nameElementSelProd2">Brutto</p>
                                    <p class="valueElementInLine2"
                                       *ngIf="currentWeighting">{{getWeighingInTons(currentWeighting.gross) | number: '1.0-3':'de'}} {{SelectedScale.unit}}</p>
                                    <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                                </div>
                            </ng-template>

                            <div class="infoElement2" *ngIf="SelectedScale.requires_mover">
                                <div fxLayout="row">
                                    <p class="nameElementSelProd2">Tara Fahrzeug</p>
                                    <p *ngIf="selectedMover" class="valueElementInLine2">{{selectedMover.name}}</p>
                                </div>
                                <p *ngIf="selectedMover && SelectedScale.unit !== 'kg'" class="valueElementInLine3">
                                    - {{getWeighingInTons(moverWeight) | number: '1.0-3':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!selectedMover" class="valueElementInLine2">-</p>
                            </div>
                            <div class="infoElement2">
                                <div fxLayout="row">
                                    <p class="nameElementSelProd2">Tara Container</p>
                                    <p *ngIf="selectedContainer"
                                       class="valueElementInLine2">{{selectedContainer.name}}</p>
                                </div>

                                <p *ngIf="selectedContainer" class="valueElementInLine3">
                                    - {{getWeighingInTons(containerWeight) | number: '1.0-3':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!selectedContainer" class="valueElementInLine2">-</p>
                            </div>

                            <div class="infoElement2" *ngIf="SelectedScale.unit === 'kg'; else elseNTBlock">
                                <p class="nameElementSelProd2">Netto</p>
                                <p class="valueElementInLine2"
                                   *ngIf="currentWeighting">{{getWeighingInTons(currentWeighting.net) | number: '1.0-3':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                            </div>
                            <ng-template #elseNTBlock>
                                <div class="infoElement2">
                                    <p class="nameElementSelProd2">Netto</p>
                                    <p class="valueElementInLine2"
                                       *ngIf="currentWeighting">{{getWeighingInTons(currentWeighting.net) | number: '1.0-3':'de'}} {{SelectedScale.unit}}</p>
                                    <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                                </div>
                            </ng-template>
                        </div>

                        <div fxLayout="column">
                            <div *ngIf="systemScaleSelected">
                                <mat-icon class="loader" (click)="loaderClick()"
                                          [ngClass]="{'checked': isToggled === true}">
                                    autorenew
                                </mat-icon>
                            </div>
                            <div *ngIf="systemScaleSelected && showBatteryIcon">
                                <mat-icon (click)="showBatteryDialog()" class="batteryIcon"
                                          *ngIf="lastScaleWeighing.voltage >= 3.6">
                                    battery_full_bar
                                </mat-icon>
                                <mat-icon (click)="showBatteryDialog()" class="batteryIcon"
                                          style="color: rgb(237,192,38)"
                                          *ngIf="lastScaleWeighing.voltage < 3.6 && lastScaleWeighing.voltage >= 3.4">
                                    battery_3_bar
                                </mat-icon>
                                <mat-icon (click)="showBatteryDialog()" class="batteryIcon"
                                          *ngIf="lastScaleWeighing.voltage < 3.4" style="color: red">
                                    battery_1_bar
                                </mat-icon>
                            </div>
                        </div>


                    </div>

                    <ng-container *ngIf="!revaluation; else elseWeightingButton">
                        <button class="ubernehmenButton" *ngIf="manuelSelected || currentWeighting"
                                [disabled]="!userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit) || !enableWeighningButton"
                                (click)="confirmQuantity()">Verwiegen
                        </button>
                        <!-- <ng-template #weighingDone>
                             <button class="ubernehmenButton">Neue Verwiegung
                             </button>
                         </ng-template>-->

                    </ng-container>
                    <ng-template #elseWeightingButton>
                        <ng-container>
                            <button class="ubernehmenButton" *ngIf="currentWeighting  || manuelSelected"
                                    [disabled]="!userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit)"
                                    (click)="revaluationInProgress()">Verwiegen
                            </button>
                        </ng-container>

                    </ng-template>


                </div>

            </div>


        </div>

    </div>
</div>

