import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from 'src/app/Services/api.service';
import {DataShareService} from 'src/app/Services/data-share.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {OptionBoxComponent} from '../../../Dialogs/option-box/option-box.component';
import {AffectedBatchesComponent} from '../../../Dialogs/affected-batches/affected-batches.component';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {UserAccessService} from '../../../Services/user-access.service';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {environment} from '../../../../environments/environment';
import {EnvironmentTypes} from '../../../Enums/environment-types';

@Component({
    selector: 'process-view',
    templateUrl: './process-view.component.html',
    styleUrls: ['./process-view.component.scss']
})
export class ProcessViewComponent implements OnInit {
    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;
    public searchString = '';
    public treatmentProcess: any;
    public processes: any[];
    public selectedProcess: any;
    public showNext: boolean;
    public skip = 0;
    public accessTypes = AccessTypes;
    public accessAppAreas = AccessAppAreas;


    constructor(private dataService: DataShareService, private apiService: APIService,
                private router: Router,
                private data: DataShareService,  private functionsService: GlobalFunctionsService,
                public dialog: MatDialog,
                public userAccess: UserAccessService) {
        this.data.currentProcess.subscribe(process => {
            this.treatmentProcess = process;
        });
    }

    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.data.ReleaseProcess();
            this.fetchProcesses(0, 100);
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }
    }

    private ApiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.data.ReleaseProcess();
            this.fetchProcesses(0, 100);
        }
    }

    public paginateRight() {
        this.skip = this.skip + 100;
        this.fetchProcesses(this.skip, 100);
    }

    public paginateLeft() {
        this.skip = this.skip - 100;
        this.fetchProcesses(this.skip, 100);
    }

    public fetchProcesses(skip, limit) {
        this.apiService.getProcessList(skip, limit).subscribe({
            next: this.handleProcesses.bind(this),
            error: this.functionsService.handleServerNotReachableError.bind(this),
        });
    }


    private handleProcesses(processes: any) {
        console.log(processes);
        this.showNext = processes.processes.length === 100;
        this.processes = processes.processes.sort((a, b) => a.name.localeCompare(b.name));
        for (const p of this.processes) {
            p.leadtimeperton = 1.0 / p.leadtimeperton;
        }
    }

    public translateProcessType(processtype: string): string {
        if (processtype === 'processing') {
            return 'Aufbereiten';
        }
        else if (processtype === 'shredding') {
            return 'Zerkleinerung';
        }
        else {
            return 'Instandhaltung';
        }

    }

    public selectProcess(process: any) {
        console.log(process);
        this.selectedProcess = process;
    }

    public changeProcess() {
        this.data.activeEdit = true;
        this.data.SetProcessToEdit(this.selectedProcess);
        this.router.navigate(['/process/config']);
    }

    public createProcess() {
        this.data.activeEdit = true;
        this.router.navigate(['/process/config']);
    }

    public performProcessDeletion() {
        this.apiService.DeleteProcess(this.selectedProcess.id).subscribe({
            next: () => {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Prozess wurde gelöscht';
                const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                dialogRef2.afterClosed().subscribe(res => {
                    if (res === true) {
                        this.selectedProcess = null;
                        this.fetchProcesses(this.skip, 100);
                    }
                });
            },
            error: (error) => {
                if (error.status === 404) {
                    const errorDialog = new MatDialogConfig();
                    errorDialog.disableClose = true;
                    errorDialog.autoFocus = true;
                    errorDialog.panelClass = 'dialogStyles';
                    errorDialog.data = 'Der zu löschende Prozess konnte nicht gefunden werden';
                    this.dialog.open(MessageboxComponent, errorDialog);
                } else if (error.status === 406) {
                    const errorDialog = new MatDialogConfig();
                    errorDialog.disableClose = true;
                    errorDialog.autoFocus = true;
                    errorDialog.panelClass = 'dialogStyles';
                    errorDialog.data = 'Der zu löschende Prozess wird in aktiven Chargen verwendet und kann daher nicht gelöscht werden';
                    this.dialog.open(MessageboxComponent, errorDialog);
                } else if (error.status === 422) {
                    const errorDialog = new MatDialogConfig();
                    errorDialog.disableClose = true;
                    errorDialog.autoFocus = true;
                    errorDialog.panelClass = 'dialogStyles';
                    errorDialog.data = 'Fehler bei der Validierung ' + error.detail.msg;
                    this.dialog.open(MessageboxComponent, errorDialog);
                }
            }
        });
    }

    public deleteProcess() {
        if (this.selectedProcess) {

            this.apiService.getBatchForProcess(this.selectedProcess.id).subscribe((batches: any) => {
                if (batches.length > 0) {
                    const dialogAffectedCharges = new MatDialogConfig();
                    dialogAffectedCharges.disableClose = true;
                    dialogAffectedCharges.autoFocus = true;
                    dialogAffectedCharges.panelClass = 'dialogStyles';
                    dialogAffectedCharges.data = batches;
                    const dialogRefAffectedCharges = this.dialog.open(AffectedBatchesComponent, dialogAffectedCharges);
                    dialogRefAffectedCharges.afterClosed().subscribe(result => {
                        if (result === true) {
                            this.performProcessDeletion();
                        }
                    });
                } else {
                    const dialogConfirmProcessDeletion = new MatDialogConfig();
                    dialogConfirmProcessDeletion.disableClose = true;
                    dialogConfirmProcessDeletion.autoFocus = true;
                    dialogConfirmProcessDeletion.panelClass = 'dialogStyles';
                    dialogConfirmProcessDeletion.data = {
                        txt: 'Wollen Sie wirklich Prozess löschen?',
                        option1: 'Ja',
                        option2: 'Nein'
                    };
                    const dialogRefConfirmProcessDeletion = this.dialog.open(OptionBoxComponent, dialogConfirmProcessDeletion);
                    dialogRefConfirmProcessDeletion.afterClosed().subscribe(result => {
                        if (result === 1) {
                            this.performProcessDeletion();
                        }
                    });
                }
            });


        }
    }
}
