import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataShareService} from '../../Services/data-share.service';
import {APIService} from '../../Services/api.service';
import * as moment from 'moment';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';

@Component({
  selector: 'app-statement-forwarding',
  templateUrl: './statement-forwarding.component.html',
  styleUrls: ['./statement-forwarding.component.css']
})
export class StatementForwardingComponent implements OnInit {

  private idToLoad: string;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private datashare: DataShareService,
              public dialog: MatDialog,
              private apiService: APIService) {
    this.route.params.subscribe(event => {
      if (event.token) {

        this.idToLoad = event.token as string;
        console.log(this.idToLoad);

        if (this.apiService.versioningFinished) {
          this.loadCalculationBatch();
        } else {
          this.apiService.OnVersioningFinished.subscribe(this.loadCalculationBatch.bind(this));
        }

      } else {
        this.showUserInfoDialog('Keine Charge angegeben');
        this.router.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
  }

  private loadCalculationBatch() {
    this.apiService.getCalculationBatch(this.idToLoad).subscribe((data: any) => {
      if (data) {
        if (data.id) {

          if (data.scheduled_start) {
            data.scheduled_start = moment.utc(data.scheduled_start).local();
          }


          if (data.scheduled_end != null) {
            console.log('has schedule ended');
            data.scheduled_end = moment.utc(data.scheduled_end).local();
          }


          console.log(data);
          this.datashare.BatchToBook = data;
          this.router.navigate(['/calculation-book']);
        } else {
          this.showUserInfoDialog('zu ladende Charge konnte nicht gefunden werden');
          this.router.navigate(['/home']);
        }
      } else {
        this.showUserInfoDialog('zu ladende Charge konnte nicht gefunden werden');
        this.router.navigate(['/home']);
      }
    }, error => {
      console.log(error);
      // this.showUserInfoDialog('zu ladende Charge konnte nicht gefunden werden');
      this.router.navigate(['/home']);
    });
  }

  private showUserInfoDialog(text: string) {
    const dialogConfigx = new MatDialogConfig();
    dialogConfigx.disableClose = true;
    dialogConfigx.autoFocus = true;
    dialogConfigx.panelClass = 'dialogStyles';
    dialogConfigx.data = text;
    this.dialog.open(MessageboxComponent, dialogConfigx);
  }

}
