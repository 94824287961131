import {Pipe, PipeTransform} from '@angular/core';
import {Proccess} from '../Models/Models';

@Pipe({
    name: 'ProcessFilter'
})
export class ProcessFilterPipe implements PipeTransform {

    transform(processes: Proccess[], search: string = ''): any[] {
        if (!search.trim()) {
            return processes;
        }


        return processes.filter(process => {
            if ( process.input && process.output && process.resource_types) {
                return process.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    process.input.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    process.output.some(out => out.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    process.resource_types.some(rt => rt.resource_type.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    process.output.some(out => out.id.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    process.input.id.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
            else {
                return process.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        });
    }

}
