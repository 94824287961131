import {Component, HostListener, OnInit} from '@angular/core';
import {APIService} from 'src/app/Services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import {ResourcetypeGroups} from '../../Enums/resourcetype-groups.enum';
import {OptionBoxComponent} from '../../Dialogs/option-box/option-box.component';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {UserAccessService} from '../../Services/user-access.service';
import {GlobalFunctionsService} from '../../Services/global-functions';

@Component({
    selector: 'app-inventar',
    templateUrl: './ressource-view.component.html',
    styleUrls: ['./ressource-view.component.scss']
})
export class RessourceViewComponent implements OnInit {
    public showNextMachine: boolean;
    public skipMachine = 0;
    public showNextWorker: boolean;
    public skipWorker = 0;
    machines: any;
    workers: any;
    public SearchWorker: string;
    public SearchMachine: string;
    public searchString = '';
    resourceName: string;
    resourceTypeList: any[];
    resourceTypesWorker: any[];
    resourceTypesMachine: any[];
    flagMaschine: boolean;
    flagWork: boolean;
    flagEditResource: boolean;
    editFlag: boolean;
    resourceToEdit: any;
    flagResource: boolean;
    public ressourceTypeListToSelect: any[];
    public ResourceErrors = [{
        code: 'RT-001',
        name: 'Allgemeiner Fehler beim Speichern'
    },
        {
            code: 'RT-002',
            name: 'Ressource mit selben Name bereits vorhanden'
        },
        {
            code: 'RT-003',
            name: 'Ressource mit selber ID bereits vorhanden'
        },
        {
            code: 'RT-005',
            name: 'Ressource mit dieser ID nicht vorhanden'
        }
    ];

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    private btnDisabled = false;

    constructor(private dataService: DataShareService, private Apiservice: APIService,
                public dialog: MatDialog, public router: Router, private functionsService: GlobalFunctionsService,
                public userAccess: UserAccessService) {
    }

    @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
        if (this.editFlag === true || this.flagResource === true) {
            event.returnValue = false;
        }
    }

    ngOnInit(): void {
        if (this.Apiservice.versioningFinished === true) {
            this.updateRessourceTypeList();
            this.updateRessourceList(0, 100);
        } else {
            this.Apiservice.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }
    }

    private ApiVersionCheckFinished() {
        if (this.Apiservice.versioningFinished === true) {
            this.updateRessourceTypeList();
            this.updateRessourceList(0, 100);
        }
    }


    updateRessourceTypeList() {
        this.resourceTypeList = [];
        this.Apiservice.getResourceTypes(ResourcetypeGroups.HUMAN, 0, 100).subscribe({
            next: this.handleWorkers.bind(this),
            error: this.functionsService.handleServerNotReachableError.bind(this)
        });

        this.Apiservice.getResourceTypes(ResourcetypeGroups.MACHINE, 0, 100)
            .subscribe({
                next: this.handleMachines.bind(this),
                error: this.functionsService.handleServerNotReachableError.bind(this)
            });
    }

    private handleWorkers(data: any) {
        this.resourceTypesWorker = data;

        for (const rt of this.resourceTypesWorker) {
            this.resourceTypeList.push({
                id: rt.id,
                name: rt.name,
                group: rt.group
            });
        }


        this.resourceTypesWorker.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    private handleMachines(data: any) {
        this.resourceTypesMachine = data;

        for (const rt of this.resourceTypesMachine) {
            this.resourceTypeList.push({
                id: rt.id,
                name: rt.name,
                group: rt.group
            });
        }


        this.resourceTypesMachine.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    updateRessourceList(skip, limit) {
        // Mashines:
        this.Apiservice.getAllRessource(ResourcetypeGroups.MACHINE, skip, limit).subscribe((data: any) => {
            if (data) {
                if (data.length > 0) {
                    this.showNextMachine = data.length === 100;
                    this.machines = data.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    if (data.type) {
                        const error = this.ResourceErrors.find(ex => ex.code === data.type);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            }
        });
        // WORKER
        this.Apiservice.getAllRessource(ResourcetypeGroups.HUMAN, skip, limit).subscribe((data: any) => {
            if (data) {
                if (data.length > 0) {
                    this.showNextWorker = data.length === 100;
                    this.workers = data.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    if (data.type) {
                        const error = this.ResourceErrors.find(ex => ex.code === data.type);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            }
        });
    }

    public loadMachines(skip, limit) {
        // Mashines:
        this.Apiservice.getAllRessource(ResourcetypeGroups.MACHINE, skip, limit).subscribe((data: any) => {
            if (data) {
                if (data.length > 0) {
                    this.showNextMachine = data.length === 100;
                    this.machines = data.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    if (data.type) {
                        const error = this.ResourceErrors.find(ex => ex.code === data.type);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            }
        });
    }

    public loadHumans(skip, limit) {
        // WORKER
        this.Apiservice.getAllRessource(ResourcetypeGroups.HUMAN, skip, limit).subscribe((data: any) => {
            if (data) {
                if (data.length > 0) {
                    this.showNextWorker = data.length === 100;
                    this.workers = data.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    if (data.type) {
                        const error = this.ResourceErrors.find(ex => ex.code === data.type);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            }
        });
    }

    public paginateRightMachine() {
        this.skipMachine = this.skipMachine + 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateLeftMachine() {
        this.skipMachine = this.skipMachine - 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateRightWorker() {
        this.skipWorker = this.skipWorker + 100;
        this.loadHumans(this.skipWorker, 100);
    }

    public paginateLeftWorker() {
        this.skipWorker = this.skipWorker - 100;
        this.loadHumans(this.skipWorker, 100);
    }

    getRessourceTypListWorker(): any[] {
        return this.resourceTypesWorker;
    }

    getRessourcTypeListMachine(): any[] {
        return this.resourceTypesMachine;
    }


    choose(item) {
        this.flagEditResource = true;
        this.flagResource = false;
        this.resourceToEdit = item;
        this.editFlag = false;
    }

    edit() {
        this.editFlag = true;
        if (this.resourceToEdit.resource_type.group === 'human') {
            this.ressourceTypeListToSelect = this.getRessourceTypListWorker();
        } else {
            this.ressourceTypeListToSelect = this.getRessourcTypeListMachine();
        }
    }

    addResourceWorker() {
        this.flagWork = true;
        this.flagMaschine = false;
        this.flagResource = false;
        this.flagEditResource = false;
        this.flagResource = true;
        this.flagEditResource = false;
        this.resourceToEdit = {};
        this.resourceToEdit.resource_type = [];
        this.resourceToEdit.resource_type.id = '';
        this.resourceToEdit.resource_type.name = '';
        this.resourceToEdit.resource_type.group = 'human';
        this.resourceToEdit.id = '';
        this.resourceToEdit.serial = '';
        this.resourceToEdit.name = '';
        this.resourceToEdit.rent = 0;
        this.resourceToEdit.depreciation = 0;
        this.ressourceTypeListToSelect = this.getRessourceTypListWorker();
    }

    addRessourceMachine() {
        this.flagWork = false;
        this.flagMaschine = true;
        this.flagResource = false;
        this.flagEditResource = false;
        this.flagResource = true;
        this.flagEditResource = false;
        this.resourceToEdit = {};
        this.resourceToEdit.resource_type = [];
        this.resourceToEdit.resource_type.id = '';
        this.resourceToEdit.resource_type.name = '';
        this.resourceToEdit.resource_type.group = 'machine';
        this.resourceToEdit.id = '';
        this.resourceToEdit.serial = '';
        this.resourceToEdit.name = '';
        this.resourceToEdit.rent = 0;
        this.resourceToEdit.depreciation = 0;
        this.ressourceTypeListToSelect = this.getRessourcTypeListMachine();
    }

    saveResource() {
        if (!this.btnDisabled) {
            if (this.resourceToEdit.name === undefined || this.resourceToEdit.name === '') {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Bitte geben Sie den Ressourcenname ein';
                this.dialog.open(MessageboxComponent, dialogConfig1);
                this.dataService.changeserverErrFlag();

            } else if (this.resourceToEdit.resource_type.id === undefined || this.resourceToEdit.resource_type.id === '') {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Bitte geben Sie den Ressourcentyp ein';
                this.dialog.open(MessageboxComponent, dialogConfig1);
                this.dataService.changeserverErrFlag();
            } else {
                this.btnDisabled = true;
                const newRessource = {
                    name: this.resourceToEdit.name,
                    resource_type_id: this.resourceToEdit.resource_type.id,
                    rent: this.resourceToEdit.rent,
                    depreciation: this.resourceToEdit.depreciation,
                    serial: this.resourceToEdit.serial,
                };

                this.Apiservice.addNewRessource(newRessource).subscribe((data: any) => {

                    if (data) {
                        if (data.id) {
                            // Everything is fine
                            this.flagResource = false;
                            this.flagEditResource = false;
                            this.resourceToEdit = {};
                            // UPDATE
                            this.updateRessourceList(0, 100);
                            this.btnDisabled = false;

                        } else {
                            const error = this.ResourceErrors.find(ex => ex.code === data.type);
                            this.functionsService.handleAPIsaveError(error, 'Ressource');
                        }
                    }

                });
            }
        }


    }

    updateResource() {
        if (this.resourceToEdit.name === undefined || this.resourceToEdit.name === '') {
            alert('Bitte geben Sie den Ressourcennamen ein');
        } else if (this.resourceToEdit.resource_type.id === undefined || this.resourceToEdit.resource_type.id === '') {
            alert('Bitte geben Sie den Ressourcentyp ein');
        } else {
            const resourceId = this.resourceToEdit.id;
            const newResource = {
                name: this.resourceToEdit.name,
                rent: this.resourceToEdit.rent,
                depreciation: this.resourceToEdit.depreciation,
                serial: this.resourceToEdit.serial,
                resource_type: {
                    id: this.resourceToEdit.resource_type.id,
                    group: this.resourceToEdit.resource_type.group,
                    name: this.resourceToEdit.resource_type.name,
                    blueline_type: this.resourceToEdit.resource_type.blueline_type,
                }
            };
            this.Apiservice.UpdateResource(newResource, resourceId).subscribe((data: any) => {
                if (data) {
                    if (data.id) {
                        // Everything is fine
                        this.flagResource = false;
                        this.flagEditResource = false;
                        this.resourceToEdit = {};
                        // UPDATE
                        this.updateRessourceList(0, 100);

                    } else {
                        const error = this.ResourceErrors.find(ex => ex.code === data.type);

                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }
            });
        }
    }

    public delete() {
        if (this.resourceToEdit) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = {
                txt: 'Wollen Sie wirklich Ressource löschen?',
                option1: 'Ja',
                option2: 'Nein'
            };
            const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                if (result === 1) {
                    this.Apiservice.DeleteResource(this.resourceToEdit.id).subscribe((dataD: any) => {
                        if (dataD) {
                            if (dataD.id) {
                                const dialogConfig1 = new MatDialogConfig();
                                dialogConfig1.disableClose = true;
                                dialogConfig1.autoFocus = true;
                                dialogConfig1.panelClass = 'dialogStyles';
                                dialogConfig1.data = 'Ressource wurde gelöscht';
                                const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                                dialogRef2.afterClosed().subscribe(res => {
                                    if (res === true) {
                                        this.flagResource = false;
                                        this.flagEditResource = false;
                                        this.resourceToEdit = {};
                                        this.updateRessourceList(0, 100);
                                    }
                                });
                            } else {
                                const error = this.ResourceErrors.find(ex => ex.code === dataD.type);

                                this.functionsService.handleAPIsaveError(error, 'Ressource');
                            }
                        }
                    });
                } else if (result === 2) {
                    console.log('not deleted');
                }
            });
        }
    }

    public cancelNew() {
        this.flagResource = false;
        this.flagEditResource = false;
        this.resourceToEdit = {};
    }

    public cancelEdit() {
        this.flagResource = false;
        this.editFlag = false;
    }
}
