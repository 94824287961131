<div class="wrapDiv" fxLayout="column">
    <div fxLayout="row" class="wrapper">
        <h1 style="text-align: center;">Fahrzeug wählen (Container: {{selectedContainer.name}})</h1>
<!--        <button class="CancelButton" (click)="Close()">-->
            <mat-icon class="CloseIcon" (click)="Close()">close</mat-icon>
<!--        </button>-->
    </div>

    <div fxFlexAlign="center" style="width: 100%" >
        <div class="displayContainers">
            <div (click)="Confirm(item)" *ngFor="let item of Mover"  class="column-content">
                <p  class="bez">{{item.name}}</p>
            </div>
        </div>
    </div>
</div>
