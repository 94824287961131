import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MaterialTypes} from '../../../Enums/material-types.enum';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {interval, Subscription} from 'rxjs';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {MatDialog} from '@angular/material/dialog';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import {UserAccessService} from '../../../Services/user-access.service';
import {EnvironmentTypes} from '../../../Enums/environment-types';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['../base-home-page/home-page.component.scss']
})
export class MaterialTableComponent implements OnInit, AfterViewInit, OnDestroy {
  tenant = environment.tenant;
  typeEnvironmentMss = EnvironmentTypes.MSS;

  stockList: any;
  public CurrentProductView = MaterialTypes.INPUT;
  displayedColumns: string[] = ['Material', 'Menge', 'InCharge', 'Offen'];
  public skip = 0;
  public showNextMaterial: boolean;
  public skipMaterial = 0;
  public searchMaterial: string;

  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  public intervalSubscriptionForReceivingDataFromAPI: Subscription;


  constructor(public dialog: MatDialog,
              private apiService: APIService,
              public router: Router,
              public userAccess: UserAccessService) {
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

    if (this.apiService.versioningFinished === true) {
      this.getDataFromAPI();
    } else {
      this.apiService.OnVersioningFinished.subscribe(this.apiVersionCheckFinished.bind(this));
    }
  }

  private apiVersionCheckFinished() {
    if (this.apiService.versioningFinished === true) {
      this.getDataFromAPI();
    }
  }

  private getDataFromAPI() {
    this.getMaterialsFromApi();
    this.intervalSubscriptionForReceivingDataFromAPI = interval(180000).subscribe(() => {
      this.getMaterialsFromApi();
    });
  }

  private getMaterialsFromApi() {
    this.receiveMaterialInStock(0, 100);
  }

  public paginateRightMaterial() {
    this.skipMaterial = this.skipMaterial + 100;
    this.receiveMaterialInStock(this.skipMaterial, 100);
  }

  public paginateLeftMaterial() {
    this.skipMaterial = this.skipMaterial - 100;
    this.receiveMaterialInStock(this.skipMaterial, 100);
  }

  showMSSproducts() {
    this.CurrentProductView = MaterialTypes.MSSMATERIAL;
    this.receiveMaterialInStock(this.skip, 100);
  }


  showVIproducts() {
    this.CurrentProductView = MaterialTypes.INPUT;
    this.receiveMaterialInStock(this.skip, 100);
  }

  showVOproducts() {
    this.CurrentProductView = MaterialTypes.OUTPUT;
    this.receiveMaterialInStock(this.skip, 100);
  }

  showVZproducts() {
    this.CurrentProductView = MaterialTypes.INTERMEDIATE;
    this.receiveMaterialInStock(this.skip, 100);
  }


  receiveMaterialInStock(skip, limit) {
    this.apiService.getMaterialStock(this.CurrentProductView, skip, limit).subscribe({
      next: this.sortMaterial.bind(this),
    });
  }

  private sortMaterial(data) {
    this.showNextMaterial = data.length === 100;
    this.stockList = data;
    this.dataSource = new MatTableDataSource(this.stockList);
    if (this.searchMaterial) {
      this.dataSource.filter = this.searchMaterial.trim().toLowerCase();
    }
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.intervalSubscriptionForReceivingDataFromAPI.unsubscribe();
  }


}
