import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataShareService} from '../Services/data-share.service';


@Injectable({
  providedIn: 'root'
})
export class Http200HeaderInceptorService implements HttpInterceptor {

  constructor(public dataService: DataShareService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map(event => this.CheckHeader(event)));
  }


  private CheckHeader(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (event.status === 200) {
        const dateString = event.headers.get('date');
        if (dateString) {
          const date = new Date(dateString);
          if (date) {
          }
        }

      }
    }
    return event;

  }
}
