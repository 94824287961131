import {
    Component,
    HostBinding,
    Inject,
    OnInit,
    AfterViewInit,
    ElementRef,
    ViewChildren,
    QueryList,
    OnDestroy
} from '@angular/core';
import * as moment from 'moment';
import {APIService} from 'src/app/Services/api.service';
import {DateService} from 'src/app/Services/date.service';
import {DataShareService} from '../../Services/data-share.service';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {map, switchMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ExecutionTypes} from '../../Enums/execution-types.enum';
import {UserAccessService} from '../../Services/user-access.service';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {ProcessTypes} from '../../Enums/process-types.enum';
import {environment} from '../../../environments/environment';
import {GlobalFunctionsService} from '../../Services/global-functions';
import {EnvironmentTypes} from '../../Enums/environment-types';




@Component({
    selector: 'app-date-card',
    templateUrl: './cw-calender.component.html',
    styleUrls: ['./cw-calender.component.scss']
})
export class CwCalenderComponent implements OnInit, AfterViewInit, OnDestroy {

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;

    constructor(public dialog: MatDialog, private sanitizer: DomSanitizer,
                public dateService: DateService,
                private apiService: APIService,
                private dataService: DataShareService,
                private router: Router,
                private functionsService: GlobalFunctionsService,
                @Inject(DOCUMENT) document,
                public userAccess: UserAccessService) {
    }

    public currentDay = '5';

    public batches: any[];
    times = ['700', '730', '800', '830', '900', '930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530'];
    days = ['2', '3', '4', '5', '6'];
    @HostBinding('style.--mon-var')
    private slon: string;
    @HostBinding('style.--tue-var')
    private tuesdayStart = 3;
    @HostBinding('style.--wed-var')
    private wedStart = 4;
    @HostBinding('style.--thur-var')
    private thursdayStart = 5;
    @HostBinding('style.--fri-var')
    private fridayStart = 6;
    @HostBinding('style.--monend-var')
    private mondayEnd = 3;
    @HostBinding('style.--tueend-var')
    private tuesdayEnd = 4;
    @HostBinding('style.--wedend-var')
    private wedEnd = 5;
    @HostBinding('style.--thurend-var')
    private thursdayEnd = 6;
    @HostBinding('style.--friend-var')
    private fridayEnd = 7;
    @HostBinding('style.--monb-var')
    private mondayStartBatch = 2;
    @HostBinding('style.--tueb-var')
    private tuesdayStartBatch = 3;
    @HostBinding('style.--wedb-var')
    private wedStartBatch = 4;
    @HostBinding('style.--thurb-var')
    private thursdayStartBatch = 5;
    @HostBinding('style.--frib-var')
    private fridayStartBatch = 6;
    @HostBinding('style.--monendb-var')
    private mondayEndBatch = 3;
    @HostBinding('style.--tueendb-var')
    private tuesdayEndBatch = 4;
    @HostBinding('style.--wedendb-var')
    private wedEndBatch = 5;
    @HostBinding('style.--thurendb-var')
    private thursdayEndBatch = 6;
    @HostBinding('style.--friendb-var')
    private fridayEndBatch = 7;
    @HostBinding('style.--moncheck-var')
    private mondayCheck = 2;
    @HostBinding('style.--tuecheck-var')
    private tuesdayCheck = 3;
    @HostBinding('style.--wedcheck-var')
    private wedCheck = 4;
    @HostBinding('style.--thurcheck-var')
    private thursdayCheck = 5;
    @HostBinding('style.--fricheck-var')
    private fridayCheck = 6;
    @HostBinding('style.--monendcheck-var')
    private mondayEnCheck = 3;
    @HostBinding('style.--tueendcheck-var')
    private tuesdayEndCheck = 4;
    @HostBinding('style.--wedendcheck-var')
    private wedEndCheck = 5;
    @HostBinding('style.--thurendcheck-var')
    private thursdayEndCheck = 6;
    @HostBinding('style.--friendcheck-var')
    private fridayEndCheck = 7;


    calendar: any;
    batchesToShow: any[];
    isToggled: boolean[];
    currentMoment: moment.Moment;
    private subscription$: Subscription;
    private subscriptionTobatch$: Subscription;
    public batchToToggle = undefined;

    @ViewChildren('div') divs: QueryList<ElementRef>;


    ngOnInit(): void {
        this.isToggled = [];
        this.show();
        this.subscriptionTobatch$ = this.dataService.BatchToCalender.subscribe(data => {
            this.batchToToggle = data;
        });

        if (this.apiService.versioningFinished === true) {
            this.subscription$ = this.dateService.date.pipe(switchMap(data => this.generate(data))).subscribe(() => {
                    if (this.batchToToggle && Object.keys(this.batchToToggle).length !== 0) {
                        const index = this.batchesToShow.findIndex(bat => bat.id === this.batchToToggle.id);
                        this.toggle(this.batchToToggle, index);
                        this.dataService.changeBatchToCalender(undefined);
                    }

                },
                error => this.apiService.showError.bind(this)
            );
            this.dataService.ReleaseBatch();
        } else {
            this.apiService.OnVersioningFinished.subscribe(() => {
                this.subscription$ = this.dateService.date.pipe(switchMap(data => this.generate(data))).subscribe({
                    error:  this.functionsService.handleServerNotReachableError.bind(this)
                });
                this.dataService.ReleaseBatch();
            });
        }
    }

    ngAfterViewInit() {


    }

    currentweek() {
        const now = moment();
        this.dateService.changeDate(now);
        this.isToggled = [];
    }

    goCw(dir: number) {
        this.dateService.changeWeek(dir);
        this.isToggled = [];
    }

    goYear(dir: number) {
        this.dateService.changeYear(dir);
        this.isToggled = [];
    }

    show() {
    }

    getIntersected(tasks, index) {
        const buffer = new Set();
        this.collectIntersected(tasks, index, buffer);

        buffer.delete(index);
        // @ts-ignore
        const testArray = Array.from(buffer).map(idx => tasks[idx]);
        for (const entry of testArray) {
            if (this.isToggled[index]) {
                if (entry.margintop > this.batchesToShow[index].margintop && entry.day === this.batchesToShow[index].day) {
                    entry.margintop = entry.margintop + this.batchesToShow[index].resources.length * 72;
                } else {
                }
            } else {
                if (entry.margintop > this.batchesToShow[index].margintop && entry.day === this.batchesToShow[index].day) {
                    entry.margintop = entry.margintop - this.batchesToShow[index].resources.length * 72;
                } else {
                }
            }
        }
    }

    collectIntersected(tasks, index, buffer) {
        const task = tasks[index];
        tasks.forEach((currentTask, i) => {
            if (this.predicate(currentTask, task) &&
                currentTask.margintop > task.margintop &&
                !buffer.has(i)) { // skip already checked to exit recursion
                buffer.add(i);
                this.collectIntersected(tasks, i, buffer);
            }
        });
    }

    predicate(currentTask, task) {
        return (currentTask.scheduleddate < task.enddate && currentTask.scheduleddate >= task.scheduleddate) // left border inside
            || (currentTask.enddate <= task.enddate && currentTask.enddate > task.scheduleddate) // right border inside
            || (currentTask.scheduleddate <= task.scheduleddate && currentTask.enddate >= task.enddate); // includes
    }

    toggle(batch, i) {
        this.isToggled[i] = !this.isToggled[i];
        this.getIntersected(this.batchesToShow, i);
    }

    public getEndTime(dt: any, leadtime: number): moment.Moment {
        if (dt) {
            let d = moment(new Date(dt));
            d = moment(moment(d).add(leadtime, 'hours').clone()).clone();
            return d;
        }
        return null;
    }

    public getDateFromDay(day: number): string {
        const startDay = this.currentMoment.clone().startOf('week');

        return startDay.add(day, 'day').format('DD.MM.yyyy');

    }

    checkOverlappedBatches() {
        for (let i = 0; i < this.batchesToShow.length; i++) {
            for (let j = i + 1; j < this.batchesToShow.length; j++) {
                if (this.batchesToShow[i].id !== this.batchesToShow[j].id && this.batchesToShow[i].date === this.batchesToShow[j].date ) {
                    if ((this.batchesToShow[i].scheduleddate < this.batchesToShow[j].enddate &&
                        this.batchesToShow[i].scheduleddate >= this.batchesToShow[j].scheduleddate) || (
                        this.batchesToShow[i].scheduleddate <= this.batchesToShow[j].scheduleddate &&
                        this.batchesToShow[i].enddate > this.batchesToShow[j].enddate) || (
                        this.batchesToShow[i].enddate <= this.batchesToShow[j].enddate &&
                        this.batchesToShow[i].enddate > this.batchesToShow[j].scheduleddate)) {
                        if (this.batchesToShow[j].margintop === this.batchesToShow[i].margintop) {
                            this.batchesToShow[j].margintop = this.batchesToShow[j].margintop + 125;
                            return false;
                        }
                    }
                }

            }
        }

        return true;
    }

    public round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    generate(now: moment.Moment): Observable<any[]> {

        this.days = ['2', '3', '4', '5', '6'];
        this.batchesToShow = [];
        this.currentMoment = now.clone();
        const startDay = now.clone().startOf('week');
        const date = startDay.clone().subtract(1, 'day');
        const endDay = now.clone().endOf('week').subtract(2, 'day');


        const curr = moment(new Date());

        if (curr.isSameOrAfter(startDay) && curr.isSameOrBefore(endDay)) {
            this.currentDay = (moment(new Date()).day() + 1).toString();
        } else {
            this.currentDay = '-1';
        }

        const week = now.clone().week();
        const year = now.clone().year();

        this.batches = [];
        // GET BATCHES
        return this.apiService.getBatchListByCw(year, week).pipe(map((data: any) => {
                if (data) {
                    console.log(data);
                    this.batches = data;
                    this.batchesToShow = [];

                    for (const bt of this.batches) {
                        let processType = '';
                        let processSort = '';

                        if (bt.process.process_category === 'processing') {
                            processType = ProcessTypes.AUFBEREITUNG;
                            processSort = 'A';
                        }
                        else if (bt.process.process_category === 'maintenance') {
                            processType = ProcessTypes.INSTANDHALTUNG;
                            processSort = 'C';
                        } else if (bt.process.process_category === 'shredding') {
                            processType = ProcessTypes.ZERKLEINERUNG;
                            processSort = 'B';
                        }

                        if (bt.scheduled_start) {
                            bt.scheduled_start = moment.utc(bt.scheduled_start);
                            bt.scheduled_start = this.round(bt.scheduled_start, moment.duration(30, 'minutes'), 'round');
                            let endDate = moment(this.getEndTime(bt.scheduled_start, bt.lead_time).clone());
                            let endOfFoundDay;
                            // CALCULATE DATE
                            let current = moment(bt.scheduled_start);
                            if (current.day() === 5) {
                                endOfFoundDay = moment(current.clone().format('yyyy-MM-DD') + ' ' + '11:30:00');
                            } else {
                                endOfFoundDay = moment(current.format('yyyy-MM-DD') + ' ' + '16:00:00');
                            }
                            const d = new Date(bt.scheduled_start);
                            let hours = Math.trunc(bt.lead_time);
                            let minutes = Math.trunc((bt.lead_time - hours) * 60);
                            if (minutes > 0 && minutes <= 30) {
                                minutes = 30;
                            } else if (minutes > 30) {
                                hours = hours + 1;
                                minutes = 0;
                            }


                            const roundedLead = hours + (minutes / 60.0);
                            endDate = moment(d).add(roundedLead, 'hours').clone();


                            if (endDate.isAfter(endOfFoundDay)) {
                                while (endDate.isAfter(endOfFoundDay)) {
                                    const endsts = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                                    const startsts = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));
                                    if (current.isBefore(endsts) && current.isAfter(startsts)) {
                                        this.batchesToShow.push({
                                            id: bt.id,
                                            date: current.format('DD-MM-YYYY'),
                                            start: (Number(current.format('HHmm'))).toString(),
                                            end: (Number(endOfFoundDay.add(-30, 'minute').format('HHmm'))).toString(),
                                            name: bt.name,
                                            input: bt.input,
                                            resources: bt.resources,
                                            lead_time: bt.lead_time,
                                            stockunavailable: bt.stockunavailable,
                                            day: current.day(),
                                            enddate: endOfFoundDay.add(30, 'minute'),
                                            scheduleddate: bt.scheduled_start,
                                            haveAfter: true,
                                            haveBefore: !current.isSame(moment(bt.scheduled_start)),
                                            margintop: 0,
                                            state: bt.state,
                                            process_type: processType,
                                            process_sort: processSort
                                        });
                                    }
                                    // Calculate Timediff
                                    const result = this.functionsService.findEndDate(endOfFoundDay, current, minutes, hours, endDate);
                                    endOfFoundDay = result.endOfFoundDay;
                                    current = result.current;
                                    minutes = result.minutes;
                                    hours = result.hours;
                                    endDate = result.endDate;
                                }

                                const endst = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                                const startst = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));

                                if (endDate.isBefore(endst) && endDate.isAfter(startst)) {
                                    this.batchesToShow.push({
                                        id: bt.id,
                                        date: current.format('DD-MM-YYYY'),
                                        start: (Number(current.format('HHmm'))).toString(),
                                        end: (Number(endDate.add(-30, 'minute').format('HHmm'))).toString(),
                                        name: bt.name,
                                        input: bt.input,
                                        resources: bt.resources,
                                        lead_time: bt.lead_time,
                                        stockunavailable: bt.stockunavailable,
                                        day: current.day(),
                                        enddate: endDate.add(30, 'minute'),
                                        scheduleddate: bt.scheduled_start,
                                        haveAfter: false,
                                        haveBefore: !current.isSame(moment(bt.scheduled_start)),
                                        margintop: 0,
                                        state: bt.state,
                                        process_type: processType,
                                        process_sort: processSort
                                    });
                                }
                            } else {
                                const endst = moment(endDay.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
                                const startst = moment(startDay.clone().add(-1, 'day').clone().format('YYYY-MM-DD HH:mm:ss'));
                                if (endDate.isBefore(endst) && endDate.isAfter(startst)) {
                                    this.batchesToShow.push({
                                        id: bt.id,
                                        date: bt.scheduled_start.format('DD-MM-YYYY'),
                                        start: (Number(bt.scheduled_start.format('HHmm'))).toString(),
                                        end: (Number(endDate.add(-30, 'minute').format('HHmm'))).toString(),
                                        name: bt.name,
                                        input: bt.input,
                                        resources: bt.resources,
                                        lead_time: bt.lead_time,
                                        stockunavailable: bt.stockunavailable,
                                        day: bt.scheduled_start.day(),
                                        enddate: endDate.add(30, 'minute'),
                                        scheduleddate: bt.scheduled_start,
                                        haveAfter: false,
                                        haveBefore: false,
                                        margintop: 0,
                                        state: bt.state,
                                        process_type: processType,
                                        process_sort: processSort
                                    });
                                }
                            }
                        }
                    }


                    for (let i = 0; i < this.batchesToShow.length; i++) {
                        if (moment(this.batchesToShow[i].date, 'DD-MM-YYYY').week() !== week) {
                            this.batchesToShow.splice(i, 1);
                        }

                    }


                    for (const batch of this.batchesToShow) {
                        if (batch.scheduleddate.day() !== batch.enddate.day()) {
                            batch.scheduleddate = moment(
                                batch.scheduleddate.clone()
                                    .add((batch.enddate.day() - batch.scheduleddate.day()), 'day')
                                    .format('yyyy-MM-DD') + ' ' + '07:00:00');
                        }
                    }

                    this.batchesToShow.sort((a, b) => (a.scheduleddate < b.scheduleddate ? -1 : 1));

                    // sorting to show "Instandhaltung" as first process type
                    this.batchesToShow.sort((a, b) => (a.process_sort > b.process_sort ? -1 : 1));

                    while (!this.checkOverlappedBatches()) {
                        this.checkOverlappedBatches();
                    }

                    console.log(this.batchesToShow);

                    // logic to show different process types on different lines
                    for (let k = 1; k < 6; k++) {
                        let marginTopMaxInstandhaltung = 0;
                        let instandhaltungExist = false;
                        for (const item of this.batchesToShow) {
                            if (item.process_type === ProcessTypes.INSTANDHALTUNG && item.day === k) {
                                instandhaltungExist = true;
                                if (item.margintop > marginTopMaxInstandhaltung) {
                                    marginTopMaxInstandhaltung = item.margintop;
                                }
                            }
                        }

                        for (const item of this.batchesToShow) {
                            if (item.process_type === ProcessTypes.ZERKLEINERUNG && item.day === k && instandhaltungExist) {
                                item.margintop = marginTopMaxInstandhaltung + 125;
                            }
                        }
                        while (!this.checkOverlappedBatches()) {
                            this.checkOverlappedBatches();
                        }

                        let marginTopMaxZerkleinerung = 0;
                        let zerkleinerungExist = false;

                        for (const item of this.batchesToShow) {
                            if (item.process_type === ProcessTypes.ZERKLEINERUNG && item.day === k) {
                                zerkleinerungExist = true;
                                if (item.margintop > marginTopMaxZerkleinerung) {
                                    marginTopMaxZerkleinerung = item.margintop;
                                }
                            }
                        }

                        const marginTopForAufbereitung = Math.max(marginTopMaxZerkleinerung, marginTopMaxInstandhaltung);

                        for (const item of this.batchesToShow) {
                            if (item.process_type === ProcessTypes.AUFBEREITUNG && item.day === k) {
                                if (zerkleinerungExist || instandhaltungExist) {
                                    item.margintop = marginTopForAufbereitung + 125;
                                }
                            }
                        }

                        while (!this.checkOverlappedBatches()) {
                            this.checkOverlappedBatches();
                        }
                    }
                    // logic to show different process types on different lines

                    const calendar = [];
                    while (date.isBefore(endDay, 'day')) {
                        const value = date.add(1, 'day').clone();
                        const testing = value.format('DD-MM-YYYY');
                        const newArray = this.batchesToShow.filter(x => x.date === testing);
                        const timeslots = new Array(24);


                        const ob = {value, newArray, timeslots};
                        calendar.push(ob);
                    }
                    this.calendar = calendar;


                } else {
                    this.batches = [];
                    this.batchesToShow = [];
                    this.calendar = [];
                    return this.batchesToShow;
                }

            }
        ));
    }

    public edit(batch: any) {
        this.dataService.BatchToShow = batch;
        this.router.navigate(['/batches']).catch(err => console.log(err));
    }

    public runTreatment($event: Event, batch: any) {
        $event.stopPropagation();

        const bt = this.batches.find(ex => ex.id === batch.id);

        if (bt) {
            if (environment.tenant === EnvironmentTypes.MSS) {
                this.router.navigate(['/mssexecution/' + bt.id]).catch(err => console.log(err));
            }
            else {
                this.router.navigate(['/execution/' + bt.id]).catch(err => console.log(err));
            }
        }
    }
    public showInfoIcon(batch: any) {

        return batch.stockunavailable === true;
    }

    public checkTreatmentFinishedTrue(batch: any) {

        if (!batch.state) {
            return true;
        }
        return batch.state !== ExecutionTypes.COMPLETE;
    }

    getTreatmentState(state: string): string {
        if (state) {
            if (state === ExecutionTypes.SETUP) {
                return 'Rüsten';
            } else if (state === ExecutionTypes.PROCESSING) {
                return 'Aufbereiten';
            } else if (state === ExecutionTypes.TEARDOWN) {
                return 'Nachbereiten';
            } else if (state === ExecutionTypes.BOOKED) {
                return 'Gebucht';
            } else if (state === ExecutionTypes.PAUSE) {
                return 'Pausiert';
            } else if (state === ExecutionTypes.COMPLETE) {
                return 'Abgeschlossen';
            } else if (state === ExecutionTypes.OFFSHIFT) {
                return 'Keine Schicht';
            }
        } else {
            return '';
        }
    }

    public gotToBooking($event: Event, batch: any) {
        $event.stopPropagation();
        if (batch.state) {
            if (batch.state === ExecutionTypes.COMPLETE) {
                this.dataService.TreatmentToSelectInBook = batch;
                this.router.navigate(['/calculation']).catch(err => console.log(err));
            }
        }
        if (batch.state) {
            if (batch.state === ExecutionTypes.BOOKED) {
                this.apiService.getCalculationBatch(batch.id)
                    .subscribe({
                        next: (data) => {
                            this.dataService.BatchToBook = data;
                            this.router.navigate(['/calculation-book']);
                        },
                        error: this.apiService.showError.bind(this)
                    });
            }
        }
    }


    ngOnDestroy() {
        this.subscription$.unsubscribe();
        this.subscriptionTobatch$.unsubscribe();
    }

}
