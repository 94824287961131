
<div *ngIf="invoicesList" class="weightingsWrapForPrinting">
    <div class="filterButtonRow filterRowForPrinting" >
        <div class="elementrow" fxLayout="row">
            <p class="headerDate">Von {{minDate| date: 'dd.MM.yyyy'}}</p>

        </div>
        <div class="elementrow" fxLayout="row">
            <p class="headerDate">Bis {{maxDate| date: 'dd.MM.yyyy'}}</p>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Materialnummer </mat-label>
                <input [(ngModel)]="productName" matInput>
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Materialname</mat-label>
                <input [(ngModel)]="productNummer" matInput (keyup.enter)="buttonSearch()" type="text">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Waage</mat-label>
                <input *ngIf="selectedScale; else elsePrintBlock"  [(ngModel)]="selectedScale.name" matInput>
                <ng-template #elsePrintBlock>
                    <input [(ngModel)]="selectedScale" matInput>
                </ng-template>
                <mat-select [(ngModel)]="selectedScale" class="select notToPrint">
                    <mat-option *ngFor="let scale of scales" [value]="scale.id" [disabled]="true">{{scale.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Von (netto)</mat-label>
                <input matInput [(ngModel)]="weightMin" type="number">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Bis (netto)</mat-label>
                <input matInput [(ngModel)]="weightMax" type="number">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper" >
                <mat-label>Benutzer</mat-label>
                <input  [(ngModel)]="user" matInput>
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Charge</mat-label>
                <input [(ngModel)]="batchName" matInput>
            </mat-form-field>
        </div>
        <div class="elementrow searchButton" fxLayout="row">
            <button (click)="buttonSearch()" class="btn searchButton"
                    mat-button>Filtern
            </button>
        </div>
    </div>
    <div fxLayout="row">
        <table *ngIf="invoicesList" [dataSource]="invoicesList" class="InvoicesTable" fxFlexAlign="stretch"
               mat-table>
            <ng-container matColumnDef="Zeit">
                <th *matHeaderCellDef class="thZeit" mat-header-cell style="width: 12%">Zeitpunkt</th>
                <td *matCellDef="let element" mat-cell>
                    {{element.time | date:dateFormat}}
                </td>
                <td class="footerCell" mat-footer-cell *matFooterCellDef> Summe </td>
            </ng-container>
            <ng-container matColumnDef="Material">
                <th *matHeaderCellDef  mat-header-cell style="width: 18%"> Material</th>
                <td *matCellDef="let element" class="tdMaterial" mat-cell>
                    {{element.material.id}} <br>
                    {{element.material.name}}
                </td>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
            <ng-container matColumnDef="Batch">
                <th *matHeaderCellDef  mat-header-cell style="width: 18%"> Charge</th>
                <td *matCellDef="let element" class="tdMaterial" mat-cell>
                    {{element.batch.name}}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>

            </ng-container>
            <ng-container matColumnDef="Fahrzeug Name">
                <th *matHeaderCellDef class="thMenge" mat-header-cell> Fahrzeug</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.mover; else noMover" class="tdMenge"
                        mat-cell> {{element.mover.name}} </td>
                </ng-container>
                <ng-template #noMover>
                    <td>-</td>
                </ng-template>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
            <ng-container matColumnDef="Fahrzeug Tara">
                <th *matHeaderCellDef class="thinChargen" mat-header-cell> Fahrzeug <br>Tara [t]</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.mover; else noMoverTara" class="tdMenge"
                        mat-cell> {{element.tare_weight_mover| number: '1.3-3':'de'}} </td>
                </ng-container>
                <ng-template #noMoverTara>
                    <td>-</td>
                </ng-template>

                <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalMoverWeight()| number: '1.3-3':'de'}} </td>


            </ng-container>
            <ng-container matColumnDef="Container Name">
                <th *matHeaderCellDef class="thOffen" mat-header-cell>Container</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.container; else noReplies" class="tdMenge"
                        mat-cell> {{element.container.name}} </td>
                </ng-container>
                <ng-template #noReplies>
                    <td>-</td>
                </ng-template>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
            <ng-container matColumnDef="Container Tara">
                <th *matHeaderCellDef  mat-header-cell>Container <br>Tara [t]</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.container; else noContainerTara" class="tdMenge"
                        mat-cell> {{element.tare_weight_container| number: '1.3-3':'de'}} </td>
                </ng-container>
                <ng-template #noContainerTara>
                    <td>-</td>
                </ng-template>

                <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalTara()| number: '1.3-3':'de'}} </td>

            </ng-container>
            <ng-container matColumnDef="Waage">
                <th *matHeaderCellDef class="thScale" mat-header-cell>Waage</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.scale; else noScale" class="tdMenge"
                        mat-cell> {{element.scale.name}} </td>
                </ng-container>
                <ng-template #noScale>
                    <td>-</td>
                </ng-template>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
            <ng-container matColumnDef="Brutto">
                <th *matHeaderCellDef class="thWeight" mat-header-cell>Brutto <br> [t]</th>
                <td *matCellDef="let element" class="tdMenge" mat-cell>
                    {{getBruttoWeight(element.net_weight, element.tare_weight_mover, element.tare_weight_container)| number: '1.3-3':'de'}}
                </td>
                <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalBrutto()| number: '1.3-3':'de'}}</td>
            </ng-container>
            <ng-container matColumnDef="Netto">
                <th *matHeaderCellDef class="thWeight" mat-header-cell>Netto <br> [t]</th>
                <td *matCellDef="let element" class="tdMenge" mat-cell>
                    {{element.net_weight| number: '1.3-3':'de'}}
                </td>
                <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalNetto()| number: '1.3-3':'de'}} </td>
            </ng-container>
            <ng-container matColumnDef="Username">
                <th *matHeaderCellDef  mat-header-cell>Benutzer</th>
                <ng-container *matCellDef="let element">
                    <td *ngIf="element.username; else noUser" class="tdMenge"
                        mat-cell> {{element.username}} </td>
                </ng-container>
                <ng-template #noUser>
                    <td>-</td>
                </ng-template>
                <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>
            <ng-container matColumnDef="DeleteIcon">
                <th *matHeaderCellDef  mat-header-cell></th>
                <ng-container *matCellDef="let element">
                    <td class="tdMenge"
                        mat-cell></td>
                </ng-container>
                <td mat-footer-cell *matFooterCellDef>  </td>

            </ng-container>
            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            <tr class="footerRow" mat-footer-row *matFooterRowDef="['Zeit', 'Material', 'Batch', 'Fahrzeug Name', 'Fahrzeug Tara', 'Container Name', 'Container Tara', 'Waage', 'Brutto', 'Netto', 'Username', 'DeleteIcon']"></tr>
        </table>
    </div>
</div>

<div class="wrapper">
    <h1 class="tableheader">Wiegungen</h1>
    <div class="filterButtonRow notToPrint">
        <div class="elementrow dateRow" fxLayout="row">
            <p class="headerDate">Von</p>
            <mat-form-field class="dateInput">
                <div fxLayout="row">
                    <input [(ngModel)]="minDate" [owlDateTimeTrigger]="dtPicker1"
                           [owlDateTime]="dtPicker1" matInput>
                    <span [owlDateTimeTrigger]="dtPicker1">
                                    <mat-icon class="material-icons-outlined">
                                    calendar_month
                                    </mat-icon>
                                      </span>
                    <owl-date-time #dtPicker1></owl-date-time>
                </div>
            </mat-form-field>

        </div>
        <div class="elementrow dateRow" fxLayout="row">
            <p class="headerDate">Bis</p>
            <mat-form-field class="dateInput">
                <div fxLayout="row">
                    <input [(ngModel)]="maxDate" [owlDateTimeTrigger]="dtPicker2"
                           [owlDateTime]="dtPicker2" matInput>
                    <span [owlDateTimeTrigger]="dtPicker2" class="trigger">
                                    <mat-icon class="material-icons-outlined">
                                    calendar_month
                                    </mat-icon>
                                      </span>
                    <owl-date-time #dtPicker2></owl-date-time>
                </div>
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Materialnummer</mat-label>
                <input [(ngModel)]="productName" matInput (keyup.enter)="buttonSearch()" type="text">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Materialname</mat-label>
                <input [(ngModel)]="productNummer" matInput (keyup.enter)="buttonSearch()" type="text">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Waage</mat-label>
                <mat-select [(ngModel)]="selectedScale" class="select">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let scale of scales" [value]="scale">{{scale.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
         <div class="elementrow" fxLayout="row">
             <mat-form-field class="inputLabelWrapper">
                 <mat-label>Von (netto)</mat-label>
                 <input matInput [(ngModel)]="weightMin" type="number" (keyup.enter)="buttonSearch()">
             </mat-form-field>
         </div>
         <div class="elementrow" fxLayout="row">
             <mat-form-field class="inputLabelWrapper">
                 <mat-label>Bis (netto)</mat-label>
                 <input matInput [(ngModel)]="weightMax" type="number" (keyup.enter)="buttonSearch()">
             </mat-form-field>
         </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Benutzer</mat-label>
                <input [(ngModel)]="user" matInput (keyup.enter)="buttonSearch()" type="text">
            </mat-form-field>
        </div>
        <div class="elementrow" fxLayout="row">
            <mat-form-field class="inputLabelWrapper">
                <mat-label>Charge</mat-label>
                <input [(ngModel)]="batchName" matInput (keyup.enter)="buttonSearch()" type="text">
            </mat-form-field>
        </div>
        <div class="elementrow searchButton" fxLayout="row">
            <button (click)="buttonSearch()" class="btn searchButton"
                    mat-button>Filtern
            </button>
        </div>
    </div>

    <div *ngIf="invoicesList" class="CalculationView">
        <div class="filterButtonRow filterRowForPrinting" >
            <div class="elementrow" fxLayout="row">
                <p class="headerDate">Von {{minDate| date: 'dd.MM.yyyy'}}</p>

            </div>
            <div class="elementrow" fxLayout="row">
                <p class="headerDate">Bis {{maxDate| date: 'dd.MM.yyyy'}}</p>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Materialnummer </mat-label>
                    <input [(ngModel)]="productName" matInput>
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Materialname</mat-label>
                    <input [(ngModel)]="productNummer" matInput (keyup.enter)="buttonSearch()" type="text">
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Waage</mat-label>
                    <input *ngIf="selectedScale; else elsePrintBlock"  [(ngModel)]="selectedScale.name" matInput>
                    <ng-template #elsePrintBlock>
                        <input [(ngModel)]="selectedScale" matInput>
                    </ng-template>
                    <mat-select [(ngModel)]="selectedScale" class="select notToPrint">
                        <mat-option *ngFor="let scale of scales" [value]="scale.id" [disabled]="true">{{scale.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Von (netto)</mat-label>
                    <input matInput [(ngModel)]="weightMin" type="number">
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Bis (netto)</mat-label>
                    <input matInput [(ngModel)]="weightMax" type="number">
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper" >
                    <mat-label>Benutzer</mat-label>
                    <input  [(ngModel)]="user" matInput>
                </mat-form-field>
            </div>
            <div class="elementrow" fxLayout="row">
                <mat-form-field class="inputLabelWrapper">
                    <mat-label>Charge</mat-label>
                    <input [(ngModel)]="batchName" matInput>
                </mat-form-field>
            </div>
            <div class="elementrow searchButton" fxLayout="row">
                <button (click)="buttonSearch()" class="btn searchButton"
                        mat-button>Filtern
                </button>
            </div>
        </div>
        <div fxLayout="row">
            <table *ngIf="invoicesList" [dataSource]="invoicesList" class="InvoicesTable" fxFlexAlign="stretch"
                   mat-table>
                <ng-container matColumnDef="Zeit">
                    <th *matHeaderCellDef class="thZeit" mat-header-cell style="width: 12%">Zeitpunkt</th>
                    <td *matCellDef="let element" mat-cell>
                        {{element.time | date:dateFormat}}
                    </td>
                    <td class="footerCell" mat-footer-cell *matFooterCellDef> Summe </td>
                </ng-container>
                <ng-container matColumnDef="Material">
                    <th *matHeaderCellDef  mat-header-cell style="width: 18%"> Material</th>
                    <td *matCellDef="let element" class="tdMaterial" mat-cell>
                        {{element.material.id}} <br>
                        {{element.material.name}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
                <ng-container matColumnDef="Batch">
                    <th *matHeaderCellDef  mat-header-cell style="width: 18%"> Charge</th>
                    <td *matCellDef="let element" class="tdMaterial" mat-cell>
                        {{element.batch.name}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>
                <ng-container matColumnDef="Fahrzeug Name">
                    <th *matHeaderCellDef class="thMenge" mat-header-cell> Fahrzeug</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.mover; else noMover" class="tdMenge"
                            mat-cell> {{element.mover.name}} </td>
                    </ng-container>
                    <ng-template #noMover>
                        <td>-</td>
                    </ng-template>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
                <ng-container matColumnDef="Fahrzeug Tara">
                    <th *matHeaderCellDef class="thinChargen" mat-header-cell> Fahrzeug <br>Tara [t]</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.mover; else noMoverTara" class="tdMenge"
                            mat-cell> {{element.tare_weight_mover| number: '1.3-3':'de'}} </td>
                    </ng-container>
                    <ng-template #noMoverTara>
                        <td>-</td>
                    </ng-template>

                        <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalMoverWeight()| number: '1.3-3':'de'}} </td>


                </ng-container>
                <ng-container matColumnDef="Container Name">
                    <th *matHeaderCellDef class="thOffen" mat-header-cell>Container</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.container; else noReplies" class="tdMenge"
                            mat-cell> {{element.container.name}} </td>
                    </ng-container>
                    <ng-template #noReplies>
                        <td>-</td>
                    </ng-template>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
                <ng-container matColumnDef="Container Tara">
                    <th *matHeaderCellDef  mat-header-cell>Container <br>Tara [t]</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.container; else noContainerTara" class="tdMenge"
                            mat-cell> {{element.tare_weight_container| number: '1.3-3':'de'}} </td>
                    </ng-container>
                    <ng-template #noContainerTara>
                        <td>-</td>
                    </ng-template>

                        <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalTara()| number: '1.3-3':'de'}} </td>

                </ng-container>
                <ng-container matColumnDef="Waage">
                    <th *matHeaderCellDef class="thScale" mat-header-cell>Waage</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.scale; else noScale" class="tdMenge"
                            mat-cell> {{element.scale.name}} </td>
                    </ng-container>
                    <ng-template #noScale>
                        <td>-</td>
                    </ng-template>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
                <ng-container matColumnDef="Brutto">
                    <th *matHeaderCellDef class="thWeight" mat-header-cell>Brutto <br> [t]</th>
                    <td *matCellDef="let element" class="tdMenge" mat-cell>
                        {{getBruttoWeight(element.net_weight, element.tare_weight_mover, element.tare_weight_container)| number: '1.3-3':'de'}}
                    </td>
                    <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalBrutto()| number: '1.3-3':'de'}}</td>
                </ng-container>
                <ng-container matColumnDef="Netto">
                    <th *matHeaderCellDef class="thWeight" mat-header-cell>Netto <br> [t]</th>
                    <td *matCellDef="let element" class="tdMenge" mat-cell>
                        {{element.net_weight| number: '1.3-3':'de'}}
                    </td>
                    <td class="footerCell" mat-footer-cell *matFooterCellDef> {{getTotalNetto()| number: '1.3-3':'de'}} </td>
                </ng-container>
                <ng-container matColumnDef="Username">
                    <th *matHeaderCellDef  mat-header-cell>Benutzer</th>
                    <ng-container *matCellDef="let element">
                        <td *ngIf="element.username; else noUser" class="tdMenge"
                            mat-cell> {{element.username}} </td>
                    </ng-container>
                    <ng-template #noUser>
                        <td>-</td>
                    </ng-template>
                    <td mat-footer-cell *matFooterCellDef>  </td>
                </ng-container>
                <ng-container matColumnDef="DeleteIcon">
                    <th *matHeaderCellDef  mat-header-cell></th>
                    <ng-container *matCellDef="let element">
                        <td class="tdMenge"
                            mat-cell>
                            <mat-icon (click)="deleteWeighing(element)">delete</mat-icon>
                        </td>
                    </ng-container>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>
                <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr class="footerRow" mat-footer-row *matFooterRowDef="['Zeit', 'Material', 'Batch', 'Fahrzeug Name', 'Fahrzeug Tara', 'Container Name', 'Container Tara', 'Waage', 'Brutto', 'Netto', 'Username', 'DeleteIcon']"></tr>
            </table>
        </div>
    </div>
    <div [ngClass]="{'paginatorBoth': skip && showNext,  'paginatorRight': !skip, 'hide': disablePaginateButton}"
         class="paginator">
        <div (click)="paginateLeft()" *ngIf="skip" class="previous">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div (click)="paginateRight()" *ngIf="showNext" class="next">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</div>

