import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-affected-ressources',
  templateUrl: './affected-ressources.component.html',
  styleUrls: ['./affected-ressources.component.css']
})
export class AffectedRessourcesComponent implements OnInit {

  public resources: any;

  constructor(public DialogRef: MatDialogRef<AffectedRessourcesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.resources = data;
  }


  ngOnInit(): void {
  }

  public Continue() {
    this.DialogRef.close(true);
  }

  public Cancel() {
    this.DialogRef.close(false);
  }

}
