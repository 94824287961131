<div class="wrapper" [class.wrapper2]="router.url === '/calendar' && sideNav.opened === false">
    <mat-toolbar>
        <mat-toolbar-row>
            <button *ngIf="loginDisplay" mat-icon-button style="margin-top: -3px;" class="notToPrint"
                    (click)="sideNav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <div style="flex: 2;">
                <img (click)="RouteTo('/home')" class="logoImg" src="assets/images/logo.png" alt="logo">
            </div>
            <div *ngIf="profile" matTooltip="{{userAccess.getUserRoles()}}"
                 style="margin-right: 20px;">{{profile.displayName}}</div>
            <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Anmelden</button>
            <ng-container *ngIf="loginDisplay">
                <a href="{{portalURL}}">
                    <button mat-raised-button *ngIf="environment.portalUrl !== ''; else logout" (click)="redirectToPortal()"
                            class="notToPrint">Portal
                    </button>
                </a>
                <ng-template #logout>
                    <button mat-raised-button (click)="redirectToPortal()" class="notToPrint">Abmelden</button>
                </ng-template>
            </ng-container>
            <div fxLayout="row" [cdkCopyToClipboard]="getCurrentFullVersion()" class="notToPrint">
                <p class="versionstring notToPrint" matTooltip="{{getCurrentFullVersion()}}">
                    Version: {{getCurrentVersion()}}</p>
            </div>


        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container style="flex: 2; background-color: white;">
        <mat-sidenav #sideNav>
            <mat-nav-list class="nav">
                <form [action]="appURL" method="POST" #refreshForm></form>
                <ng-container *ngFor="let item of navItems">
                        <app-menu-list-item [item]="item"></app-menu-list-item>
                </ng-container>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="mainContent">
                <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
                <router-outlet *ngIf="!isIframe"></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

