import {Component} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {APIService} from "../../Services/api.service";
import {Router} from "@angular/router";
import {UserAccessService} from "../../Services/user-access.service";
import {
    MessageElectricityCostsComponent
} from "../../Dialogs/messageToShowSaveElectricityCosts/message-electricity-costs.component";


@Component({
    selector: 'app-settings-page',
    templateUrl: './settings-page.component.html',
    styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
    public electricityCosts: number;
    public overheadCosts: number;


    constructor(public dialog: MatDialog,
                private apiService: APIService,
                public router: Router,
                public userAccess: UserAccessService) {
    }


    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.getEnergyFromAPI();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.apiVersionCheckFinished.bind(this));
        }
    }

    private apiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.getEnergyFromAPI();
        }
    }

    public getEnergyFromAPI() {
        this.apiService.getEnergyCosts().subscribe({
            next: (energyCosts) => {
                this.handleReceivedEnergy(energyCosts);
            }
        });
    }


    public saveElectricity() {
        this.apiService.updateEnergyCosts(this.electricityCosts,
            this.overheadCosts).subscribe({
            next: (energyCosts) => {
                const confirmationDialog = new MatDialogConfig();
                confirmationDialog.disableClose = true;
                confirmationDialog.autoFocus = true;
                confirmationDialog.panelClass = 'dialogStyles';
                confirmationDialog.data = {
                    header: 'Gespeichert',
                    content: `Stromkosten ${this.electricityCosts.toLocaleString('de')} €/kWh \nGemeinkosten Strom ${this.overheadCosts.toLocaleString('de')} %`,
                };
                this.dialog.open(MessageElectricityCostsComponent, confirmationDialog);
            }
        });

    }

    private handleReceivedEnergy(data: any) {
        this.electricityCosts = data.energy_costs;
        this.overheadCosts = data.energy_overhead;
    }

    onInputElectricityCosts(value: any, $event) {
        if (value.value > 50000) {
            this.electricityCosts = 50000;
        }
        if (value.value.length > 8) {
            this.electricityCosts = 0;
        }

        if (value.value < 0) {
            this.electricityCosts = 0;
        }
        if ($event.key === 'e') {
            this.electricityCosts = 0;
        }
    }

    onInputOverheadCosts(value: any, $event) {
        if (value.value > 100) {
            this.overheadCosts = 100;
        }
        if (value.value.length > 5) {
            this.overheadCosts = 0;
        }
        if (value.value < 0) {
            this.overheadCosts = 0;
        }
        if ($event.key === 'e') {
            this.overheadCosts = 0;
        }
    }


}
