<div fxLayout="column" class="content">
    <div>

        <h1 class="tableheader" style="margin-left: 5px; ">Prozesse
            <button (click)="createProcess()" *ngIf="userAccess.hasAccessRightsEn(accessAppAreas.process, accessTypes.edit)">
                <mat-icon class="newButton icon" matPrefix>add</mat-icon>
            </button>
        </h1>
        <mat-form-field>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="search" placeholder="suchen" class="search-input" [(ngModel)]="searchString">
        </mat-form-field>
    </div>
    <div class="selectionContent" fxLayout="row" *ngIf="userAccess.hasAccessRightsEn(accessAppAreas.process, accessTypes.view)">
        <cdk-virtual-scroll-viewport class="listview viewport" itemSize="200">
            <div class="processentry"
                 [ngClass]="{'processentrySel': selectedProcess && selectedProcess.id === process.id}"
                 *ngFor="let process of processes | ProcessFilter:searchString" fxLayout="column"
                 (click)="selectProcess(process)">
                <img *ngIf="process.transferable" class="ksalogo" src="../../../../assets/images/ksa.png" alt="ksalogo">

                <p class="elementheader">{{process.name}}</p>
                <div class="flexRow">
                    <div class="elementrow" fxLayout="column">
                        <div *ngIf="tenant !==  typeEnvironmentMss && process.process_category !=='maintenance'">
                            <p class="header">Input</p>
                            <div class="outputmaterialentry" *ngIf="process.input">
                                <p class="outmaterial">{{process.input.id}} {{process.input.name}}</p>
                            </div>
                        </div>

                        <div *ngIf="process.resource_types.length > 0">
                            <p class="header">Ressourcentypen</p>
                            <div class="ressourcesentry" *ngFor="let ressour of process.resource_types">
                                <p class="resour">{{ressour.quantity| number: '1.0-2':'de'}}x {{ressour.resource_type.name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flexIcon" *ngIf="process.process_category !=='maintenance'">
                        <mat-icon class="iconArrow">chevron_right</mat-icon>
                    </div>

                    <div class="flexOutput" *ngIf="process.output && process.process_category !=='maintenance'">
                        <p class="addHeader">Output</p>
                        <div class="outputmaterialentry" *ngFor="let outmat of process.output">
                            <p class="outmaterial">{{outmat.id}} {{outmat.name}}</p>
                        </div>

                    </div>
                </div>

            </div>
        </cdk-virtual-scroll-viewport>

        <div class="selectedProcess" *ngIf="selectedProcess && userAccess.hasAccessRightsEn(accessAppAreas.process, accessTypes.view)">
            <div class="headerline" fxLayout="row">
                <p class="viewheader">Ausgewählt</p>
                <button (click)="changeProcess()" *ngIf="userAccess.hasAccessRightsEn(accessAppAreas.process, accessTypes.edit)">
                    <mat-icon class="newButton icon" matPrefix>edit</mat-icon>
                </button>
                <button (click)="deleteProcess()" *ngIf="userAccess.hasAccessRightsEn(accessAppAreas.process, accessTypes.delete)">
                    <mat-icon class="newButton icon" matPrefix>delete</mat-icon>
                </button>
            </div>
            <hr>
            <hr>
            <div class="elementrow" fxLayout="row">
                <p class="processName">{{selectedProcess.name}}</p>
            </div>
            <img *ngIf="selectedProcess.transferable" class="ksalogoselected" src="../../../../assets/images/ksa.png" alt="ksalogo">
            <ng-container *ngIf="selectedProcess.process_category !=='maintenance'; else maintenanceSingleProcess">
                <div class="elementrow" fxLayout="row">
                    <p class="header">Typ</p>
                    <p class="txt">{{translateProcessType(selectedProcess.process_category)}}</p>
                </div>
                <div class="elementrow" fxLayout="row" *ngIf="tenant !==  typeEnvironmentMss">
                    <p class="header">Input</p>
                    <p class="txt">{{selectedProcess.input.id}} {{selectedProcess.input.name}}</p>
                </div>
                <div class="elementrow" fxLayout="row">
                    <p class="header">Min. Chargengröße</p>
                    <p class="txt">{{selectedProcess.minbatchsize | number: '1.0-3':'de'}} t</p>
                </div>
                <div class="elementrow" fxLayout="row">
                    <p class="header">Durchsatz</p>
                    <p class="txt">{{selectedProcess.leadtimeperton | number: '1.0-3':'de'}} t/h</p>
                </div>
                <div class="elementrow" fxLayout="row">
                    <p class="header">Rüstzeit vorgelagert</p>
                    <p class="txt">{{selectedProcess.setuptimebefore | number: '1.0-2':'de'}} h</p>
                </div>
                <div class="elementrow" fxLayout="row">
                    <p class="header">Rüstzeit nachgelagert</p>
                    <p class="txt">{{selectedProcess.setuptimeafter | number: '1.0-2':'de'}} h</p>
                </div>

                <div class="headerlineResources" fxLayout="row">
                    <p class="viewheader">Output</p>
                </div>
                <hr class="breakerUnderElement">

                <div class="outputmaterialentry" *ngFor="let outmat of selectedProcess.output">
                    <p class="outmaterialselected">{{outmat.id}} {{outmat.name}}</p>
                </div>

                <div class="headerlineResources" fxLayout="row">
                    <p class="viewheader">Ressourcentypen</p>
                </div>
                <hr class="breakerUnderElement">

                <div class="outputmaterialentry" *ngFor="let ress of selectedProcess.resource_types">
                    <p class="outmaterialselected">{{ress.quantity| number: '1.0-2':'de'}}x {{ress.resource_type.name}}</p>
                </div>
            </ng-container>
            <ng-template #maintenanceSingleProcess>
                <div class="elementrow" fxLayout="row">
                    <p class="header">Typ</p>
                    <p class="txt">{{ translateProcessType(selectedProcess.process_category)}}</p>
                </div>
                <ng-container *ngIf="selectedProcess.resource_types">
                    <div class="headerlineResources" fxLayout="row">
                        <p class="viewheader">Ressourcentypen</p>
                    </div>
                    <hr class="breakerUnderElement">

                    <div class="outputmaterialentry" *ngFor="let ress of selectedProcess.resource_types">
                        <p class="outmaterialselected">{{ress.quantity| number: '1.0-2':'de'}}x {{ress.resource_type.name}}</p>
                    </div>
                </ng-container>


            </ng-template>


        </div>
    </div>
    <div class="paginator"  [ngClass]="{'paginatorBoth': skip && showNext,  'paginatorRight': !skip}">
        <div class="previous" (click)="paginateLeft()" *ngIf="skip">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div class="next" (click)="paginateRight()" *ngIf="showNext">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>

</div>


