<h1 class="tableheader">Fahrzeuge</h1>
<div class="Wrapper">
    <div fxLayout="column" *ngIf="userAccess.hasAccessRightsEn(accesAreas.mover, accessTyps.view)">
        <table class="TaraTable" *ngIf="dataSource" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="Bezeichnung">
                <th mat-header-cell *matHeaderCellDef> Bezeichnung</th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Typ">
                <th mat-header-cell *matHeaderCellDef> Typ</th>
                <td mat-cell *matCellDef="let element">
                    {{getScaleType(element.scale_type)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="EUI">
                <th mat-header-cell *matHeaderCellDef> EUI</th>
                <td mat-cell *matCellDef="let element"  class="makeMultiLine">
                    -
                </td>
            </ng-container>
            <ng-container matColumnDef="Tara">
                <th mat-header-cell *matHeaderCellDef> Tara</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="{'strokedp': ShowEdit(element) && tareWeighingSuccessFull}">
                    {{element.tare | number: '1.3-3':'de'}} t
                    </div>
                    <div *ngIf="ShowEdit(element) && tareWeighingSuccessFull">
                        {{tare | number: '1.3-3':'de'}} t
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="NeuesTara">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" class="newTara">
                    <button *ngIf="!ShowEdit(element) && userAccess.hasAccessRightsEn(accesAreas.mover, accessTyps.edit)" mat-button class="btn" (click)="showAutoManuelArea(i)">Neues Tara</button>
                    <button *ngIf="ShowEdit(element) && tareWeighingSuccessFull && userAccess.hasAccessRightsEn(accesAreas.mover, accessTyps.edit)" mat-button class="btn" (click)="saveTare()">Speichern</button>
                    <button *ngIf="ShowEdit(element) && userAccess.hasAccessRightsEn(accesAreas.mover, accessTyps.edit)" mat-button class="btn" (click)="cancelChange()">Abbrechen</button>
                    <ng-container *ngIf="showAutoManuelChoice[i] && userAccess.hasAccessRightsEn(accesAreas.container, accessTyps.edit)">
                        <div class="autoManuelWrap" >
                            <div>
                                <button mat-button class="btn" [ngClass]="{'btndisabled' : btnAutoDisabled[i]}" [disabled]="btnAutoDisabled[i]" (click)="createNewTara(element, i)">Auto</button>
                                <button mat-button class="btn manuelBtn" (click)="createNewTaraManual(element, i)">Manuell</button>
                            </div>
                            <div *ngIf="showManualInput[i]">
                                <div class="manuelInputWrap" >
                                    <p style="margin-top: 7px;">
                                        Tara
                                    </p>
                                    <mat-form-field class="formField">
                                        <input type="number" matInput [(ngModel)]="manualTara">
                                    </mat-form-field>
                                    <p style="margin-top: 7px;">
                                        t
                                    </p>
                                </div>
                                <div class="btnSaveWrap">
                                    <button mat-button class="btn" (click)="saveTaraManual()">Speichern</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <mat-spinner *ngIf="showspinner[i]" class="spinner" diameter="30"></mat-spinner>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

