import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {Router} from '@angular/router';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {MoverSelectionComponent} from '../../Dialogs/mover-selection/mover-selection.component';
import {DataShareService} from '../../Services/data-share.service';
import {UserAccessService} from '../../Services/user-access.service';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';

@Component({
    selector: 'app-container-view',
    templateUrl: './container-view.component.html',
    styleUrls: ['./container-view.component.scss']
})
export class ContainerViewComponent implements OnInit {

    tare: number;
    displayedColumns: string[] = ['Bezeichnung', 'Typ', 'EUI', 'Tara', 'NeuesTara'];
    showSpinner: boolean [] = [false];


    public selectedContainer: any;

    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private scale: any;
    private weighing: any;
    private weighingHandler: any;
    private index = -1;
    public tareWeighingSuccessFull = false;
    private moverWeight: any;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public showAutoManuelChoice: boolean [] = [false];
    private indexForshowAutoManuelChoice = -1;
    public btnAutoDisabled: boolean [] = [false];
    public manualTara = 0;
    public showManualInput: boolean [] = [false];
    public selectedContainerForManual: any;

    constructor(public dialog: MatDialog,
                private apiService: APIService,
                public router: Router,
                public dataService: DataShareService,
                public userAccess: UserAccessService) {
    }

    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.receiveContainer();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.receiveContainer.bind(this));
        }
    }

    receiveContainer() {
        this.apiService.getAvailableContainers().subscribe((containers: any) => {
            if (containers.length > 0) {
                containers = containers.sort((a, b) => {
                    return a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    });
                });
                // containers.forEach(e => e.tags.eui = e.eui.tags.length === 0 ? '-' : e.tags.eui.join('\n'));
                containers.forEach(e => {
                    e.EUItoShow = '';
                    if (e.tags.length > 0) {
                        e.tags.forEach(tag => e.EUItoShow = e.EUItoShow + tag.eui + '\n' );
                    }
                    else {
                        e.EUItoShow = '-';
                    }
                });
                this.dataSource.data = containers;

                this.showSpinner = [];
                for (const mv of containers) {
                    this.showSpinner.push(false);
                    this.showAutoManuelChoice.push(false);
                    this.btnAutoDisabled.push(false);
                    this.showManualInput.push(false);
                }
            }
        });
    }

    public getScaleType(type: string): string {
        switch (type) {
            case 'platform': {
                return 'Plattform';
            }
            case 'bridge': {
                return 'Brücke';
            }
            default: {
                return '';
            }
        }
    }

    public ShowEdit(element: any): boolean {
        if (this.selectedContainer) {
            if (this.selectedContainer.id === element.id) {
                return true;
            }
        }
        return false;
    }


    createNewTara(container: any, i: number) {
        this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
        this.tareWeighingSuccessFull = false;
        this.showSpinner[i] = true;
        this.selectedContainer = container;
        this.dataService.selectedContainer = container;
        this.tare = container.tare;
        this.index = i;
        this.prepareForScaling();

        // const dialogConfig1 = new MatDialogConfig();
        // dialogConfig1.disableClose = true;
        // dialogConfig1.autoFocus = true;
        // dialogConfig1.panelClass = 'dialogStyles';
        // dialogConfig1.data = 'Funktion in der aktuellen Version nicht verfügbar';
        // const dialogRef2 = this.dialog.open(MessagebComponent, dialogConfig1);
        //
        // setTimeout(() => {
        //     this.showSpinner[i] = false;
        // }, 2000);
    }

    public showAutoManuelArea(i) {
        this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
        this.showAutoManuelChoice[i] = true;
        this.showManualInput[this.indexForshowAutoManuelChoice] = false;
        this.btnAutoDisabled[this.indexForshowAutoManuelChoice] = false;
        this.indexForshowAutoManuelChoice = i;
    }

    public createNewTaraManual(container: any, i: number) {
        this.showManualInput[i] = true;
        this.btnAutoDisabled[i] = true;
        this.selectedContainerForManual = container;
    }

    public saveTaraManual() {

        if (this.manualTara > 0) {
            this.apiService.updateContainerTare(this.selectedContainerForManual.id,
                this.selectedContainerForManual.name,
                this.selectedContainerForManual.scale_type,
                this.manualTara).subscribe(() => {
                this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
                this.showManualInput[this.indexForshowAutoManuelChoice] = false;
                this.btnAutoDisabled[this.indexForshowAutoManuelChoice] = false;
                this.manualTara = 0;
                this.receiveContainer();
            });
        } else {
            this.showMessage('Wert kann nicht als Tara gesetzt werden (Tara negativ)');
        }
    }

    public saveTare() {

        if (this.tare > 0) {
            this.apiService.updateContainerTare(this.selectedContainer.id,
                this.selectedContainer.name,
                this.selectedContainer.scale_type,
                this.tare).subscribe(() => {
                this.selectedContainer = null;
                this.tareWeighingSuccessFull = false;
                this.weighing = null;
                this.scale = null;
                this.receiveContainer();
                this.tare = 0;
            });
        } else {
            this.showMessage('Wert kann nicht als Tara gesetzt werden (Tara negativ)');
        }
    }

    public cancelChange() {
        this.selectedContainer = null;
        this.showSpinner[this.index] = false;
        this.index = -1;
        this.weighing = null;
        this.scale = null;
        this.tareWeighingSuccessFull = false;
        this.receiveContainer();
    }

    private showMessage(text: string) {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = text;
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }


    private prepareForScaling() {
        // 1. Get scales
        this.apiService.getAvailableScales().subscribe((scales: any) => {
            const requiredScales = scales.filter(ex => ex.type === this.selectedContainer.scale_type && ex.online === true);
            console.log(scales);
            console.log(requiredScales);
            if (requiredScales.length > 0) {

                this.scale = requiredScales[0];

                if (this.scale) {
                    if (this.scale.requires_mover === true) {
                        // to choose the mover to get tara weight
                        let dialogConfig1: MatDialogConfig;
                        dialogConfig1 = {
                            position: {
                                top: '64px'
                            },
                            height: '100%',
                            width: '100vw',
                            maxWidth: '100vw',
                            panelClass: 'full-screen-modal',
                        };
                        dialogConfig1.disableClose = true;
                        dialogConfig1.autoFocus = true;
                        dialogConfig1.panelClass = 'dialogStyles';
                        dialogConfig1.data = this.selectedContainer.scale_type;
                        console.log(dialogConfig1);
                        const dialogRef2 = this.dialog.open(MoverSelectionComponent, dialogConfig1);

                        dialogRef2.afterClosed().subscribe(result => {
                            if (result === true) {
                                this.moverWeight = this.dataService.selectedMover.tare;
                                this.initScale();
                            }
                            else {
                                this.selectedContainer = null;
                                this.showSpinner[this.index] = false;
                                this.tareWeighingSuccessFull = false;
                            }
                        });
                    } else {
                        this.initScale();
                    }

                } else {
                    this.showMessage('Keine passende Waage gefunden');
                    this.selectedContainer = null;
                    this.showSpinner[this.index] = false;
                    this.tareWeighingSuccessFull = false;
                }
            } else {

                this.showMessage('Keine entsprechende Waage vorhanden');
                this.selectedContainer = null;
                this.showSpinner[this.index] = false;
                this.tareWeighingSuccessFull = false;
            }
        });
    }

    private initScale() {
        if (this.selectedContainer && this.scale) {
            this.apiService.createTareWeighting(this.scale.id).
            subscribe({
                next: this.handleWeighing.bind(this),
                error: this.handleError.bind(this)
            });
        }
    }

    private handleWeighing(weighing: any) {
        if (weighing.id) {
            if (weighing.status === 'transmitted') {
                this.weighing = weighing;
                // GET WEIHING
                this.runWeighingHandler();
            }
        }
    }

    private handleError() {
        this.selectedContainer = null;
        this.showSpinner[this.index] = false;
        this.tareWeighingSuccessFull = false;
    }

    private runWeighingHandler() {
        if (this.weighingHandler) {
            clearInterval(this.weighingHandler);
        }

        this.weighingHandler = setInterval(() => {

            if (this.weighing) {
                this.apiService.getPreliminaryWeighing(this.weighing.id).
                subscribe({
                    next: this.handlePreliminaryWeighing.bind(this),
                    error: this.handleWeighingError.bind(this)
                });
            } else {
                this.showWeighingError('Wiegung wurde abgebrochen');
            }
        }, 1000);
    }

    private handleWeighingError() {
        this.showWeighingError('Wiegung wurde abgebrochen');
    }

    private handlePreliminaryWeighing(weigh: any) {
        if (weigh.status === 'available') {
            clearInterval(this.weighingHandler);

            if (weigh.net_weight !== null) {
                this.tare = weigh.net_weight;
                // if there is a mover, we need to subtract the weight from mover
                if (this.scale.requires_mover === true) {
                    this.tare = this.tare - this.moverWeight;
                }
                this.tareWeighingSuccessFull = true;
            } else {
                this.showWeighingError('Wiegung ungültig');
            }
            this.showSpinner[this.index] = false;
        }
    }

    private showWeighingError(msg: string) {
        this.showMessage(msg);
        clearInterval(this.weighingHandler);
        this.selectedContainer = null;
        this.weighing = null;
        this.scale = null;
        this.showSpinner[this.index] = false;
        this.tareWeighingSuccessFull = false;
    }


}
