import {AccessAppAreas} from '../Enums/access-app-areas';
import {AccessTypes} from '../Enums/access-types';
import {UserRoles} from '../Enums/user-roles';

export const UserAccessTable: any[] = [
    {
        area: AccessAppAreas.batch,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpAdmin
                ]
            },
            {
                type: AccessTypes.delete,
                roles: [
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpAdmin
                ]
            },
            {
                type: AccessTypes.execute,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.container,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpUser
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.resource_usage,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor

                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor

                ]
            }
        ]
    },
    {
        area: AccessAppAreas.settings,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpAdmin,
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.execution,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.delete,
                roles: [
                    UserRoles.ArpAdmin,
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.material,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.mover,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.plan,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.process,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.delete,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.resource,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.delete,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.resource_type,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.delete,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.detail,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.scale,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpUser,
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.weighing,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpUser,
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpReviewer,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpUser
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.calculation,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpSupervisor
                ]
            }
        ]
    },
    {
        area: AccessAppAreas.weighing_filter,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor
                ]
            },
        ]
    },
    {
        area: AccessAppAreas.week_report,
        access: [
            {
                type: AccessTypes.view,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpAccountant,
                    UserRoles.ArpSupervisor
                ]
            },
            {
                type: AccessTypes.edit,
                roles: [
                    UserRoles.ArpAdmin,
                    UserRoles.ArpSupervisor,
                    UserRoles.ArpAccountant
                ]
            }
        ]
    },
];
