<div fxLayout="row" style="height: 100%">
    <app-executions-table (newExBatch)="updateExecutionBatchFromChildComponent($event)" [exBatch]="exBatch" class="blankIntro" [ngClass]="{slider2: !slideLeft}" fxLayout="column"
                          *ngIf="executionBatch && !slideRight && !showAddCarriage">
    </app-executions-table>
    <div class="blankIntro" [ngClass]="{slider: slideCenter, slider2: !slideCenter}"
         *ngIf="checkIfSumNull() && executionBatch !== null && executionBatch !== undefined && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.view) && executionBatch.process.process_category !== 'maintenance'">
        <div fxLayout="row" class="carriagesDiv">
            <div *ngFor="let railwayTrack of executionBatch.railroad_tracks; let i = index" class="trackDiv" [ngClass]="{'halftransparent': !plusButtons[i] && showAddCarriage && !selectedCarriageForEditing}">
                <div class="bumperBlock"></div>
                <div class="trackDesignation" [ngClass]="{'trackDesignationRight': i === 1}">
                    Gleis {{i + 1}}U
                </div>
                <div class="trackBackground">

                </div>
                <div *ngFor="let carriage of railwayTrack" class="carriageCell">
                    <div class="carriageBoxToAdd" (click)="editCarriage(carriage)"
                         [ngClass]="{'blurred':selectedCarriageForEditing && selectedCarriageForEditing.id !== carriage.id, 'selectedForWeighing': selectedCarriageForEditing && selectedCarriageForEditing.id === carriage.id}">
                        <div class="loadsBoxAdd">
                            <div class="deleteIcon" (click)="deleteCarriage($event, carriage)">
                                <mat-icon>delete_outline</mat-icon>
                            </div>
                            <p class="maxLoadLine">{{carriage.maximal_load /1000 | number: '1.0-3':'de'}} t</p>
                            <p class="carriageName">{{carriage.name}}</p>
                            <p class="carriageName">{{carriage.recipient}}</p>


                            <p class="carriageOutputName">
                                {{getMaterialName(carriage.output_material)}}
                            </p>


                        </div>
                    </div>
                </div>
                <mat-icon class="addNewCarriageIcon" [style.margin-top]="railwayTrack.length == 0 ? '55px' : '35px'" (click)="showAddCarriageBox(i)"
                          [ngClass]="{'bluePlus': plusButtons[i]}">
                    add
                </mat-icon>
            </div>
            <div class="vl"></div>
        </div>
    </div>

    <div class="blankIntro" fxLayout="column" [ngClass]="{slider: slideCenter, slider2: !slideCenter}"
         *ngIf="executionBatch !== null && executionBatch !== undefined && showAddCarriage && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit) && executionBatch.process.process_category !== 'maintenance'">
        <div class="actions2">
            <div fxLayout="row">
                <div class="wrapForNewCarriage" *ngIf="!selectedCarriageForEditing; else editCarriage">
                    <p class="newCarraigeTitle">Neuer Waggon</p>
                    <div>
                        <div class="wrapForCarriageInputs">
                            <p class="inputTitle">Nummer</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input type="string" matInput [(ngModel)]="carriageToSave.name">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="wrapForCarriageInputs" style="margin-top: -40px;">
                            <p class="inputTitle">Empfänger</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input type="string" matInput [(ngModel)]="carriageToSave.recipient">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="wrapForCarriageInputs maxWeightWrap">
                            <p class="inputTitle" [ngClass]="{justifycenter: manuelSelected}">Max Gewicht</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input step="0.001" pattern="[0-9]*(\.?[0-9]{1,4}$)?" min="0"
                                           type="number" matInput [(ngModel)]="carriageToSave.maximal_load">
                                </mat-form-field>

                            </div>
                            <p style="padding-left: 5px;">t</p>
                        </div>
                        <div class="wrapForCarriageInputs outputWrap">
                            <p class="inputTitle">Produkt</p>
                            <div class="inputFormInput">
                                <mat-form-field class="conTxt matFormInput">
                                    <mat-select [(ngModel)]="outputChosen" class="select" (selectionChange)="onSelectOutput()">
                                        <mat-option *ngFor="let outputmaterial of executionBatch.output"
                                                    [value]="outputmaterial">{{outputmaterial.name}}</mat-option>
                                    </mat-select>
                                    <div *ngIf="showOutputError" class="errorMessage">
                                        Bitte wählen Sie Produkt
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex">
                        <p class="inputTitle"></p>
                        <div class="inputFormInput saveCarriageButtonWrap">
                            <button class="saveButtonCarriage" (click)="saveCarriage()">
                                Speichern
                            </button>
                        </div>
                    </div>

                </div>
                <ng-template #editCarriage>
                    <div class="wrapForNewCarriage">
                        <p class="newCarraigeTitle">Waggon {{selectedCarriageForEditing.name}}</p>
                        <div class="wrapForCarriageInputs">
                            <p class="inputTitle">Nummer</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input type="string" matInput
                                           [(ngModel)]="selectedCarriageForEditing.name"
                                           value="{{selectedCarriageForEditing.name}}">
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="wrapForCarriageInputs" style="margin-top: -40px;">
                            <p class="inputTitle">Empfänger</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input type="string" matInput [(ngModel)]="selectedCarriageForEditing.recipient"
                                           value="{{selectedCarriageForEditing.recipient}}">
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="wrapForCarriageInputs maxWeightWrap">
                            <p class="inputTitle" [ngClass]="{justifycenter: manuelSelected}">Max Gewicht</p>
                            <div class="inputFormInput">
                                <mat-form-field class="matFormInput">
                                    <input step="0.001" pattern="[0-9]*(\.?[0-9]{1,4}$)?" min="0"
                                           type="number" matInput [(ngModel)]="selectedCarriageForEditing.maximal_load"
                                           value="{{selectedCarriageForEditing.maximal_load}}">
                                </mat-form-field>

                            </div>

                            <p style="padding-left: 5px;">t</p>
                        </div>
                        <div class="wrapForCarriageInputs outputWrap">
                            <p class="inputTitle">Produkt</p>
                            <div class="inputFormInput">
                                <mat-form-field class="conTxt matFormInput">
                                    <mat-select [(ngModel)]="outputChosen" class="select" (selectionChange)="onSelectOutputEdit()">
                                        <mat-option *ngFor="let outputmaterial of executionBatch.output"
                                                    [value]="outputmaterial">{{outputmaterial.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="display: flex">
                            <p class="inputTitle"></p>
                            <div class="inputFormInput saveCarriageButtonWrap">
                                <button class="saveButtonCarriage" (click)="putCarriage()">
                                    Speichern
                                </button>
                            </div>

                        </div>
                    </div>

                </ng-template>
                <button class="CancelButton" (click)="closeAddCarriage()">
                    <mat-icon class="CloseIcon">close</mat-icon>
                </button>
            </div>

        </div>

    </div>

    <div class="blankIntro" [ngClass]="{slider: slideCenter, slider2: !slideCenter}"
         *ngIf="!checkIfSumNull() && executionBatch !== null && executionBatch !== undefined && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.view) && executionBatch.process.process_category !== 'maintenance'">
        <div fxLayout="row" class="carriagesDiv">
            <div *ngFor="let railwayTrack of executionBatch.railroad_tracks; let i = index"
                 class="trackDiv carriagesDivStartedExecution">
                <div class="bumperBlock"></div>
                <div class="trackDesignation" [ngClass]="{'trackDesignationRight': i === 1}">
                    Gleis {{i + 1}}U
                </div>
                <div class="trackBackground">

                </div>
                <div *ngFor="let carriage of railwayTrack" class="carriageCell">
                    <div class="carriageBox carriageBox-hover" (click)="selectCarriage(carriage)"
                         [ngClass]="{'blurred':selectedCarriageForWeighing && selectedCarriageForWeighing.id !== carriage.id, 'selectedForWeighing': selectedCarriageForWeighing && selectedCarriageForWeighing.id === carriage.id}">
                        <div class="loadsBox">
                            <div class="maxLoadCurrentLoadDiv">
                                <p>{{carriage.maximal_load/1000 | number: '1.0-3':'de'}} t</p>
                                <p class="currentLoadLine">{{carriage.current_load/1000| number: '1.0-3':'de'}} t</p>
                            </div>
                            <p class="carriageName">{{carriage.name}}</p>
                            <p class="carriageName">{{carriage.recipient}}</p>
                            <p class="carriageOutputName">
                                {{getMaterialName(carriage.output_material)}}
                            </p>

                        </div>
                        <div class="loadIndicator" [ngClass]="{'loadIndicatorblurred':selectedCarriageForWeighing && selectedCarriageForWeighing.id !== carriage.id}"  [ngStyle]="{'height.%': heightExp(carriage)}"></div>
                    </div>
                </div>
            </div>
            <div class="vl"></div>
        </div>
    </div>

    <div class="blankIntro" (swiperight)="onSwipe()" [ngClass]="{slider: slideRight}" fxLayout="column"
         *ngIf="executionBatch !== null && executionBatch !== undefined && slideRight && !showAddCarriage && userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit) && executionBatch.process.process_category !== 'maintenance'">
        <div class="weighningWrapper">
            <div class="headerSelectedProductWrap">
                <div class="scaleInterface">
                    <div class="scaleButtons" *ngIf="userAccess.hasAccessRightsEn(accessAreas.scale, accessTyps.view)">
                        <div *ngFor="let s of Scales">
                            <button *ngIf="s" class="scaleButton"
                                    [ngClass]="{'selectedScaleButton': SelectedScale && SelectedScale.id === s.id}"
                                    (click)="selectScaleInterface(s)">
                                <div>
                                    {{s.name}}
                                </div>
                                <div class="scaleIconDiv">
                                    <mat-icon class="circleIcon"
                                              [ngClass]="{'onlineCircle': s.online === true, 'offlineCircle':  s.online === false}">
                                        circle
                                    </mat-icon>
                                </div>

                            </button>

                        </div>
                        <div>
                            <button class="scaleButton2" (click)="chooseManuell()"
                                    [class.backgroundChosen]="manuelSelected">
                                Manuell
                            </button>
                        </div>
                        <div class="closeButtonWrap">
                            <button class="CancelButton" (click)="closeScalesInterface()">
                                <mat-icon class="CloseIcon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="wrapForScale">
                        <div *ngIf="manuelSelected">

                            <div class="ManuelWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Brutto</p>
                                <div style="margin-left: 5px">
                                    <mat-form-field>
                                        <input class="fontSize" type="number" [(ngModel)]="manualBrutto" value="{{manualBrutto}}"
                                               (keyup)="onBruttoInput($event)" matInput>
                                    </mat-form-field>

                                </div>

                                <p style="padding-left: 5px;">t</p>
                            </div>
                            <div class="ManuelWrap TaraWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Tara</p>
                                <div style="margin-left: 22px">
                                    <mat-form-field>
                                        <input class="fontSize" step="0.001" pattern="[0-9]*(\.?[0-9]{1,4}$)?" min="0"
                                               type="number"
                                               [(ngModel)]="manualTara" value="{{manualTara}}"
                                               (keyup)="onTaraInput($event)" matInput>
                                    </mat-form-field>

                                </div>

                                <p style="padding-left: 5px;">t</p>
                            </div>


                            <div class="ManuelWrap NettoWrap">
                                <p [ngClass]="{justifycenter: manuelSelected}">Netto</p>
                                <div style="margin-left: 11px">
                                    <p>{{productQuantity | number: '1.3-3':'de'}}</p>
                                </div>
                                <p style="padding-left: 5px;" *ngIf="productQuantity">t</p>
                            </div>

                        </div>

                        <div class="wrapData"  *ngIf="systemScaleSelected">
                            <div class="infoElement2" *ngIf="SelectedScale.unit === 'kg'">
                                <p class="nameElementSelProd2">Brutto</p>
                                <p class="valueElementInLine2"
                                   *ngIf="currentWeighting">{{currentWeighting.net | number: '1.0-0':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                            </div>
                            <div class="infoElement2">
                                <div fxLayout="row">
                                    <p class="nameElementSelProd2">Tara Container</p>
                                </div>
                                <p *ngIf="selectedContainer" class="valueElementInLine3">
                                    0 {{SelectedScale.unit}}</p>
                            </div>
                            <div class="infoElement2" *ngIf="SelectedScale.unit === 'kg'">
                                <p class="nameElementSelProd2">Netto</p>
                                <p class="valueElementInLine2"
                                   *ngIf="currentWeighting">{{currentWeighting.net | number: '1.0-0':'de'}} {{SelectedScale.unit}}</p>
                                <p *ngIf="!currentWeighting" class="valueElementInLine2"> - </p>
                            </div>
                        </div>

                    </div>
                    <ng-container>
                        <button class="ubernehmenButton" *ngIf="manuelSelected"
                                [disabled]="!userAccess.hasAccessRightsEn(accessAreas.weighing, accessTyps.edit)"
                                (click)="confirmQuantityForManualWeighting()">Verwiegen
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>


</div>

