import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import {DataShareService} from '../../../Services/data-share.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {MessageboxComponent} from '../../../Dialogs/messageb/messagebox.component';
import {WebsocketService} from '../../../Services/websocket.service';
import {ErrorWeighningComponent} from '../../../Dialogs/errorMessageWeighning/errorweighning.component';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {UserAccessService} from '../../../Services/user-access.service';
import {OptionBoxInputComponent} from '../../../Dialogs/option-box-input/option-box-input.component';
import {Subscription} from 'rxjs';
import {OptionBoxComponent} from '../../../Dialogs/option-box/option-box.component';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {
    RailwayCarriage,
    RailwayCarriageToSave,
    RailwayCarriageToEdit
} from 'src/app/Components/execution-screen/interfaces';
import {
    onBruttoInput,
    onTaraInput,
    getCurrentSumTime,
    updateExecutionBatch,
    startWebSocketSubscription,
    unsubscribeWebSocketSubscription
} from '../sharedFunctions';
import {
    MessageForMssWeighningConfirmationComponent
} from "../../../Dialogs/messageForMssWeighningConfirmation/messageForMssWeighningConfirmation.component";
import {logging} from "protractor";


moment.locale('de');


@Component({
    selector: 'app-execution-screen-mss',
    templateUrl: './execution-screen-mss.component.html',
    styleUrls: ['./execution-screen-mss.component.scss'],
})
export class ExecutionScreenMssComponent implements OnInit, OnDestroy, OnChanges {
    @Input() exBatch;
    @Output() newExBatch = new EventEmitter<any>();

    constructor(public apiService: APIService,
                public router: Router,
                public functionsService: GlobalFunctionsService,
                public dataService: DataShareService,
                public dialog: MatDialog,
                public websocket: WebsocketService,
                public userAccess: UserAccessService) {

    }

    public plusButtons: boolean[] = [false, false, false];

    public selectedCarriageForWeighing: RailwayCarriage;
    public selectedCarriageForEditing: RailwayCarriageToEdit;
    public carriageToSave: RailwayCarriageToSave = {
        scale_type: 'excavator',
        name: '',
        recipient: '',
        maximal_load: 0,
        output_material: '',
        railroad_track: 1,
        tare: 0,
        batch_id: '',
    };
    public showAddCarriage = false;
    public outputChosen: any;


    public treatment: any;
    public selectedProduct: any;
    public productQuantity: number;
    public isToggled = false;
    public systemScaleSelected = false;


    public batch: any;

    public Scales: any[];
    public SelectedScale: any;
    public currentWeighting: any;


    public selectedContainer: RailwayCarriage;


    public manuelSelected = false;
    public executionBatch: any;
    public currentExecution: any;
    public lastExecution: any;
    public slideLeft: boolean;
    public slideCenter: boolean;
    public slideRight: boolean;
    public manualTara: any;
    public manualBrutto: number;

    public accessTyps = AccessTypes;
    public accessAreas = AccessAppAreas;
    public commentText: any;
    public showBatteryIcon = false;

    public lastScaleWeighing: any;
    private subscriptionWebSocketConnected: Subscription;
    private subscriptionWebSocketMessage: Subscription;
    private subscriptionWebSocketError: Subscription;
    private subscriptionWebSocketClosed: Subscription;
    private subscriptionWebSocketConnectionError: Subscription;
    public showOutputError = false;


    public timeoutId: ReturnType<typeof setTimeout>;

    ngOnChanges(): void {
        this.executionBatch = this.exBatch;
        updateExecutionBatch.call(this);
    }


    ngOnInit(): void {
        // tslint:disable-next-line:max-line-length
        startWebSocketSubscription.call(this, this.WebsocketConnected, this.WebsocketMessage, this.WebsocketError, this.WebsocketClosed, this.WebsocketConnectError);
    }

    ngOnDestroy() {
        // tslint:disable-next-line:max-line-length
        unsubscribeWebSocketSubscription.call(this, this.subscriptionWebSocketConnected, this.subscriptionWebSocketMessage, this.subscriptionWebSocketError, this.subscriptionWebSocketClosed, this.subscriptionWebSocketConnectionError);
    }


    updateExecutionBatchForParentComponent(value: any) {
        this.newExBatch.emit(value);
    }

    public updateExecutionBatchFromChildComponent(newExBatch: any) {
        this.executionBatch = newExBatch;
        this.updateExecutionBatchForParentComponent(newExBatch);
    }


    public selectCarriage(carriage: any) {

        if (this.userAccess.hasAccessRightsEn(AccessAppAreas.weighing, AccessTypes.edit)) {
            this.manualTara = 0;
            this.manualBrutto = 0;
            this.productQuantity = undefined;
            if (!this.selectedCarriageForWeighing) {
                this.getScales();
                this.selectedCarriageForWeighing = JSON.parse(JSON.stringify(carriage));
                this.selectedContainer = JSON.parse(JSON.stringify(carriage));
            } else {
                this.selectedCarriageForWeighing = JSON.parse(JSON.stringify(carriage));
                this.selectedContainer = JSON.parse(JSON.stringify(carriage));
            }
        }

    }

    public showAddCarriageBox(i: number) {
        this.outputChosen = undefined;
        this.selectedCarriageForEditing = undefined;
        this.plusButtons.fill(false);
        this.plusButtons[i] = true;
        this.showAddCarriage = true;
        this.slideCenter = true;
        this.slideRight = true;
        this.carriageToSave.railroad_track = i + 1;

    }

    public closeAddCarriage() {
        this.plusButtons.fill(false);
        this.showAddCarriage = false;
        this.slideRight = false;
        this.slideCenter = false;
        this.selectedCarriageForWeighing = undefined;
        this.selectedCarriageForEditing = undefined;
    }

    public editCarriage(carriage: RailwayCarriageToEdit) {
        this.selectedCarriageForEditing = JSON.parse(JSON.stringify(carriage));
        this.plusButtons.fill(false);
        this.outputChosen = this.executionBatch.output.find(o => o.id === carriage.output_material);
        this.showAddCarriage = true;
        this.slideCenter = true;
        this.slideRight = true;
    }

    public deleteCarriage($event: MouseEvent, carriage: RailwayCarriage) {
        $event.stopPropagation();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = {
            txt: `Soll der Waggon (Nr.: ${carriage.name}) wirklich gelöscht werden?`,
            option1: 'Ja',
            option2: 'Nein'
        };
        const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                this.apiService.deleteContainer(carriage).subscribe({
                    next: null,
                    error: this.handleDeleteContainerError.bind(this)
                });
                // tslint:disable-next-line:max-line-length
                this.executionBatch.railroad_tracks[carriage.railroad_track - 1] = this.executionBatch.railroad_tracks[carriage.railroad_track - 1].filter(el => el.id !== carriage.id);
            } else if (result === 2) {
                console.log('not deleted');
            }
        });
    }

    private handleDeleteContainerError() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Container konnte nicht gelöscht werden';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    public onSelectOutput() {
        this.showOutputError = false;
    }

    public onSelectOutputEdit() {
        this.selectedCarriageForEditing.output_material = this.outputChosen.name;
    }

    public saveCarriage() {
        this.carriageToSave.batch_id = this.executionBatch.id;
        if (this.outputChosen == null) {
            this.showOutputError = true;
            return;
        } else {
            this.showOutputError = false;
        }
        this.carriageToSave.output_material = this.outputChosen.id;
        this.carriageToSave.maximal_load = this.carriageToSave.maximal_load * 1000;
        this.apiService.createContainer(this.carriageToSave).subscribe({
            next: this.handleCreateContainer.bind(this),
            error: this.handleUpdateContainerError.bind(this)
        });
        this.outputChosen = null;
    }

    private handleCreateContainer(data: any) {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Waggon wurde gespeichert';
        this.dialog.open(MessageboxComponent, dialogConfig1);
        this.carriageToSave.name = null;
        this.carriageToSave.recipient = null;
        this.carriageToSave.maximal_load = 0;
        this.executionBatch.railroad_tracks[this.carriageToSave.railroad_track - 1].push(data);
    }

    public putCarriage() {
        this.selectedCarriageForEditing.batch_id = this.executionBatch.id;
        if (this.outputChosen == null) {
            this.showOutputError = true;
            return;
        } else {
            this.showOutputError = false;
        }
        this.selectedCarriageForEditing.output_material = this.outputChosen.id;
        this.apiService.updateContainer(this.selectedCarriageForEditing, this.selectedCarriageForEditing.id).subscribe({
            next: this.handleUpdateContainer.bind(this),
            error: this.handleUpdateContainerError.bind(this)
        });

    }


    private handleUpdateContainerError() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Container konnte nicht gespeichert werden';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    private handleUpdateContainer() {
        this.apiService.getSingleBatch(this.exBatch.id).subscribe((data2: any) => {
            if (data2) {
                if (data2.id) {
                    this.executionBatch = data2;
                    this.editCarriage(this.selectedCarriageForEditing);
                }
            }
        });
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Waggon wurde gespeichert';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    public heightExp(carriage: RailwayCarriage): number {
        if (carriage.current_load === 0) {
            return 0;
        } else if (carriage.maximal_load <= carriage.current_load) {
            return 100;
        } else {
            return Number(((carriage.current_load / carriage.maximal_load) * 100).toFixed(2));
        }
    }

    public getMaterialName(materialId: string) {
        const outputMat = this.executionBatch.output_weights[materialId];
        return outputMat.name;
    }

    ///////////// Websocket
    private WebsocketMessage(msg: any) {
        console.log(msg);
        if (msg.type === 'message') {
            if (msg.data) {
                const result = JSON.parse(msg.data);
                if (result.topic === 'weighing' && result.net !== null) {
                    this.isToggled = false;
                    console.log('got correct weighing result');
                    this.currentWeighting = result;
                    // round to 3 decimal places
                    this.productQuantity = result.net / 1000;
                    this.manualBrutto = result.net / 1000;
                    this.manualTara = 0;
                    this.confirmQuantityForScaleWeighting();
                } else {
                    this.isToggled = false;
                }
            }
        }

    }

    private WebsocketConnected() {

    }


    private WebsocketClosed() {

    }

    private WebsocketConnectError() {

    }

    private WebsocketError() {

    }

    private resetWeighing() {
        this.currentWeighting = null;
        this.closeWebsocket();
    }


    onSwipe() {
        this.slideRight = false;
        this.slideCenter = false;
        this.selectedProduct = undefined;
    }

    public closeScalesInterface() {
        this.slideRight = false;
        this.slideCenter = false;
        this.selectedCarriageForWeighing = undefined;
        this.resetWeighing();

    }

    public selectScaleInterface(scale: any) {
        if (scale) {
            this.selectedContainer = this.selectedCarriageForWeighing;
            this.showBatteryIcon = false;
            this.manuelSelected = false;
            this.SelectedScale = scale;
            this.systemScaleSelected = true;
            this.websocket.connectToSecuredServer(true);
        }
    }


    chooseManuell() {
        this.systemScaleSelected = false;
        this.SelectedScale = null;
        this.manuelSelected = true;
        this.resetWeighing();
    }

    private closeWebsocket() {
        if (this.websocket.IsWebsocketConnected()) {
            this.websocket.Disconnect();
        }
    }

    public confirmQuantityForManualWeighting() {
        this.closeWebsocket();
        if (this.productQuantity > 0) {
            if (this.productQuantity * 1000 + this.selectedCarriageForWeighing.current_load > this.selectedCarriageForWeighing.maximal_load) {
                const dialogConfig2 = new MatDialogConfig();
                dialogConfig2.disableClose = true;
                dialogConfig2.autoFocus = true;
                dialogConfig2.panelClass = 'dialogStyles';
                dialogConfig2.data = {
                    txt: 'Das maximal Gewicht wird überschritten. Soll trotzdem verwogen werden?',
                    option1: 'Ja',
                    option2: 'Nein'
                };
                const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig2);
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 1) {
                        this.makeManualWeighing();
                    } else if (result === 2) {
                        console.log('no weighted');
                    }
                });
            } else {
                this.makeManualWeighing();
            }
        } else {
            this.showErrorWeighing();
        }
    }

    public confirmQuantityForScaleWeighting() {
        this.closeWebsocket();
        if (this.SelectedScale && this.currentWeighting) {
            console.log(`product quantity ${this.productQuantity}`);
            console.log(`selectedCarriageForWeighing.current_load ${this.selectedCarriageForWeighing.current_load}`);
            console.log(`selectedCarriageForWeighing.maximal_load ${this.selectedCarriageForWeighing.maximal_load}`);
            if (this.productQuantity <= 0) {
                const dialogConfig1 = new MatDialogConfig();
                dialogConfig1.disableClose = true;
                dialogConfig1.autoFocus = true;
                dialogConfig1.panelClass = 'dialogStyles';
                dialogConfig1.data = 'Verwiegung nicht möglich, Nettowerte gleich oder kleiner Null können nicht gespeichert werden';
                const dialogReferenceToCheckIfTheUserClosedConfirmationMessage = this.dialog.open(MessageboxComponent, dialogConfig1);
                dialogReferenceToCheckIfTheUserClosedConfirmationMessage.afterClosed().subscribe(result => {
                    this.websocket.connectToSecuredServer(true);
                });
                // tslint:disable-next-line:max-line-length
            } else if (this.productQuantity * 1000 + this.selectedCarriageForWeighing.current_load > this.selectedCarriageForWeighing.maximal_load) {
                const dialogConfig2 = new MatDialogConfig();
                dialogConfig2.disableClose = true;
                dialogConfig2.autoFocus = true;
                dialogConfig2.panelClass = 'dialogStyles';
                dialogConfig2.data = {
                    txt: 'Das maximal Gewicht wird überschritten. Soll trotzdem verwogen werden?',
                    option1: 'Ja',
                    option2: 'Nein'
                };
                const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig2);
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 1) {
                        this.makeScaleWeighing();
                    } else if (result === 2) {
                        this.websocket.connectToSecuredServer(true);
                    }
                });
            } else {
                this.makeScaleWeighing();
            }
        }
    }

    public makeScaleWeighing() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = {
            txt: 'Folgende Verwiegung wird gespeichert:',
            weight: this.currentWeighting.net,
            batch: this.executionBatch.name,
            unit: this.SelectedScale.unit,
            option1: 'Abbrechen'
        };

        const dialogReferenceForMessageForMssWeighingConfirmationComponent = this.dialog.open(MessageForMssWeighningConfirmationComponent, dialogConfig);

        this.makeWeighingAfter3secondsAndCloseDialog(dialogReferenceForMessageForMssWeighingConfirmationComponent);

        dialogReferenceForMessageForMssWeighingConfirmationComponent.afterClosed().subscribe(result => {
            if (result) {
                this.websocket.connectToSecuredServer(true);
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
            }
        });



    }


    public makeWeighingAfter3secondsAndCloseDialog(dialogRef: MatDialogRef<any>) {
        this.timeoutId =  setTimeout(() => {
            {
                this.closeDialog(dialogRef);
                this.makeWeighingScaleApiCall();
            }
        }, 3000);
    }


    private closeDialog(dialogRef: MatDialogRef<any>) {
        dialogRef.close();
    }


    private makeWeighingScaleApiCall() {
        const weightId = this.currentWeighting.id;
        const batchId = this.executionBatch.id;
        const materialId = this.selectedCarriageForWeighing.output_material;
        const containerId = this.selectedCarriageForWeighing.id;
        this.apiService.SetScaleWeightResult(weightId, batchId, materialId, containerId).subscribe((setWeightResult: any) => {
            if (setWeightResult) {
                if (setWeightResult.id && setWeightResult.material) {
                    if (this.executionBatch) {
                        if (this.executionBatch.output_weights) {
                            const outputMat = this.executionBatch.output_weights[setWeightResult.material.id];
                            if (outputMat) {
                                outputMat.weight += setWeightResult.net_weight;
                                this.currentWeighting = null;
                                this.selectedCarriageForWeighing.current_load += setWeightResult.net_weight;
                                this.apiService.getSingleBatch(this.exBatch.id).subscribe((batch: any) => {
                                    if (batch) {
                                        if (batch.id) {
                                            this.executionBatch = batch;
                                            this.websocket.connectToSecuredServer(true);
                                        }
                                    }
                                });
                            }
                        }

                    }
                } else {
                    this.handleErrorWeighing();
                }
            } else {
                this.handleErrorWeighing();
            }
        });
    }

    public makeManualWeighing() {
        this.closeWebsocket();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialogStyles';
        dialogConfig.data = {
            txt: 'Möchten Sie folgende Verwiegung speichern?',
            weight: this.productQuantity,
            batch: this.executionBatch.name,
            unit: 't',
            option1: 'Speichern',
            option2: 'Abbrechen'
        };
        const dialogRef = this.dialog.open(OptionBoxInputComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                const data = {
                    net_weight: this.productQuantity * 1000,
                    batch_id: this.executionBatch.id,
                    material_id: this.selectedCarriageForWeighing.output_material,
                    container_id: this.selectedCarriageForWeighing.id
                };

                this.manualTara = 0;
                this.manualBrutto = 0;
                this.productQuantity = undefined;

                this.apiService.SetWeightResult(data).subscribe((setWeightResult: any) => {
                    if (setWeightResult) {
                        if (setWeightResult.id && setWeightResult.material) {
                            if (this.executionBatch) {
                                if (this.executionBatch.output_weights) {
                                    const outputMat = this.executionBatch.output_weights[setWeightResult.material.id];
                                    if (outputMat) {
                                        outputMat.weight += setWeightResult.net_weight;
                                        this.selectedCarriageForWeighing.current_load += setWeightResult.net_weight;
                                        this.apiService.getSingleBatch(this.exBatch.id).subscribe((data2: any) => {
                                            if (data2) {
                                                if (data2.id) {
                                                    this.executionBatch = data2;
                                                }
                                            }
                                        });
                                    }
                                }

                            }
                        } else {
                            this.handleErrorWeighing();
                        }
                    } else {
                        this.handleErrorWeighing();
                    }
                });
            } else if (result === 2) {
                console.log('no weighted');
            }
        });
    }

    private handleErrorWeighing() {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = 'Fehler beim Speichern der Verwiegung';
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }

    public showErrorWeighing() {
        let dialogConfig1: MatDialogConfig;
        dialogConfig1 = {
            data: {content: `<b>Verwiegung nicht möglich.</b><br/><b>Nettowerte gleich oder kleiner Null können nicht gespeichert werden (Brutto - Tara)</b>`}
        };
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        this.dialog.open(ErrorWeighningComponent, dialogConfig1);
    }

    checkIfSumNull(): boolean {
        return getCurrentSumTime.call(this) === '0:00:00';
    }

    onBruttoInput($event) {
        onBruttoInput.call(this, $event);
    }

    onTaraInput($event) {
        onTaraInput.call(this, $event);
    }

    public getScales() {
        this.apiService.getAvailableScales().subscribe((scales: any) => {
            if (scales) {
                if (scales.length > 0) {
                    this.Scales = scales;
                    this.selectScaleInterface(this.Scales[0]);
                    this.systemScaleSelected = true;
                } else {
                    this.chooseManuell();
                }
            }
            this.productQuantity = 0.0;
            this.slideCenter = true;
            this.slideRight = true;
        });
    }


}

