import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {APIService} from '../../Services/api.service';
import {Router} from '@angular/router';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {UserAccessService} from '../../Services/user-access.service';

@Component({
    selector: 'app-mover-view',
    templateUrl: './mover-view.component.html',
    styleUrls: ['./mover-view.component.scss']
})
export class MoverViewComponent implements OnInit {
    tare: number;
    displayedColumns: string[] = ['Bezeichnung', 'Typ', 'EUI', 'Tara', 'NeuesTara'];
    showspinner: boolean [] = [false];


    public selectedMover: any;

    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private scale: any;
    private weighing: any;
    private weighingHandler: any;
    private index = -1;
    public tareWeighingSuccessFull = false;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    public showAutoManuelChoice: boolean [] = [false];
    private indexForshowAutoManuelChoice = -1;
    public btnAutoDisabled: boolean [] = [false];
    public manualTara = 0;
    public showManualInput: boolean [] = [false];
    public selectedMoverForManual: any;

    constructor(public dialog: MatDialog,
                private Apiservice: APIService,
                public router: Router,
                public userAccess: UserAccessService) {
    }

    ngOnInit(): void {
        if (this.Apiservice.versioningFinished === true) {
            this.receiveMover();
        } else {
            this.Apiservice.OnVersioningFinished.subscribe(this.receiveMover.bind(this));
        }
    }

    receiveMover() {
        this.Apiservice.getAvailableMover().subscribe((mover: any) => {
            if (mover.length > 0) {
                this.dataSource.data = mover;
                this.showspinner = [];
                for (const mv of mover) {
                    this.showspinner.push(false);
                }
            }
        });
    }

    public getScaleType(type: string): string {
        switch (type) {
            case 'platform': {
                return 'Plattform';
            }
            case 'bridge': {
                return 'Brücke';
            }
            default: {
                return '';
            }
        }
    }

    public ShowEdit(element: any): boolean {
        if (this.selectedMover) {
            if (this.selectedMover.id === element.id) {
                return true;
            }
        }
        return false;
    }




    createNewTara(mover: any, i: number) {
        this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
        this.tareWeighingSuccessFull = false;
        this.showspinner[i] = true;
        this.selectedMover = mover;
        this.tare = mover.tare;
        this.index = i;
        this.prepareForScaling();


        // const dialogConfig1 = new MatDialogConfig();
        // dialogConfig1.disableClose = true;
        // dialogConfig1.autoFocus = true;
        // dialogConfig1.panelClass = 'dialogStyles';
        // dialogConfig1.data = 'Funktion in der aktuellen Version nicht verfügbar';
        // const dialogRef2 = this.dialog.open(MessagebComponent, dialogConfig1);
        //
        // setTimeout(() => {
        //     this.showSpinner[i] = false;
        // }, 2000);
    }

    public showAutoManuelArea(i) {
        this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
        this.showAutoManuelChoice[i] = true;
        this.showManualInput[this.indexForshowAutoManuelChoice] = false;
        this.btnAutoDisabled[this.indexForshowAutoManuelChoice] = false;
        this.indexForshowAutoManuelChoice = i;
    }

    public createNewTaraManual(container: any, i: number) {
        this.showManualInput[i] = true;
        this.btnAutoDisabled[i] = true;
        this.selectedMoverForManual = container;
    }

    public saveTaraManual() {

        if (this.manualTara > 0) {
            this.Apiservice.updateMoverTare(this.selectedMoverForManual.id,
                this.selectedMoverForManual.name,
                this.selectedMoverForManual.scale_type,
                this.manualTara).subscribe(() => {
                this.showAutoManuelChoice[this.indexForshowAutoManuelChoice] = false;
                this.showManualInput[this.indexForshowAutoManuelChoice] = false;
                this.btnAutoDisabled[this.indexForshowAutoManuelChoice] = false;
                this.manualTara = 0;
                this.receiveMover();
            });
        } else {
            this.showMessage('Bitte Menge angeben');
        }
    }

    public saveTare() {

        if (this.tare > 0) {
            this.Apiservice.updateMoverTare(this.selectedMover.id,
                this.selectedMover.name,
                this.selectedMover.scale_type,
                this.tare).subscribe(() => {
               this.selectedMover = null;
               this.tareWeighingSuccessFull = false;
               this.weighing = null;
               this.scale = null;
               this.receiveMover();
               this.tare = 0;
            });
        } else {
            this.showMessage('Bitte Menge angeben');
        }
    }

    public cancelChange() {
        this.selectedMover = null;
        this.showspinner[this.index] = false;
        this.index = -1;
        this.weighing = null;
        this.scale = null;
        this.tareWeighingSuccessFull = false;
        this.receiveMover();
    }

    private showMessage(text: string) {
        const dialogConfig1 = new MatDialogConfig();
        dialogConfig1.disableClose = true;
        dialogConfig1.autoFocus = true;
        dialogConfig1.panelClass = 'dialogStyles';
        dialogConfig1.data = text;
        this.dialog.open(MessageboxComponent, dialogConfig1);
    }


    private prepareForScaling() {
        // 1. Get scales
        console.log('getting scales');
        this.Apiservice.getAvailableScales().subscribe((scales: any) => {
            console.log(scales);
            const requiredScales = scales.filter(ex => ex.type === this.selectedMover.scale_type && ex.online === true);

            if (requiredScales.length > 0) {

                // 2. Check if have mover
                for (const scl of requiredScales) {
                    if (scl.requires_mover === true) {
                        this.scale = scl;
                    }
                }
                if (this.scale) {
                    // GET CONTAINER
                        this.initScale();

                } else {
                    this.showMessage('Keine passende Waage gefunden (Fahrzeug wird benötigt)');
                    this.selectedMover = null;
                    this.showspinner[this.index] = false;
                    this.tareWeighingSuccessFull = false;
                }
            } else {
                this.showMessage('Keine entsprechende Waage vorhanden');
                this.selectedMover = null;
                this.showspinner[this.index] = false;
                this.tareWeighingSuccessFull = false;
            }
        });
    }

    private initScale() {
        if (this.selectedMover && this.scale) {
            console.log(this.selectedMover.id);
            this.Apiservice.createTareWeighting(this.scale.id).subscribe((weighing: any) => {
               if (weighing.id) {
                    if (weighing.status === 'transmitted') {
                        this.weighing = weighing;
                        // GET WEIHING
                        this.runWeighingHandler();
                    }
               }
            }, error => {
                console.log(error);
                this.selectedMover = null;
                this.showspinner[this.index] = false;
                this.tareWeighingSuccessFull = false;
            });
        }
    }

    private runWeighingHandler() {
        if (this.weighingHandler) {
            clearInterval(this.weighingHandler);
        }

        this.weighingHandler = setInterval(() => {

            if (this.weighing) {
                this.Apiservice.getPreliminaryWeighing(this.weighing.id).subscribe((weigh: any) => {
                    if (weigh.status === 'available') {
                        clearInterval(this.weighingHandler);

                        if (weigh.net_weight !== null) {
                            this.tare = weigh.net_weight;
                            this.tareWeighingSuccessFull = true;
                        } else {
                            this.showWeighingError('Wiegung ungültig');
                        }
                        this.showspinner[this.index] = false;
                    }
                }, () => {
                    this.showWeighingError('Wiegung wurde abgebrochen');
                });
            } else {
                this.showWeighingError('Wiegung wurde abgebrochen');
            }
        }, 1000);
    }

    private showWeighingError(msg: string) {
        this.showMessage(msg);
        clearInterval(this.weighingHandler);
        this.selectedMover = null;
        this.weighing = null;
        this.scale = null;
        this.showspinner[this.index] = false;
        this.tareWeighingSuccessFull = false;
    }
}
