<div>
    <h1 class="tableheader">Auslastung</h1>
    <div class="filterButtonRow">
        <div class="elementrow">
            <p class="headerDate">Von</p>
            <mat-form-field class="dateInput">
                <div fxLayout="row">
                    <input [(ngModel)]="startDate" [owlDateTimeTrigger]="dtPicker1"
                           [owlDateTime]="dtPicker1" matInput>
                    <span [owlDateTimeTrigger]="dtPicker1" class="iconWrapper">
                                    <mat-icon class="material-icons-outlined calendarIcon">
                                    calendar_month
                                    </mat-icon>
                                      </span>
                    <owl-date-time #dtPicker1></owl-date-time>
                </div>
            </mat-form-field>

        </div>
        <div class="elementrow">
            <p class="headerDate">Bis</p>
            <mat-form-field class="dateInput">
                <div fxLayout="row">
                    <input [(ngModel)]="endDate" [owlDateTimeTrigger]="dtPicker2"
                           [owlDateTime]="dtPicker2" matInput>
                    <span [owlDateTimeTrigger]="dtPicker2" class="iconWrapper">
                                    <mat-icon class="material-icons-outlined calendarIcon">
                                    calendar_month
                                    </mat-icon>
                                      </span>
                    <owl-date-time #dtPicker2></owl-date-time>
                </div>
            </mat-form-field>
        </div>

        <div class="elementrow noprint">
            <button (click)="evaluateResourceTypes()" class="btn"
                    mat-button>Auswerten
            </button>
        </div>
    </div>
    <ng-container *ngIf="resourceTypes">
        <mat-accordion multi *ngIf="resourceTypes.length > 0; else warningMessage">
            <ng-container *ngFor="let resType of resourceTypes">
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{resType.name}}
                        </mat-panel-title>
                        <mat-panel-description>
                            <table class="tableOfParameters">
                                <tr>
                                    <td class="tableCellPercentage">{{resType.time_used / resType.time_available  | percent:'1.0-1':'de' }}</td>
                                    <td class="tableCell"> {{resType.time_used / 3600| number: '1.1-1':'de'}}
                                        / {{resType.time_available / 3600| number: '1.1-1':'de'}} h
                                    </td>
                                    <td class="tableCell">
                                        {{resType.time_used / 3600 * resType.cost_per_hour| number: '1.1-1':'de'}}
                                        / {{resType.time_available / 3600 * resType.cost_per_hour| number: '1.1-1':'de'}} €
                                    </td>
                                </tr>
                            </table>
                        </mat-panel-description>
                        <div class="emptyDiv">
                        </div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent *ngIf="resType.resource_list.length > 0">
                        <div *ngFor="let resource of resType.resource_list; let lastItem = last;">
                            <div class="displayFLex">
                                <mat-panel-title>
                                    {{resource.name}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <table class="tableOfParameters">
                                        <tr>
                                            <td class="tableCellPercentage">{{resource.time_used / resource.time_available  | percent:'1.0-1':'de' }}</td>
                                            <td class="tableCell"> {{resource.time_used / 3600| number: '1.1-1':'de'}}
                                                / {{resource.time_available / 3600| number: '1.1-1':'de'}} h
                                            </td>
                                            <td class="tableCell">
                                                {{resource.time_used / 3600 * resType.cost_per_hour| number: '1.1-1':'de'}}
                                                / {{resource.time_available / 3600 * resType.cost_per_hour| number: '1.1-1':'de'}}
                                                €
                                            </td>
                                        </tr>
                                    </table>
                                </mat-panel-description>
                                <div class="emptyDiv">
                                </div>
                            </div>
                            <div *ngIf="!lastItem">
                                <hr style="width: 100%; color: black; opacity: 35%">
                            </div>
                        </div>

                    </ng-template>
                </mat-expansion-panel>
            </ng-container>

        </mat-accordion>
        <ng-template #warningMessage>
            <div>
                Es wurden keine Daten im ausgewählten Zeitraum gefunden.
            </div>
        </ng-template>
    </ng-container>


</div>
