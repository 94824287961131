import {EnvironmentTypes} from '../app/Enums/environment-types';

export const environment = {
    tenant: EnvironmentTypes.VSA,
    production: true,
    redirectUri: 'https://arp-vsa.schaufler-metalle.com/',
    postLogoutRedirectUri: 'https://arp-vsa.schaufler-metalle.com/',
    arpApiUrl: 'https://arp-api.azurewebsites.net',
    portalUrl: 'https://arp.schaufler-metalle.com/?autoredirect=false',
    msalAuthority: 'https://login.microsoftonline.com/a76381c7-ea6e-41f4-8bb8-cb6ec292a8a0',
    msalClientId: '2130da08-abcf-4bac-b711-16aab937ddac',
    authScopes: [
        {
            apiUrl: 'https://arp-api.azurewebsites.net/v2_0',
            scope: 'api://0f83e30e-fa2a-4b4c-b640-3ac4c12aad47/Api.ReadWrite.All'
        }
    ]
};
