
    <mat-dialog-content>
        <div fxLayout="column">
            <p><strong>Wollen Sie wirklich den Prozess löschen?</strong></p>
            <p><strong>Folgende Chargen sind noch nicht abgeschlossen bzw. eingeplant?</strong></p>
            <div fxLayout="column" *ngFor="let bt of batches">
                <p>
                    {{bt.name}}
                </p>
            </div>

        </div>

    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="yesButton"  (click)="Cancel()">Abbrechen</button>
        <button class="noButton"  (click)="Continue()">Fortfahren</button>
    </mat-dialog-actions>

