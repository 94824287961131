import {Validator, NG_VALIDATORS, UntypedFormControl} from '@angular/forms';
import {Directive, Input, Output, EventEmitter} from '@angular/core';
import {DataShareService} from './data-share.service';

@Directive({
    selector: '[appStepValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true}
    ]
})
export class ForbiddenValidatorDirective implements Validator {
    @Input('defaultQuantity') gteNum: number;
    @Input('stepSize') stepSize: number;
    @Input('id') id: string;
    @Output() newItemEvent = new EventEmitter<any>();
    @Output() valid = new EventEmitter<boolean>();

    objectToemit = {
        validity: true,
        IdRessource: '',
        suggestedValue: 0
    };

    constructor(private dataService: DataShareService) {
    }

    validate(c: UntypedFormControl) {

        const v: number = +c.value;

        let suggestedVal: number;

        if (isNaN(v) || v === 0) {
            this.objectToemit = {
                validity: false,
                IdRessource: this.id,
                suggestedValue: 0
            };
            this.newItemEvent.emit(this.objectToemit);
            return {nullError: true, requiredValue: this.gteNum};
        }

        if (this.stepSize.toString() === '0') {
            this.objectToemit = {
                validity: true,
                IdRessource: this.id,
                suggestedValue: 0
            };
            this.newItemEvent.emit(this.objectToemit);
            return null;
        }

        if (v < 0) {
            return null;
        }


        if (v > 999) {
            this.objectToemit = {
                validity: true,
                IdRessource: this.id,
                suggestedValue: 0
            };
            this.newItemEvent.emit(this.objectToemit);
            return null;
        }


        if (((v - this.gteNum) % this.stepSize) !== 0) {
            if (v < this.gteNum) {
                this.objectToemit = {
                    validity: false,
                    IdRessource: this.id,
                    suggestedValue: this.gteNum
                };
                this.newItemEvent.emit(this.objectToemit);
                this.dataService.validRessourceChange(false, this.id, this.gteNum);
                return {gte: true, requiredValue: this.gteNum};
            } else {
                suggestedVal = Number(Math.round((c.value - this.gteNum) / this.stepSize)) * this.stepSize + Number(this.gteNum);
                if (suggestedVal > 999) {
                    suggestedVal = Number(Math.round((c.value - this.gteNum * 2) / this.stepSize)) * this.stepSize + Number(this.gteNum);
                }
                this.objectToemit = {
                    validity: false,
                    IdRessource: this.id,
                    suggestedValue: suggestedVal
                };
                this.newItemEvent.emit(this.objectToemit);
                this.dataService.validRessourceChange(false, this.id, suggestedVal);
                return {gte: true, requiredValue: this.gteNum};
            }

        }
        this.dataService.validRessourceChange(true, this.id, 0);
        this.objectToemit = {
            validity: true,
            IdRessource: this.id,
            suggestedValue: 0
        };
        this.newItemEvent.emit(this.objectToemit);
        return null;
    }

}
