import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataShareService {

    private selectedProcess = new BehaviorSubject('');
    public selectedContainer: any;
    public selectedMover: any;
    public serverErrorFlag = new BehaviorSubject(true);
    currentProcess = this.selectedProcess.asObservable();
    public ProcessToEdit: any;
    public BatchToEdit: any;
    public BatchToShow: any;
    public BatchForTreatment: any;
    public TreatmentToHandle: any;
    public BatchToBook: any;
    public TreatmentToSelectInBook: any;
    public BatchToCalender = new BehaviorSubject({});
    public BatchToPlan: any;
    public currentServerTime: Date;
    public serverTimeHandler;
    public activeEdit = false;
    public lastScaleWeighing = new BehaviorSubject({
        eui: '',
        distance: 0,
        voltage: 0,
        containerid: '',
        scaleName: ''

    });

    public validRessource = new BehaviorSubject({
        validity: true,
        IdRessource: '',
        suggestedValue: 0
    });


    constructor() {
        this.currentServerTime = new Date();
        this.runServerTimeHandler();
    }

    private runServerTimeHandler() {
        this.serverTimeHandler = setInterval(() => {
            this.currentServerTime.setSeconds(this.currentServerTime.getSeconds() + 1);
            // console.log(this.currentServerTime);
        }, 1000);
    }

    changeBatchToCalender(batch) {
        this.BatchToCalender.next(batch);
    }

    changeserverErrFlag() {
        this.serverErrorFlag.next(!this.serverErrorFlag.value);
    }

    validRessourceChange(valid: boolean, id: string, suggestedVal: number) {
        this.validRessource.next({
            validity: valid,
            IdRessource: id,
            suggestedValue: suggestedVal
        });
    }

    lastScaleWeighingChange(eui: string, distance: number, voltage: number, containerid: string, scaleName: string) {
        this.lastScaleWeighing.next({
            eui,
            distance,
            voltage,
            containerid,
            scaleName
        });
    }

    changeProcess(process: any) {
        this.selectedProcess.next(process);
    }

    chooseContainer(container: any) {
        this.selectedContainer = container;
    }

    chooseMover(mover: any) {
        this.selectedMover = mover;
    }

    public SetProcessToEdit(process: any) {
        this.ProcessToEdit = process;
    }

    public ReleaseProcess() {
        this.ProcessToEdit = null;
    }

    public SetBatchToEdit(batch: any) {
        this.BatchToEdit = batch;
    }

    public ReleaseBatch() {
        this.BatchToEdit = null;
    }
}

