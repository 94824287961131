<ng-container>
    <div>
        <h1 class="tableheader" style="margin-left: 5px; ">Anstehende Chargen</h1>
    </div>
    <cdk-virtual-scroll-viewport class="listview" itemSize="200">
        <div (click)="selectBatch(batch)" *ngFor="let batch of batches" class="batchentry">
                <img *ngIf="batch.transfer" class="ksalogo" src="assets/images/ksa.png" alt="img">
                <div *ngIf="!batch.scheduled_start" class="unplanedBatch">
                    <i class="material-icons icon">warning</i>
                    <p class="unplanedErrorTxt">ACHTUNG: Charge ist ungeplant. Bearbeitung notwendig</p>
                </div>

                <ng-container *ngIf="tenant ===  typeEnvironmentVsa; else mssBlock">
                    <div class="elementrow" fxLayout="row">
                        <p class="header">Chargenname</p>
                        <p class="txt">{{batch.name}}</p>
                    </div>
                    <div class="elementrow" fxLayout="row">
                        <p class="header">Startzeitpunkt</p>
                        <p *ngIf="batch.scheduled_start; else elseBlock"
                           class="txt">{{batch.scheduled_start | moment: 'dddd DD. MMMM yyyy HH:mm'}}</p>
                        <ng-template #elseBlock>Ungeplant</ng-template>
                    </div>
                    <div class="addinfo" fxLayout="row">
                        <div class="elementrow" fxLayout="column">
                            <ng-container *ngIf="batch.input">
                                <p class="header">Input</p>
                                <div class="outputmaterialentry">
                                    <p class="outmaterial">{{batch.input.id}} {{batch.input.name}}
                                        - {{batch.quantity| number: '1.0-3':'de'}}t</p>
                                </div>
                            </ng-container>
                            <p *ngIf="batch.resources && batch.resources.length > 0" class="header">Ressourcen</p>
                            <div *ngFor="let ressour of batch.resources" class="ressourcesentry">
                                <p class="resour">{{ressour.name}} ({{ressour.resource_type.name}})</p>
                            </div>

                        </div>
                        <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                            <div class="flexIcon">
                                <mat-icon class="iconArrow">chevron_right</mat-icon>
                            </div>
                        </ng-container>
                        <div class="addinfo flexResources">
                            <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                                <p class="addHeader">Output</p>
                                <div *ngFor="let outmat of batch.output" class="outputmaterialentry">
                                    <p class="outmaterial">{{outmat.id}} {{outmat.name}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-template #mssBlock>
                    <div fxLayout="row">
                        <div class="mssLeftColumn">
                            <div class="elementrow" fxLayout="row">
                                <p class="header">Chargenname</p>
                                <p class="txt">{{batch.name}}</p>
                            </div>
                            <div class="elementrow" fxLayout="row">
                                <p class="header">Startzeitpunkt</p>
                                <p *ngIf="batch.scheduled_start; else elseBlock"
                                   class="txt">{{batch.scheduled_start | moment: 'dddd DD. MMMM yyyy HH:mm'}}</p>
                                <ng-template #elseBlock>Ungeplant</ng-template>
                            </div>
                            <div class="elementrow" fxLayout="column">
                                <p *ngIf="batch.resources && batch.resources.length > 0" class="header">Ressourcen</p>
                                <div *ngFor="let ressour of batch.resources" class="ressourcesentry">
                                    <p class="resour">{{ressour.name}} ({{ressour.resource_type.name}})</p>
                                </div>

                            </div>
                        </div>
                        <div class="mssOutputs">
                            <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                                <div class="flexIcon">
                                    <mat-icon class="iconArrow">chevron_right</mat-icon>
                                </div>
                            </ng-container>
                            <div class="addinfo flexResources">
                                <ng-container *ngIf="batch.process.process_category !== 'maintenance'">
                                    <p class="addHeader">Output</p>
                                    <div *ngFor="let outmat of batch.output" class="outputmaterialentry">
                                        <p class="outmaterial">{{outmat.id}} {{outmat.name}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
        </div>
    </cdk-virtual-scroll-viewport>
    <div [ngClass]="{'paginatorBoth': skip && showNext,  'paginatorRight': !skip}"
         class="paginator">
        <div (click)="paginateLeft()" *ngIf="skip" class="previous">
            <mat-icon>keyboard_arrow_left</mat-icon>
            vorherige Einträge
        </div>
        <div (click)="paginateRight()" *ngIf="showNext" class="next">
            nächste Einträge
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</ng-container>

