<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 15 + 'px'}" (click)="onItemSelected(item)"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   class="menu-list-item">
    <ng-container>
        <div [ngClass]="{'fontBold': depth == 0, 'font700': item.disabled}"> {{item.displayName}}</div>
        <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" [class.fullOpacity]="expanded">
      expand_more
    </mat-icon>
  </span>
    </ng-container>

</a>
<div *ngIf="expanded">
    <ng-container *ngFor="let child of item.children">
        <ng-container *ngIf="userAccess.hasAccessRightsEn(child.accessAreas, child.accessTypes)">
            <app-menu-list-item [item]="child" [depth]="depth+1">
            </app-menu-list-item>
        </ng-container>
    </ng-container>
</div>

