import {Component, OnDestroy, OnInit} from '@angular/core';
import {APIService} from 'src/app/Services/api.service';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {DataShareService} from '../../../Services/data-share.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ExecutionTypes} from '../../../Enums/execution-types.enum';
import {UserAccessService} from '../../../Services/user-access.service';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {interval, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {GlobalFunctionsService} from '../../../Services/global-functions';
import {EnvironmentTypes} from '../../../Enums/environment-types';

moment.locale('de');


@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
    treatments: any;
    public showMore: boolean;
    public intervalSubscriptionForReceivingDataFromAPI: Subscription;

    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;

    constructor(public dialog: MatDialog,
                private apiService: APIService,
                public router: Router,
                private http: HttpClient,
                private dataService: DataShareService,
                private functionsService: GlobalFunctionsService,
                public userAccess: UserAccessService) {
    }


    ngOnInit(): void {
        if (this.apiService.versioningFinished === true) {
            this.getDataFromAPI();
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.apiVersionCheckFinished.bind(this));
        }
    }

    private apiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.getDataFromAPI();
        }
    }

    private getDataFromAPI() {
        this.getTreatmentsFromApi();
        this.intervalSubscriptionForReceivingDataFromAPI = interval(180000).subscribe(() => {
            this.getTreatmentsFromApi();
        });
    }

    private getTreatmentsFromApi() {
        this.getTreatmentsFromAPI();
    }


    public getTreatmentsFromAPI() {
        this.apiService.getOpenExecutions().
        subscribe({
            next: this.handleReceivedTreatments.bind(this),
        });
    }

    private handleReceivedTreatments(data: any) {
        this.treatments = data;
        if (this.treatments.length > 0) {
            this.treatments.forEach(tr => {
                this.apiService.getSingleBatch(tr.batch_id).subscribe((data2: any) => {
                    tr.executions = data2.executions;
                });
            });

        }
        if (this.treatments.length > 0) {
            setInterval(() => {
                this.treatments = this.treatments;
            }, 1000);
        }
    }

    public getTreatmentState(state: string): string {
        if (state === ExecutionTypes.SETUP) {
            return 'Rüsten';
        } else if (state === ExecutionTypes.PROCESSING) {
            return 'Aufbereiten';
        } else if (state === ExecutionTypes.TEARDOWN) {
            return 'Nachbereiten';
        } else if (state === ExecutionTypes.PAUSE) {
            return 'Pausiert';
        } else if (state === ExecutionTypes.MAINTENANCE) {
            return 'Instandhaltung';
        } else if (state === ExecutionTypes.MALFUNCTION) {
            return 'Störung';
        } else {
            return 'Kein Status';
        }
    }

    public selectTreatment(treatment: any) {
        if (environment.tenant === EnvironmentTypes.MSS) {
            this.router.navigate(['/mssexecution/' + treatment.batch_id]).catch(err => console.log(err));
        }
        else {
            this.router.navigate(['/execution/' + treatment.batch_id]).catch(err => console.log(err));
        }
    }

    public showMoreCharges() {
        this.showMore = !this.showMore;

    }

    public getCurrentTime(type: string, tr: any): string {
        if (tr.executions) {
            // SUM ALL
            let seconds = 0.0;
            for (const et of tr.executions.filter(ex => ex.execution_type === type && ex.ended)) {
                const start = moment.utc(et.started).local();
                const end = moment.utc(et.ended).local();

                seconds += end.diff(start, 'seconds');
            }
            const cur = moment.utc(tr.started).local();
            const now = moment(this.dataService.currentServerTime).local();
            seconds += now.diff(cur, 'seconds', true);

            return this.functionsService.durationToString(Math.round(seconds));
        }
        return '0:00:00';
    }

    ngOnDestroy() {
        this.intervalSubscriptionForReceivingDataFromAPI.unsubscribe();
    }

}
