import { Injectable, EventEmitter } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket: WebSocket;
  private route = '/scales/info';
  private IsConnected = false;
  private CurrentConnection: string;

  private waitForConnect = false;
  private systemDisconnect = false;
  private autoReconnectActive = true;
  private reconnects = 0;

  private url = environment.arpApiUrl;

  public ConnectionError = new EventEmitter<number>();
  public WebsocketConnected = new EventEmitter();
  public WebsocketClosed = new EventEmitter();
  public WebsocketError = new EventEmitter<any>();
  public WebsocketMessage = new EventEmitter<any>();
  public Disconnected = new EventEmitter();

  constructor() {
    this.url = this.url.replace('https://', '');

  }

  public IsWebsocketConnected(): boolean {
    return this.IsConnected;
  }

  public connectToSecuredServer(forceClose = false) {
    this.reconnects = 0;
    this.CurrentConnection = 'wss://' + this.url + this.route;
    this.prepareForConnection(forceClose);
  }

  public Disconnect() {
    this.autoReconnectActive = false;

    if (this.IsConnected) {
      if (this.socket) {
        this.systemDisconnect = true;
        this.socket.close();
      } else {
        this.IsConnected = false;
        this.waitForConnect = false;
        this.systemDisconnect = false;
        this.socket = null;
        this.Disconnected.emit();
      }
    } else {
      this.Disconnected.emit();
    }
  }

  private prepareForConnection(forceClose = false) {

    this.waitForConnect = false;
    this.autoReconnectActive = true;

    if (this.IsConnected) {
      if (forceClose) {
        this.waitForConnect = true;
        if (this.socket) {
          if (this.socket.readyState === this.socket.OPEN) {
            // NOW CLOSING
            this.autoReconnectActive = false;
            this.socket.close();
          }
        } else {
          this.socket = null;
          this.IsConnected = false;
          this.prepareForConnection(forceClose);
        }
      } else {
        this.ConnectionError.emit(0);
      }
    } else {
      try {
        this.socket = new WebSocket(this.CurrentConnection);
        this.socket.onopen = () => this.websocketConnectionOpened();
        this.socket.onclose = () => this.websocketConnectionClosed();
        this.socket.onerror = (error) => this.websocketConnectionError(error);
        this.socket.onmessage = (message) => this.webSocketOnMessage(message);

        if (!this.autoReconnectActive || this.reconnects === 3) {
          setTimeout(() => {
            if (!this.IsConnected) {
              if (!this.waitForConnect && !this.systemDisconnect) {
                console.log('connection timeout');
                this.ConnectionError.emit(3);
                try {
                  this.socket.close();
                  this.socket = null;
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }, 15000);
        }
      } catch (ex) {
        console.log('websocket open error');
        this.ConnectionError.emit(2);
      }

    }
  }

  private websocketConnectionOpened() {
    this.reconnects = 0;
    console.log('websocket connection to ' + this.CurrentConnection + ' opened');
    this.IsConnected = true;
    this.WebsocketConnected.emit();

  }

  private websocketConnectionClosed() {

    console.log('websocket closed');

    if (this.autoReconnectActive && this.reconnects < 3) {
      console.log('websocket connection to ' + this.CurrentConnection + ' closed');
      console.log('websocket reconnect to ' + this.CurrentConnection);
      if (this.IsConnected) {
        this.IsConnected = false;
      }
      this.reconnects += 1;
      this.prepareForConnection();
    } else if (!this.waitForConnect) {
      console.log('websocket connection to ' + this.CurrentConnection + ' closed');

      if (this.IsConnected) {
        this.IsConnected = false;
      }
      this.WebsocketClosed.emit();
    } else if (this.systemDisconnect) {
      this.systemDisconnect = false;
      if (this.IsConnected) {
        this.IsConnected = false;
      }
      this.Disconnected.emit();
    } else {
      this.waitForConnect = false;
      if (this.IsConnected) {
        this.IsConnected = false;
      }
      this.prepareForConnection();
    }
  }

  private websocketConnectionError(error: Event) {
    console.log('Websocket connection error');
    console.log(error);

    this.waitForConnect = false;
    this.systemDisconnect = false;

    if (this.IsConnected) {
      this.WebsocketError.emit(error);
    } else {
      this.WebsocketError.emit(1);
    }
  }

  private webSocketOnMessage(message: any) {
    if (this.IsConnected) {
      console.log('message received from ' + this.CurrentConnection);
      console.log(message);
      this.WebsocketMessage.emit(message);
    }
  }

  public SendMessage(message: string) {
    console.log('send message to websocket ' + this.CurrentConnection);
    this.socket.send(message);
  }

}
