import {Directive} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appMinMaxValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: MinMaxValidatorDirective, multi: true}
    ]
})
export class MinMaxValidatorDirective implements Validator {
    validate(c: UntypedFormControl) {
        const v: number = +c.value;
        if (isNaN(v) || v === 0) {
            return {nullError: true};
        }
        return null;
    }

    constructor() {
    }

}
