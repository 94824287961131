import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {EnvironmentTypes} from '../../../Enums/environment-types';
import {interval, Subscription} from 'rxjs';
import {AccessTypes} from '../../../Enums/access-types';
import {AccessAppAreas} from '../../../Enums/access-app-areas';
import {MatDialog} from '@angular/material/dialog';
import {APIService} from '../../../Services/api.service';
import {Router} from '@angular/router';
import {UserAccessService} from '../../../Services/user-access.service';
import * as moment from 'moment/moment';
import {DataShareService} from '../../../Services/data-share.service';

@Component({
  selector: 'app-batches-table',
  templateUrl: './batches-table.component.html',
  styleUrls: ['../base-home-page/home-page.component.scss']
})
export class BatchesTableComponent implements OnInit, OnDestroy {
  tenant = environment.tenant;
  typeEnvironmentVsa = EnvironmentTypes.VSA;
  public intervalSubscriptionForReceivingDataFromAPI: Subscription;
  public showNext: boolean;
  public skip = 0;
  public batches: any;

  constructor(public dialog: MatDialog,
              private apiService: APIService,
              public router: Router,
              public userAccess: UserAccessService,
              private dataService: DataShareService ) {
  }

  ngOnInit(): void {
    if (this.apiService.versioningFinished === true) {
      this.getDataFromAPI();
    } else {
      this.apiService.OnVersioningFinished.subscribe(this.apiVersionCheckFinished.bind(this));
    }
  }

  private apiVersionCheckFinished() {
    if (this.apiService.versioningFinished === true) {
      this.getDataFromAPI();
    }
  }

  private getDataFromAPI() {
    this.getBatchesFromApi();
    this.intervalSubscriptionForReceivingDataFromAPI = interval(180000).subscribe(() => {
      this.getBatchesFromApi();
    });
  }

  private getBatchesFromApi() {
    this.getBatchesFromAPI(0, 100);
  }

  public paginateRight() {
    this.skip = this.skip + 100;
    this.getBatchesFromAPI(this.skip, 100);
  }

  public paginateLeft() {
    this.skip = this.skip - 100;
    this.getBatchesFromAPI(this.skip, 100);
  }

  getBatchesFromAPI(skip, limit) {

    this.apiService.getBatchList(skip, limit).subscribe({
      next: this.handleReceivedBatches.bind(this)
    });
  }

  private handleReceivedBatches(data: any) {
    this.showNext = data.length === 100;
    const bts = data;

    for (const b of bts) {
      if (b.scheduled_start) {
        b.scheduled_start = moment.utc(b.scheduled_start).local();
      } else {
        b.scheduled_start = null;
      }
    }
    this.batches = bts.filter(ex => !ex.scheduled_start);
    const all = bts.filter(ex => ex.scheduled_start)
        .sort((a, b) => a.scheduled_start.valueOf() - b.scheduled_start.valueOf());
    for (const a of all) {
      this.batches.push(a);
    }

    let batchesWithTreatmentFinshedTrue = this.batches.filter(ex => ex.treatment);
    batchesWithTreatmentFinshedTrue = batchesWithTreatmentFinshedTrue.filter(ex => ex.treatment.finished);
    for (const b of batchesWithTreatmentFinshedTrue) {
      const index = this.batches.indexOf(b);
      if (index > -1) {
        this.batches.splice(index, 1);
      }
    }
  }

  public selectBatch(batch: any) {
    if (batch.scheduled_start) {
      if (environment.tenant === EnvironmentTypes.MSS) {
        if (this.userAccess.hasAccessRightsEn(AccessAppAreas.execution, AccessTypes.view)) {
          this.router.navigate(['/mssexecution/' + batch.id]).catch(err => console.log(err));
        }
      }
      else {
        if (this.userAccess.hasAccessRightsEn(AccessAppAreas.execution, AccessTypes.view)) {
          this.router.navigate(['/execution/' + batch.id]).catch(err => console.log(err));
        }
      }
    } else {
      if (this.userAccess.hasAccessRightsEn(AccessAppAreas.batch, AccessTypes.view)) {
        this.dataService.BatchToShow = batch;
        this.router.navigate(['/batches']).catch(err => console.log(err));
      }
    }
  }

  ngOnDestroy() {
    this.intervalSubscriptionForReceivingDataFromAPI.unsubscribe();
  }

}
