export enum ProcessTypes {
    AUFBEREITUNG = 'Aufbereitung',
    ZERKLEINERUNG = 'Zerkleinerung',
    INSTANDHALTUNG = 'Instandhaltung'
}

export enum ProcessTypesAPI {
    PROCESSING = 'processing',
    SHREDDING = 'shredding',
    MAINTENANCE = 'maintenance'
}

