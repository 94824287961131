import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-errorweighning',
  templateUrl: './errorweighning.component.html',
  styleUrls: ['./errorweighning.component.css']
})
export class ErrorWeighningComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<ErrorWeighningComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

}
