import {Component, HostListener, OnInit} from '@angular/core';
import {APIService} from '../../Services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageboxComponent} from '../../Dialogs/messageb/messagebox.component';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {ResourcetypeGroups} from '../../Enums/resourcetype-groups.enum';
import {AffectedRessourcesComponent} from '../../Dialogs/affected-ressources/affected-ressources.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OptionBoxComponent} from '../../Dialogs/option-box/option-box.component';
import {UserAccessService} from '../../Services/user-access.service';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {GlobalFunctionsService} from '../../Services/global-functions';

// VALIDATION: https://angular.io/guide/form-validation


@Component({
    selector: 'app-add-resource-type',
    templateUrl: './resourcetype-view.component.html',
    styleUrls: ['./resourcetype-view.component.scss']
})
export class ResourcetypeViewComponent implements OnInit {
    machines: any;
    workers: any;
    public showNextMachine: boolean;
    public skipMachine = 0;
    public showNextWorker: boolean;
    public skipWorker = 0;
    public searchString = '';
    public searchString2 = '';
    resourceName: string;
    resourceType: any;
    resourceId: string;
    flagEditResource: boolean;
    editFlag: boolean;
    resourceToEdit: any;
    flagResource: boolean;
    private btnDisabled = false;

    public RessourceTypeErrors = [
        {
            code: 'RT-001',
            name: 'Allgemeiner Fehler beim Speichern'
        },
        {
            code: 'RT-002',
            name: 'Ressource mit selben Name bereits vorhanden'
        },
        {
            code: 'RT-003',
            name: 'Name nicht gesetzt'
        },
        {
            code: 'RT-004',
            name: 'Typ nicht gesetzt'
        },
        {
            code: 'RT-005',
            name: 'Standardmenge nicht gesetzt'
        },
        {
            code: 'RT-006',
            name: 'Schrittweite nicht gesetzt'
        },
        {
            code: 'RT-007',
            name: 'Ressource Typ mit dieser ID nicht vorhanden'
        }
    ];

    public resourceTypeForm: UntypedFormGroup;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;

    constructor(public router: Router, private Apiservice: APIService,
                public dialog: MatDialog,  private functionsService: GlobalFunctionsService,
                public userAccess: UserAccessService) {
    }

    @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
        if (this.editFlag === true || this.flagResource === true) {
            event.returnValue = false;
        }
    }

    ngOnInit(): void {
        if (this.Apiservice.versioningFinished === true) {
            this.loadResourceTypes(0, 100);

        } else {
            this.Apiservice.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }
    }

    get name() {
        return this.resourceTypeForm.get('name');
    }

    get costperhour() {
        return this.resourceTypeForm.get('costperhour');
    }

    get costperton() {
        return this.resourceTypeForm.get('costperton');
    }

    get energyConsumption() {
        return this.resourceTypeForm.get('energyConsumption');
    }

    get stepsize() {
        return this.resourceTypeForm.get('stepsize');
    }

    get defaultQuantity() {
        return this.resourceTypeForm.get('defaultQuantity');
    }

    get group() {
        return this.resourceTypeForm.get('group');
    }


    private ApiVersionCheckFinished() {
        if (this.Apiservice.versioningFinished === true) {
            this.loadResourceTypes(0, 100);
        }
    }

    public getCurrentResourceType(): string {
        if (this.resourceToEdit) {
            if (this.resourceToEdit.group === ResourcetypeGroups.MACHINE) {
                return 'MASCHINE';
            } else {
                return 'MITARBEITER';
            }
        }
        return '';
    }

    private loadResourceTypes(skip: number, limit: number) {
        // LOAD MACHINE
        this.loadMachineTypes(skip, limit);
        // LOAD HUMAN
        this.loadHumanTypes(skip, limit);
    }

    private loadMachines(skip, limit) {
        // LOAD MACHINES
        this.loadMachineTypes(skip, limit);
    }

    private loadHumans(skip: number, limit: number) {
        // LOAD HUMANS
        this.loadHumanTypes(skip, limit);
    }

    private loadHumanTypes(skip: number, limit: number) {
        this.Apiservice.getResourceTypes(ResourcetypeGroups.HUMAN, skip, limit).subscribe((data: any) => {
            if (data) {
                this.showNextWorker = data.length === 100;
                this.workers = data.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                if (data.type) {
                    const resourceError = this.RessourceTypeErrors.find(ex => ex.code === data.type);
                    if (resourceError) {
                        const dialogConfig1 = new MatDialogConfig();
                        dialogConfig1.disableClose = true;
                        dialogConfig1.autoFocus = true;
                        dialogConfig1.panelClass = 'dialogStyles';
                        dialogConfig1.data = resourceError.name;
                        this.dialog.open(MessageboxComponent, dialogConfig1);
                    }
                }
            }
        });
    }

    private loadMachineTypes(skip: number, limit: number) {
        this.Apiservice.getResourceTypes(ResourcetypeGroups.MACHINE, skip, limit).subscribe((data: any) => {
            if (data) {
                this.showNextMachine = data.length === 100;
                this.machines = data.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                if (data.type) {
                    const resourceError = this.RessourceTypeErrors.find(ex => ex.code === data.type);
                    if (resourceError) {
                        const dialogConfig1 = new MatDialogConfig();
                        dialogConfig1.disableClose = true;
                        dialogConfig1.autoFocus = true;
                        dialogConfig1.panelClass = 'dialogStyles';
                        dialogConfig1.data = resourceError.name;
                        this.dialog.open(MessageboxComponent, dialogConfig1);
                    }
                }
            }
        });
    }

    public paginateRightMachine() {
        this.skipMachine = this.skipMachine + 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateLeftMachine() {
        this.skipMachine = this.skipMachine - 100;
        this.loadMachines(this.skipMachine, 100);
    }

    public paginateRightWorker() {
        this.skipWorker = this.skipWorker + 100;
        this.loadHumans(this.skipWorker, 100);
    }

    public paginateLeftWorker() {
        this.skipWorker = this.skipWorker - 100;
        this.loadHumans(this.skipWorker, 100);
    }

    choose(item) {
        // GET DETAILS
        if (this.userAccess.hasAccessRightsEn(AccessAppAreas.resource_type, AccessTypes.detail)) {

            this.Apiservice.getRessourceType(item.id).subscribe((resourcetype: any) => {
                if (resourcetype) {
                    this.resourceToEdit = {
                        id: item.id,
                        group: item.group,
                        name: item.name,
                        blueline_type: item.blueline_type,
                        cost_per_hour: resourcetype.cost_per_hour ? resourcetype.cost_per_hour : 0,
                        cost_per_ton: resourcetype.cost_per_ton ? resourcetype.cost_per_ton : 0,
                        power_consumption: resourcetype.power_consumption ? resourcetype.power_consumption : 0,
                        default_quantity: resourcetype.default_quantity,
                        step_size: resourcetype.step_size,
                        bluectrl_sync: resourcetype.bluectrl_sync
                    };
                    this.setFormGroup();
                    this.flagEditResource = true;
                    this.flagResource = false;
                    this.editFlag = false;
                }
            });
        }
    }

    edit() {
        this.Apiservice.getByResourceTypeAffectedResources(this.resourceToEdit.id).subscribe((data: any) => {
            if (data) {
                if (data.length > 0) {

                    // SOME AFFECTED
                    const bts = data;

                    // for (const b of bts) {
                    //  b.schedule = moment(b.schedule);
                    // }

                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus = true;
                    dialogConfig.panelClass = 'dialogStyles';
                    dialogConfig.data = bts;
                    const dialogRef = this.dialog.open(AffectedRessourcesComponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(datax => {
                        if (datax === true) {
                            this.editFlag = true;
                        }
                    });

                } else {
                    this.editFlag = true;
                }
            } else {
                this.editFlag = true;
            }
        });
    }

    public setFormGroup() {
        this.resourceTypeForm = new UntypedFormGroup({
            name: new UntypedFormControl(this.resourceToEdit.name, [
                Validators.required,
                Validators.minLength(3)
            ]),
            costperhour: new UntypedFormControl(this.resourceToEdit.cost_per_hour, [
                Validators.required,
                Validators.min(0),
                Validators.max(2000)
            ]),
            costperton: new UntypedFormControl(this.resourceToEdit.cost_per_ton, [
                Validators.required,
                Validators.min(0),
                Validators.max(2000)
            ]),
            energyConsumption: new UntypedFormControl(this.resourceToEdit.power_consumption, [
                Validators.required,
                Validators.min(0),
                Validators.max(2000)
            ]),
            defaultQuantity: new UntypedFormControl(this.resourceToEdit.default_quantity, [
                Validators.required,
                Validators.min(0.1),
                Validators.max(5)
            ]),
            stepsize: new UntypedFormControl(this.resourceToEdit.step_size, [
                Validators.required,
                Validators.min(0.1),
                Validators.max(5)
            ]),
            group: new UntypedFormControl(this.resourceToEdit.group, [
                Validators.required,
            ]),

        });
    }

    public initializeResource(group: ResourcetypeGroups) {
        this.resourceName = '';
        this.resourceType = undefined;
        this.resourceId = '';
        this.resourceToEdit = {};
        this.resourceToEdit.group = group;
        this.resourceToEdit.name = '';
        this.resourceToEdit.cost_per_hour = 0;
        this.resourceToEdit.cost_per_ton = 0;
        this.resourceToEdit.power_consumption = 0;
        this.resourceToEdit.default_quantity = 0;
        this.resourceToEdit.step_size = 0;
        this.setFormGroup();
        this.flagResource = true;
        this.flagEditResource = false;
    }

    addResourceTypMachine() {
        this.initializeResource(ResourcetypeGroups.MACHINE);
    }

    addResourceTypWorker() {
        this.initializeResource(ResourcetypeGroups.HUMAN);
    }

    saveResource() {
        if (!this.btnDisabled) {
            if (this.resourceTypeForm.valid) {
                this.btnDisabled = true;
                const resourceTypeNew = {
                    name: this.name.value,
                    group: this.group.value,
                    default_quantity: this.defaultQuantity.value,
                    step_size: this.stepsize.value,
                    cost_per_hour: this.costperhour.value,
                    cost_per_ton: this.costperton.value,
                    power_consumption: this.energyConsumption.value
                };

                this.Apiservice.AddNewRessouceType(resourceTypeNew).subscribe((data: any) => {
                    if (data) {
                        if (data.id) {
                            // Everything is fine
                            this.flagResource = false;
                            this.flagEditResource = false;
                            this.btnDisabled = false;
                            this.resourceToEdit = {};
                            // UPDATE
                            this.loadResourceTypes(0, 100);

                        } else {
                            const error = this.RessourceTypeErrors.find(ex => ex.code === data.type);
                            this.functionsService.handleAPIsaveError(error, 'Ressource');
                        }
                    }

                });
            } else {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'dialogStyles';
                dialogConfig.data = 'Bitte Felder korrekt ausfüllen';
                this.dialog.open(MessageboxComponent, dialogConfig);
            }
        }


    }

    updateResource() {
        if (this.resourceTypeForm.valid) {
            const resourceTypeNew = {
                name: this.name.value,
                group: this.group.value,
                default_quantity: this.defaultQuantity.value,
                step_size: this.stepsize.value,
                cost_per_hour: this.costperhour.value,
                cost_per_ton: this.costperton.value,
                power_consumption: this.energyConsumption.value,
                blueline_type: this.resourceToEdit.blueline_type,
            };
            const resourcetypeId = this.resourceToEdit.id;
            this.Apiservice.UpdateRessourceType(resourceTypeNew, resourcetypeId).subscribe((data: any) => {
                if (data) {
                    if (data.id) {
                        // Everything is fine
                        this.flagResource = false;
                        this.flagEditResource = false;
                        this.resourceToEdit = {};
                        // UPDATE
                        this.loadResourceTypes(0, 100);

                    } else {
                        const error = this.RessourceTypeErrors.find(ex => ex.code === data.type);
                        this.functionsService.handleAPIsaveError(error, 'Ressource');
                    }
                }

            });

        } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = 'Bitte Felder korrekt ausfüllen';
            this.dialog.open(MessageboxComponent, dialogConfig);
        }

    }

    public delete() {
        // DELETE
        if (this.resourceToEdit) {
            this.Apiservice.getByResourceTypeAffectedResources(this.resourceToEdit.id).subscribe((data: any) => {
                if (data) {
                    if (data.length > 0) {

                        // SOME AFFECTED
                        const bts = data;

                        for (const b of bts) {
                            b.schedule = moment(b.schedule);
                        }

                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = bts;
                        const dialogRef = this.dialog.open(AffectedRessourcesComponent, dialogConfig);
                        dialogRef.afterClosed().subscribe(datax => {
                            if (datax === true) {
                                this.Apiservice.DeleteResourceType(this.resourceToEdit.id).subscribe((dataD: any) => {
                                    if (dataD) {
                                        if (dataD.id) {
                                            const dialogConfig1 = new MatDialogConfig();
                                            dialogConfig1.disableClose = true;
                                            dialogConfig1.autoFocus = true;
                                            dialogConfig1.panelClass = 'dialogStyles';
                                            dialogConfig1.data = 'Ressourcentyp wurde gelöscht';
                                            const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                                            dialogRef2.afterClosed().subscribe(result => {
                                                if (result === true) {
                                                    // NAVIGATE BACK TO BATCHES
                                                    this.flagResource = false;
                                                    this.flagEditResource = false;
                                                    this.resourceToEdit = {};
                                                    this.loadResourceTypes(0, 100);
                                                }
                                            });
                                        } else {
                                            const error = this.RessourceTypeErrors.find(ex => ex.code === dataD.errorcode);
                                            this.functionsService.handleAPIsaveError(error, 'Ressource');
                                        }
                                    }
                                });
                            }
                        });

                    } else {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true;
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'dialogStyles';
                        dialogConfig.data = {
                            txt: 'Wollen Sie wirklich Ressourcentyp löschen?',
                            option1: 'Ja',
                            option2: 'Nein'
                        };
                        const dialogRef = this.dialog.open(OptionBoxComponent, dialogConfig);
                        dialogRef.afterClosed().subscribe(result => {
                            if (result === 1) {
                                this.Apiservice.DeleteResourceType(this.resourceToEdit.id).subscribe((dataD: any) => {
                                    if (dataD) {
                                        if (dataD.id) {
                                            const dialogConfig1 = new MatDialogConfig();
                                            dialogConfig1.disableClose = true;
                                            dialogConfig1.autoFocus = true;
                                            dialogConfig1.panelClass = 'dialogStyles';
                                            dialogConfig1.data = 'Ressourcentyp wurde gelöscht';
                                            const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                                            dialogRef2.afterClosed().subscribe(res => {
                                                if (res === true) {
                                                    // NAVIGATE BACK TO BATCHES
                                                    this.flagResource = false;
                                                    this.flagEditResource = false;
                                                    this.resourceToEdit = {};
                                                    this.loadResourceTypes(0, 100);
                                                }
                                            });
                                        } else {
                                            const error = this.RessourceTypeErrors.find(ex => ex.code === dataD.errorcode);
                                            this.functionsService.handleAPIsaveError(error, 'Ressource');
                                        }
                                    }
                                });
                            } else if (result === 2) {
                                console.log('not deleted');
                            }
                        });
                    }
                } else {
                    this.Apiservice.DeleteResourceType(this.resourceToEdit.id).subscribe((dataD: any) => {
                        if (dataD) {
                            if (dataD.id) {
                                const dialogConfig1 = new MatDialogConfig();
                                dialogConfig1.disableClose = true;
                                dialogConfig1.autoFocus = true;
                                dialogConfig1.panelClass = 'dialogStyles';
                                dialogConfig1.data = 'Ressourcentyp wurde gelöscht';
                                const dialogRef2 = this.dialog.open(MessageboxComponent, dialogConfig1);
                                dialogRef2.afterClosed().subscribe(result => {
                                    if (result === true) {
                                        // NAVIGATE BACK TO BATCHES
                                        this.flagResource = false;
                                        this.flagEditResource = false;
                                        this.resourceToEdit = {};
                                        this.loadResourceTypes(0, 100);
                                    }
                                });
                            } else {
                                const error = this.RessourceTypeErrors.find(ex => ex.code === dataD.errorcode);
                                this.functionsService.handleAPIsaveError(error, 'Ressource');
                            }
                        }
                    });
                }
            });
        }
    }

    public cancelNew() {
        this.flagResource = false;
        this.flagEditResource = false;
        this.resourceToEdit = {};
    }

    public cancelEdit() {
        this.flagResource = false;
        this.editFlag = false;
    }

}

