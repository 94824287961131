import {Component, Inject, OnInit} from '@angular/core';
import {APIService} from '../../Services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataShareService} from '../../Services/data-share.service';

@Component({
  selector: 'app-mover-selection',
  templateUrl: './mover-selection.component.html',
  styleUrls: ['./mover-selection.component.scss']
})
export class MoverSelectionComponent implements OnInit {

  messageID: string;
  public Mover: any;
  public scaleType: string;
  public selectedContainer: any;

  constructor(private Apiservice: APIService, public DialogRef: MatDialogRef<MoverSelectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string, public dataService: DataShareService) {
    if (data) {
      this.scaleType = data as string;
    }

  }

  ngOnInit(): void {
    this.receiveMover();
    this.selectedContainer = this.dataService.selectedContainer;
  }

  public Confirm(tara) {
    this.dataService.chooseMover(tara);
    this.DialogRef.close(true);
  }

  public Close() {
    this.dataService.chooseMover(null);
    this.DialogRef.close(false);
  }


  receiveMover() {
    this.messageID = '1';
    this.Apiservice.getAvailableMover().subscribe((mover: any) => {
      if (mover) {
        if (mover.length > 0) {
          this.Mover = mover.filter(ex => ex.scale_type === this.scaleType);
          this.Mover = this.Mover.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
        }
      }
    });


    // return this.Apiservice.getTara(this.messageID);
  }

}
