
<!--used for printing-->
<ng-container *ngIf="Batch && userAccess.hasAccessRightsEn(accesAreas.calculation, accessTyps.view)">
    <div class="contentWrapperForPrinting">
        <div class="contentWrapperInnerForPrinting">
            <div class="overviewer" fxLayout="column">
                <div class="CalculationView">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Charge</p>
                    </div>
                    <div fxLayout="row">

                        <table class="batchdetailtable">
                            <tr class="productcalcentry">
                                <td class="batchheader">Name</td>
                                <td>{{Batch.name}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Prozesstyp</td>
                                <td>{{Batch.process.process_category_to_show}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Realer Startzeitpunkt</td>
                                <td *ngIf="Batch.executions.length > 0">{{getStartTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Abgeschlossen</td>
                                <td *ngIf="Batch.executions.length > 0">{{getEndTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</td>
                            </tr>
                            <tr style="vertical-align: baseline;">
                                <td class="batchheader">Kommentar</td>
                                <td>
                                    {{commentText}}
                                </td>
                            </tr>

                        </table>

                        <table *ngIf="Batch.execution_times" class="timetable">
                            <tr>
                                <th class="tableheaderL"></th>
                                <th class="timeelement">Soll</th>
                                <th class="timeelement">Ist</th>
                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Rüsten</td>
                                <td class="timeelement">{{Batch.setup_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.setup; else setupElse"
                                    class="timeelement">{{Batch.execution_times.setup / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #setupElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Aufbereiten</td>
                                <td class="timeelement">{{Batch.processing_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.teardown; else processingElse"
                                    class="timeelement">{{Batch.execution_times.processing / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #processingElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Nachbearbeiten</td>
                                <td class="timeelement">{{Batch.teardown_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.teardown; else teardownElse"
                                    class="timeelement">{{Batch.execution_times.teardown / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #teardownElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Störung</td>
                                <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.malfunction; else malfunctionElse"
                                    class="timeelement">{{Batch.execution_times.malfunction / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #malfunctionElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Instandhaltung</td>
                                <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.maintenance; else maintenanceElse"
                                    class="timeelement">{{Batch.execution_times.maintenance / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #maintenanceElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Pausen</td>
                                <td class="timeelement"></td>
                                <td *ngIf="Batch.execution_times.pause; else pauseElse"
                                    class="timeelement">{{Batch.execution_times.pause / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #pauseElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td>
                                    <hr>
                                </td>
                                <td>
                                    <hr>
                                </td>
                                <td>
                                    <hr>
                                </td>
                            </tr>

                            <tr class="productcalcentry">
                                <td class="batchheader">Durchlaufzeit</td>
                                <td class="timeelement">{{Batch.lead_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_time; else exElse"
                                    class="timeelement">{{Batch.execution_time / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #exElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>


                        </table>
                    </div>
                </div>
                <div *ngIf="Batch.process.process_category !== 'maintenance'" class="CalculationView" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Aufgabe</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL">Artikel</th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter">Preis</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr class="productcalcentry">
                            <ng-container *ngIf="tenant !==  typeEnvironmentMss; else mssBlock">
                                <td *ngIf="Batch.transfer" class="entryCalcProdl">
                                    {{Batch.input.id}} {{Batch.input.name}} (Charge: {{Batch.transfer.erp_batch}})
                                </td>
                                <td *ngIf="!Batch.transfer" class="entryCalcProdl">
                                    {{Batch.input.id}} {{Batch.input.name}}
                                </td>
                            </ng-container>
                            <ng-template #mssBlock>
                                <td *ngIf="Batch.transfer" class="entryCalcProdl">
                                    (Charge: {{Batch.transfer.erp_batch}})
                                </td>
                                <td *ngIf="!Batch.transfer" class="entryCalcProdl">
                                    Aufgabematerial
                                </td>
                            </ng-template>

                            <td *ngIf="!userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.edit) || Batch.booked; else editable"
                                class="entryCalcProd justify-center">{{Batch.quantity | number: '1.3-3':'de'}} t
                            </td>
                            <ng-template #editable>
                                <td class="entryCalcProd">
                                    <mat-form-field class="inputInputClass">
                                        <input [(ngModel)]="batchQuantity" class="quantityInput" matInput
                                               type="number" value="{{batchQuantity}}"> t
                                    </mat-form-field>
                                </td>
                            </ng-template>
                            <td style="text-align: center;">
                                <button (click)="saveNewQuantity()" *ngIf="batchQuantity !== Batch.quantity"
                                        class="actionButton5">Speichern
                                </button>
                            </td>
                            <td class="entryCalcProd">
                                <mat-form-field class="preisInputClass">
                                    <input (keyup)="onInput($event.target, $event)" [(ngModel)]="inputPrice"
                                           class="quantityInput" matInput
                                           type="number"
                                           value="{{inputPrice}}"> €/t
                                </mat-form-field>

                            </td>
                            <td>

                            </td>
                            <td class="entryCalcProdr">{{getTotalInput() | number: '1.2-2':'de'}} €</td>
                        </tr>

                    </table>
                </div  >
                <div *ngIf="Batch.process.process_category !== 'maintenance'" class="CalculationView" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Produkt</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL">Artikel</th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter">Anteil</th>
                            <th class="tableheaderCenter">Preis</th>
                            <th class="tableheaderCenter">Kosten</th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr *ngFor="let outputproduct of productCalculation" class="productcalcentry">
                            <td class="entryCalcProdl">{{outputproduct.id}} {{outputproduct.name}} {{getOutputSubstitution(Batch, outputproduct.id)}}</td>
                            <td class="entryCalcProd">{{outputproduct.weight | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd">{{outputproduct.weight / Batch.quantity | percent }}</td>
                            <td class="entryCalcProd">
                                <mat-form-field class="preisInputClass">
                                    <input (keyup)="onOutput($event.target, outputproduct, $event)"
                                           [(ngModel)]="outputproduct.price" class="quantityInput"
                                           matInput type="number"
                                           value="{{outputproduct.price}}"> €/t
                                </mat-form-field>

                            </td>
                            <td class="entryCalcProd">
                                <ng-container *ngIf="outputproduct.weight !== 0; else nullBlock">
                                    {{getCostsProTonne()*outputproduct.weight / Batch.quantity | number: '1.2-2':'de'}} €/t
                                </ng-container>
                                <ng-template #nullBlock>
                                    0 €/t
                                </ng-template>
                            </td>



                            <td class="entryCalcProdr">{{outputproduct.weight * outputproduct.price | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>

                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL">Total</td>
                            <td class="entryCalcProdTotal">{{getProductTotalQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProdTotal">{{getProductTotalQuantity() / (getProductTotalQuantity() + getDeltaQuantity()) | percent}}</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{productOutputTotal | number: '1.2-2':'de'}} €</td>
                        </tr>

                    </table>
                </div>
                <div class="CalculationView pageBreakBeforeAlways" fxLayout="column">
                    <div class="calHeader" fxLayout="row" *ngIf="Batch.process.process_category !== 'maintenance'; else maintenanceBlock">
                        <p class="viewheader">Aufbereitungskosten</p>
                        <div class="energyInput">
                            <p class="centerText"> €/kWh</p>
                            <mat-form-field class="processingCostInput">
                                <input (keyup)="onResource($event.target, $event)" [(ngModel)]="energyPrice"
                                       class="quantityInput" matInput
                                       pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                                       type="number" value="{{energyPrice}}">
                            </mat-form-field>

                        </div>
                    </div>
                    <ng-template #maintenanceBlock>
                        <div class="calHeader" fxLayout="row">
                            <p class="viewheader">Instandhaltungskosten</p>
                            <div class="energyInput">
                                <p class="centerText"> €/kWh</p>
                                <mat-form-field class="processingCostInput">
                                    <input (keyup)="onResource($event.target, $event)" [(ngModel)]="energyPrice"
                                           class="quantityInput" matInput
                                           pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                                           type="number" value="{{energyPrice}}">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                    <table class="marginLeft">
                        <ng-container *ngIf="isWorkersInRessources()">
                            <tr>
                                <th class="tableheaderL">Mitarbeiter</th>
                                <th class="tableheaderCenterTime">Zeit</th>
                                <th class="tableheaderCenterVerschleis">

                                </th>
                                <th class="tableheaderCenterVerbrauch">
                                    Kosten
                                </th>
                                <th class="tableheaderR">Gesamt</th>
                            </tr>
                            <tr *ngFor="let res of humansResources" class="productcalcentry">
                                <td class="entryCalcProdl">{{res.name}}</td>
                                <td class="entryCalcProd">{{res.TimeString}}</td>
                                <td class="entryCalcProd"></td>
                                <td class="entryCalcProd">
                                    <div class="energyFlexWrap paddingForEuroValue">
                                        <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                        {{res.totalValue | number: '1.2-2':'de'}} €
                                    </div>
                                </td>
                                <td class="entryCalcProdr">{{res.totalValue | number: '1.2-2':'de'}} €</td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="isMachinesInRessources()">
                            <tr>
                                <th class="tableheaderL">Maschine</th>
                                <th class="tableheaderCenterTime">Zeit</th>
                                <th class="tableheaderCenterVerschleis">
                                    Direkte Kosten
                                </th>
                                <th class="tableheaderCenterVerbrauch">
                                    Verbrauch
                                </th>
                                <th class="tableheaderR">Gesamt</th>
                            </tr>
                            <tr *ngFor="let res of machinesResources" class="productcalcentry">
                                <td class="entryCalcProdl">{{res.name}}</td>
                                <td class="entryCalcProd">{{res.TimeString}}</td>
                                <td class="entryCalcProd">{{getMachineWear(res)| number: '1.2-2':'de'}} €</td>
                                <td class="entryCalcProd energyCostCell" *ngIf="res.energy_consumption_measured; else standardCell">
                                    <div class="energyFlexWrap">
                                        <div class="toggleButtonEnergy"
                                             *ngIf="res.energy_consumption_measured">
                                        </div>
                                        <div class="valueWrapper">
                                            <ng-container
                                                    *ngIf="res.energy_consumption_measured && res.checkedButtonEnergy; else notmeasured">
                                                {{res.energy_consumption | number: '1.2-2':'de'}} kWh
                                            </ng-container>
                                            <ng-template #notmeasured>
                                                {{res.power_consumption | number: '1.2-2':'de'}} kWh
                                            </ng-template>
                                        </div>
                                    </div>
                                </td >
                                <ng-template #standardCell>
                                    <td class="entryCalcProd">
                                        <div class="energyFlexWrap">
                                            <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                            <ng-container *ngIf="res.power_consumption !== null; else nullDiv"> {{res.power_consumption | number: '1.2-2':'de'}} kWh</ng-container>
                                            <ng-template #nullDiv>
                                                0,00 kWh
                                            </ng-template>
                                        </div>
                                    </td>
                                </ng-template>
                                <td class="entryCalcProdr">{{getTotalMachineRes(res) | number: '1.2-2':'de'}} €</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <th class="tableheaderL">Zusatz</th>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                <mat-form-field [style.width.px]="200">
                                    <mat-label>Beschreibung</mat-label>
                                    <input (focusout)="onFocusOutEvent()" [(ngModel)]="additionalCostsName"
                                           [disabled]="!enableAdditionalCostsInput" matInput
                                           type="text">
                                </mat-form-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="text-align: right;">
                                <mat-form-field [style.width.px]="75" style="margin-left: 10px">
                                    <input (focusout)="onFocusOutEvent()" (keyup)="onkeyUp($event)"
                                           [(ngModel)]="additionalCosts" [disabled]="!enableAdditionalCostsInput"
                                           [style.width.px]="65" matInput
                                           style="text-align: right; padding-right: 5px"
                                           type="number" value="{{additionalCosts}}"> <ng-container>€</ng-container>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableheaderL">Gemeinkosten </th>
                            <th class="tableheaderCenterTime"></th>
                            <th class="tableheaderCenterVerschleis">

                            </th>
                            <th class="tableheaderCenterVerbrauch">
                                Wert
                            </th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                AfA
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap paddingForEuroValue">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>

                                </div>
                            </td>
                            <td style="text-align: right;">
                                {{getDepreciationCosts()| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                Mieten/Zinsen
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap paddingForEuroValue">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>

                                </div>
                            </td>
                            <td style="text-align: right;">
                                {{getRentCosts()| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                Strom
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                    {{getTotalMachinesPowerConsumption() | number: '1.2-2':'de'}} kWh
                                </div>
                            </td>
                            <td style="text-align: right;"  *ngIf="overheadCosts">
                                {{getTotalValueOfMachinesOverheadCosts() | number: '1.2-2':'de'}} €
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL">Total</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{getTotalValueOfResWithAdditionalCostsAndOverheadCosts() | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>

                    </table>


                </div>

                <div class="CalculationView pageBreakBeforeAlways" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Übersicht</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL"></th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR">Gesamt</th>

                        </tr>
                        <tr *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdl" ><strong>AUFGABE</strong></td>
                            <td class="entryCalcProd">{{batchQuantity | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                <ng-container *ngIf="getTotalInput()<0; else Elseblock">+</ng-container>
                                <ng-template #Elseblock>
                                    -
                                </ng-template>
                                <ng-container *ngIf="getTotalInput()<0; else ElseInput">
                                    {{(getTotalInput()) * -1 | number: '1.2-2':'de'}} €
                                </ng-container>
                                <ng-template #ElseInput>
                                    {{(getTotalInput())  | number: '1.2-2':'de'}} €
                                </ng-template>
                            </td>
                        </tr>

                        <tr *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdl"><strong>PRODUKT</strong></td>
                            <td class="entryCalcProd">{{getProductTotalQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                <ng-container *ngIf="productOutputTotal<0; else Else">-</ng-container>
                                <ng-template #Else>
                                    +
                                </ng-template>
                                <ng-container *ngIf="productOutputTotal<0; else ElseOutput">
                                    {{(productOutputTotal) * -1 | number: '1.2-2':'de'}} €
                                </ng-container>
                                <ng-template #ElseOutput>
                                    {{productOutputTotal  | number: '1.2-2':'de'}} €
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl"><strong>AUFBEREITUNGSKOSTEN</strong></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                - {{(getTotalValueOfResWithAdditionalCostsAndOverheadCosts())| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>

                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL italic">ERGEBNIS</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{((getTotalValueOfResWithAdditionalCostsAndOverheadCosts() * -1.0) + productOutputTotal - getTotalInput()) | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>
                        <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdTotalL italic">Aufbereitungskosten pro Tonne</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td *ngIf="getProductTotalQuantity() > 0"
                                class="entryCalcProdTotalR">{{getCostsProTonne() | number: '1.2-2':'de'}}
                                €/t
                            </td>

                        </tr>
                        <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdTotalL italic">Ergebnis pro Tonne</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td *ngIf="getProductTotalQuantity() > 0"
                                class="entryCalcProdTotalR">{{((productOutputTotal - getTotalInput()) - getTotalValueOfResWithAdditionalCostsAndOverheadCosts()) / getProductTotalQuantity()  | number: '1.2-2':'de'}}
                                €/t
                            </td>
                        </tr>

                    </table>
                    <table class="blueRow" *ngIf="Batch.process.process_category !== 'maintenance'">
                        <tr>
                            <th class="tableheaderL"></th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter">Anteil</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR"></th>
                        </tr>
                        <tr class="productcalcentry ">
                            <td class="entryCalcProdTotalL">Schwund</td>
                            <td class="entryCalcProdTotal">{{getDeltaQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProdTotal">{{getDeltaQuantity() / (getProductTotalQuantity() + getDeltaQuantity()) | percent}}</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
        <div class="weighingsWrapForPrinting" *ngIf="Batch.process.process_category !== 'maintenance'">
            <div class="overviewer" fxLayout="column">
                <div class="CalculationViewForPrinting">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Verwiegung</p>
                    </div>
                    <div fxLayout="row">
                        <table *ngIf="invoicesList" [dataSource]="invoicesList" class="InvoicesTable"
                               fxFlexAlign="stretch"
                               mat-table
                               matSort>
                            <ng-container matColumnDef="Zeit">
                                <th *matHeaderCellDef class="thZeit" mat-header-cell> Zeit</th>
                                <td *matCellDef="let element" mat-cell>
                                    {{element.time | date: dateFormat}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Material">
                                <th *matHeaderCellDef class="thMaterial" mat-header-cell> Material</th>
                                <td *matCellDef="let element" class="tdMaterial" mat-cell>
                                    {{element.material.name}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Fahrzeug Name">
                                <th *matHeaderCellDef class="thMenge" mat-header-cell> Fahrzeug</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.mover; else noMover" class="tdMenge"
                                        mat-cell> {{element.mover.name}} </td>
                                </ng-container>
                                <ng-template #noMover>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Fahrzeug Tara">
                                <th *matHeaderCellDef class="thinChargen" mat-header-cell> Fahrzeug <br>Tara [t]</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.mover; else noMoverTara" class="tdMenge"
                                        mat-cell> {{element.tare_weight_mover| number: '1.0-3':'de'}} </td>
                                </ng-container>
                                <ng-template #noMoverTara>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Container Name">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Container</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.container; else noReplies" class="tdMenge"
                                        mat-cell> {{element.container.name}} </td>
                                </ng-container>
                                <ng-template #noReplies>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Container Tara">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Container <br>Tara [t]</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.container; else noContainerTara" class="tdMenge"
                                        mat-cell> {{element.tare_weight_container| number: '1.0-3':'de'}} </td>
                                </ng-container>
                                <ng-template #noContainerTara>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Waage">
                                <th *matHeaderCellDef class="thScale" mat-header-cell>Waage</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.scale; else noScale" class="tdMenge"
                                        mat-cell> {{element.scale.name}} </td>
                                </ng-container>
                                <ng-template #noScale>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Brutto">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Brutto <br> [t]</th>
                                <td *matCellDef="let element" class="tdMenge" mat-cell>
                                    {{getBruttoWeight(element.net_weight, element.tare_weight_mover, element.tare_weight_container)| number: '1.3-3':'de'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Netto">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Netto <br> [t]</th>
                                <td *matCellDef="let element" class="tdMenge" mat-cell>
                                    {{element.net_weight| number: '1.3-3':'de'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Username">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Benutzer</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.username; else noUser" class="tdMenge"
                                        mat-cell> {{element.username}} </td>
                                </ng-container>
                                <ng-template #noUser>
                                    <td>-</td>
                                </ng-template>

                            </ng-container>
                            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-container>
<!--used for printing-->

<section *ngIf="Batch && userAccess.hasAccessRightsEn(accesAreas.calculation, accessTyps.view)" fxLayout="column" class="noPrinting">
    <div class="headerline">
        <p class="viewheader">Aufbereitung buchen</p>
        <button (click)="goBack()" class="actionButton4">Zurück</button>
        <button (click)="bookTreatment()"
                *ngIf="!Batch.booked && userAccess.hasAccessRightsEn(accesAreas.calculation, accessTyps.edit)"
                class="actionButton4">
            Abschließen
        </button>
    </div>
    <hr class="hrclass">

    <div class="contentWrapper">
        <div class="contentWrapperInner">
            <div class="overviewer" fxLayout="column">
                <div class="CalculationView">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Charge</p>
                    </div>
                    <div fxLayout="row">

                        <table class="batchdetailtable">
                            <tr class="productcalcentry">
                                <td class="batchheader">Name</td>
                                <td>{{Batch.name}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Prozesstyp</td>
                                <td>{{Batch.process.process_category_to_show}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Realer Startzeitpunkt</td>
                                <td *ngIf="Batch.executions.length > 0">{{getStartTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</td>
                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Abgeschlossen</td>
                                <td *ngIf="Batch.executions.length > 0">{{getEndTimeOfBatch() | moment:'dddd DD. MMMM YYYY HH:mm'}}</td>
                            </tr>
                            <tr style="vertical-align: baseline;">
                                <td class="batchheader">Kommentar</td>
                                <td>
                                    {{commentText}}
                                </td>
                            </tr>

                        </table>

                        <table *ngIf="Batch.execution_times" class="timetable">
                            <tr>
                                <th class="tableheaderL"></th>
                                <th class="timeelement">Soll</th>
                                <th class="timeelement">Ist</th>
                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Rüsten</td>
                                <td class="timeelement">{{Batch.setup_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.setup; else setupElse"
                                    class="timeelement">{{Batch.execution_times.setup / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #setupElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Aufbereiten</td>
                                <td class="timeelement">{{Batch.processing_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.teardown; else processingElse"
                                    class="timeelement">{{Batch.execution_times.processing / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #processingElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                                <td class="batchheader">Nachbearbeiten</td>
                                <td class="timeelement">{{Batch.teardown_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.teardown; else teardownElse"
                                    class="timeelement">{{Batch.execution_times.teardown / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #teardownElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Störung</td>
                                <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.malfunction; else malfunctionElse"
                                    class="timeelement">{{Batch.execution_times.malfunction / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #malfunctionElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Instandhaltung</td>
                                <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_times.maintenance; else maintenanceElse"
                                    class="timeelement">{{Batch.execution_times.maintenance / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #maintenanceElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td class="batchheader">Pausen</td>
                                <td class="timeelement"></td>
                                <td *ngIf="Batch.execution_times.pause; else pauseElse"
                                    class="timeelement">{{Batch.execution_times.pause / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #pauseElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>
                            <tr class="productcalcentry">
                                <td>
                                    <hr>
                                </td>
                                <td>
                                    <hr>
                                </td>
                                <td>
                                    <hr>
                                </td>
                            </tr>

                            <tr class="productcalcentry">
                                <td class="batchheader">Durchlaufzeit</td>
                                <td class="timeelement">{{Batch.lead_time | number: '1.2-2':'de'}} h</td>
                                <td *ngIf="Batch.execution_time; else exElse"
                                    class="timeelement">{{Batch.execution_time / 3600 | number: '1.2-2':'de'}}
                                    h
                                </td>
                                <ng-template #exElse>
                                    <td class="timeelement">{{0.00 | number: '1.2-2':'de'}} h</td>
                                </ng-template>

                            </tr>


                        </table>
                    </div>
                </div>
                <div *ngIf="Batch.process.process_category !== 'maintenance'" class="CalculationView" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Aufgabe</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL">Artikel</th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter">Preis</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr class="productcalcentry">
                            <ng-container *ngIf="tenant !==  typeEnvironmentMss; else mssBlock">
                                <td *ngIf="Batch.transfer" class="entryCalcProdl">
                                    {{Batch.input.id}} {{Batch.input.name}} (Charge: {{Batch.transfer.erp_batch}})
                                </td>
                                <td *ngIf="!Batch.transfer" class="entryCalcProdl">
                                    {{Batch.input.id}} {{Batch.input.name}}
                                </td>
                            </ng-container>
                            <ng-template #mssBlock>
                                <td *ngIf="Batch.transfer" class="entryCalcProdl">
                                    (Charge: {{Batch.transfer.erp_batch}})
                                </td>
                                <td *ngIf="!Batch.transfer" class="entryCalcProdl">
                                    Aufgabematerial
                                </td>
                            </ng-template>

                            <td *ngIf="!userAccess.hasAccessRightsEn(accesAreas.batch, accessTyps.edit) || Batch.booked; else editable"
                                class="entryCalcProd justify-center">{{Batch.quantity | number: '1.3-3':'de'}} t
                            </td>
                            <ng-template #editable>
                                <td class="entryCalcProd">
                                    <mat-form-field class="inputInputClass">
                                        <input [(ngModel)]="batchQuantity" class="quantityInput" matInput
                                               type="number" value="{{batchQuantity}}"> t
                                    </mat-form-field>
                                </td>
                            </ng-template>
                            <td style="text-align: center;">
                                <button (click)="saveNewQuantity()" *ngIf="batchQuantity !== Batch.quantity"
                                        class="actionButton5">Speichern
                                </button>
                            </td>
                            <td class="entryCalcProd">
                                <mat-form-field class="preisInputClass">
                                    <input (keyup)="onInput($event.target, $event)" [(ngModel)]="inputPrice"
                                           class="quantityInput" matInput
                                           type="number"
                                           value="{{inputPrice}}"> €/t
                                </mat-form-field>

                            </td>
                            <td>

                            </td>
                            <td class="entryCalcProdr">{{getTotalInput() | number: '1.2-2':'de'}} €</td>
                        </tr>

                    </table>
                </div  >
                <div *ngIf="Batch.process.process_category !== 'maintenance'" class="CalculationView" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Produkt</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL">Artikel</th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter">Anteil</th>
                            <th class="tableheaderCenter">Preis</th>
                            <th class="tableheaderCenter">Kosten</th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr *ngFor="let outputproduct of productCalculation" class="productcalcentry">
                            <td class="entryCalcProdl">{{outputproduct.id}} {{outputproduct.name}} {{getOutputSubstitution(Batch, outputproduct.id)}}</td>
                            <td class="entryCalcProd">{{outputproduct.weight | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd">{{outputproduct.weight / Batch.quantity | percent }}</td>
                            <td class="entryCalcProd">
                                <mat-form-field class="preisInputClass">
                                    <input (keyup)="onOutput($event.target, outputproduct, $event)"
                                           [(ngModel)]="outputproduct.price" class="quantityInput"
                                           matInput type="number"
                                           value="{{outputproduct.price}}"> €/t
                                </mat-form-field>

                            </td>
                            <td class="entryCalcProd">
                                <ng-container *ngIf="outputproduct.weight !== 0; else nullBlock">
                                    {{getCostsProTonne()*outputproduct.weight / Batch.quantity | number: '1.2-2':'de'}} €/t
                                </ng-container>
                                <ng-template #nullBlock>
                                    0 €/t
                                </ng-template>
                            </td>



                            <td class="entryCalcProdr">{{outputproduct.weight * outputproduct.price | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>

                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL">Total</td>
                            <td class="entryCalcProdTotal">{{getProductTotalQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProdTotal">{{getProductTotalQuantity() / (getProductTotalQuantity() + getDeltaQuantity()) | percent}}</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{productOutputTotal | number: '1.2-2':'de'}} €</td>
                        </tr>

                    </table>
                </div>
                <div class="CalculationView" fxLayout="column">
                    <div class="calHeader" fxLayout="row" *ngIf="Batch.process.process_category !== 'maintenance'; else maintenanceBlock">
                        <p class="viewheader">Aufbereitungskosten</p>
                        <div class="energyInput">
                            <p class="centerText"> €/kWh</p>
                            <mat-form-field class="processingCostInput">
                                <input (keyup)="onResource($event.target, $event)" [(ngModel)]="energyPrice"
                                       class="quantityInput" matInput
                                       pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                                       type="number" value="{{energyPrice}}">
                            </mat-form-field>

                        </div>
                    </div>
                    <ng-template #maintenanceBlock>
                        <div class="calHeader" fxLayout="row">
                            <p class="viewheader">Instandhaltungskosten</p>
                            <div class="energyInput">
                                <p class="centerText"> €/kWh</p>
                                <mat-form-field class="processingCostInput">
                                    <input (keyup)="onResource($event.target, $event)" [(ngModel)]="energyPrice"
                                           class="quantityInput" matInput
                                           pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                                           type="number" value="{{energyPrice}}">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                    <table class="marginLeft">
                        <ng-container *ngIf="isWorkersInRessources()">
                            <tr>
                                <th class="tableheaderL">Mitarbeiter</th>
                                <th class="tableheaderCenterTime">Zeit</th>
                                <th class="tableheaderCenterVerschleis">
                                </th>
                                <th class="tableheaderCenterVerbrauch">
                                    Kosten
                                </th>
                                <th class="tableheaderR">Gesamt</th>
                            </tr>
                            <tr *ngFor="let res of humansResources" class="productcalcentry">
                                <td class="entryCalcProdl">{{res.name}}</td>
                                <td class="entryCalcProd">{{res.TimeString}}</td>
                                <td class="entryCalcProd"></td>
                                <td class="entryCalcProd">
                                    <div class="energyFlexWrap paddingForEuroValue">
                                        <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                        {{res.totalValue | number: '1.2-2':'de'}} €
                                    </div>
                                </td>
                                <td class="entryCalcProdr">{{res.totalValue | number: '1.2-2':'de'}} €</td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="isMachinesInRessources()">
                            <tr>
                                <th class="tableheaderL">Maschine</th>
                                <th class="tableheaderCenterTime">Zeit</th>
                                <th class="tableheaderCenterVerschleis">
                                    Direkte Kosten
                                </th>
                                <th class="tableheaderCenterVerbrauch">
                                    Verbrauch
                                </th>
                                <th class="tableheaderR">Gesamt</th>
                            </tr>
                            <tr *ngFor="let res of machinesResources" class="productcalcentry">
                                <td class="entryCalcProdl">{{res.name}}</td>
                                <td class="entryCalcProd">{{res.TimeString}}</td>
                                <td class="entryCalcProd">{{getMachineWear(res)| number: '1.2-2':'de'}} €</td>
                                <td class="entryCalcProd energyCostCell" *ngIf="res.energy_consumption_measured; else standardCell">
                                    <div class="energyFlexWrap">
                                        <div class="toggleButtonEnergy"
                                             *ngIf="res.energy_consumption_measured">
                                            <mat-icon  *ngIf="res.checkedButtonEnergy; else emptyDiv" matTooltip="gemessen" class="iconEnergy">electric_bolt
                                            </mat-icon>
                                            <ng-template #emptyDiv>
                                                <mat-icon style="visibility: hidden">electric_bolt
                                                </mat-icon>
                                            </ng-template>
                                            <mat-slide-toggle color="primary"
                                                              [checked]="res.checkedButtonEnergy"
                                                              [(ngModel)]="res.checkedButtonEnergy">
                                            </mat-slide-toggle>
                                        </div>
                                        <div class="valueWrapper">
                                            <ng-container
                                                    *ngIf="res.energy_consumption_measured && res.checkedButtonEnergy; else notmeasured">
                                                {{res.energy_consumption | number: '1.2-2':'de'}} kWh
                                            </ng-container>
                                            <ng-template #notmeasured>
                                                {{res.power_consumption | number: '1.2-2':'de'}} kWh
                                            </ng-template>
                                        </div>

                                    </div>
                                </td >
                                <ng-template #standardCell>
                                    <td class="entryCalcProd">
                                        <div class="energyFlexWrap">
                                            <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                            <ng-container *ngIf="res.power_consumption !== null; else nullDiv"> {{res.power_consumption | number: '1.2-2':'de'}} kWh</ng-container>
                                            <ng-template #nullDiv>
                                                0,00 kWh
                                            </ng-template>
                                        </div>
                                    </td>
                                </ng-template>
                                <td class="entryCalcProdr">{{getTotalMachineRes(res) | number: '1.2-2':'de'}} €</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <th class="tableheaderL">Zusatz</th>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                <mat-form-field [style.width.px]="200">
                                    <mat-label>Beschreibung</mat-label>
                                    <input (focusout)="onFocusOutEvent()" [(ngModel)]="additionalCostsName"
                                           [disabled]="!enableAdditionalCostsInput" matInput
                                           type="text">
                                </mat-form-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="text-align: right;">
                                <mat-form-field [style.width.px]="75" style="margin-left: 10px">
                                    <input (focusout)="onFocusOutEvent()" (keyup)="onkeyUp($event)"
                                           [(ngModel)]="additionalCosts" [disabled]="!enableAdditionalCostsInput"
                                           [style.width.px]="65" matInput
                                           style="text-align: right; padding-right: 5px"
                                           type="number" value="{{additionalCosts}}"> <ng-container>€</ng-container>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableheaderL">Gemeinkosten </th>
                            <th class="tableheaderCenterTime"></th>
                            <th class="tableheaderCenterVerschleis">

                            </th>
                            <th class="tableheaderCenterVerbrauch">
                                Wert
                            </th>
                            <th class="tableheaderR">Gesamt</th>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                AfA
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap paddingForEuroValue">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>

                                </div>
                            </td>
                            <td style="text-align: right;">
                                {{getDepreciationCosts()| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                Mieten/Zinsen
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap paddingForEuroValue">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>

                                </div>
                            </td>
                            <td style="text-align: right;">
                                {{getRentCosts()| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl">
                                Strom
                            </td>
                            <td></td>
                            <td></td>
                            <td class="entryCalcProd">
                                <div class="energyFlexWrap">
                                    <div class="toggleButtonEnergy" style="visibility: hidden"></div>
                                    {{getTotalMachinesPowerConsumption() | number: '1.2-2':'de'}} kWh
                                </div>
                            </td>
                            <td style="text-align: right;"  *ngIf="overheadCosts">
                                {{getTotalValueOfMachinesOverheadCosts() | number: '1.2-2':'de'}} €
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL">Total</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{getTotalValueOfResWithAdditionalCostsAndOverheadCosts() | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>

                    </table>


                </div>

                <div class="CalculationView testClass" fxLayout="column">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Übersicht</p>
                    </div>
                    <table class="marginLeft">
                        <tr>
                            <th class="tableheaderL"></th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR">Gesamt</th>

                        </tr>
                        <tr *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdl" ><strong>AUFGABE</strong></td>
                            <td class="entryCalcProd">{{batchQuantity | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                <ng-container *ngIf="getTotalInput()<0; else Elseblock">+</ng-container>
                                <ng-template #Elseblock>
                                    -
                                </ng-template>
                                <ng-container *ngIf="getTotalInput()<0; else ElseInput">
                                    {{(getTotalInput()) * -1 | number: '1.2-2':'de'}} €
                                </ng-container>
                                <ng-template #ElseInput>
                                    {{(getTotalInput())  | number: '1.2-2':'de'}} €
                                </ng-template>
                            </td>
                        </tr>

                        <tr *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdl"><strong>PRODUKT</strong></td>
                            <td class="entryCalcProd">{{getProductTotalQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                <ng-container *ngIf="productOutputTotal<0; else Else">-</ng-container>
                                <ng-template #Else>
                                    +
                                </ng-template>
                                <ng-container *ngIf="productOutputTotal<0; else ElseOutput">
                                    {{(productOutputTotal) * -1 | number: '1.2-2':'de'}} €
                                </ng-container>
                                <ng-template #ElseOutput>
                                    {{productOutputTotal  | number: '1.2-2':'de'}} €
                                </ng-template>
                            </td>
                        </tr>
                        <tr>
                            <td class="entryCalcProdl"><strong>AUFBEREITUNGSKOSTEN</strong></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProd"></td>
                            <td class="entryCalcProdr">
                                - {{(getTotalValueOfResWithAdditionalCostsAndOverheadCosts())| number: '1.2-2':'de'}} €
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>
                            <td>
                                <div class="totalLineCalc2"></div>
                            </td>

                        </tr>
                        <tr class="productcalcentry">
                            <td class="entryCalcProdTotalL italic">ERGEBNIS</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR">{{((getTotalValueOfResWithAdditionalCostsAndOverheadCosts() * -1.0) + productOutputTotal - getTotalInput()) | number: '1.2-2':'de'}}
                                €
                            </td>
                        </tr>
                        <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdTotalL italic">Aufbereitungskosten pro Tonne</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td *ngIf="getProductTotalQuantity() > 0"
                                class="entryCalcProdTotalR">{{getCostsProTonne() | number: '1.2-2':'de'}}
                                €/t
                            </td>

                        </tr>
                        <tr class="productcalcentry" *ngIf="Batch.process.process_category !== 'maintenance'">
                            <td class="entryCalcProdTotalL italic">Ergebnis pro Tonne</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td *ngIf="getProductTotalQuantity() > 0"
                                class="entryCalcProdTotalR">{{((productOutputTotal - getTotalInput()) - getTotalValueOfResWithAdditionalCostsAndOverheadCosts()) / getProductTotalQuantity()  | number: '1.2-2':'de'}}
                                €/t
                            </td>
                        </tr>

                    </table>
                    <table class="blueRow" *ngIf="Batch.process.process_category !== 'maintenance'">
                        <tr>
                            <th class="tableheaderL"></th>
                            <th class="tableheaderCenter">Menge</th>
                            <th class="tableheaderCenter">Anteil</th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderCenter"></th>
                            <th class="tableheaderR"></th>
                        </tr>
                        <tr class="productcalcentry ">
                            <td class="entryCalcProdTotalL">Schwund</td>
                            <td class="entryCalcProdTotal">{{getDeltaQuantity() | number: '1.3-3':'de'}} t</td>
                            <td class="entryCalcProdTotal">{{getDeltaQuantity() / (getProductTotalQuantity() + getDeltaQuantity()) | percent}}</td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotal"></td>
                            <td class="entryCalcProdTotalR"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
        <div class="weighingsWrap" *ngIf="Batch.process.process_category !== 'maintenance'">
            <div class="overviewer" fxLayout="column">
                <div class="CalculationView">
                    <div class="calHeader" fxLayout="row">
                        <p class="viewheader">Verwiegung</p>
                    </div>
                    <div fxLayout="row">
                        <table *ngIf="invoicesList" [dataSource]="invoicesList" class="InvoicesTable"
                               fxFlexAlign="stretch"
                               mat-table
                               matSort>
                            <ng-container matColumnDef="Zeit">
                                <th *matHeaderCellDef class="thZeit" mat-header-cell> Zeit</th>
                                <td *matCellDef="let element" mat-cell>
                                    {{element.time | date: dateFormat}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Material">
                                <th *matHeaderCellDef class="thMaterial" mat-header-cell> Material</th>
                                <td *matCellDef="let element" class="tdMaterial" mat-cell>
                                    {{element.material.name}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Fahrzeug Name">
                                <th *matHeaderCellDef class="thMenge" mat-header-cell> Fahrzeug</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.mover; else noMover" class="tdMenge"
                                        mat-cell> {{element.mover.name}} </td>
                                </ng-container>
                                <ng-template #noMover>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Fahrzeug Tara">
                                <th *matHeaderCellDef class="thinChargen" mat-header-cell> Fahrzeug <br>Tara [t]</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.mover; else noMoverTara" class="tdMenge"
                                        mat-cell> {{element.tare_weight_mover| number: '1.0-3':'de'}} </td>
                                </ng-container>
                                <ng-template #noMoverTara>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Container Name">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Container</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.container; else noReplies" class="tdMenge"
                                        mat-cell> {{element.container.name}} </td>
                                </ng-container>
                                <ng-template #noReplies>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Container Tara">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Container <br>Tara [t]</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.container; else noContainerTara" class="tdMenge"
                                        mat-cell> {{element.tare_weight_container| number: '1.0-3':'de'}} </td>
                                </ng-container>
                                <ng-template #noContainerTara>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Waage">
                                <th *matHeaderCellDef class="thScale" mat-header-cell>Waage</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.scale; else noScale" class="tdMenge"
                                        mat-cell> {{element.scale.name}} </td>
                                </ng-container>
                                <ng-template #noScale>
                                    <td>-</td>
                                </ng-template>
                            </ng-container>
                            <ng-container matColumnDef="Brutto">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Brutto <br> [t]</th>
                                <td *matCellDef="let element" class="tdMenge" mat-cell>
                                    {{getBruttoWeight(element.net_weight, element.tare_weight_mover, element.tare_weight_container)| number: '1.3-3':'de'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Netto">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Netto <br> [t]</th>
                                <td *matCellDef="let element" class="tdMenge" mat-cell>
                                    {{element.net_weight| number: '1.3-3':'de'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Username">
                                <th *matHeaderCellDef class="thOffen" mat-header-cell>Benutzer</th>
                                <ng-container *matCellDef="let element">
                                    <td *ngIf="element.username; else noUser" class="tdMenge"
                                        mat-cell> {{element.username}} </td>
                                </ng-container>
                                <ng-template #noUser>
                                    <td>-</td>
                                </ng-template>

                            </ng-container>
                            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






