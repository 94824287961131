import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataShareService} from '../../Services/data-share.service';
import {APIService} from '../../Services/api.service';

@Component({
    selector: 'app-show-battery-status',
    templateUrl: './show-battery-status.component.html',
    styleUrls: ['./show-battery-status.component.scss']
})
export class ShowBatteryStatusComponent implements OnInit, OnDestroy {
    private subscription: any;
    public selectedContainer = {
        name: '',
    };
    public batteryPercentage: number;
    constructor(public DialogRef: MatDialogRef<ShowBatteryStatusComponent>, public apiService: APIService,
                @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataShareService, ) {
    }

    lastScaleWeighing: any;

    ngOnInit(): void {
        this.selectedContainer = {name: ''};
        this.subscription = this.dataService.lastScaleWeighing.subscribe(lastScaleWeighing => {
            console.log(lastScaleWeighing);
            if (lastScaleWeighing.containerid !== null) {
                this.apiService.getAvailableContainers().subscribe((container: any) => {
                    if (container) {
                        if (container.length > 0) {
                            this.selectedContainer = container.find(ex => ex.id === this.lastScaleWeighing.containerid);
                        }
                    }
                    else {
                        this.selectedContainer = {name: ''};
                    }
                });
            }
            else {
                this.selectedContainer = {name: ''};
            }
            this.lastScaleWeighing = lastScaleWeighing;
            this.batteryPercentage = Math.round(99.086 * this.lastScaleWeighing.voltage - 316.58);
            if (this.batteryPercentage < 23) {
                this.batteryPercentage = 0;
            }
            if (this.batteryPercentage > 100) {
                this.batteryPercentage = 100;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public Confirm() {
        this.DialogRef.close(true);
    }

}
