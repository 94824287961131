import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './Components/login/login.component';
import {HomePageComponent} from './Components/home-page/base-home-page/home-page.component';
import {RessourceViewComponent} from './Components/ressource-view/ressource-view.component';
import {CwCalenderComponent} from './Components/cw-calender/cw-calender.component';
import {ResourcetypeViewComponent} from './Components/resourcetype-view/resourcetype-view.component';
import {TreatmentBookViewComponent} from './Components/treatment-book-view/treatment-book-view.component';
import {TreatmentBookComponent} from './Components/treatment-book/treatment-book.component';
import {MoverViewComponent} from './Components/mover-view/mover-view.component';
import {VERSION} from '../environments/version';
import {StatementForwardingComponent} from './Components/statement-forwarding/statement-forwarding.component';
import {LoginFailedComponent} from './Components/login-failed/login-failed.component';
import {ContainerViewComponent} from './Components/container-view/container-view.component';
import {WeighingFilterComponent} from './Components/weighing-filter/weighing-filter.component';
import {WeekReportComponent} from './Components/week-report/week-report.component';
import {ExecutionScreenBaseComponent} from './Components/execution-screen/execution-screen-base/execution-screen-base.component';
import {environment} from '../environments/environment';
import {MssProcessComponent} from './Components/process/mss-process/mss-process.component';
import {VsaProcessComponent} from './Components/process/vsa-process/vsa-process.component';
import {EnvironmentTypes} from './Enums/environment-types';
import {NewBatchComponent} from './Components/batch-new/base-batch-new/new-batch.component';
import {BatchViewComponent} from './Components/batch-view/base-batch-view/batch-view.component';
import {ProcessViewComponent} from './Components/process-view/base-process-view/process-view.component';
import {ResourceUsageComponent} from './Components/resource-usage/resource-usage.component';
import {SettingsPageComponent} from './Components/settings-page/settings-page.component';

const routes: Routes = [
    {
        path: 'login-failed',
        component: LoginFailedComponent,

    },
    {
        path: 'home',
        component: HomePageComponent,

    },
    {
        // Needed for hash routing
        path: 'code',
        component: LoginComponent
    },
    {
        path: '',
        component: LoginComponent,

    },
    {
        path: 'resource-view',
        component: RessourceViewComponent,
    },
    {
        path: 'process',
        component: ProcessViewComponent,
    },
    {
        path: 'process/config',
        component: environment.tenant === EnvironmentTypes.VSA ? VsaProcessComponent : MssProcessComponent,
    },
    {
        path: 'batches',
        component: BatchViewComponent,
    },
    {
        path: 'batches/config',
        component: NewBatchComponent,
    },
    {
        path: 'batches/config/:id',
        component: NewBatchComponent,
    },
    {
        path: 'calendar',
        component: CwCalenderComponent,
    },
    {
        path: 'resourcetypes',
        component: ResourcetypeViewComponent,
    },
    {
        path: 'execution/:id',
        component: ExecutionScreenBaseComponent,
    },
    {
        path: 'execution',
        component: ExecutionScreenBaseComponent,
    },
    {
        path: 'calculation',
        component: TreatmentBookViewComponent,
    },
    {
        path: 'calculation-book',
        component: TreatmentBookComponent,
    },
    {
        path: 'mover-view',
        component: MoverViewComponent,
    },
    {
        path: 'weighing-filter',
        component: WeighingFilterComponent,
    },
    {
        path: 'container-view',
        component: ContainerViewComponent,
    },
    {
        path: 'week-report',
        component: WeekReportComponent,
    },
    {
        path: 'statement/:token',
        component: StatementForwardingComponent,
    },
    {
        path: 'version',
        data: VERSION,
        redirectTo: 'home'
    },
    {
        path: 'mssexecution/:id',
        component: ExecutionScreenBaseComponent,
    },
    {
        path: 'mssexecution/',
        component: ExecutionScreenBaseComponent,
    },
    {
        path: 'resource-usage',
        component: ResourceUsageComponent,
    },
    {
        path: 'settings',
        component: SettingsPageComponent,
    }
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal


@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        // Don't perform initial navigation in iframes
        initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled' // Remove this line to use Angular Universal
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
