import {Component, OnDestroy, OnInit} from '@angular/core';
import {APIService} from '../../Services/api.service';
import {Router} from '@angular/router';
import {DataShareService} from '../../Services/data-share.service';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {AccessTypes} from '../../Enums/access-types';
import {AccessAppAreas} from '../../Enums/access-app-areas';
import {UserAccessService} from '../../Services/user-access.service';
import {GlobalFunctionsService} from '../../Services/global-functions';
import {environment} from "../../../environments/environment";
import {EnvironmentTypes} from "../../Enums/environment-types";
moment.locale('de');

moment.locale('de');

@Component({
    selector: 'app-execution-screen-vsa-book-view',
    templateUrl: './treatment-book-view.component.html',
    styleUrls: ['./treatment-book-view.component.scss']
})
export class TreatmentBookViewComponent implements OnInit, OnDestroy {

    tenant = environment.tenant;
    typeEnvironmentMss = EnvironmentTypes.MSS;

    public batches: any[];

    public SelectedBatch: any;
    public SearchString: string;
    checked = false;
    flag = false;
    flagShowBtn = true;
    public showNext = false;
    public skip = 0;
    public limit = 100;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;
    toggle() {
        this.skip = 0;
        if (!this.checked && !this.flag) {

            this.dataService.TreatmentToSelectInBook = null;
            this.updateTreatmentList(this.skip, this.limit, true);
            this.flag = true;
            this.checked = true;
            this.flagShowBtn = false;
            this.SelectedBatch = null;
            return;
        }
        if (!this.checked) {
            this.dataService.TreatmentToSelectInBook = null;
            this.updateTreatmentList(this.skip, this.limit, true);
            this.SelectedBatch = null;
        }
        else {
            this.dataService.TreatmentToSelectInBook = null;
            this.updateTreatmentList(this.skip, this.limit, false);
            this.SelectedBatch = null;
        }
        this.SelectedBatch = null;
        this.checked = !this.checked;
        this.flagShowBtn = !this.flagShowBtn;
    }

    constructor(public apiService: APIService,
                public router: Router,
                public dataService: DataShareService,
                public functionsService: GlobalFunctionsService,
                public dialog: MatDialog,
                public userAccess: UserAccessService) {
    }

    ngOnInit(): void {

        if (this.apiService.versioningFinished === true) {
            this.updateTreatmentList(this.skip, this.limit);
        } else {
            this.apiService.OnVersioningFinished.subscribe(this.ApiVersionCheckFinished.bind(this));
        }


    }

    ngOnDestroy() {
        this.dataService.TreatmentToSelectInBook = null;
    }

    private ApiVersionCheckFinished() {
        if (this.apiService.versioningFinished === true) {
            this.updateTreatmentList(this.skip, this.limit);
        }
    }

    public paginateRight() {
        this.skip = this.skip + this.limit;
        this.paginate();
    }

    public paginateLeft() {
        this.skip = this.skip - this.limit;
        this.paginate();
    }

    public paginate(){
        this.dataService.TreatmentToSelectInBook = null;
        this.updateTreatmentList(this.skip, this.limit, this.checked);
        this.SelectedBatch = null;
    }

    public updateTreatmentList(skip, limit, showBooked = false) {
        this.apiService.GetExecutionsForBooking(skip, limit, showBooked).subscribe((data: any) => {
            if (data) {
                this.showNext = data.length === limit;
                for (const tr of data) {
                    if (tr.scheduled_start) {
                        tr.scheduled_start = moment.utc(tr.scheduled_start).local();
                    } else {
                        tr.scheduled_start = null;
                    }
                    if (tr.scheduled_end) {
                        tr.scheduled_end = moment.utc(tr.scheduled_end).local();
                    } else {
                        tr.scheduled_end = null;
                    }
                }

                this.batches = data;

                if (this.dataService.TreatmentToSelectInBook) {
                    const tr = this.batches.find(ex => ex.id === this.dataService.TreatmentToSelectInBook.id);
                    if (tr) {
                        this.apiService.getCalculationBatch(tr.id).subscribe((bth: any) => {
                            if (bth) {
                                if (bth.id) {

                                    if (bth.scheduled_start) {
                                        bth.scheduled_start = moment.utc(bth.scheduled_start).local();
                                    }


                                    if (bth.scheduled_end != null) {
                                        bth.scheduled_end = moment.utc(bth.scheduled_end).local();
                                    }


                                    this.SelectedBatch = bth;
                                }
                            }
                        });

                    }
                }


            }
        });
    }

    public selectBatch(batch: any) {
        this.apiService.getCalculationBatch(batch.id).subscribe((data: any) => {
            if (data) {
                console.log(data);
                if (data.id) {

                    if (data.scheduled_start) {
                        data.scheduled_start = moment.utc(data.scheduled_start).local();
                    }


                    if (data.scheduled_end != null) {
                        console.log('has schedule ended');
                        data.scheduled_end = moment.utc(data.scheduled_end).local();
                    }


                    this.SelectedBatch = data;

                }
            }
        });


    }

    public getStartTimeOfBatch(): moment.Moment {
        if (this.SelectedBatch) {
            if (this.SelectedBatch.executions) {
                const executions = this.SelectedBatch.executions.filter(ex => ex.started).sort((a, b) => (a.started < b.started ? -1 : 1));
                if (executions.length > 0) {
                    return moment.utc(executions[0].started).local();
                }
            }
        }
        return null;
    }

    public getEndTimeOfBatch(): moment.Moment {
        if (this.SelectedBatch) {
            if (this.SelectedBatch.executions) {
                const executions = this.SelectedBatch.executions.filter(ex => ex.ended).sort((a, b) => (a.started < b.started ? -1 : 1));
                if (executions.length > 0) {
                    return moment.utc(executions[executions.length - 1].ended).local();
                }
            }
        }
        return null;
    }

    public getRealRuntime(): number {
        if (this.SelectedBatch) {
            if (this.SelectedBatch.execution_time) {
                return (this.SelectedBatch.execution_time / 3600);
            }

        }
        return 0;
    }

    public bookBatch() {

        this.dataService.BatchToBook = this.SelectedBatch;
        this.router.navigate(['/calculation-book']);
    }

    getOutputProducts(): any[] {
        const result = [];

        if (this.SelectedBatch) {
            if (this.SelectedBatch.output_weights) {
                for (const key of Object.keys(this.SelectedBatch.output_weights)) {
                    if (this.SelectedBatch.output_weights[key]) {
                        const wg = this.SelectedBatch.output_weights[key];

                        result.push({
                            id: key,
                            name: wg.name,
                            weight: this.tenant ===  this.typeEnvironmentMss ? wg.weight / 1000 : wg.weight,
                        });
                    }
                }
            }
        }
        return result;

    }

    getOutputMaterialSum() {
        let result;
        let sum = 0;
        result = this.getOutputProducts();
        result.forEach(el => sum += el.weight);
        return sum;
    }


}
